<template>
  <section class="services-intro">
    <div class="container">
     <h1>
        {{ $t('services.title.page.payment.title') }}
        <span class="emphasis-variant">
          {{ $t('services.title.page.payment.crypto') }}
        </span>
      </h1>

      <h2>Como pagar boleto com <span class="emphasis-variant">bitcoin</span>?</h2>

      <p>
        Faça seu
        <a href="#" @click.prevent="openLoginModal" class="text weight-bold">Login</a> com email e senha ou
        <a class="weight-bold" @click="openRegisterModal">Cadastre-se aqui</a>.
      </p>
      <p>
        Selecione no <span class="weight-bold">Menu de Serviços</span> acima o item <span class="weight-bold">Pagamento de Boleto com Bitcoin</span>. Você pode  "+ Adicionar" a
        quantidade de itens que desejar no mesmo pedido ou “- Excluir” um item de serviço.
      </p>
      <p>
        <span class="weight-bold">Preencha todos os campos</span> corretamente: Linha Digitável, data de vencimento, valor de desconto / juros/multa
        (caso aplicável), valor total do boleto.
      </p>
      <p>
        Verifique todos os dados encontrados no <span class="weight-bold">Resumo do Pedido</span>: Cotação Bitcoin recente, a quantidade de itens
        adicionados, o total da taxa de serviço, o valor em Reais e em BTC a ser feito a transação da sua carteira para
        a carteira da PagueComBitcoin.
      </p>
      <p>
        Clique no botão <span class="weight-bold">Finalizar Pedido</span> para ser direcionado à página de <span class="weight-bold">Checkout</span>.
      </p>
      <p>
        Confira as informações do <span class="weight-bold">Valor do Pedido em BTC</span>, Cotação Bitcoin e Valor em
        Real. O <span class="weight-bold">Valor Confirmado</span> BTC será atualizado após confirmação.
      </p>

      <p>
        Para garantirmos o valor da cotação, você deve <span class="weight-bold">realizar a transação de bitcoins em até
        <span class="emphasis-variant">{{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos</span></span> por meio das seguintes opções:
      </p>
      <ul class="simple-list">
        <li>Copiar e colar  o código para realizar o envio;</li>
        <li>Clicar no botão Abrir Carteira, caso tiver uma carteira bitcoin instalada no mesmo dispositivo;</li>
        <li>Realizar a leitura do QRCode pela carteira instalada no seu dispositivo móvel.</li>
      </ul>

      <p>
        Após realizar a transação do Valor do Pedido em BTC da sua carteira para a carteira da PagueComBitcoin, você
        será comunicado por e-mail com o processamento e confirmação do pagamento.
      </p>

      <p class="weight-bold">
        Você também pode acompanhar o status do seu
        <router-link class="weight-bold emphasis-variant" :to="{ name: 'checkout-last' }">último pedido</router-link>
        pelo link ou atalho da área de usuário logada.
      </p>
      <p class="weight-bold">
        Mais informações sobre Como pagar boleto com bitcoins em Dúvidas Frequentes - FAQ.
      </p>

      <h2>Tipos de boleto</h2>

      <p>
        Na PagueComBitcoin, você pode pagar seus boletos de forma simples e rápida com todo suporte e segurança.
      </p>

      <p>
        Você pode pagar com bitcoins <span class="weight-bold">boletos de cobrança</span> (cartão de crédito, compras
        online, financiamentos); <span class="weight-bold">boletos de consumo</span> (água, luz, telefone, gás,
        condomínio, etc)  e boletos de recolhimento de taxas e impostos como DAS, DARF, etc. Guias de recolhimento de
        imposto infelizmente não são suportados pela nossa plataforma.
      </p>

      <p>
        <span class="text-underline">Nossa plataforma não realiza validação da linha digitável de boletos</span> de
        consumo (água, luz, telefone, gás, condomínio, etc) ou guias de pagamento de taxas e tributo, ou seja, boletos
        que iniciam com número 8 na linha digitável. Portanto, atenção no preenchimento dos campos e dados para evitar
        erros de qualquer natureza.
      </p>

      <p>
        Lembramos que após a emissão de qualquer boleto, há um <span class="weight-bold">prazo para efetivação do
        processo de registro</span> na instituição bancária. Caso haja uma tentativa de pagamento antes da conclusão
        do processo de registro, o banco pode retornar uma mensagem de ‘título inválido’, ‘registro de título não
        encontrado’ ou algo semelhante. Indicamos aguardar algumas horas para o boleto estar disponível para pagamento.
      </p>

      <h2>Prazos e Valores</h2>

      <p>
        <span class="weight-bold">O valor mínimo para pagamento de boletos é de
        <span class="emphasis-variant">R$ {{ (settings.restriction && settings.restriction.minBilletValue ? settings.restriction.minBilletValue : 0) | currency }}</span></span>. Não existe
        limite para valor ou quantidade de boletos, ou seja, <span class="text-underline">você pode pagar todos seus
        boletos em um ÚNICO PEDIDO</span>.
      </p>

      <p>
        Caso necessário <span class="weight-bold">desconto ou juros/mora do valor</span>, preencha os dados exatos em
        cada campo específico. É fundamental informar os valores corretamente para evitar qualquer erro na operação.
      </p>
      <p>
        Após realizar a transação de bitcoins e confirmação, seu boleto será processado em alguns minutos. Pedidos
        finalizados após às 20h ou aos finais de semana e feriados serão processados somente no próximo dia útil. O
        prazo médio de compensação bancária é de <span class="weight-bold">01 a 03 dias úteis</span> dependendo do
        emissor. Em caso de dúvidas, verifique com a instituição bancária emissora do boleto.
      </p>
      <p>
        Cobramos uma <span class="weight-bold">taxa de conveniência de R$ {{ (settings.itemFees && settings.itemFees.billet ? settings.itemFees.billet : 0) | currency }}</span> para cada item de pagamento de boleto.  Em caso de necessidade de
        estorno, será realizado a transferência em bitcoins ou em reais dependendo do status do pedido com desconto da
        taxa de transferência. Mais em Taxas e Informações.
      </p>
      <p>
        Caso desejar comprovante bancário de pagamento do boleto, solicite pelo e-mail
        <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a> com o Assunto: Comprovante Bancário e
        informe o Código do Pedido (ID) encontrado na página de checkout.
      </p>

    </div>
    <ct-user-login-modal
      :isModalVisible="isModalLoginVisible"
      @close="closeLoginModal"
      @successLogin="closeLoginModal"
    />
  </section>
</template>

<script>
import CtUserLoginModal from '../Navbar/UserLoginModal';
import { mapActions, mapState } from 'vuex';
import currency from './../../filters/currency';

export default {
  name: 'ServicesIntroBillet',
  components: {
    CtUserLoginModal,
  },
  filters: {
    currency,
  },
  data () {
    return {
      isModalLoginVisible: false,
    };
  },
  methods: {
    ...mapActions(['openRegisterModal']),
    openLoginModal () {
      this.isModalLoginVisible = true;
    },
    closeLoginModal () {
      this.isModalLoginVisible = false;
    },
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.settings,
    }),
  },
};
</script>

<style lang="scss" scoped>

</style>
