<template>
  <svg version="1.1" class="pix-icon" :class="{type: true}" :style="{ height: size }" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 970.8 970.6" xml:space="preserve">
    <g>
      <path d="M913.4613,586.861958 L803.401605,696.565256 C800.978016,695.597167 798.356584,694.992112 795.586768,694.992112 L745.550232,694.992112 C719.677638,694.992112 694.353611,684.535855 676.075527,666.303514 L532.683621,523.375941 C519.2707,510.001972 501.640105,503.306024 484.023,503.306024 C466.392405,503.306024 448.7753,510.001972 435.357882,523.362496 L291.430898,666.823413 C273.148317,685.055755 247.828787,695.512011 221.956193,695.512011 L160.426721,695.512011 C157.805289,695.512011 155.350225,696.130513 153.034551,697 L42.5386996,586.861958 C-14.1795665,530.331839 -14.1795665,438.672643 42.5386996,382.13356 L153.030055,272 C155.345728,272.869487 157.805289,273.487989 160.426721,273.487989 L221.956193,273.487989 C247.828787,273.487989 273.148317,283.944245 291.430898,302.176587 L435.344393,445.624059 C461.356377,471.529401 506.698615,471.542847 532.683621,445.610613 L676.075527,302.700968 C694.353611,284.464145 719.677638,274.007888 745.550232,274.007888 L795.586768,274.007888 C798.36108,274.007888 800.978016,273.407314 803.406101,272.439226 L913.4613,382.13356 C970.179567,438.672643 970.179567,530.331839 913.4613,586.861958" id="path1002"></path>
      <path d="M221.245453,751.853052 C258.747215,751.853052 294.014695,737.243424 320.529367,710.731154 L464.413495,566.788771 C474.775705,556.423984 491.696903,556.379017 502.086087,566.802261 L645.444236,710.209542 C671.963404,736.721813 707.230884,751.326944 744.732646,751.326944 L762,751.326944 L579.912807,933.459407 C523.206115,990.180198 431.267907,990.180198 374.561215,933.459407 L193,751.853052 L221.245453,751.853052 Z" id="path998"></path>
      <path d="M744.728423,223.733521 C707.227254,223.733521 671.960331,238.277504 645.446078,264.678774 L502.085698,407.472208 C492.024848,417.52493 474.479047,417.493585 464.418197,407.472208 L320.536343,264.154868 C294.017595,237.758076 258.750672,223.214093 221.249502,223.214093 L193,223.214093 L374.562842,42.3591267 C431.268638,-14.1197089 523.205393,-14.1197089 579.911189,42.3591267 L762,223.733521 L744.728423,223.733521 Z" id="path994"></path>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '27px',
    },
  },
};
</script>

<style lang="scss">
  @import "../../../themes/variables";

  .pix-icon {
    fill: $spotlight-color;

    &.light {
      fill: $slate-grey-color;
    }
  }
</style>
