<template>
<div class="category-index">
  <div class="row">

    <!-- Serviços -->
    <div class="col-xs-12 col-md-6 col-lg-3">
      <ct-anchor anchor="servicos">
        <ct-category-item
        title="Serviços"
        >
        <template slot="icon">
          <ct-sevices-btc-icon />
        </template>

        <template slot="category-list">
          <ul>
            <li>
              <ct-anchor anchor="servicos-pagamento">
                <span>Serviços de Pagamento</span>
              </ct-anchor>
            </li>
            <li>
              <ct-anchor anchor="pagamento-boleto" v-if="enabledBilletService">
                <span>Pagamento de Boleto</span>
              </ct-anchor>
            </li>
            <li>
              <ct-anchor anchor="conversao-de-bitcoins" v-if="enabledShipmentService">
                <span>Conversão de Bitcoin</span>
              </ct-anchor>
            </li>
            <li>
              <ct-anchor anchor="bitcoin-por-pix" v-if="enabledPixService">
                <span>Bitcoin por Pix</span>
              </ct-anchor>
            </li>
            <li>
              <ct-anchor anchor="comprar-gift-card" v-if="enabledGiftcardService">
                <span>Comprar Gift Card</span>
              </ct-anchor>
            </li>
            <li>
              <ct-anchor anchor="recarga-celular" v-if="enabledRechargeService">
                <span>Recarga de Celular</span>
              </ct-anchor>
            </li>
            <li>
              <ct-anchor anchor="gateway-de-pagamento">
                <span>Gateway de Pagamento</span>
              </ct-anchor>
            </li>
          </ul>
        </template>

        </ct-category-item>
      </ct-anchor>
    </div>

    <!-- Suporte -->
    <div class="col-xs-12 col-md-6 col-lg-3">
      <ct-anchor anchor="suporte">
        <ct-category-item
        title="Suporte"
        >
          <template slot="icon">
            <ct-support-icon />
          </template>

          <template slot="category-list">
          <ul>
            <li>
              <ct-anchor anchor="cadastro">
                <span>Cadastro e senha</span>
              </ct-anchor>
            </li>
            <li>
              <ct-anchor anchor="meus-pedidos">
                <span>Meus Pedidos</span>
              </ct-anchor>
            </li>
            <li>
              <ct-anchor anchor="pagamentos">
                <span>Pagamento e comprovantes</span>
              </ct-anchor>
            </li>
            <li>
              <ct-anchor anchor="cotacao">
                <span>Cotação BTC</span>
              </ct-anchor>
            </li>
          </ul>
        </template>

        </ct-category-item>
      </ct-anchor>
    </div>

    <!-- PagueComBitcoin -->
    <div class="col-xs-12 col-md-6 col-lg-3">
      <ct-anchor anchor="paguecombitcoin">
        <ct-category-item
        title="PagueComBitcoin"
        >

          <template slot="icon">
            <ct-about-icon/>
          </template>

          <template slot="category-list">
          <ul>
            <li>
              <ct-anchor anchor="aplicativo-android-e-ios">
                <span>Aplicativo Android e IOS</span>
              </ct-anchor>
            </li>
            <li>
              <ct-anchor anchor="cotacao-btc">
                <span>Cotação BTC</span>
              </ct-anchor>
            </li>
            <li>
              <ct-anchor anchor="sobre-nos">
                <span>Sobre Nós</span>
              </ct-anchor>
            </li>
          </ul>
        </template>

        </ct-category-item>
      </ct-anchor>
    </div>

    <!-- Informações Gerais -->
    <div class="col-xs-12 col-md-6 col-lg-3">
      <ct-anchor anchor="informacoes">
        <ct-category-item
        title="Informações Gerais"
        >

          <template slot="icon">
            <ct-info-icon />
          </template>

          <template slot="category-list">
          <ul>
            <li>
              <ct-anchor anchor="bitcoins">
                <span>Bitcoins e Criptomoedas</span>
              </ct-anchor>
            </li>
            <li>
              <ct-anchor anchor="receita-federal">
                <span>Receita Federal</span>
              </ct-anchor>
            </li>
            <li>
              <ct-anchor anchor="seguranca">
                <span>Segurança e fraude</span>
              </ct-anchor>
            </li>
          </ul>
        </template>

        </ct-category-item>
      </ct-anchor>
    </div>

  </div>
</div>
</template>

<script>
import CtCategoryItem from '@/components/Shared/IndexBar/CategoryItem';
import CtSevicesBtcIcon from '@/components/Shared/Icons/ServicesBtcIcon';
import CtSupportIcon from '@/components/Shared/Icons/SupportIcon';
import CtAboutIcon from '@/components/Shared/Icons/AboutIcon';
import CtInfoIcon from '@/components/Shared/Icons/InfoIcon';
import CtAnchor from '@/components/Shared/Anchor';
import { mapGetters } from 'vuex';

export default {
  name: 'CategoryIndex',
  components: {
    CtSevicesBtcIcon,
    CtCategoryItem,
    CtSupportIcon,
    CtAboutIcon,
    CtInfoIcon,
    CtAnchor,
  },
  computed: {
    ...mapGetters([
      'enabledPixService',
      'enabledShipmentService',
      'enabledRechargeService',
      'enabledBilletService',
      'enabledGiftcardService',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import '@/themes/variables';

.category-index{
  margin: 60px 0;
  .brand{
    color: $spotlight-color;
  }

  #about-icon{
    max-height: 37px;
  }

  #info-icon{
    max-height: 35px;
  }
}
</style>
