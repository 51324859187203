<template>
  <div class="main-menu-component">
    <div class="main-menu">
      <div class="brand">
        <router-link :to="{ name: 'home' }">
          Pague <br /> com <br /> Bitcoin.
        </router-link>
      </div>

      <div class="menu-icon-mobile" @click="open">
        <span :class="{ 'open': isMenuOpen || isMenuOpenHover }" class="icon icon-hamburguer"></span>
        Menu
      </div>

      <ul class="menu" :class="{'open': isMenuOpen || isMenuOpenHover }" @mouseleave="hiddenMenu">
        <li>
          <p class="quotation">
            <small>
              Cotação
            </small>
            R$ {{ (quotation.BTCBRL && quotation.BTCBRL.buy ? quotation.BTCBRL.buy : 0) | currency }}
          </p>
        </li>
        <li>
          <router-link :to="{ name: 'home' }">{{ $t('pages.home') }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'services' }">{{ $t('pages.services') }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'about' }">{{ $t('pages.about') }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'faq' }">{{ $t('pages.faq') }}</router-link>
        </li>
        <li class="item-mobile">
          <a @click="openReferralModal">Indique e ganhe</a>
        </li>
        <li class="item-mobile item-mobile-md">
          <a @click="openContactModal">{{ $t('pages.contact') }}</a>
        </li>
        <li class="item-mobile" v-if="isLogged">
          <router-link :to="{ name: 'checkout-last' }" class="btn btn-action-secundary">Último pedido</router-link>
        </li>
        <li class="item-mobile-btn" v-if="!isLogged">
          <a class="btn btn--primary" @click="openRegisterModal">Cadastre-se</a>
        </li>
        <li class="item-mobile-btn">
          <a class="btn btn--secondary" v-if="!isLogged" @click="showModal">Login</a>
          <a class="btn btn--secondary" v-if="isLogged"  @click="showModal">Logout</a>
        </li>
      </ul>
    </div>
    <ul class="menu">
      <li class="menu-item" v-if="enabledBilletService">
        <router-link :to="{ name: 'service-billet' }"
          alt="Pagamento de Boleto"
          title="Pagamento de Boleto"
        >
          <ct-billet-icon class="icon billet-icon" />
          <span class="item-text">Pagamento <br /> de Boleto</span>
        </router-link>
      </li>
      <li class="menu-item" v-if="enabledShipmentService">
        <router-link :to="{ name: 'service-shipment' }"
          alt="Conversão de Bitcoin"
          title="Conversão de Bitcoin"
        >
          <ct-btc-icon class="icon btc-icon" />
          <span class="item-text">Conversão <br /> de Bitcoin</span>
        </router-link>
      </li>
      <li class="menu-item" v-if="enabledPixService">
        <router-link :to="{ name: 'service-pix' }"
          alt="Bitcoin por PIX"
          title="Bitcoin por PIX"
        >
          <ct-pix-icon class="icon btc-icon" />
          <span class="item-text">Bitcoin <br/>por PIX </span>
        </router-link>
      </li>
      <li class="menu-item" v-if="enabledGiftcardService">
        <router-link :to="{ name: 'service-gift-card' }"
          alt="Gift Card"
          title="Gift Card"
        >
          <ct-gift-card-icon class="icon giftcard-icon" />
          <span class="item-text">Gift <br /> Card</span>
        </router-link>
      </li>
      <li class="menu-item menu-item-cell-phone" v-if="enabledRechargeService">
        <router-link :to="{ name: 'service-cell-phone' }"
          alt="Recarga de Celular"
          title="Recarga de Celular"
        >
          <ct-cellphone-icon class="icon recharge-icon" />
          <span class="item-text">Recarga <br /> de Celular</span>
        </router-link>
      </li>
    </ul>
    <ct-user-login-modal
      :isModalVisible="isModalVisible"
      @close="closeLoginModal"
      @successLogin="closeLoginModal"
    />
    <ct-referral-modal
      v-if="isReferralModalOpen"
      @close="closeReferralModal"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import CtUserLoginModal from './UserLoginModal';
import CtReferralModal from './ReferralModal';
import currency from './../../filters/currency';
import CtBilletIcon from '@/components/Shared/Icons/BilletIcon';
import CtBtcIcon from '@/components/Shared/Icons/BtcIcon';
import CtGiftCardIcon from '@/components/Shared/Icons/GiftCardIcon';
import CtPixIcon from '@/components/Shared/Icons/PixIcon';
import CtCellphoneIcon from '@/components/Shared/Icons/CellphoneIcon';

export default {
  name: 'MainMenu',
  data () {
    return {
      isMenuOpen: false,
      isMenuOpenHover: false,
      lastScrollPx: 0,
      showMenu: true,
      isModalVisible: false,
    };
  },
  filters: {
    currency,
  },
  components: {
    CtUserLoginModal,
    CtReferralModal,
    CtBilletIcon,
    CtBtcIcon,
    CtGiftCardIcon,
    CtCellphoneIcon,
    CtPixIcon,
  },
  computed: {
    ...mapState({
      user: state => state.auth.user || {},
      quotation: state => state.settings.ticker,
      isReferralModalOpen: state => state.isReferralModalOpen,
    }),
    ...mapGetters([
      'isLogged',
      'enabledPixService',
      'enabledShipmentService',
      'enabledRechargeService',
      'enabledBilletService',
      'enabledGiftcardService',
    ]),
  },
  methods: {
    ...mapActions(['openRegisterModal', 'openContactModal', 'openReferralModal', 'closeReferralModal']),
    open () {
      this.isMenuOpen = !this.isMenuOpen;
      if (!this.isMenuOpen) {
        this.hiddenMenu();
      }
    },
    openMenu () {
      this.isMenuOpenHover = true;
    },
    hiddenMenu () {
      this.isMenuOpenHover = false;
      this.isMenuOpen = false;
    },
    handleScroll () {
      if (window.scrollY === 0) {
        this.showMenu = true;
      } else if (this.lastScrollPx > 0) {
        this.showMenu = (window.scrollY < this.lastScrollPx);
      } else {
        this.showMenu = false;
      }

      this.lastScrollPx = window.scrollY;
      if (!this.showMenu) {
        this.hiddenMenu();
      }
    },
    showModal () {
      this.isModalVisible = true;
    },
    closeLoginModal () {
      this.isModalVisible = false;
    },
  },
  watch: {
    '$route' () {
      this.hiddenMenu();
      this.lastScrollPx = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.main-menu-component {
  display: flex;
  justify-content: flex-start;

  @media print {
    display: none;
  }

  @media only screen and (max-width: $grid-md-max) {
    width: 100%;
  }

  .menu {
    display: flex;
    padding: 0px 0 0;
    list-style: none;

    @media only screen and (max-width: $grid-md-max) {
      display: none;
    }

    .menu-item {
      align-self: center;
      display: flex;
      min-width: 100px;

      &.menu-item-cell-phone {
        @media only screen and (min-width: $grid-md-max) {
          min-width: 123px;
        }
      }

      a {
        text-decoration: none;
        display: flex;
        color: $white-color;
        padding: 15px 15px;
        border-bottom: 5px solid transparent;
        @media only screen and (max-width: $grid-md-max) {
          padding: 15px 15px;
        }

        &:hover{
          background-color: #001425;
        }

        &.router-link-active {
          border-bottom: 5px solid #f15a22;
        }

        .item-text {
          color: $white-color;
          margin-left: 8px;
        }

        .icon {
          fill: $white-color;
          min-height: 27px;
          color: $white-color;
          min-width: 19px;
          align-self: center;
          background-repeat: no-repeat;

          &.icon-btc-menu {
            min-width: 35px;
          }

          &.giftcard-icon {
            margin-top: 5px;
          }
        }
      }
    }
  }

  .main-menu {
    width: 92px;
    @media only screen and (max-width: $grid-md-max) {
      width: 100%;
      max-height: 62px;
      display: flex;
      align-items: center;
      justify-content: space-between
    }

    .brand {
      padding: 10px 0px 0px;
      @media only screen and (max-width: $grid-md-max) {
        max-width: 80px;
        padding: 10px 12px;
      }

      a {
        color: $primary-color;
        text-decoration: none;
        line-height: 1;
        text-align: left;
        font: {
          family: 'Oswald', 'OpenSans', sans-serif;
          size: 16px;
        }
        @media only screen and (max-width: $grid-md-max) {
          font-size: 14px;
          line-height: 1;
        }
      }
    }

    .menu-icon {
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      height: 28px;
      font-size: 15px;
      line-height: 1;
      color: $white-color;
      display: flex;
      justify-content: center;
      width: 100%;
      @media only screen and (max-width: $grid-md-max) {
        display: none
      }

      .icon {
        height: 17px;
        width: 25px;
        display: flex;
        background: $white-color;
        margin-right: 10px;

        &.icon-hamburguer {
          mask: url("/assets/img/icons/icon-menu.svg") no-repeat center;
          mask-size: 100% 100%;
        }
      }
    }

    .menu-icon-mobile {
      display: none;
      @media only screen and (max-width: $grid-md-max) {
        -webkit-tap-highlight-color: transparent;
        font-size: 15px;
        color: $white-color;
        width: 92px;
        height: 100%;
        @include flex-center()
      }
      @media only screen and (max-width: $grid-xs-max) {
        font-size: 16px
      }

      .icon {
        background: $white-color;
        display: flex;
        height: 12px;
        width: 20px;
        margin-right: 5px;

        &.icon-hamburguer {
          mask: url("/assets/img/icons/icon-menu.svg") no-repeat center;
          mask-size: 100% 100%;

          &.open {
            mask: url("/assets/img/icons/close-button.svg") no-repeat center;
            mask-size: 100% 100%;
          }
        }
      }
    }

    ul {
      &.menu {
        list-style: none;
        min-width: 151px;
        margin: 0px;
        padding: 0px;
        display: none;
        background: $spotlight-color;
        @media only screen and (max-width: $grid-md-max) {
          width: 100%;
          position: absolute;
          top: 98px;
        }
        @media only screen and (max-width: $grid-sm-max) {
          top: 61px;
        }
        &:active,
        &.open {
          left: 0;
          display: block;
        }

        li {
          width: 100%;
          border-top: 1px solid #003e72;
          background: $spotlight-color;
          height: 46px;
          font-size: 13px;
          @media only screen and (max-width: $grid-md-max) {
            padding: 0 104px
          }
          @media only screen and (max-width: $grid-xs-max) {
            padding: 0 78px;
            max-height: 34px
          }
          .quotation {
            font-family: Oswald;
            color: $white-color;
            display: block;
            margin: 0 auto;
            text-align: center;
            border-bottom: 1px solid #003e72;
            height: 100%;
            line-height: 34px;

            @media only screen and (max-width: $grid-xs-max) {
              font-size: 12px
            }
            small {
              font-size: 14px;
              color: $primary-color;
              @media only screen and (max-width: $grid-xs-max) {
                font-size: 10px
              }
            }
          }
          &:nth-child(1) {
            border-top: none;
          }

          &:hover {
            background: $white-color;
            @media only screen and (max-width: $grid-md-max) {
              background: none;
            }
          }
          &.item-mobile {
            display: none;
            a {
              cursor: pointer;
            }
            @media screen and (max-width: $grid-md-max){
              display: block;
            }
          }
          &.item-mobile-md {
            @media screen and (max-width: $grid-md-max){
              display: block;
            }
          }
          &.item-mobile-btn {
            display: none;
            @media screen and (max-width: $grid-md-max) {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 8px 0;

              &:last-child {
                margin-bottom: 10px
              }

            }

            @media only screen and (max-width: $grid-xs-max) {
              margin: 5px 0
            }

            a {
              max-height: 42px;
              max-width: 218px;
              @media only screen and (max-width: $grid-xs-max) {
                max-width: 164px;
                max-height: 32px
              }
            }
          }

          @media only screen and (max-width: $grid-md-max) {
            border-top: none;
          }

          a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 20px;
            color: $white-color;
            text-decoration: none;

            &.btn-action-secundary{
              color: $primary-color;
            }

            &:hover {
              color: $spotlight-color;
              @media only screen and (max-width: $grid-md-max) {
                color: $white-color
              }
            }
            @media only screen and (max-width: $grid-md-max) {
              justify-content: center;
              padding: 0px;
              border-bottom: 1px solid #003e72;
              margin: 0 auto;
              font-weight: 400
            }
            @media only screen and (max-width: $grid-xs-max) {
              font-size: 14px
            }
          }
        }
      }
    }
  }
}
</style>
