<template>
  <div class="register-duplicate-component">
    <h1>{{ $t('register.steps.duplicate.title') }}</h1>
    <p>
      {{ $t('register.steps.duplicate.contact') }}
      <br>
      <a class="btn-mailto" href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>
    </p>
    <h2>{{ $t('register.steps.duplicate.loginTitle') }}</h2>

    <div class="form-group">
      <input
        :class="['form-control form-control-sm', {'is-invalid' : errors.has('email')}]"
        type="text"
        placeholder="e-mail"
        name="email"
        v-model="user.email"
        v-validate="'required|email'"
      />
      <span class="msg-error">{{ errors.first('email') }}</span>
    </div>

    <div class="form-group">
      <input
        :class="['form-control form-control-sm', {'is-invalid' : errors.has('senha')}]"
        type="password"
        placeholder="Senha"
        name="password"
        v-model="user.password"
        v-validate="'required'"
        data-vv-name="senha"
      />
      <span class="msg-error">{{ errors.first('senha') }}</span>
    </div>

    <div class="form-action">
      <a href="#" @click="openResetPasswordModal(); closeRegisterModal();" class="btn-link">Esqueceu a senha?</a>
      <button @click.prevent @click="login" class="btn btn--primary">Login</button>
    </div>

    <div class="form-group" v-if="hasError">
      <span class="msg-error-default">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'RegisterDuplicate',
  data: () => ({
    user: {},
    errorMessage: '',
    hasError: false,
  }),
  methods: {
    ...mapActions(['loginUserToken', 'closeRegisterModal', 'openResetPasswordModal']),
    async login () {
      try {
        const isValid = await this.$validator.validate();
        if (isValid) {
          await this.loginUserToken(this.user);
          this.user = {};
          this.closeRegisterModal();
        }
      } catch (err) {
        this.errorMessage = err.message;
        if (err.response && err.response.status === 401) {
          this.errorMessage = 'E-mail e/ou senha inválidos. Tente novamente.';
        }

        this.hasError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.register-duplicate-component {
  padding: 35px 40px 30px;
  @media screen and (max-width: $grid-xs-max) {
    padding: 0px;
  }

  h1, h2, p, .btn-mailto {
    color: $tertiary-color;
    line-height: 1;
  }
  h1 {
    font-size: 35px;
    font-weight: 400;
    margin-bottom: 30px;
    @media screen and (max-width: $grid-xs-max) {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  h2 {
    font-size: 19px;
    font-weight: 400;
    margin-bottom: 25px;
    text-align: center;
    @media screen and (max-width: $grid-xs-max) {
      font-size: 10px;
      margin-bottom: 15px;
    }
  }
  p {
    margin-bottom: 30px;
    @media screen and (max-width: $grid-xs-max) {
      margin-bottom: 20px;
    }
  }

  .form-action {
    max-width: 240px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    margin-right: auto;
    margin-left: auto;
  }
}

</style>
