<template>
  <section class="services-intro">
    <div class="container">
      <h1>
        <span class="emphasis-variant">
          {{ $t('services.title.page.pix.crypto') }}
        </span>
        {{ $t('services.title.page.pix.titleTwo') }}
      </h1>

      <p>
        Chegou o Pix! Faça a Conversão de Bitcoins em Reais e receba em minutos, sem necessidade de uma exchange.
      </p>

      <p style="font-weight: bold">
       Cotação PagueComBitcoin atual: <span class="emphasis-variant">R$ {{ quotationValue | currency }}</span>.
      </p>

      <h2>COMO CONVERTER BITCOINS POR <span class="emphasis-variant">PIX</span>?</h2>

      <p>
        Faça seu
        <a href="#" @click.prevent="openLoginModal" class="text weight-bold">Login</a> com email e senha ou
        <a class="weight-bold" @click="openRegisterModal">Cadastre-se aqui</a>.
      </p>

      <p>
        Selecione no <strong>Menu de Serviços</strong> acima o item Bitcoin por Pix. Você pode "+ Adicionar" a quantidade de itens que desejar no mesmo pedido ou “- Excluir” um item de serviço.
      </p>

      <p>
        <strong>Preencha todos os campos</strong> corretamente com os dados da sua <strong>chave pix</strong> para realizar a transferência em reais.
      </p>

      <p>
        No caso de <strong>Transferência para Conta de Terceiros</strong>, é necessário informar corretamente o Nome Completo ou Razão Social do Titular da Conta, CPF/ CNPJ do titular, um email válido do terceiro, selecionar a finalidade da operação e <strong>chave pix da conta a ser enviada a transferência</strong>.
      </p>

      <p>
        Verifique as informações do <strong>Resumo do Pedido</strong>: Cotação BTC recente, a quantidade de itens adicionados, o total da taxa de serviço, o valor total em Reais e o valor total em BTC a ser pago e clique em <strong>Finalizar Pedido</strong> para ser direcionado à página de <strong>Checkout</strong>.
      </p>

      <p>
        Na página de Checkout / pagamento, confira as informações do <strong>Valor do Pedido em BTC</strong>, Cotação Bitcoin e Valor em Real. O <strong>Valor Confirmado</strong> BTC será atualizado após confirmação.
      </p>

      <p>
        Para garantirmos o valor da cotação, você deve <span class="weight-bold">realizar a transação de bitcoins em
        até <span class="emphasis-variant">{{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos</span></span> por meio das
        seguintes opções:
      </p>
      <ul class="simple-list">
        <li><span class="weight-bold">Copiar e colar  o código</span> para realizar o envio;</li>
        <li>Clicar no botão <span class="weight-bold">Abrir Carteira</span>, caso tiver uma carteira bitcoin instalada no mesmo dispositivo;</li>
        <li>Realizar a <span class="weight-bold"> leitura do QRCode</span> pela carteira instalada no seu dispositivo móvel. </li>
      </ul>

      <p>
        Após realizar a transação do <span class="weight-bold">Valor do Pedido em BTC</span> da sua carteira para a
        carteira da PagueComBitcoin, você será comunicado por e-mail com o processamento e confirmação do pagamento.
      </p>
      <p class="weight-bold">
        Você também pode acompanhar o status do seu
        <router-link class="weight-bold emphasis-variant" :to="{ name: 'checkout-last' }">último pedido</router-link>
        pelo link ou atalho da área de usuário logada.
      </p>

      <p class="weight-bold">
        Verifique seu e-mail e acompanhe as mensagens de cada etapa do seu pedido.
      </p>

      <h2>
        Prazos e valores
      </h2>

      <p>
        O valor mínimo para Conversão de Bitcoins por Pix é de
        <span class="weight-bold emphasis-variant">R$ {{ (settings.restriction && settings.restriction.minPixValue ? settings.restriction.minPixValue : 0) | currency }}</span>
        e máximo de
        <span class="weight-bold emphasis-variant">R$ {{ (settings.restriction && settings.restriction.maxPixValue ? settings.restriction.maxPixValue : 0) | currency }}</span>.
        O limite para conversão de bitcoins é compartilhado entre movimentações Pix e TED, sendo diário de
        <span class="weight-bold emphasis-variant">R$ {{ (settings.restriction && settings.restriction.maxShipmentDailyLimitValue ? settings.restriction.maxShipmentDailyLimitValue : 0) | currency }}</span>,
        totalizando até
        <span class="weight-bold emphasis-variant">R$ {{ (settings.restriction && settings.restriction.maxShipmentMonthlyLimitValue ? settings.restriction.maxShipmentMonthlyLimitValue : 0) | currency }}</span>
        mensais. Para operações em grandes volumes, consulte nosso serviço exclusivo de Mesa OTC  pelo email
        <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>.
      </p>

      <p>
        Assim que a transação de bitcoins for confirmada, a transferências por Pix serárealizada automaticamente.
        Atenção: nesta primeira fase Beta, a conclusão da transação poderá ocorrer em até 1 dias útil.
      </p>

      <p>
        Cobramos uma
        <span class="weight-bold">taxa de conveniência de
          <span class="emphasis-variant">
            R$ {{ (settings.itemFees && settings.itemFees.pix ? settings.itemFees.pix : 0) | currency }}
          </span>
        </span> para cada item de Conversão por Pix, ou seja, cada operação.
        Em caso de necessidade de estorno, será realizado a transferência em bitcoins ou em reais dependendo do status
        do pedido com desconto da taxa de transferência. Mais em
        <router-link :to="{ name: 'taxes' }">Taxas e Informações</router-link>.
      </p>
    </div>

    <ct-user-login-modal
      :isModalVisible="isModalLoginVisible"
      @close="closeLoginModal"
      @successLogin="closeLoginModal"
    />
  </section>
</template>

<script>
import currency from './../../filters/currency';
import { mapActions, mapState } from 'vuex';
import CtUserLoginModal from '../Navbar/UserLoginModal';

export default {
  name: 'ServicesIntroPix',
  filters: {
    currency,
  },
  components: {
    CtUserLoginModal,
  },
  data () {
    return {
      isModalLoginVisible: false,
    };
  },
  methods: {
    ...mapActions(['openRegisterModal']),
    openLoginModal () {
      this.isModalLoginVisible = true;
    },
    closeLoginModal () {
      this.isModalLoginVisible = false;
    },
  },
  computed: {
    ...mapState({
      quotation: state => state.settings.ticker,
      settings: (state) => state.settings.settings,
    }),
    quotationValue () {
      if (!this.quotation || !this.quotation.BTCBRL || !this.quotation.BTCBRL.buy) {
        return 0;
      }

      return this.quotation.BTCBRL.buy;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
