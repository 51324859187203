<template>
<div>
   <section class="services-intro">
    <div class="container">
      <h1 class="text-center">Serviços de pagamento com <span class="emphasis-variant">bitcoin</span></h1>

      <p>
        Com a PagueComBitcoin você pode utilizar seus bitcoins para:
      </p>

      <ul class="simple-list">
        <li><router-link :to="{ name: 'service-billet' }">Pagamento de Boletos com Bitcoins</router-link></li>
        <li><router-link :to="{ name: 'service-shipment' }">Conversão de seus bitcoins em reais</router-link></li>
        <li><router-link :to="{ name: 'service-pix' }">Conversão de seus bitcoins em reais com PIX</router-link></li>
        <li><router-link :to="{ name: 'service-cell-phone' }">Recarga de Celular Pré-pago das principais operadores</router-link></li>
        <li><router-link :to="{ name: 'service-gift-card' }">Comprar Gift Cards (Cartões presente) com Bitcoins</router-link></li>
      </ul>

      <p>
        Para operações em grandes volumes, oferecemos atendimento personalizado com nossos consultores
        especialistas por meio do serviço exclusivo de <span class="weight-bold">Mesa OTC</span>.
        Solicite mais informações pelo email <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>.
      </p>

      <p>
        Dúvidas gerais ou sobre como utilizar nossa plataforma?
      </p>

      <p>
        Acesse nosso <router-link class="weight-bold" :to="{ name: 'faq' }">FAQ - DÚVIDAS FREQUENTES</router-link>
        ou tutorial <router-link class="weight-bold" :to="{ name: 'tutorials-how-to-use' }">COMO USAR NOSSOS SERVIÇOS</router-link>!
      </p>

      <h2>Pagamento de boleto com <span class="emphasis-variant">Bitcoin</span></h2>

      <p>
        Na PagueComBitcoin, você pode pagar seus boletos de forma simples e rápida com todo suporte e segurança.
      </p>
      <p>
        Você pode pagar com bitcoins boletos de cobrança (cartão de crédito, compras online, financiamentos) e
        boletos de consumo, convênio/concessionárias (água, luz, telefone, gás, condomínio, etc) e boletos de
        recolhimento de taxas e impostos como DAS, DARF, etc. Guias de recolhimento de imposto infelizmente não são
        suportados.
      </p>

      <ul class="simple-list">
        <li>
          Valor mínimo do boleto para pagamento:
          <span class="weight-bold emphasis-variant">
            R$ {{ (settings.restriction && settings.restriction.minBilletValue ? settings.restriction.minBilletValue : 0) | currency }}
          </span>
        </li>
        <li>
          Não existe valor máximo ou limite de quantidade de boletos
        </li>
        <li>
          Informe o valor exato de desconto ou juros - se aplicável
        </li>
        <li>
          Aguarde o processo de registro do boleto pela instituição bancária
        </li>
        <li>
          Antes de realizar a transação, inclua o valor da taxa de rede da sua carteira ou exchange
        </li>
      </ul>

      <p>
        Após realizar a transferência de bitcoins e confirmação, seu boleto será processado em alguns minutos. Pedidos
        finalizados após às 20h ou aos finais de semana e feriados serão processados somente no próximo dia útil. O
        prazo médio de compensação bancária é de <span class="weight-bold">01 a 03 dias úteis</span> dependendo do
        emissor. Em caso de dúvidas, verifique com a instituição bancária emissora do boleto.
      </p>

      <p>
        <span class="weight-bold">
          Mais informações em <ct-anchor page="faq" anchor="pagamento-boleto">Como Pagar Boletos com Bitcoins</ct-anchor>.
        </span>
      </p>

      <h2>Conversão de <span class="emphasis-variant">Bitcoins</span> em reais</h2>

      <p>
        <span class="weight-bold">Cotação PagueComBitcoin atual: <span class="emphasis-variant">BTC/BRL R$
          {{ (quotation.BTCBRL && quotation.BTCBRL.buy ? quotation.BTCBRL.buy : 0) | currency }}.</span></span>
      </p>
      <p>
        Deseja vender seus bitcoins em poucos minutos de forma simples, segura e sem burocracia? Faça a Conversão de
        Bitcoins em Reais em apenas 3 passos, sem necessidade de uma exchange.
      </p>

      <ul class="simple-list">
        <li>
          Valor mínimo para conversão de bitcoins:
          <span class="weight-bold emphasis-variant">
            R$ {{ (settings.restriction && settings.restriction.minShipmentValue ? settings.restriction.minShipmentValue : 0) | currency }}
          </span>
        </li>
        <li>
          Valor máximo para cada item:
          <span class="weight-bold emphasis-variant">
            R$ {{ (settings.restriction && settings.restriction.maxShipmentValue ? settings.restriction.maxShipmentValue : 0) | currency }}
          </span>
        </li>
        <li>
          Limites de conversão por mês:
          <span class="weight-bold emphasis-variant">
            R$ {{ (settings.restriction && settings.restriction.maxShipmentMonthlyLimitValue ? settings.restriction.maxShipmentMonthlyLimitValue : 0) | currency }}
          </span>
        </li>
        <li>
          Possível realizar transferências para contas de sua titularidade, de terceiros, PF ou PJ
        </li>
        <li>
          Antes de realizar a transação, inclua o valor da taxa de rede da sua carteira ou exchange
        </li>
      </ul>

      <p>
        Assim que a transação de bitcoins for confirmada, realizamos a transferência bancária para a conta informada
        automaticamente. Pedidos finalizados em dias úteis após às 16h30 ou aos finais de semana e feriados serão
        processadas somente no próximo dia útil devido encerramento do período de processamento bancário.
      </p>

      <p>
        <span class="weight-bold">
          Mais informações em <ct-anchor page="faq" anchor="conversao-de-bitcoins">Como realizar Conversão de Bitcoins em reais</ct-anchor>.
        </span>
      </p>

      <h2>Gift Card com <span class="emphasis-variant">Bitcoins</span></h2>

      <p>
        Na PagueComBitcoin oferecemos diversos gift cards ou cartões presente
        dos principais provedores de streaming e games: Blizzard, Google Play, League of Legends, Level UP, Netflix,
        Razer Gold, Spotify, Steam, Uber, Uber Eats, Xbox Game Pass , Xbox Game Pass Ultimate e Xbox Live.
      </p>

      <ul class="simple-list">
        <li>
          Você pode comprar diversos gift cards em um único pedido
        </li>
        <li>
          Não existe limite máximo de quantidade de gift cards
        </li>
        <li>
          Após pedido finalizado, você receberá um Código PIN para resgate
        </li>
      </ul>

      <p>
        Em caso de indisponibilidade da operadora, algumas solicitações podem sofrer atraso de até 24h até a
        normalização do serviço. Se a compra do Gift Card for um presente para um amigo/familiar, compartilhe
        o código para o destinatário realizar o resgate utilizando sua própria assinatura.
      </p>

      <p>
        <span class="weight-bold">
          Mais informações em <ct-anchor page="faq" anchor="gift-cards">Como Comprar Gift Cards com bitcoins</ct-anchor>.
        </span>
      </p>

      <h2>Recarga de celular com <span class="emphasis-variant">Bitcoin</span></h2>

      <p>
        Recarregue seu celular pré-pago das principais operadoras de telefonia (Claro, Nextel, Oi, Tim, Vivo, etc)
        utilizando seus bitcoins em apenas alguns minutos.
      </p>

      <ul class="simple-list">
        <li>
          Valor mínimo e máximo variam conforme operadora e DDD
        </li>
        <li>
          Não existe limite de quantidade de recarga
        </li>
        <li>
          Possível realizar recargas de celulares de sua titularidade ou de terceiros
        </li>
      </ul>

      <p>
        Após a confirmação da transação, realizamos o processamento da recarga junto à operadora de forma automatizada.
        Assim que a operadora confirmar a operação, sua recarga será realizada e um email de confirmação será enviado.
      </p>

      <p>
        O tempo médio de recarga é de 5 minutos independente de horário e de dia da semana. Em caso de indisponibilidade
        da operadora, algumas solicitações podem sofrer atraso até a normalização do serviço, realizaremos a recarga de
        forma manual em horário comercial das 09h às 20h.
      </p>

      <p>
        <span class="weight-bold">
          Mais informações em <ct-anchor page="faq" anchor="recarga-celular">Como recarregar seu celular pré-pago com bitcoins</ct-anchor>.
        </span>
      </p>
    </div>

  </section>
  <ct-user-login-modal
    :isModalVisible="isModalLoginVisible"
    @close="closeLoginModal"
    @successLogin="closeLoginModal"
  />

</div>
</template>

<script>
import CtUserLoginModal from '../Navbar/UserLoginModal';
import { mapActions, mapState } from 'vuex';
import CtAnchor from '@/components/Shared/Anchor';
import currency from './../../filters/currency';

export default {
  name: 'ServicesIntro',
  filters: {
    currency,
  },
  components: {
    CtUserLoginModal,
    CtAnchor,
  },
  data () {
    return {
      isModalLoginVisible: false,
    };
  },
  methods: {
    ...mapActions(['openRegisterModal']),
    openLoginModal () {
      this.isModalLoginVisible = true;
    },
    closeLoginModal () {
      this.isModalLoginVisible = false;
    },
  },
  computed: {
    ...mapState({
      quotation: state => state.settings.ticker,
      settings: (state) => state.settings.settings,
    }),
  },
};
</script>

<style lang="scss">
@import '../../themes/variables';

.services-intro {
  h1, h2 {
    @media only screen and (max-width: $grid-sm-max) {
      text-align: center;
    }
  }

  .anchor-component {
    display: inline-block;
  }
}
</style>
