<template>
  <section class="services-intro">
    <div class="container">
      <h1>
        {{ $t('services.title.page.giftcard.titleOne') }}
        <span class="emphasis-variant">
          {{ $t('services.title.page.giftcard.crypto') }}
        </span>
      </h1>

      <p>
        O cartão presente ou gift card funciona como uma alternativa aos cartões de crédito para compra de serviços online, de aplicativos, streaming ou games. Pode ser utilizado por clientes assinantes do serviço ou por quem ainda não possui uma assinatura ativa.
      </p>

      <p>
        Na <span class="emphasis-variant">PagueComBitcoin</span>, oferecemos diversos cartões presente dos principais provedores de streaming e games. Você pode comprar cartões presente dos seguintes provedores: Blizzard, Google Play, League of Legends, Level UP, Netflix, Razer Gold, Spotify, Steam, Uber, Uber Eats, Xbox.
      </p>

      <h2>Tipos de <span class="emphasis-variant">Gift Cards</span></h2>

      <p>
        Na PagueComBitcoin, você pode comprar os seguintes cartões presente:
      </p>

      <ul class="simple-list">
        <li>Gift Card Digital Blizzard</li>
        <li>Gift Card Digital Google Play </li>
        <li>Gift Card Digital League of Legends</li>
        <li>Gift Card Digital Level UP</li>
        <li>Gift Card Digital Netflix</li>
        <li>Gift Card Digital Razer Gold</li>
        <li>Gift Card Digital Spotify</li>
        <li>Gift Card Digital Steam</li>
        <li>Gift Card Digital Uber / Uber Eats</li>
        <li>Gift Card Digital Xbox , Xbox Game Pass , Xbox Game Pass Ultimate e Xbox Live</li>
      </ul>

      <h2>Como comprar Gift Card com <span class="emphasis-variant">Bitcoin</span>?</h2>

      <p>
        Siga os passos abaixo para comprar um cartão presente com seus bitcoins:
      </p>

      <ul class="simple-list">
        <li>
          Faça seu
          <a @click.prevent="openLoginModal" class="weight-bold">Login</a> com email e senha ou
          <a class="weight-bold" @click="openRegisterModal">Cadastre-se aqui</a>.
        </li>
        <li>No Menu Principal, selecione o Serviço Gift Card;</li>
        <li>Escolha o provedor e o tipo do item - o valor será incluído automaticamente;</li>
        <li>Caso desejar adicionar outros itens, clique em [+] Adicionar;</li>
        <li>Após adicionar todos os itens, verifique o Resumo do Pedido;</li>
        <li>Clique no botão Finalizar Pedido para ser direcionado à página de Checkout / Pagamento;</li>
        <li>Realize a transação do VALOR DO PEDIDO (valor btc)  da sua carteira para a carteira da PagueComBitcoin em até {{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos;</li>
        <li>Não esqueça de incluir o valor em BTC da taxa de transação da sua carteira caso não seja calculado automaticamente;</li>
        <li>Após a confirmação da transação, realizamos o processamento de todos os itens do seu pedido.</li>
      </ul>

      <p>
        Para garantirmos o valor da cotação, você deve <b>realizar a transação de bitcoins em até <span class="emphasis-variant">{{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos</span></b> por meio das seguintes opções:
      </p>

      <ul class="simple-list">
        <li><b>Copiar e colar o código</b> para realizar o envio;</li>
        <li>Clicar no botão <b>Abrir Carteira</b>, caso tiver uma carteira bitcoin instalada no mesmo dispositivo;</li>
        <li>Realizar a <b>leitura do QRCode</b> pela carteira instalada no seu dispositivo móvel. </li>
      </ul>

      <p>
        Após realizar a transação do <b>Valor do Pedido em BTC</b> da sua carteira para a carteira da PagueComBitcoin, você será comunicado por e-mail com o processamento e confirmação do pagamento.
      </p>

      <p>
        <b>
          Você também pode acompanhar o status do seu
          <router-link :to="{ name: 'checkout-last' }" class="weight-bold">
            Último Pedido
          </router-link>
          pelo link ou atalho da área de usuário logada.
        </b>
      </p>

      <p>
        <b>Verifique seu e-mail e acompanhe as mensagens de cada etapa do seu pedido.</b>
      </p>

      <h2>COMO RESGATAR O GIFT CARD?</h2>

      <p>
        Após a confirmação da transação de bitcoins e o valor de pagamento, será enviado por email um Código PIN (Personal Identification Number), número de identificação, para realizar o resgate. Se a compra do Gift Card for um presente para um amigo/familiar, compartilhe o código para o destinatário realizar o resgate utilizando sua própria assinatura.
      </p>

      <p>
        Para realizar o resgate, siga o passo-a-passo de cada provedor encontrado nos links abaixo.
      </p>

      <p>
        <b>Serviços Online e Streaming</b>
      </p>

      <ul class="simple-list">
        <li><a href="https://play.google.com/intl/pt-BR_br/about/giftcards/" target="_blank" >Google Play</a></li>
        <li><a href="https://www.netflix.com/br-en/redeem" target="_blank" >Netflix</a></li>
        <li><a href="https://support.spotify.com/br/account_payment_help/payment_help/spotify-gift-cards/" target="_blank" >Spotify</a></li>
        <li><a href="https://www.uber.com/br/pt-br/gift-cards/" target="_blank" >Uber / Uber eats</a></li>
      </ul>

      <p>
        <b>Games  / Jogos</b>
      </p>

      <ul class="simple-list">
        <li><a href="https://giftcards.blizzard.com/pt-pt/" target="_blank" >Blizzard</a></li>
        <li><a href="https://levelupgames.uol.com.br/levelup/compre-creditos/seuegiftcard.lhtml" target="_blank" >Levelup</a></li>
        <li><a href="https://support-leagueoflegends.riotgames.com/hc/pt-br/articles/205063264-Comprando-RP-Cart%C3%B5es-Pr%C3%A9-Pagos" target="_blank" >League of Legends</a></li>
        <li><a href="https://gold.razer.com/gold/reload" target="_blank" >Razer Gold</a></li>
        <li><a href="https://store.steampowered.com/account/redeemwalletcode" target="_blank" >Steam</a></li>
        <li><a href="https://support.xbox.com/help/subscriptions-billing/redeem-codes-gifting/redeem-prepaid-codes" target="_blank" >Xbox</a></li>
      </ul>

      <h2>PRAZOS E VALORES</h2>

      <p>
        Os valores disponíveis variam conforme o provedor e tipo de produto. Não existe limite máximo de valor ou quantidade de gift cards, ou seja, você pode comprar quantos cartões presente que desejar em um ÚNICO PEDIDO.
      </p>

      <p>
        Cobramos uma taxa de conveniência de
        <span class="emphasis-variant">R$ {{ (settings.itemFees && settings.itemFees.giftCard ? settings.itemFees.giftCard : 0) | currency }}</span>
        para cada item. Após a confirmação da transação e do valor do pedido, realizamos o processamento da emissão do cartão digital,
         ou seja, do código PIN junto ao provedor em alguns minutos independente do horário e do dia da semana. Em caso
         de indisponibilidade da operadora, algumas solicitações podem sofrer atraso de até 24h até a normalização do serviço.
      </p>
    </div>

    <ct-user-login-modal
      :isModalVisible="isModalLoginVisible"
      @close="closeLoginModal"
      @successLogin="closeLoginModal"
    />
  </section>
</template>

<script>
import currency from './../../filters/currency';
import { mapActions, mapState } from 'vuex';
import CtUserLoginModal from '../Navbar/UserLoginModal';

export default {
  name: 'ServicesIntroShipment',
  filters: {
    currency,
  },
  components: {
    CtUserLoginModal,
  },
  data () {
    return {
      isModalLoginVisible: false,
    };
  },
  methods: {
    ...mapActions(['openRegisterModal']),
    openLoginModal () {
      this.isModalLoginVisible = true;
    },
    closeLoginModal () {
      this.isModalLoginVisible = false;
    },
  },
  computed: {
    ...mapState({
      quotation: state => state.settings.ticker,
      settings: (state) => state.settings.settings,
    }),
  },
};
</script>

<style lang="scss" scoped>

</style>
