<template>
  <div class="navbar-component" v-show="showMenu">
    <div class="container-top">
      <ct-top-navbar-menu />
    </div>
    <div class="container-menu">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-9 col-md-12">
            <ct-main-menu />
          </div>
          <div class="hidden-xs hidden-sm col-lg-3 col-md-3">
            <ct-user-menu />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CtMainMenu from './MainMenu';
import CtUserMenu from './UserMenu';
import CtTopNavbarMenu from './TopNavbarMenu';

export default {
  name: 'Navbar',
  components: {
    CtMainMenu,
    CtUserMenu,
    CtTopNavbarMenu,
  },
  data: () => ({
    showMenu: false,
    routersServices: [
      'services',
      'service-billet',
      'service-cell-phone',
      'service-shipment',
      'service-gift-card',
      'service-pix',
    ],
  }),
  created () {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    hiddenMenu () {
      this.isMenuOpen = false;
    },
    handleScroll () {
      if (this.routersServices.includes(this.$route.name)) {
        this.showMenu = true;
        if (window.scrollY >= 600) {
          this.showMenu = false;
        }

        // if (window.scrollY === 0) {
        //   this.showMenu = true;
        // } else if (this.lastScrollPx > 0) {
        //   this.showMenu = (window.scrollY < this.lastScrollPx);
        // } else {
        //   this.showMenu = false;
        // }

        // this.lastScrollPx = window.scrollY;
        if (!this.showMenu) {
          this.hiddenMenu();
        }
        return;
      }

      this.showMenu = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.navbar-component {
  font-family: "Oswald", sans-serif;
  width: 100%;
  text-transform: uppercase;
  position: fixed;
  z-index: 3;
  top: 0;

  @media only print {
    display: none;
  }

  .container-top {
    @media only screen and (max-width: $grid-md-max) {
      display: none;
    }

    height: 28px;
    background-color: #004c8b;
  }

  .container-menu {
    height: 70px;
    box-shadow: 0px 4.9px 13px -1px rgba(0, 0, 0, 0.35);
    background-color: #003058;
    @media only screen and (max-width: $grid-sm-max) {
      height: 62px;
    }
  }

  .brand {
      padding: 15px;
      @media only screen and (max-width: $grid-sm-max) {
        padding: 10px 12px;
        max-width: 80px
      }

      a {
        color: $primary-color;
        text-decoration: none;
        line-height: 1;
        text-align: left;
        font: {
          size: 19px;
          weight: 400;
        }
        @media only screen and (max-width: $grid-sm-max) {
          font-size: 14px;
          line-height: 1;
        }
      }
    }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 0;
    max-width: 1200px;

  }
}
</style>
