import http from './HttpService';

export default {
  async requestPasswordReset (email) {
    const res = await http().post('/api/v1/users/password/reset/request', { email });
    return res.data;
  },
  async resetPassword (password, token) {
    const res = await http().post('/api/v1/users/password/reset', { password, token });
    return res.data;
  },
};
