<template>
  <section class="badge-app-component">
    <div class="container badge-app-container">
      <div class="row badge-app-row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 content">
          <h1>Baixe já nosso aplicativo</h1>
        </div>
      </div>
      <div class="row row-badgers">
        <ct-ios-badge size="medium" />
        <ct-android-badge size="medium" />
      </div>
    </div>
  </section>
</template>

<script>
import CtAndroidBadge from './AndroidBadge';
import CtIosBadge from './IosBadge';

export default {
  name: 'BadgessApp',
  components: {
    CtAndroidBadge,
    CtIosBadge,
  },
};
</script>

<style lang="scss" scope>
@import '../../themes/variables';
@import "../../themes/mixins/layout";

.badge-app-component {
  margin-top: 106px;
  background-color: $spotlight-color;
  padding-top: 35px;
  padding-bottom: 48px;

  h1 {
    color: $white-color;
    text-align: center;
  }

  .row-badgers {
    display: flex;
    justify-content: center;
  }
}
</style>
