<template>
  <form action="" class="form-reset-password-component">
    <div class="form-group">
      <input
        type="password"
        name="password"
        v-model="reset.password"
        v-validate="{ required: true, min: 6 }"
        :class="['form-control placeholder-bold', { 'is-invalid': errors.has($t('resetPassword.form.newPassword'))}]"
        :data-vv-name="$t('resetPassword.form.newPassword')"
        :placeholder="$t('resetPassword.form.newPassword')"
        ref="senha"
        />
        <span class="msg-error">{{ errors.first($t('resetPassword.form.newPassword')) }}</span>
    </div>
    <div class="form-group">
      <input
        type="password"
        name="passwordConfirm"
        v-model="reset.passwordConfirm"
        :class="['form-control placeholder-bold', { 'is-invalid': errors.has($t('resetPassword.form.confirmedPassword'))}]"
        v-validate="'required|confirmed:senha'"
        :data-vv-name="$t('resetPassword.form.confirmedPassword')"
        :placeholder="$t('resetPassword.form.confirmedPassword')"
        />
        <span class="msg-error">{{ errors.first($t('resetPassword.form.confirmedPassword')) }}</span>
    </div>
   <button type="button" class="btn btn-primary" @click="resetPassword"><b>{{ $t('resetPassword.form.button') }}</b></button>
  </form>
</template>

<script>
export default {
  name: 'FormResetPassword',
  data: () => ({
    reset: {},
  }),
  methods: {
    async resetPassword () {
      const isValid = await this.$validator.validate();
      if (isValid) {
        await this.$emit('resetPassword', { password: this.reset.password });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";

.form-reset-password-component {
  .form-group:not(:first-child) {
    margin-bottom: 10px;

    @media only screen and (max-width: $grid-xs-max) {
      margin-bottom: 0;
    }
  }
}

</style>
