/**
 *
 * @return {String}
 */
const fingerprintDisplay = () => {
  let strDisplay = null;
  const strSep = '|';
  const strOnError = 'Error';

  try {
    const strScreen = window.screen;
    if (strScreen) {
      strDisplay = strScreen.colorDepth + strSep + strScreen.width + strSep + strScreen.height + strSep + strScreen.availWidth + strSep + strScreen.availHeight;
    }
    return strDisplay;
  } catch (err) {
    return strOnError;
  }
};

/**
 *
 * @return {String}
 */
const fingerprintLanguage = () => {
  const strSep = '|';
  const strPair = '=';
  const strOnError = 'Error';
  let strLang = 'lang' + strPair + strSep;

  try {
    const strTypeLng = typeof (navigator.language);
    const strTypeBrLng = typeof (navigator.browserLanguage);
    const strTypeSysLng = typeof (navigator.systemLanguage);
    const strTypeUsrLng = typeof (navigator.userLanguage);

    if (strTypeLng !== 'undefined') {
      strLang = 'lang' + strPair + navigator.language + strSep;
    } else if (strTypeBrLng !== 'undefined') {
      strLang = 'lang' + strPair + navigator.browserLanguage + strSep;
    }

    let strConcat = 'syslang' + strPair + strSep;
    if (strTypeSysLng !== 'undefined') {
      strConcat = 'syslang' + strPair + navigator.systemLanguage + strSep;
    }

    strLang += strConcat;
    strConcat = 'userlang' + strPair;

    if (strTypeUsrLng !== 'undefined') {
      strLang += 'userlang' + strPair + navigator.userLanguage;
    }

    strLang += strConcat;
    return strLang;
  } catch (err) {
    return strOnError;
  }
};

/**
 *
 * @return {String}
 */
const fingerprintTouch = () => {
  try {
    if (document.createEvent('TouchEvent')) {
      return 'true';
    }
    return 'false';
  } catch (ignore) {
    return 'false';
  }
};

/**
 *
 * @return {String}
 */
function fingerprintPlatform () {
  try {
    return navigator.platform.toLowerCase();
  } catch (e) {
    return 'other';
  }
}

/**
 *
 * @return {String}
 */
function fingerprintBrowser () {
  try {
    const strUserAgent = navigator.userAgent.toLowerCase();

    if (/chrome[/\s](\d+\.\d+)/.test(strUserAgent)) {
      return 'chrome';
    }

    if (/version[/\s](\d+\.\d+)/.test(strUserAgent)) {
      return 'firefox';
    }

    if (/version[/\s](\d+\.\d+)/.test(strUserAgent)) {
      return 'safari';
    }

    return 'other';
  } catch (e) {
    return 'other';
  }
}

/**
 *
 * @param {String} string
 * @param {String} prefix
 * @return {string|number}
 */
const hash = (string, prefix) => {
  let hash = 0;
  if (string.length === 0) return hash;
  for (let i = 0; i < string.length; i++) {
    let char = string.charCodeAt(i) * (i + 1 + string.length);
    hash = (((hash << 5) - hash) + char);
    hash = hash & hash;
  }
  if (hash < 0) {
    hash *= -1;
  }

  return prefix + hash.toString(32).toUpperCase();
};

/**
 *
 * @param {String} prefix
 * @return {String}
 */
const getFingerprint = (prefix = 'CT') => {
  const display = fingerprintDisplay();
  const language = fingerprintLanguage();
  const touch = fingerprintTouch();
  const platform = fingerprintPlatform();
  const browser = fingerprintBrowser();

  const result = display + 'x' + language + 'x' + touch + 'x' + platform + 'x' + browser;
  return String(hash(result, prefix));
};

export default getFingerprint;
