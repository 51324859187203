<template>
  <div class="taxes-component">
    <ct-banner :banner="banner" />
    <ct-taxes-content />
  </div>
</template>

<script>
import CtBanner from '@/components/Banner/Banner';
import CtTaxesContent from '@/components/ContentTaxes/TaxesContent';
import config from '@/config';

export default {
  name: 'Taxes',
  data () {
    return {
      banner: {
        textAlt: 'Informações e taxas dos serviços.',
        imgUrl: '/assets/img/banners/banner-taxas.jpg',
        imgUrlMobile: '/assets/img/banners/banner-taxas-mobile.jpg',
      },
      pageInfo: {
        title: 'Informações e taxas dos serviços',
        description: 'Na Pague com Bitcoin, você possui todas as informações livres para consulta.',
      },
    };
  },
  metaInfo () {
    return {
      title: this.pageInfo.title,
      meta: [
        { name: 'description', content: this.pageInfo.description },
        { name: 'og:description', content: this.pageInfo.description },
        { name: 'og:image', content: config.app.url + this.banner.imgUrl },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.pageInfo.title },
        { name: 'twitter:description', content: this.pageInfo.description },
        { name: 'twitter:image', content: config.app.url + this.banner.imgUrl },
      ],
    };
  },
  components: {
    CtBanner,
    CtTaxesContent,
  },
};
</script>

<style>

</style>
