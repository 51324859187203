<template>
  <div class="terms-content-component">

    <div class="container">
      <div class="terms-info">
        <p>
          Estes TERMOS E CONDIÇÕES GERAIS DE USO (“Termos de Uso”) descrevem os termos e as condições aplicáveis
          ao acesso, uso das funcionalidades e contratação dos serviços disponíveis da  PAGUECOMBITCOIN da
          CITAR TECH EIRELI ME (“Plataforma”, “Site” ou “Aplicativo”) pelos Usuários.
        </p>
        <div class="content-box">
          <p>
            AO UTILIZAR OS SERVIÇOS OFERECIDOS PELA CITAR TECH EIRELI ME, O USUÁRIO AUTOMATICAMENTE CONCORDA COM
            TODAS AS REGRAS DESTE TERMOS DE USO E DEMAIS CONDIÇÕES AQUI APRESENTADAS, RESPONSABILIZANDO-SE
            INTEGRALMENTE POR TODOS E QUAISQUER ATOS PRATICADOS AO UTILIZAR NOSSOS SERVIÇOS.
          </p>
          <p>
            CASO O USUÁRIO NÃO CONCORDE COM QUALQUER DAS DISPOSIÇÕES DO PRESENTE TERMOS DE USO, NÃO
            DEVERÁ UTILIZAR OS SERVIÇOS OFERECIDOS.
          </p>
        </div>
      </div>

      <div class="terms-text" id="responsabilidades">
        <p class="terms-title title-3">Das responsabilidades</p>
        <p class="terms-clause">
          A <b class="upper">PagueComBitcoin</b> fornece o serviço de intermediação de pagamentos de boletos bancários, recargas
          de créditos de celulares pré-pagos, conversão direta de bitcoins em reais e venda de gift cards digitais (cartões presente).

          <ul>
            Para a correta prestação dos serviços são estabelecidas como responsabilidades do usuário:
            <li>O correto preenchimento dos campos requeridos nos formulários de cadastro dos serviços, principalmente
              dados pessoais e ao que se refere ao uso de um endereço de e-mail válido para o envio de notificações,
              acompanhamento de pedidos de suporte e ao fornecimento de um número de celular válido e registrado sob o
              CPF ou CNPJ do cliente;
            </li>
            <li>O informe de dados válidos e verdadeiros para a efetivação do pagamento quando requeridos em virtude
              da obrigatoriedade estabelecida pela legislação vigente;
            </li>
            <li>
              Enviar para a PAGUECOMBITCOIN somente criptoativos de atividades lícitas, de carteiras íntegras, sem a
              utilização de mixer ou qualquer outro serviço de branqueamento de transações;
            </li>
            <li>
              Utilizar para o cadastro, número de celular próprio e serviço de e-mail adequados, sendo expressamente
              proibido utilizar serviços de e-mails e números de celulares temporários.
            </li>
            <li>
              O correto preenchimento do DDD, número de celular e operadora para a efetivação da recarga de créditos de
              celulares pré-pagos;
            </li>
            <li>
              Informar contas bancárias registradas sob seu CPF/CNPJ ou de terceiros exclusivamente para serviços de
              conversão de bitcoins em reais;
            </li>
            <li>
              A transferência do valor total e exato especificado no pedido da transação dentro do prazo estipulado no
              pedido, para que a intenção de transação seja reconhecida pela rede cripto ativo escolhido;
            </li>
            <li>
              O cadastro das informações dos pedidos e a transferência de fundos necessários a efetivação da operação em
              prazo que permita o atendimento os limites estabelecidos para a prestação dos serviços;
            </li>
            <li>
              A não manipulação de informações cadastrais necessárias a prestação dos serviços, a cópia, reprodução do
              site ou da plataforma da <b class="upper">PaguecomBitcoin</b> sem prévia autorização;
            </li>
            <li>O não relacionamento da marca ou serviços ofertados pela <b class="upper">PagueComBitcoin</b> com ofertas públicas de
              opções de investimento envolvendo criptoativos ou outros serviços ilegais ou não autorizados pelos órgãos
              reguladores;
            </li>
            <li>
              O domínio do conhecimento básico necessário ao uso de criptoativos, bem como o conhecimento de recursos
              e particularidades do ativo utilizado como meio de pagamento.
            </li>

          </ul>
          <ul>
            São de responsabilidade da PagueComBitcoin:
            <li>
              Pagamentos dos boletos bancários, recargas de créditos de celulares pré-pagos e transferências dos reais das conversões entre Bitcoin e Real, desde que os dados necessários
              para a efetivação da operação sejam válidos e que sigam as condições, prazos e limites estabelecidos nesse
              documento para a sua efetivação;
            </li>
            <li>
              Notificação através do e-mail informado no cadastro do andamento do processamento do pedido e notificação
              de eventuais inconsistências que impeçam a execução das operações;
            </li>
            <li>
              O estorno de valores pagos à <b class="upper">PAGUECOMBITCOIN</b> ou saldo residual em caso de impossibilidade de
              execução de um ou mais de seus itens de serviços, com desconto de taxas;
            </li>
            <li>
              Notificação da realização dos pagamentos, transferências e recargas através do link único para o pedido;
            </li>
            <li>
              Disponibilização dos comprovantes da realização das transferências e pagamentos, caso requerido pelo
              usuário através do suporte, através do e-mail fornecido no ato do cadastro;
            </li>
            <li>
              Suporte ao website, aplicativo ou dúvidas de qualquer natureza serão realizados através
              do e-mail, formulário da plataforma web ou de contato através dos perfis da <b class="upper">PAGUECOMBITCOIN</b>
              nas redes sociais;
            </li>
            <li>
              O atendimento integral a Instrução Normativa RFB nº 1.888 (IN 1888), modificada pela Instrução
              Normativa RFB nº 1.899 (IN 1899).
            </li>
            <li>
              Comunicação ao COAF em caso de operação suspeita, bem como a outros órgãos competentes;
            </li>
            <li>O repasse de comissionamento referente ao programa de indicação conforme regras estabelecidas a seguir
              neste documento.
            </li>
          </ul>
          <ul>
            Não são de responsabilidade da PagueComBitcoin:
            <li>
              Suporte aos usuários do website e aplicativos relativos a assuntos alheios a utilização da própria plataforma da
              <b class="upper">PagueComBitcoin</b>, como por exemplo assuntos relacionados a ecossistemas e carteiras de criptoativos
              e plataformas de exchange, etc;
            </li>
            <li>
              Efetivação das operações contidas no pedido que apresentem inconsistências nos dados informados ou que não
              tenham o volume total de criptoativos necessários a efetivação do pagamento;
            </li>
            <li>
              Recuperação de valores para websites fraudulentos que utilizam de forma
              criminosa o nome ou marca semelhante à <b class="upper">PAGUECOMBITCOIN</b>;
            </li>
            <li>
              Realização das operações cadastradas no pedido nas situações abaixo:
              <ul>
                <li>Que contenham informações cadastrais incorretas;</li>
                <li>Que os valores não foram transferidos na sua totalidade requisitada;</li>
                <li>
                  Em caso de lentidão da rede de criptoativo usado no pagamento que gere excessiva demora na
                  confirmação da transação realizada;
                </li>
                <li>
                  Na identificação de transação realizada pelo cliente para pagamento do pedido após o prazo de
                  expiração definido na plataforma
                </li>
                <li>
                  No cancelamento da transação realizada pelo cliente para o pagamento do pedido em virtude da
                  utilização de taxa de rede baixa ou por tentativa de gasto duplo;
                </li>
                <li>
                  Qualquer outro fator que não seja de expressa responsabilidade e controle da PagueComBitcoin;
                </li>
                <li>
                  Pagamento de boleto bancário com o vencimento no dia da criação do pedido, que seja cadastrado na
                  plataforma próximo ao limite ou após o fim do expediente de processamento eletrônico de pagamentos
                  fixado as 20:00, que force o pagamento do boleto no próximo dia útil;
                </li>
                <li>Pagamento de comissionamento a usuário sob suspeita de fraude ou não observem as regras
                  estabelecidas para o programa de indicação.
                </li>
              </ul>
            </li>
          </ul>
        </p>
      </div>
      <div class="terms-text" id="estorno">
        <p class="terms-title title-3">Da política de estorno</p>
        <p class="terms-clause">
          Os estornos serão realizados em bitcoins, em reais ou por meio de outro serviço disponível de valor
          equivalente, pelo menos uma vez ao dia em dias úteis, a <b class="upper">PagueComBitcoin</b> poderá a seu critério
          estabelecer a realização de lotes de estornos adicionais no decorrer do dia. Somente serão estonados saldos
          que ultrapassarem a taxa de administrativa cobrada em cada serviço
          <ul class="normal-list">
            Os estornos serão feitos através dos seguintes métodos:
            <li>
              Estornos em reais serão feitos através de transferência bancária para conta de própria titularidade do
              usuário com desconto da taxa de serviço;
            </li>
            <li>
              Estornos em criptoativos serão realizados para a carteira de origem do pagamento com desconto de taxa de
              transação de rede equivalente a 0,0005 btcs independente da rede segwit ou bep20
            </li>
            <li>
              Estornos por meio de recarga de celular pré-pago serão realizados para um número de celular indicada
              pelo cliente com desconto de taxa de serviço;
            </li>
            <li>
              Estornos por meio do serviço de gift card serão realizados com envio de código PIN para resgates de
              créditos de um dos fornecedores disponíveis com desconto de taxa de serviço;
            </li>
            <li>
              Estornos de valor parcial serão realizados somente em reais, recargas de celular pré-pago ou gift card, descontadas as taxas de serviço.
            </li>
          </ul>
        </p>
        <p class="terms-clause">
          Fica estabelecido o prazo limite de um dia útil para a realização de estornos após o envio das informações por
          parte do cliente necessárias à sua execução. Toda a comunicação relativa a operações de estorno será feita
          exclusivamente através do e-mail <a class="mailto" href="mailto:info@paguecombitcoin.com" target="_top">
          info@paguecombitcoin.com</a>.
        </p>
        <p class="terms-clause">
          Por razões de segurança o pedido do estorno por parte do cliente sempre deverá ser realizado através do
          endereço do e-mail utilizado no cadastro do pedido alvo do estorno.
        </p>
        <p class="terms-clause">
          Em caso de existência de saldo residual no pagamento do pedido cadastrado pelo cliente, seja por pagamento
          parcial ou pagamento de valor superior ao especificado, o saldo será estornado em reais através de
          transferência bancária para conta que tenha como titular o próprio usuário ou por meio de outro serviço
          disponível da <b class="upper">PagueComBitcoin</b>.
        </p>
        <p class="terms-clause">
          Em caso de impossibilidade do processamento parcial ou total dos itens contidos no pedido por cadastro incorreto de informações ou de inviabilidade por indisponibilidade de serviços de parceiros
          comerciais, de agentes bancários ou ainda por esgotamento do prazo de vencimento, o saldo poderá ser estornado
          em reais através de transferência bancária para conta que tenha como titular o próprio usuário.
        </p>
      </div>
      <div class="terms-text" id="limites-prazos-e-excecoes">
        <p class="terms-title title-3">Dos limites, prazos e exceções</p>
        <p class="terms-clause">
          No momento a plataforma da <b class="upper">PagueComBitcoin</b> não permite a realização de pagamentos de guias
          de tributos, taxas e outros recolhimentos governamentais.
        </p>
        <p class="terms-clause">
          O prazo para execução dos pagamentos de boletos e transferências bancárias é de até 48 horas úteis
          após a confirmação da transação bitcoin para o pagamento do pedido. Em caso de confirmação da transação após o
          término de expediente bancário ou do término da efetivação dos pagamentos através do sistema eletrônico de
          pagamentos, estes serão realizados no próximo dia útil caso não existam impeditivos.
        </p>
        <p class="terms-clause">
          Caso o boleto bancário vença durante o processamento do pedido, a <b class="upper">PagueComBitcoin</b> realizará o estorno
          do valor em reais através de transferência bancária para conta que tenha como titular o próprio usuário ou
          solicitará um novo boleto atualizado.
        </p>
        <p class="terms-clause">
          Pedidos realizados após às 18h nas sextas-feiras, finais de semana, feriados ou fora do período de disponibilidade
          de compensação bancária com itens de serviços de pagamento de boletos ou conversão de bitcoins em reais por
          meio de transferências bancárias serão sempre realizados no próximo dia útil.
        </p>
        <p class="terms-clause">
          Recargas de créditos de celulares pré-pagos e compra de gift card são, em circunstâncias normais,
          realizadas por volta de 5 minutos após a confirmação da transação de pagamento do pedido. A
          <b class="upper">PagueComBitcoin</b> não se responsabiliza por eventuais oscilações que gerem indisponibilidade das redes
          das operadoras de telefonia celular e dos parceiros comerciais conveniados que possam gerar
          atraso na liberação dos créditos.
        </p>
        <p class="terms-clause">
          Após o recebimento dos criptoativos pela plataforma, a <b class="upper">PagueComBitcoin</b> fará a verificação
          da integridade da carteira de origem, podendo não aceitar tal transação e realizar o estorno imediato à
          carteira de origem, retirada a taxa de rede equivalente a 0,0005 btcs independente da rede segwit ou bep20.
        </p>

        <p class="terms-clause">
          No ato da realização do serviço, seja ele pagamento de boletos, transferência bancária e recargas de créditos
          de celulares pré-pagos serão verificadas a integridade do destinatário e da linha no que se refere ao
          envolvimento dos mesmos na execução de crimes e lista de sanções nacionais e internacionais. Em caso de
          positivo os dados usados para a execução passarão a constar em blacklist que levará ao impedimento da
          conclusão do pagamento e do uso do número do celular em todos os serviços ofertados na <b class="upper">PagueComBitcoin</b>.
          Nestes casos, será estornado o valor em reais através de transferência bancária para conta que tenha como
          titular o próprio usuário.
        </p>
        <p class="terms-clause">
          As informações da blacklist serão fornecidas às autoridades, quando requisitadas através do procedimento legal
          cabível.
        </p>
        <p class="terms-clause">
          Não é permitida a realização de integrações ou automações não autorizadas com a plataforma da
          <b class="upper">PagueComBitcoin</b> bem como a utilização informações falsas sobre parcerias e integrações entre
          plataformas.
        </p>
      </div>
      <div class="terms-text" id="programa-de-indicacao">
        <p class="terms-title title-3">Do programa de indicação</p>
        <p class="terms-clause">
          A <b class="upper">PAGUECOMBITCOIN</b> disponibiliza o <b>Programa de Indicação</b> para todos os usuários cadastrados
          em nossa plataforma. Suas regras podem ser atualizadas e/ou o próprio programa pode ser descontinuado
          sem aviso prévio.
        </p>
        <p class="terms-clause">
          Cada cliente, usuário ativo,  terá um link de rastreio único para ser compartilhado para suas indicações
          não cadastradas na <b class="upper">PAGUECOMBITCOIN</b>. É possível encontrar seu link na área logada, na página de checkout ou
          solicitando pelo email info@paguecombitcoin.com.
        </p>
        <p class="terms-clause">
          Após o novo usuário indicado realizar o cadastro pelo link na <b class="upper">PAGUECOMBITCOIN</b>, o cliente será
          comissionado em <b>{{ currentReferralFeeValue }}</b>% sobre o valor de TODOS os pedidos finalizados deste novo usuário.
        </p>
        <p class="terms-clause">
          A comissão será paga até dia 10 de cada mês, quando solicitado pelo cliente, retirada a taxa de conveniência vigente para o serviço
        </p>
        <p class="terms-clause">
          A <b class="upper">PagueComBitcoin</b> poderá a qualquer momento cancelar a participação do usuário no Programa de
          Indicação e cancelar comissões geradas e ainda não pagas quando identificada as seguintes situações:
          <ul class="paragraph-indent">
            <li>Indício de fraude ou tentativa artificial de geração de comissionamento;</li>
            <li>Indício de envolvimento com empresas de Marketing Multinível ou Pirâmides financeiras;</li>
            <li>Situação cadastral indisponível com alertas de sanções em órgãos nacionais e internacionais e/ou Pessoa Politicamente Expostas;</li>
          </ul>
        </p>

        <p class="terms-clause">
          A participação do usuário no Programa de Indicação não dá o direito de uso da marca, poder de representação
          da <b class="upper">PAGUECOMBITCOIN</b> e/ou qualquer vínculo empregatício ou de prestação de serviço.
        </p>
      </div>
      <div class="terms-text" id="politica-de-privacidade">
        <p class="terms-title title-3">POLITICA DE PRIVACIDADE DE DADOS</p>

        <p class="terms-clause">
          A <b class="upper">PagueComBitcoin</b> está comprometida em resguardar a sua privacidade e proteger os seus dados pessoais, por isso, é importante explicar ao usuário quais são os dados tratados pela plataforma.
        </p>
        <p class="terms-clause">
          A legislação Brasileira define como dado pessoal como todo aquele que se refira a uma pessoa física identificada ou identificável.
        </p>
        <p class="terms-clause">
          A Paguecombitcoin coleta dados pessoais para a identificação individual do usuário e para melhor entender o perfil e interesses de seus clientes.
        </p>
        <p class="terms-clause">
          Ao se cadastrar na plataforma, o usuário poderá autorizar o tratamento de seus dados pessoais mediante o consentimento, clicando no botão “ENTENDI”, concordando com a política de privacidade da plataforma.
        </p>
        <p class="terms-clause">
          Ao concordar com a sua política de privacidade, o usuário está dando consentimento para que a <b class="upper">PagueComBitcoin</b> faça o tratamento de seus dados pessoais fornecidos durante o cadastro.
        </p>
        <p class="terms-clause">
          Serão tratados os seguintes dados dos usuários: Coleta, Utilização, Compartilhamento, Acesso e Processamento.
        </p>
        <p class="terms-clause">
          Ao se cadastrar na plataforma, será feita a coleta dos dados pessoais do usuário. A utilização e compartilhamento de dados consistem no uso internode informações para a efetivação dos serviços contratados pelo usuário, assim também o acesso será usado para consultas internas de dados pelos controladores. O Compartilhamento e Processamento estão associados aos dados fornecidos pelo próprio usuário e que são necessários à efetivação do serviço de recargas e de pagamento de boletos.
        </p>
        <p class="terms-clause">
          Consoante sua Política de Compliance, a <b class="upper">PagueComBitcoin</b> poderá solicitar a atualização dos dados pessoais do usuário, que podem compreender: cópia de documentos pessoais e/ou empresariais, documentos comprobatórios de capacidade financeira, selfie, comprovantes de endereço pessoal e/ou fiscal, entre outros documentos que se façam necessários à segurança e integridade do usuário e da plataforma PAGUECOMBITCOIN. O não envio dos documentos solicitados poderá acarretar em bloqueio da conta do usuário.
        </p>
        <p class="terms-clause">
          Para cadastros de menores de idade é necessária a apresentação de instrumento de emancipação ou declaração de autorização dos responsáveis legais com reconhecimento de firma em cartório.
        </p>
        <p class="terms-clause">
          A PAGUECOMBITCOIN se reserva ao direito de solicitar tais dados pessoais e/ou empresariais sempre que entender necessário para o fim exclusivo de comprovar a veracidade das informações prestadas. Circunstâncias em que o usuário tiver ordens de pagamento em aberto, a PAGUECOMBITCOIN tem o prazo de 7 dias úteis para avaliação da documentação apresentada. Decorrido o prazo, a PAGUECOMBITCOIN dará seguimento ao processamento do pedido ou estorno do valor em reais através de transferência bancária para conta que tenha como titular o próprio usuário ou carteira de origem, retiradas as taxas de conveniência e/ou taxa de rede, conforme política de estorno.
        </p>
        <p class="terms-clause">
          A PAGUECOMBITCOIN não faz o tratamento de dados sensíveis, tais como: informações sobre raça ou etnia, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico.
        </p>
        <p class="terms-clause">
          Ao se cadastrar na <b class="upper">PagueComBitcoin</b>, o usuário deverá informar se deseja receber notificações e ofertas personalizadas de acordo com os seus interesses, marcando um checkbox na tela de cadastro.
        </p>
        <p class="terms-clause">
          Se você, usuário, ainda tiver dúvidas sobre a Política de Privacidade da <b class="upper">PagueComBitcoin</b>, contate o nosso suporte para maiores esclarecimentos.
        </p>
        <p class="terms-title title-3">Da privacidade</p>
        <p class="terms-clause">
          O usuário declara-se ciente que em caso de eventual exposição de dados contidos no banco de dados da
          <b class="upper">PagueComBitcoin</b> por ações de terceiros, este ocorrerá de forma ilegal e criminosa, sem qualquer
          envolvimento da PAGUECOMBITOIN nessas ações, ficando assim isenta de qualquer responsabilidade advinda de ocorrência
          dessa natureza.
        </p>
        <p class="terms-clause">
          O usuário também se declara ciente de que os dados ou informações referentes ao uso do site estão sujeitos ao
          Marco Civil da Internet e serão fornecidos em caso de solicitação judicial, por determinação de lei
          superveniente ou por ordem de autoridade competente.
        </p>
        <p class="terms-clause">
          A <b class="upper">PagueComBitcoin</b>, também se reserva o direito de realizar coleta de dados envolvendo exclusivamente o
          uso do site para a melhoria dos serviços e no auxílio da identificação de novas oportunidades de negócios.
        </p>
        <p class="terms-clause">
          A <b class="upper">PagueComBitcoin</b> só compartilha dados estritamente necessários à realização dos serviços e
          exclusivamente à parceiros comerciais qualificados. Salvas essas exceções a <b class="upper">PagueComBitcoin</b> não
          compartilha dados com terceiros.
        </p>
        <p class="terms-clause">
          A <b class="upper">PagueComBitcoin</b> não solicita informações de autenticação de sua plataforma ou de plataformas de
          terceiros e não solicita outras informações pessoais que não sejam estritamente necessárias e diretamente
          relacionadas a efetivação dos pagamentos como forma de garantia da privacidade dos seus usuários.
        </p>
      </div>
      <div class="terms-text" id="seguranca">
        <p class="terms-title title-3">Da segurança</p>
        <p class="terms-clause">
          Todos os pedidos gerados na <b class="upper">PagueComBitcoin</b> possuem URL única permitindo o acompanhamento do processo
          de pagamento em tempo real, possibilitando inclusive a validação da sua autenticação através de contato com
          suporte.
        </p>
        <p class="terms-clause">
          A <b class="upper">PagueComBitcoin</b> não solicita, sob hipótese nenhuma, o acesso remoto aos seus dispositivos, a links
          que não sejam relacionados aos seus parceiros comerciais, da sua própria plataforma ou de exploradores de
          blocos de criptoativos.
        </p>
        <p class="terms-clause">
          Todas as mensagens de e-mails contendo notificações sobre o andamento da execução das operações incluídas no
          seu pedido são sempre encaminhadas como texto puro, sem anexos de qualquer espécie através do endereço de
          e-mail <a class="mailto" href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>.
        </p>
        <p class="terms-clause">
          A única URL oficial da nossa plataforma é o <router-link class="mailto" :to="{ name: 'home' }">
          https://paguecombitcoin.com</router-link>, qualquer outro endereço não explicitado neste documento e que não
          tenha sido amplamente divulgado em nossas redes oficiais devem ser consideradas não autorizadas de nossa
          plataforma feitas por terceiros e consequentemente fora de nosso controle.
        </p>
        <p class="terms-clause">
          Caso você identifique a reprodução de nossa plataforma, o uso de nossa identidade visual, marca ou logotipo
          envie imediatamente um e-mail para <a class="mailto" href="mailto:info@paguecombitcoin.com">
          info@paguecombitcoin.com</a>.
        </p>
      </div>
      <div class="terms-text" id="suporte">
        <p class="terms-title title-3">Suporte</p>
        <p class="terms-clause">
          Todo o suporte prestado pela <b class="upper">PagueComBitcoin</b> é realizado exclusivamente através de texto com o objetivo
          de permitir a indexação e arquivamento dessas informações.
        </p>
        <p class="terms-clause">
          O suporte é realizado entre o período das 9:00 às 20:00 nos dias úteis. Durante os finais de semana a equipe
          de suporte só realiza atendimento a assuntos relacionados à disponibilidade da plataforma e
          emergência relacionadas a algum pedido criado pelo cliente.
        </p>
        <p class="terms-clause">
          Em caso de necessidade, a prestação de informações históricas relativas à execução das operações realizadas
          pelo cliente, só serão informadas após a devida comprovação de identidade do cliente, podendo levar até 5 dias
          úteis. Todo suporte deverá ser realizado através do endereço de e-mail cadastrado na plataforma.
        </p>
        <p class="terms-clause">
          Em caso de dúvidas e comentários nos contate através do e-mail <a href="mailto:info@paguecombitcoin.com">
          info@paguecombitcoin</a> ou nos envie uma mensagem de texto através do site ou dos nossos perfis oficiais nas
          redes sociais.
        </p>
      </div>
      <div class="terms-text" id="foro">
        <p class="terms-title title-3">Do foro de eleição</p>
        <p class="terms-clause">
          Para qualquer disputa ou controvérsia relativa à interpretação ou execução a esses termos e condições de uso,
          que não seja dirimida amigavelmente entre as partes, fica eleito o foro de Niterói – Rio de Janeiro para resolução de qualquer litígio.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import currency from '@/filters/currency';

export default {
  name: 'TermsContent',
  computed: {
    ...mapGetters(['currentReferralFee']),
    currentReferralFeeValue () {
      return this.currentReferralFee ? currency(this.currentReferralFee) : '?';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';
.terms-content-component {
  .container {
    width: 100%;
    max-width: 1200px;
    .terms-info {
      margin: 65px auto;
      line-height: 25px;
      p {
        color: $text-color;
        text-align: left;
      }
      font: {
        family: Roboto, sans-serif;
      }
      @media only screen and (max-width: $grid-xl-max) {
        padding: 0 20px
      }
      @media only screen and (max-width: $grid-xs-max) {
        font-size: 12px;
        line-height: 16px;
        max-width: 100%;
        margin: 15px auto 35px;
      }

      .content-box {
        border: 1px solid #000;
        padding: 30px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
    .terms-text {
      margin-bottom: 30px;

      @media only screen and (max-width: $grid-xl-max) {
        padding: 0 20px
      }

      @media only screen and (max-width: $grid-xs-max) {
        padding: 0 5px
      }

      a {
        font-weight: bold;
        color: $text-color;
        text-decoration: none;

        &:hover {
          text-decoration: underline
        }
      }

      b {
        font-weight: bold;
      }

      .upper {
        text-transform: uppercase;
      }

      &:last-child {
        margin-bottom: 65px
      }
      .terms-clause {
        color: $text-color;
        ul {
          font-weight: bold;
          margin-top: 20px;

          &.normal-list {
            font-weight: 400;

            li {
              &:first-child {
                margin-top: 0
              }
            }
          }

          &.paragraph-indent {
            margin-left: 50px;
          }

          li {
            font-weight: 400;
            margin-left: 15px;
            &:first-child {
              margin-top: 20px
            }

            ul {
              margin-top: 0;
              margin-left: 50px;
              list-style-type: disc;
              li {
                &:first-child {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
