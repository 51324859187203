<template>
  <div class="card card-image-component" :class="{ blue: color === 'blue', yellow: color === 'yellow', right: align }">
    <slot name="img"></slot>
    <div class="card-title title-2">{{ title }}</div>
    <slot name="body"></slot>
    <div class="card-action">
      <slot name="action"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'blue',
    },
    align: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss">
@import '../../themes/variables';
@import "../../themes/mixins/layout";

.card-image-component {
  position: relative;
  width: 100%;
  max-width: 322px;
  min-height: 296px;

  &.right {
    text-align: right;

    .card-action {
      justify-content: flex-end;
      @media only screen and (max-width: $grid-sm-max) {
        justify-content: flex-start
      }
      @media only screen and (max-width: $grid-xs-max) {
        margin: 20px 0 0
      }
    }
  }

  &.blue {
    background: $spotlight-color;
    .card-title {
      color: $primary-color;
    }

    p {
      color: $white-color;
    }

    .card-sub-title {
      color: $primary-color;
    }
  }

  &.yellow {
    background: $primary-color;

    .card-title {
      color: $spotlight-color;
    }

    p {
      color: $white-color;
    }

    .card-sub-title {
      color: $spotlight-color;
    }
  }

  padding: 20px 10px 10px 30px;
  @media only screen and (max-width: $grid-md-max) {
    left: 75px;
  }

  @media only screen and (max-width: $grid-xs-max) {
    max-width: 201px;
    min-height: 0;
    padding-left: 32px;
    right: -8px;
    left: 0;
    margin-bottom: 40px
  }

  .card-title {
    margin: 20px 0 15px 0;
    @media only screen and (max-width: $grid-xs-max) {
      font-size: 22px;
      margin: 10px 0 5px 0
    }
  }

  p {
    text-transform: uppercase;
    margin-bottom: 5px;

    @media only screen and (max-width: $grid-xs-max) {
      font-size: 12px
    }
  }

  .card-sub-title {
    margin-bottom: 15px;
    line-height: 16px;
    font-weight: 500;
    @media only screen and (max-width: $grid-xs-max) {
      font-size: 15px;
      margin-bottom: 8px
    }
  }

  p.title-4 {
    font-weight: 500;
  }

  .card-action {
    margin: 25px 0 20px;
    display: flex;
    @media only screen and (max-width: $grid-xs-max) {
      margin: 20px 0 0
    }
  }
}
</style>
