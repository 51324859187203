<template>
  <div class="about-component">
    <router-link :to="{ name: 'bitcoin' }">
      <ct-banner :banner="banner" />
    </router-link>
    <ct-about-content />
    <ct-newsletter title="Quer receber notícias da pague?"/>
    <ct-depositions :depositions="depositions" />
  </div>
</template>

<script>
import CtBanner from '@/components/Banner/Banner';
import CtAboutContent from '@/components/ContentAbout/AboutContent';
import CtNewsletter from '@/components/ContentHome/Newsletter';
import CtDepositions from '@/components/Depositions/Depositions';
import config from '@/config';
import depositions from '@/config/depositions';

export default {
  name: 'About',
  data () {
    return {
      banner: {
        textAlt: 'Quem  somos, conheça a paguecombitcoin',
        imgUrl: '/assets/img/banners/banner-quem-somos.jpg',
        imgUrlMobile: '/assets/img/banners/banner-quem-somos-mobile.jpg',
      },
      depositions,
      pageInfo: {
        title: 'Conheça a PagueComBitcoin',
        description: 'Pagamento de boletos, PIX, Conversão de bitcoins e recarga de celular em um só lugar. Conheça a pague com bitcoin.',
      },
    };
  },
  metaInfo () {
    return {
      title: this.pageInfo.title,
      meta: [
        { name: 'description', content: this.pageInfo.description },
        { name: 'og:description', content: this.pageInfo.description },
        { name: 'og:image', content: config.app.url + this.banner.imgUrl },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.pageInfo.title },
        { name: 'twitter:description', content: this.pageInfo.description },
        { name: 'twitter:image', content: config.app.url + this.banner.imgUrl },
      ],
    };
  },
  components: {
    CtBanner,
    CtAboutContent,
    CtNewsletter,
    CtDepositions,
  },
};
</script>

<style lang="scss" scoped>

.about-component {}

</style>
