<template>
  <div class="recharge-item" :id="`recharge-form-${this.uuid}`">
    <div>
      <form role="form" class="row">
        <div class="col-lg-4 col-md-3 col-sm-6 col-xs-6">
          <label :for="`ddd${this.uuid}`">
            {{ $t('services.recharge.fields.ddd') }}
          </label>
          <ct-select
            name="ddd"
            :id="`ddd${this.uuid}`"
            class="form-control"
            v-model="phoneRecharge.ddd"
            :placeholder="$t('services.recharge.fields.ddd')"
            v-validate="{ required: true }"
            :reduce="ddd => ddd.value"
            :class="{'is-invalid': errors.has('ddd')}"
            :data-vv-as="$t('services.recharge.fields.ddd')"
            :options="ddds"
            @input="changeDDD"
          />
          <div class="invalid-feedback">{{ errorMessages.ddd || errors.first('ddd') }}</div>
        </div>
        <div class="col-lg-4 col-md-3 col-sm-6 col-xs-6">
          <label :for="`number${this.uuid}`">
            {{ $t('services.recharge.fields.number') }}
          </label>
          <the-mask
            class="form-control"
            :id="`number${this.uuid}`"
            name="number"
            mask="#####-####"
            v-model="phoneRecharge.number"
            :masked="false"
            :placeholder="$t('services.recharge.placeholder.number')"
            v-validate="{ required: true, cellPhone: true }"
            :class="{'is-invalid': errors.has('number')}"
            :data-vv-as="$t('services.recharge.fields.number')"
            @input="changeValue('number')"
          ></the-mask>
          <div class="invalid-feedback">{{ errorMessages.number || errors.first('number') }}</div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 col-xs-6" :class="`operator${this.uuid}`">
          <label :for="`operator${this.uuid}`">
            {{ $t('services.recharge.fields.operator') }}
          </label>
          <ct-select
            name="operator"
            :id="`operator${this.uuid}`"
            class="from-control"
            v-model="phoneRecharge.operator"
            :reduce="item => item.value"
            :placeholder="$t('services.recharge.placeholder.operator')"
            v-validate="{ required: true }"
            :class="{'is-invalid': errors.has('operator')}"
            :data-vv-as="$t('services.recharge.fields.operator')"
            :options="operators"
            @input="changeOperator"
            :disabled="disableOperator"
          >
            <p slot="no-options">
              Operadora não encontrada
            </p>
          </ct-select>
          <div class="invalid-feedback">{{ errorMessages.operator || errors.first('operator') }}</div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 col-xs-6 ml-lg-auto ml-md-auto">
          <label :for="`amount${this.uuid}`">
            {{ $t('services.sell.fields.amount') }}
          </label>
          <ct-select
            name="amount"
            :id="`amount${this.uuid}`"
            class="from-control"
            v-model="phoneRecharge.amount"
            :reduce="item => item.value"
            :placeholder="$t('services.recharge.placeholder.amount')"
            v-validate="{ required: true }"
            :class="{'is-invalid': errors.has('amount')}"
            :data-vv-as="$t('services.recharge.fields.amount')"
            :options="rechargeValues"
            :disabled="disableValue"
            @input="changeValue('amount')"
          >
            <p slot="no-options">
              Nenhum valor disponível
            </p>
          </ct-select>
          <div class="invalid-feedback">{{ errorMessages.amount || errors.first('amount') }}</div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CtSelect from '../Shared/Select';
import { TheMask } from 'vue-the-mask';
import { mapGetters, mapState, mapActions } from 'vuex';

export default {
  name: 'Recharge',
  props: {
    uuid: {
      type: [ String, Number ],
      required: true,
    },
  },
  components: {
    TheMask,
    CtSelect,
  },
  methods: {
    ...mapActions([
      'getOperatorsRechargeForSelect',
      'getAmountsRechargeForSelect',
    ]),
    async changeValue (field) {
      await this.$emit('verifyError', { uuid: this.uuid, field });
    },
    async changeDDD () {
      this.phoneRecharge.operator = null;
      this.phoneRecharge.amount = null;
      await this.getDDDOperators();
      await this.$emit('verifyError', { uuid: this.uuid, field: 'ddd' });
    },
    async changeOperator () {
      this.phoneRecharge.amount = null;
      await this.getOperatorAmounts();
      await this.$emit('verifyError', { uuid: this.uuid, field: 'operator' });
    },
    async getDDDOperators () {
      if (this.phoneRecharge.ddd) {
        await this.getOperatorsRechargeForSelect(this.phoneRecharge.ddd);
      }
    },
    async getOperatorAmounts () {
      if (this.phoneRecharge.operator) {
        await this.getAmountsRechargeForSelect({
          ddd: this.phoneRecharge.ddd,
          operator: this.phoneRecharge.operator,
        });
      }
    },
    async validate () {
      return this.$validator.validate();
    },
  },
  computed: {
    ...mapState({
      operators: (state) => state.select.operatorsRecharge,
      amounts: (state) => state.select.amountsRecharge,
      itemsErrors: (state) => state.services.invoice.itemsErrors,
    }),
    ...mapGetters(['getRechargeByUUID', 'getAllowedDDDs']),
    ddds () {
      return this.getAllowedDDDs;
    },
    rechargeValues () {
      return [...new Set(this.amounts)];
    },
    phoneRecharge () {
      return this.getRechargeByUUID(this.uuid);
    },
    disableOperator () {
      return !(this.phoneRecharge.ddd && this.phoneRecharge.number && this.phoneRecharge.number.length >= 8);
    },
    disableValue () {
      return !(!this.disableOperator && this.phoneRecharge.operator);
    },
    errorMessages () {
      const messages = {
        ddd: '',
        number: '',
        operator: '',
        amount: '',
      };
      for (const row of this.itemsErrors) {
        if (row.uuid.toString() === this.uuid.toString()) {
          messages[row.field] = row.message;
        }
      }
      return messages;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../themes/variables";

.recharge-item {
  .row {
    width: 100%;

    .col-offset {
      width: 40px;
      @media only screen and (max-width: $grid-lg-max) {
        display: none;
      }
    }
    @media only screen and (max-width: $grid-md-min) {
      margin: 0;
      padding: 0;
    }
  }
}

</style>
