<template>
  <section class="newsletter-component">
    <div class="content">
      <h2 class="title">{{ title }}</h2>
      <div class="input-group">
        <input
          name="email"
          id="email-newsletter"
          type="email"
          placeholder="Preencha seu e-mail aqui."
          v-model="data.email"
          required
          @input="inputNewEmail"
          v-validate="{ required: true, email: true }"
          :class="{'is-invalid': errors.has('email')}"
          data-vv-as="e-mail"
        />
        <button type="button" @click="save">
          <span class="icon icon-sent" v-if="!isLoading"></span>
          <span class="fas fa-spinner fa-pulse" v-else></span>
        </button>
      </div>
      <div class="invalid-feedback">{{ errors.first('email') }}</div>
      <div class="alert alert-success" v-if="success">
        E-mail cadastrado com sucesso!
      </div>
      <div class="alert alert-error" v-if="error">
        {{ errorMessage }}
      </div>
    </div>
  </section>
</template>

<script>
import NewsletterService from '../../services/NewsletterService';

export default {
  name: 'Newsletter',
  data () {
    return {
      data: {
        email: '',
      },
      isLoading: false,
      error: false,
      errorMessage: null,
      success: false,
    };
  },
  props: {
    title: {
      type: String,
      default: 'Receba notícias da pague.',
    },
  },
  methods: {
    inputNewEmail () {
      this.success = false;
      this.error = false;
    },
    async save () {
      const isValid = await this.$validator.validate();
      if (isValid) {
        try {
          this.isLoading = true;
          await NewsletterService.create(this.data);
          this.data = {
            email: '',
          };
          this.success = true;
          await this.$validator.reset();
        } catch (error) {
          this.errorMessage = error.response.data.message;
          this.error = true;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.newsletter-component {
  @media print {
    display: none;
  }

  padding: 62px 0;
  @include flex-center();
  background: $spotlight-color;
  @media only screen and (max-width: $grid-xs-max) {
    padding: 36px 0
  }

  .content {
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .title {
      margin: 0 0 24px;
      color: $primary-color;
      font-size: 33px;
      text-align: center;
      @media only screen and (max-width: $grid-xs-max) {
        font-size: 19px;
        margin-bottom: 15px;
      }
    }

    .input-group {
      display: flex;
      border: 4px solid $spotlight-color;
      width: 100%;
      height: 48px;
      max-width: 451px;
      @media only screen and (max-width: $grid-xs-max) {
        max-width: 284px;
        height: 35px;
      }

      input {
        width: 100%;
        height: 100%;
        padding: 0 10px;
        border: none;
        font-size: 17px;
        @media only screen and (max-width: $grid-xs-max) {
          font-size: 10px
        }

        &.is-invalid {
          background-color: #ffecec;
        }

        &::placeholder {
          text-align: center;
          font: {
            family: 'Roboto', sans-serif;
            size: 17px;
            font-weight: 300
          }
          @media only screen and (max-width: $grid-xs-max) {
            font-size: 10px
          }
        }
      }
      button {
        border: none;
        background: $primary-color;
        width: 40px;
        @include flex-center();
        @media only screen and (max-width: $grid-xs-max) {
          width: 26px
        }

        .icon {
          width: 22px;
          height: 22px;
          display: flex;
          background: $spotlight-color;
          @media only screen and (max-width: $grid-xs-max) {
            width: 14px;
            height: 14px;
          }

          &.icon-sent {
            mask: url("/assets/img/icons/icon-sent.svg") no-repeat center;
            mask-size: 100% 100%;
          }
        }

        &:hover,
        &:active {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
