const getState = () => ({
  invoice: {
    billets: [],
    sellBtc: [],
    recharges: [],
    giftCards: [],
    pix: [],
    itemsErrors: [],
    network: 'BTC',
  },
});

export default Object.assign(getState(), { getState: getState });
