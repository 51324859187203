const getParams = (paramsArray) => {
  let params = {};

  for (let i = 0; i < paramsArray.length; ++i) {
    let param = paramsArray[i]
      .split('=', 2);

    if (param.length !== 2) { continue; }

    params[param[0]] = decodeURIComponent(param[1].replace(/\+/g, ' '));
  }

  return params;
};

export const getQueryString = () => {
  return getParams(window.location.search.substr(1).split('&'));
};

export default getQueryString;
