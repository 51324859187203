<template>
  <transition name="modal">
    <div class="modal" @click="$emit('close')">
      <div class="modal-action">
        <slot name="button" @click="$emit('close')"></slot>
      </div>
      <div class="modal-content" @click.stop :style="styleContent">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    styleContent: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  methods: {
    close () {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>

.modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2000;
  background: rgba(0,0,0,0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;

  .modal-action {
    position: relative;
  }

  .modal-content {
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-content,
.modal-leave-active .modal-content{
  transform: scale(1.1);
}

</style>
