<template>
  <transition appear :name="transitionName">
    <div
      class="cookie"
      :class="['cookie__' + type, 'cookie__' + type + '--' + position]"
      v-if="isOpen"
      :id="elementId"
    >
      <div :class="'cookie__' + type + '__wrap'">
        <div
          v-if="showPostponeButton === true"
          @click="postpone"
          :class="'cookie__' + type + '__postpone-button'"
          title="Close"
        >
          <slot name="postponeContent"> &times; </slot>
        </div>

        <div :class="'cookie__' + type + '__content'">
          <div class="row">
            <div class="col-xs-12 col-md-9">
              <slot name="message">
                We use cookies to ensure you get the best experience on our website.
                <a href="https://cookiesandyou.com/" target="_blank"
                  >Learn More...</a
                >
              </slot>
            </div>
            <div  class="col-xs-12 col-md-3 accent-content" >
              <button
                @click="accept"
                :class="[
                  'cookie__' + type + '__buttons__button',
                  'cookie__' + type + '__buttons__button--accept',
                ]"
                >
              <slot name="acceptContent"> Got It! </slot>
            </button>
            </div>
          </div>
        </div>

        <div :class="'cookie__' + type + '__buttons'">
          <button
            v-if="disableDecline === false"
            @click="decline"
            :class="[
              'cookie__' + type + '__buttons__button',
              'cookie__' + type + '__buttons__button--decline',
            ]"
          >
            <slot name="declineContent"> Opt Out </slot>
          </button>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import * as tinyCookie from 'tiny-cookie';

export default {
  name: 'BannerAccept',
  props: {
    elementId: {
      type: String,
      required: true,
    },

    debug: {
      type: Boolean,
      default: false,
    },

    disableDecline: {
      type: Boolean,
      default: false,
    },

    // floating: bottom-left, bottom-right, top-left, top-rigt
    // bar:  bottom, top
    position: {
      type: String,
      default: 'bottom-left',
    },

    // floating, bar
    type: {
      type: String,
      default: 'floating',
    },

    // slideFromBottom, slideFromTop, fade
    transitionName: {
      type: String,
      default: 'slideFromBottom',
    },

    showPostponeButton: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      status: null,
      supportsLocalStorage: true,
      isOpen: false,
    };
  },
  computed: {
    containerPosition () {
      return `cookie--${this.position}`;
    },
    containerType () {
      return `cookie--${this.type}`;
    },
  },
  mounted () {
    this.checkLocalStorageFunctionality();
    this.init();
  },
  methods: {
    init () {
      let visitedType = this.getCookieStatus();
      if (
        visitedType &&
        (visitedType === 'accept' ||
          visitedType === 'decline' ||
          visitedType === 'postpone')
      ) {
        this.isOpen = false;
      }

      if (!visitedType) {
        this.isOpen = true;
      }

      this.status = visitedType;
      this.$emit('status', visitedType);
    },
    checkLocalStorageFunctionality () {
      // Check for availability of localStorage
      try {
        const test = '__check-localStorage';
        window.localStorage.setItem(test, test);
        window.localStorage.removeItem(test);
      } catch (e) {
        console.error(
          'Local storage is not supported, falling back to cookie use',
        );
        this.supportsLocalStorage = false;
      }
    },
    setCookieStatus (type) {
      if (this.supportsLocalStorage) {
        if (type === 'accept') {
          localStorage.setItem(
            `${this.elementId}`,
            'accept',
          );
          localStorage.setItem(
            `${this.elementId}-at`,
            new Date().toISOString(),
          );
        }
        if (type === 'decline') {
          localStorage.setItem(
            `${this.elementId}`,
            'decline',
          );
        }
        if (type === 'postpone') {
          localStorage.setItem(
            `${this.elementId}`,
            'postpone',
          );
        }
      } else {
        if (type === 'accept') {
          tinyCookie.set(
            `${this.elementId}`,
            'accept',
          );
          tinyCookie.set(
            `${this.elementId}-at`,
            new Date().toISOString(),
          );
        }
        if (type === 'decline') {
          tinyCookie.set(
            `${this.elementId}`,
            'decline',
          );
        }
        if (type === 'postpone') {
          tinyCookie.set(
            `${this.elementId}`,
            'postpone',
          );
        }
      }
    },
    getCookieStatus () {
      if (this.supportsLocalStorage) {
        return localStorage.getItem(`${this.elementId}`);
      } else {
        return tinyCookie.get(`${this.elementId}`);
      }
    },
    accept () {
      if (!this.debug) {
        this.setCookieStatus('accept');
      }

      this.status = 'accept';
      this.isOpen = false;
      this.$emit('clicked-accept');
    },
    decline () {
      if (!this.debug) {
        this.setCookieStatus('decline');
      }

      this.status = 'decline';
      this.isOpen = false;
      this.$emit('clicked-decline');
    },
    postpone () {
      if (!this.debug) {
        this.setCookieStatus('postpone');
      }

      this.status = 'postpone';
      this.isOpen = false;
      this.$emit('clicked-postpone');
    },
    removeCookie () {
      localStorage.removeItem(`${this.elementId}`);
      localStorage.removeItem(`${this.elementId}-at`);
      this.status = null;
      this.$emit('removed-cookie');
    },
  },
};
</script>

<style lang="scss">
@import "../../themes/variables";

.cookie {
  &__floating {
    -ms-overflow-style: none;
    position: fixed;
    padding: 10px 20px;
    overflow: hidden;
    z-index: 9999;
    width: 100%;
    background: $spotlight-color;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: $white-color;
    border-top: 1px solid $white-color;
    font-size: 1rem;
    line-height: 1.5;
    bottom: 0px;
    left: 0;
    right: 0;

    &__content {
      padding-top: 5px;
      padding-bottom: 5px;

      .accent-content {
        text-align: right;
        @media (max-width: 790px) {
          margin-top: 20px;
          text-align: center;
        }
      }
    }
    @media (max-width: 790px) {
        margin-bottom: 65px;
        border-bottom: 1px solid $white-color;;
    }
  }
}

// Animations
// slideFromBottom
.slideFromBottom-enter,
.slideFromBottom-leave-to {
  transform: translate(0px, 10em);
}

.slideFromBottom-enter-to,
.slideFromBottom-leave {
  transform: translate(0px, 0px);
}

.slideFromBottom-enter-active {
  transition: transform 0.2s ease-out;
}

.slideFromBottom-leave-active {
  transition: transform 0.2s ease-in;
}

// slideFromTop
.slideFromTop-enter,
.slideFromTop-leave-to {
  transform: translate(0px, -10em);
}

.slideFromTop-enter-to,
.slideFromTop-leave {
  transform: translate(0px, 0px);
}

.slideFromTop-enter-active {
  transition: transform 0.2s ease-out;
}

.slideFromTop-leave-active {
  transition: transform 0.2s ease-in;
}

// fade
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
