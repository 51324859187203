const getState = () => ({
  settings: {
    itemFees: {},
  },
  ticker: {
    BTCBRL: {},
  },
});

export default Object.assign(getState(), { getState: getState });
