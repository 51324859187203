<template>
  <div class="contact-modal-component">
    <ct-modal>
      <div slot="button">
        <div class="btn-close" @click="$emit('close')">
          <span class="icon icon-close"></span>
        </div>
      </div>
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
          <h2 class="modal-header-content">
            <span class="conversation-icon"></span>
            <p>Fale conosco</p>
          </h2>
        </div>
          <ct-form-contact/>
        </div>

      </div>

    </ct-modal>
  </div>
</template>

<script>
import CtModal from '../Shared/Modal';
import CtFormContact from './FormContact';

export default {
  name: 'ContactModal',
  components: {
    CtModal,
    CtFormContact,
  },

};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.contact-modal-component {
  .modal {
    .btn-close {
      background: $tertiary-color;
      position: absolute;
      cursor: pointer;
      z-index: 3000;
      @include flex-center();
      width: 51px;
      height: 51px;
      top: -25px;
      left: 162px;
      @media only screen and (max-width: $grid-xs-max) {
        width: 34px;
        height: 34px;
        top: -17px;
        left: 112px;
      }

      .icon {
        display: flex;
        background: $spotlight-color;
        width: 18px;
        height: 18px;
        @media only screen and (max-width: $grid-xs-max) {
          width: 12px;
          height: 12px;
        }
        &.icon-close {
          mask: url('/assets/img/icons/icon-close.svg')no-repeat center;
          mask-size: 100% 100%;
        }
      }
    }
    .modal-content {
      .card-modal {
        .modal-header{
      top:0;
      cursor: pointer;
      display: flex;

      h2 {
        font-size: 18px;
        font-family: 'Roboto', sans-serif;
        color: $white-color;
        &.modal-header-content{
          width: 100%;
          padding: 10px 0;
          align-items: center;
           .conversation-icon{
              background: url("/assets/img/icons/conversation-ballon.png") no-repeat;
              width: 28px;
              height: 24px;
              display: inline-block;
              margin-right: 10px;
            }

            p {
              display: inline-block;
              height: 100%;
              vertical-align: middle;
              margin-bottom: 0;
            }
        }
      }

    }

        width: 380px;
        background: $spotlight-color;
        padding: 10px;
        padding: 25px 45px;
        @media only screen and (max-width: $grid-xs-max) {
          width: 260px;
          padding: 25px 20px;
        }
      }
    }
  }
}
</style>
