import GiftCardService from '../../../services/GiftCardService';
import RechargeService from '../../../services/RechargeService';

export default {
  async getOperatorsRechargeForSelect ({ commit }, ddd) {
    const operators = await RechargeService.getOperatorsForSelect(ddd);
    commit('SET_OPERATORS_FOR_SELECT', operators.data.map((operator) => {
      return {
        value: operator.id,
        label: operator.label,
      };
    }));
  },
  async getAmountsRechargeForSelect ({ commit }, { ddd, operator }) {
    const response = await RechargeService.getAmountsForSelect(ddd, operator);
    const amounts = response.data || [];
    commit('SET_AMOUNTS_FOR_SELECT', amounts.sort((n1, n2) => {
      if (n1.value > n2.value) return 1;
      if (n1.value < n2.value) return -1;
      return 0;
    }));
  },
  async getOperatorsGiftCardForSelect ({ commit }) {
    const operators = await GiftCardService.getOperatorsForSelect();
    commit('SET_GIFT_CARD_OPERATORS_FOR_SELECT', operators.map((operator) => {
      return {
        value: operator.value,
        label: operator.label,
      };
    }));
  },
  async getAmountsGiftCardForSelect ({ commit }, operator) {
    const amounts = await GiftCardService.getAmountsForSelect(operator);
    commit('SET_GIFT_CARD_AMOUNTS_FOR_SELECT', { operator, amounts });
  },
};
