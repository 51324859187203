<template>
  <div class="sell-btc-item" :id="`btc-form-${this.uuid}`">
    <div>
      <form role="form" class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 check-group">
          <div class="checkbox" :class="{'checked': bitcoinConversion.isThirdParty}">
            <input type="checkbox" :id="`isThirdParty${this.uuid}`" :value="true" v-model="bitcoinConversion.isThirdParty" />
          </div>
          <label :for="`isThirdParty${this.uuid}`">{{ $t('services.sell.fields.isThirdParty') }}</label>

        </div>
        <div :class="{'col-lg-2 col-md-12 col-sm-12 col-xs-12': isCaixa, 'col-lg-4 col-md-12 col-sm-12 col-xs-12': !isCaixa}">
          <div class="form-group">
            <label :for="`bank${this.uuid}`">
              {{ $t('services.sell.fields.bank') }}
            </label>
            <ct-select
              name="bank"
              :id="`bank${this.uuid}`"
              class="from-control"
              v-model="bitcoinConversion.bank"
              :reduce="bank => bank.value"
              :placeholder="$t('services.sell.placeholder.bank')"
              v-validate="{ required: true }"
              :class="{'is-invalid': errors.has('bank')}"
              :data-vv-as="$t('services.sell.fields.bank')"
              :options="banks"
            />
            <div class="invalid-feedback">{{ errorMessages.bank || errors.first('bank') }}</div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 col-xs-6 col-agency">
          <label :for="`agency${this.uuid}`">
            {{ $t('services.sell.fields.agency') }}
          </label>
          <input
            type="text"
            class="form-control"
            :id="`agency${this.uuid}`"
            name="agency"
            v-model="bitcoinConversion.agency"
            :placeholder="$t('services.sell.placeholder.agency')"
            v-validate="{ required: true, regex: /^[0-9\-]+$/ }"
            :readonly="disableAgency"
            @input="changeAgency"
            maxlength="6"
            :class="{'is-invalid': errors.has('agency')}"
            :data-vv-as="$t('services.sell.fields.agency')"
          />
          <div class="invalid-feedback">{{ errorMessages.agency || errors.first('agency') }}</div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 col-xs-6">
          <label class="account" :for="`account${this.uuid}`">
            {{ $t('services.sell.fields.account') }} <small style="font-size: 11px;"> {{ $t('services.sell.fields.accountComplement') }} </small>
          </label>
          <input
            type="text"
            class="form-control"
            :id="`account${this.uuid}`"
            name="account"
            v-model="bitcoinConversion.account"
            :placeholder="$t('services.sell.placeholder.account')"
            v-validate="{ required: true, regex: /^[0-9\-]+$/ }"
            :readonly="disableAccount"
            @input="applyAccountMask(false)"
            @change="changeAccount"
            maxlength="13"
            minlength="3"
            :class="{'is-invalid': errors.has('account')}"
            :data-vv-as="$t('services.sell.fields.account')"
          />
          <div class="invalid-feedback">{{ errorMessages.account || errors.first('account') }}</div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 col-xs-6">
          <div class="form-group">
            <label :for="`kind${this.uuid}`">
              {{ $t('services.sell.fields.kind') }}
            </label>
            <ct-select
              name="kind"
              :id="`kind${this.uuid}`"
              class="from-control"
              v-model="bitcoinConversion.kind"
              :reduce="item => item.value"
              v-validate="{ required: true }"
              :disabled="disableKind"
              :placeholder="$t('services.sell.placeholder.kind')"
              :class="{'is-invalid': errors.has('kind')}"
              :data-vv-as="$t('services.sell.fields.kind')"
              :options="kindsAccount"
            />
            <div class="invalid-feedback">{{ errorMessages.kind || errors.first('kind') }}</div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 col-xs-6" v-if="isCaixa">
          <label :for="`operation${this.uuid}`">
            {{ $t('services.sell.fields.operation') }}
          </label>
          <input
            type="text"
            class="form-control"
            :id="`operation${this.uuid}`"
            name="operation"
            v-model="bitcoinConversion.operation"
            :readonly="disableKind"
            @change="changeOperation"
            :placeholder="$t('services.sell.placeholder.operation')"
          />
          <div class="invalid-feedback">{{ errorMessages.operation || errors.first('operation') }}</div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6 col-xs-6">
          <label :for="`amount${this.uuid}`">
            {{ $t('services.sell.fields.amount') }}
          </label>
          <ct-input-numeric
            :thousand-separator="`.`"
            :decimal-separator="`,`"
            :precision="2"
            autocomplete="off"
            class="form-control"
            :id="`amount${this.uuid}`"
            name="amount"
            :readonly="disableAmount"
            :placeholder="$t('services.sell.placeholder.amount')"
            v-validate="{ required: true, decimal: 2 }"
            :data-vv-as="$t('services.sell.fields.amount')"
            :class="{'is-invalid': errors.has('amount')}"
            v-model="bitcoinConversion.amount"
            @change="changeValue"
          ></ct-input-numeric>
          <div class="invalid-feedback">{{ errorMessages.amount || errors.first('amount') }}</div>
        </div>

        <div class="row third-party-data" v-if="bitcoinConversion.isThirdParty">
          <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label :for="`name${this.uuid}`">
              {{ $t('services.sell.fields.name') }}
            </label>
            <input type="text"
              class="form-control"
              :id="`name${this.uuid}`"
              name="name"
              v-model.trim="bitcoinConversion.name"
              :placeholder="$t('services.sell.placeholder.name')"
              v-validate="{ required: true, fullName: true }"
              :class="{'is-invalid': errors.has('name')}"
              :data-vv-as="$t('services.sell.fields.name')"
              @change="changeName"
            />
            <div class="invalid-feedback">{{ errorMessages.name || errors.first('name') }}</div>
          </div>
          <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12">
            <label :for="`document${this.uuid}`">
              {{ $t('services.sell.fields.document') }}
            </label>
            <the-mask type="text"
              class="form-control"
              :id="`document${this.uuid}`"
              name="document"
              :mask="documentMask"
              :masked="false"
              v-model.trim="bitcoinConversion.document"
              :placeholder="$t('services.sell.placeholder.document')"
              v-validate="{ required: true, cpf: isCpf, cnpj: !isCpf }"
              :class="{'is-invalid': errors.has('document')}"
              :data-vv-as="$t('services.sell.fields.document')"
              @input="changeDocument"
            />
            <div class="invalid-feedback">{{ errorMessages.document || errors.first('document') }}</div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <label :for="`email${this.uuid}`">
              {{ $t('services.sell.fields.email') }}
            </label>
            <input type="text"
              class="form-control"
              :id="`email${this.uuid}`"
              name="email"
              v-model.trim="bitcoinConversion.email"
              :placeholder="$t('services.sell.placeholder.email')"
              v-validate="{ required: true, email: true }"
              :class="{'is-invalid': errors.has('email')}"
              :data-vv-as="$t('services.sell.fields.email')"
              @change="changeEmail"
            />
            <div class="invalid-feedback">{{ errorMessages.email || errors.first('email') }}</div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <label :for="`purpose${this.uuid}`">
              {{ $t('services.sell.fields.purpose') }}
            </label>
            <ct-select
              name="purpose"
              :id="`purpose${this.uuid}`"
              class="from-control"
              v-model="bitcoinConversion.purpose"
              :reduce="purpose => purpose.value"
              :placeholder="$t('services.sell.placeholder.purpose')"
              v-validate="{ required: true }"
              :class="{'is-invalid': errors.has('purpose')}"
              :data-vv-as="$t('services.sell.fields.purpose')"
              :options="purposes"
            />
            <div class="invalid-feedback">{{ errorMessages.purpose || errors.first('purpose') }}</div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CtInputNumeric from '../Shared/InputNumeric';
import CtSelect from '../Shared/Select';
import { mapGetters, mapState, mapActions } from 'vuex';
import { TheMask } from 'vue-the-mask';

export default {
  name: 'SellBitcoinForm',
  props: {
    uuid: {
      type: [ String, Number ],
      required: true,
    },
  },
  components: {
    CtInputNumeric,
    CtSelect,
    TheMask,
  },
  methods: {
    ...mapActions([
    ]),
    async validate () {
      const isValid = await this.$validator.validate();
      return isValid;
    },
    async changeValue () {
      await this.$emit('verifyError', { uuid: this.uuid, field: 'amount' });
    },
    async changeAgency () {
      this.applyAgencyMask();
      await this.$emit('verifyError', { uuid: this.uuid, field: 'agency' });
    },
    async changeAccount () {
      this.applyAccountMask(true);
      await this.$emit('verifyError', { uuid: this.uuid, field: 'account' });
    },
    async changeName () {
      await this.$emit('verifyError', { uuid: this.uuid, field: 'name' });
    },
    async changeDocument () {
      await this.$emit('verifyError', { uuid: this.uuid, field: 'document' });
    },
    async changeEmail () {
      await this.$emit('verifyError', { uuid: this.uuid, field: 'email' });
    },
    applyAgencyMask () {
      const agency = this.bitcoinConversion.agency.replace(/[^\d]/g, '');
      if (agency && agency.length > 4) {
        this.bitcoinConversion.agency = [agency.slice(0, agency.length - 1), '-', agency.slice(agency.length - 1)].join('');
      }
    },
    applyAccountMask (changed = false) {
      const account = this.bitcoinConversion.account.replace(/[^\d]/g, '');
      const position = account.length - 1;
      if ((account.length > 11 || changed) && account.length > 2) {
        this.bitcoinConversion.account = [account.slice(0, position), '-', account.slice(position)].join('');
      }
    },
    changeOperation () {
      const operation = this.bitcoinConversion.operation;
      if (operation && operation.length < 3) {
        this.bitcoinConversion.operation = operation.toString().padStart(3, '0');
      }
    },
  },
  computed: {
    ...mapState({
      kindsAccount: (state) => state.select.kindsAccount,
      invoice: (state) => state.services.invoice,
      bankList: (state) => state.banks.bankList,
      itemsErrors: (state) => state.services.invoice.itemsErrors,
      purposes: (state) => state.select.purposes,
    }),
    ...mapGetters([
      'getSellBtcByUUID',
      'getBanksForSelect',
    ]),
    bitcoinConversion () {
      return this.getSellBtcByUUID(this.uuid);
    },
    isCaixa () {
      return String(this.bitcoinConversion.bank) === '104';
    },
    disableAgency () {
      return !(this.bitcoinConversion.bank);
    },
    disableAccount () {
      return !(this.bitcoinConversion.bank && this.bitcoinConversion.agency);
    },
    disableKind () {
      return !(!this.disableAccount && this.bitcoinConversion.account);
    },
    disableAmount () {
      return !(!this.disableKind && this.bitcoinConversion.kind);
    },
    banks () {
      return this.getBanksForSelect;
    },
    errorMessages () {
      const messages = {
        bank: '',
        agency: '',
        account: '',
        kind: '',
        operation: '',
        amount: '',
      };
      for (const row of this.itemsErrors) {
        if (row.uuid.toString() === this.uuid.toString()) {
          messages[row.field] = row.message;
        }
      }
      return messages;
    },
    documentMask () {
      return ['###.###.###-##', '##.###.###/####-##'];
    },
    isCpf () {
      return this.bitcoinConversion.document && this.bitcoinConversion.document.length <= 11;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";
.sell-btc-item {
  .sell-bitcoin-alert {
      @include flex-center();
      margin: 15px 0;
      padding: 2px 10px;
      background: $very-light-pink;
      text: {
        align: center;
        transform: uppercase
      }

      .alert-title {
        color: $secondary-color;
        font-size: 12px;
      }
      .alert-text {
        font-size: 10px;
        margin-left: 5px;
        a {
          color: $spotlight-color;
          text-decoration: underline;
        }
      }
    }
    .check-group {
      display: flex;
      margin: 5px 0;
      .checkbox {
        width: 25px;
        height: 25px;
        background-color: white;
        border: 1px solid #003058;
        @media only screen and (max-width: $grid-sm-max) {
          width: 18px;
          height: 18px;
        }
        input[type='checkbox'] {
          width: 15px;
          height: 15px;
          opacity: 0;
          &:hover {
            cursor: pointer;
          }
        }
        &.checked {
          background-color: #003058;
          background-image: url('/assets/img/check-icon-white.png');
          background-position: center center;
          background-size: 15px 15px;
          background-repeat: no-repeat;
          @media only screen and (max-width: $grid-sm-max) {
            background-size: 11px 11px;
          }
        }
      }
      label {
        color: $secondary-color;
        text-transform: uppercase;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    @media only screen and (max-width: $grid-md-max) {
      .check-group {
        label {
          margin-top: 0;
        }
      }
    }
    .third-party-data {
      margin: 5px 0 15px;
    }
  }
</style>
