<template>
  <div class="shortcut-menu-component">
    <header :class="{ 'shortcut-menu-alert': !alertIsVisible }">
      {{ $t('shortcutMenu.infoMessage') }}
      <span class="icon-arrow"/>
    </header>
    <nav>
      <ul>
        <li v-if="enabledBilletService">
          <router-link :to="{ name: 'service-billet' }">
            <ct-billet-icon class="icon icon-billet" />
            {{ $t('shortcutMenu.menu.billets') }}
          </router-link>
        </li>
        <li v-if="enabledShipmentService">
          <router-link :to="{ name: 'service-shipment' }">
            <ct-btc-icon class="icon icon-bitcoin" />
            {{ $t('shortcutMenu.menu.bitcoin') }}
          </router-link>
        </li>
        <li style="text-align: center" v-if="enabledPixService">
          <router-link :to="{ name: 'service-pix' }">
            <ct-pix-icon class="icon icon-pix" />
            {{ $t('shortcutMenu.menu.pix') }}
          </router-link>
        </li>
        <li v-if="enabledGiftcardService">
          <router-link :to="{ name: 'service-gift-card' }">
            <ct-gift-card-icon class="icon icon-gift-card" />
            {{ $t('shortcutMenu.menu.giftCard') }}
          </router-link>
        </li>
        <li v-if="enabledRechargeService">
          <router-link :to="{ name: 'service-cell-phone' }">
            <ct-cellphone-icon class="icon icon-recharge" />
            {{ $t('shortcutMenu.menu.recharge') }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import CtBilletIcon from '@/components/Shared/Icons/BilletIcon';
import CtBtcIcon from '@/components/Shared/Icons/BtcIcon';
import CtPixIcon from '@/components/Shared/Icons/PixIcon';
import CtCellphoneIcon from '@/components/Shared/Icons/CellphoneIcon';
import CtGiftCardIcon from '@/components/Shared/Icons/GiftCardIcon';

export default {
  name: 'ShorcutMenu',
  data () {
    return {
    };
  },
  components: {
    CtBilletIcon,
    CtBtcIcon,
    CtCellphoneIcon,
    CtGiftCardIcon,
    CtPixIcon,
  },
  computed: {
    ...mapState({
      alertIsVisible: state => state.dashboard.alertShortcutMenuIsVisible,
    }),
    ...mapGetters([
      'enabledPixService',
      'enabledShipmentService',
      'enabledRechargeService',
      'enabledBilletService',
      'enabledGiftcardService',
    ]),
  },
  methods: {
    ...mapActions(['toggleVisibilityShortcutAlert']),
    handleScroll () {
      const visible = (document.documentElement.scrollTop || document.body.scrollTop) === 0;
      if (!visible && this.alertIsVisible) {
        this.toggleVisibilityShortcutAlert();
        return this.removeScrollListener();
      }

      if (!this.alertIsVisible) {
        this.removeScrollListener();
      }
    },
    removeScrollListener () {
      window.removeEventListener('scroll', this.handleScroll);
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  beforeDestroy () {
    if (this.alertIsVisible) {
      this.removeScrollListener();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";

.shortcut-menu-component {
  display: none;
  @media only screen and (max-width: $grid-md-max) {
    display: block;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
  }

  header {
    @media only screen and (max-width: $grid-md-max) {
      background-color: $primary-color;
      background-image: url("/assets/img/pattern-fixed-menu.png");
      background-repeat: repeat;
      padding: 10px 0;
      color: $spotlight-color;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      font: {
        weight: 500;
        size: 14px;
      }
    }

    &.shortcut-menu-alert {
      display: none;
    }

    span {
      display: flex;
      margin: 5px 2px 0 0;

      &.icon-arrow {
        background: url('/assets/img/icons/icon-arrow.png');
        width: 14px;
        height: 18px;
      }
    }
  }
  nav {
    @media only screen and (max-width: $grid-md-max) {
      background: $spotlight-color;
      padding: 5px 0;
    }
    ul {
      @media only screen and (max-width: $grid-md-max) {
        list-style: none;
        display: flex;
        justify-content: space-around
      }
      li {
        @media only screen and (max-width: $grid-md-max) {

        }
        a {
          @media only screen and (max-width: $grid-md-max) {
            color: $white-color;
            font-size: 10px;
            text: {
              transform: uppercase;
              decoration: none;
            }
          }

          .icon {
            fill: $white-color;
            @media only screen and (max-width: $grid-md-max) {
              display: flex;
              margin: 5px auto;

            }
            &.icon-billet {
              width: 14px;
              height: 20px;
            }
            &.icon-recharge {
              width: 13px;
              height: 21px;
            }
            &.icon-gift-card {
              width: 26px;
              height: 21px;
            }
            &.icon-bitcoin {
              width: 26px;
              height: 19px;
            }
            &.icon-pix {
              width: 26px;
              height: 19px;
            }
          }
        }
      }
    }
  }
}

</style>
