<template>
  <form action="" class="email-reset-password-component">
    <div class="form-group">
      <input
        type="text"
        name="email"
        v-model="reset.email"
        v-validate="{ required: true, email: true }"
        :class="['form-control form-control-lg placeholder-center',
        { 'is-invalid': errors.has($t('resetPassword.emailReset.form.email'))}]"
        :data-vv-name="$t('resetPassword.emailReset.form.email')"
        :placeholder="$t('resetPassword.emailReset.form.email')"
      />
      <span class="msg-error">{{ errors.first($t('resetPassword.emailReset.form.email')) }}</span>
    </div>
    <button type="button" class="btn btn-primary margin-right-auto" @click="sendEmail">
      {{ $t('resetPassword.emailReset.form.button') }}
    </button>
  </form>
</template>

<script>
export default {
  name: 'EmailResetPassword',
  data: () => ({
    reset: {},
  }),
  methods: {
    async sendEmail () {
      const isValid = await this.$validator.validate();
      if (isValid) {
        await this.$emit('sendEmail', { email: this.reset.email });
      }
    },
  },
};
</script>

<style lang="scss">

.email-reset-password-component {
  .form-group {
    .form-control {
      margin-bottom: 3px;
    }
  }
}

</style>
