<template>
  <section class="dead-line">
    <div class="container">
      <h2> PRAZOS E CONFIRMAÇÕES </h2>

      <ul class="simple-list">
        <li>
          Pagamento de Boleto: O boleto é processado automaticamente em dias úteis. Pedidos finalizados após às 20h ou
          aos finais de semana e feriados serão processados somente no próximo dia útil. O prazo médio de compensação
          bancária é de 01 a 03 dias úteis dependendo da instituição bancária emissora do boleto.
        </li>
        <li>
          Conversão de Bitcoins: A transferência bancária é realizada via TED automaticamente em dias úteis. Pedidos
          finalizados após às 16h ou aos finais de semana e feriados serão processados somente no próximo dia útil.
        </li>
        <li>
          Recarga de Celular Pré-pago: A solicitação de recarga é realizada automaticamente em dias úteis. Assim que a
          operadora confirmar a operação, o tempo médio de recarga é de 5 minutos independente de horário e de dia da
          semana. Em caso de indisponibilidade da operadora, algumas solicitações podem sofrer atraso até a normalização
          do serviço, realizaremos a recarga de forma manual em horário comercial das 09h às 20h.
        </li>
        <li>
          Gift Card: Assim que a operadora confirmar a operação, o processamento do Gift Card é realizado
          automaticamente. Em alguns minutos, você receberá por e-mail seu cartão presente digital, ou seja, o código
          PIN para realizar o resgate junto ao fornecedor.
        </li>
      </ul>
      <p>Mais informações acesse nosso FAQ - Dúvidas Frequentes:</p>
      <router-link :to="{ name: 'faq' }" >
        https://paguecombitcoin.com/duvidas-frequentes
      </router-link>

    </div>

  </section>
</template>

<script>

export default {
  name: 'DeadLine',
};
</script>

<style lang="scss" scoped>
a{
  font-family: 'Roboto', sans-serif;
  font-size: 1.3em;
}
</style>
