<template>
  <svg version="1.1" class="giftcard-icon" :class="{type: true}" :style="{height: size}" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 418.3 362.44" style="enable-background:new 0 0 418.3 362.44;" xml:space="preserve">
    <path d="M356.3,59.7l-335.98,57.1c-13.26,2.25-22.22,14.88-19.97,28.14l33.51,197.17c2.25,13.27,14.88,22.22,28.15,19.97
      l335.98-57.1c13.26-2.25,22.22-14.88,19.96-28.15L384.44,79.67C382.19,66.41,369.56,57.45,356.3,59.7z M401.39,279.66
      c0.7,4.13-2.09,8.07-6.22,8.77l-335.98,57.1c-4.13,0.7-8.07-2.09-8.77-6.22L16.91,142.13c-0.7-4.13,2.09-8.06,6.22-8.76l335.98-57.1
      c4.13-0.7,8.06,2.09,8.76,6.22L401.39,279.66z"/>
    <rect x="36.74" y="226.44" transform="matrix(0.9859 -0.1676 0.1676 0.9859 -38.9623 40.1161)" width="363" height="49"/>
    <rect x="86.4" y="203.88" transform="matrix(0.1676 0.9859 -0.9859 0.1676 383.6884 -29.651)" width="246" height="17"/>
    <path class="st0" d="M219.14,99.44c-15.39,2.62-27.96,2.65-28.92,2.65l-10.35-0.02l2.03-10.15c0.17-0.86,4.34-21.43,13.71-42.79
      c13.42-30.58,30.41-47.05,50.48-48.92c17.13-1.6,29.94,5.92,35.15,20.63c7.37,20.79-2.9,51.94-21.13,64.1
      C248.21,92.89,232.6,97.15,219.14,99.44z M201.14,84.54c13.17-1.07,35.33-4.25,49.55-13.74c11.88-7.92,19.36-30.69,14.54-44.28
      c-1.62-4.58-5.21-10.54-17.55-9.38C223.73,19.38,207.82,59.9,201.14,84.54z"/>
    <path class="st0" d="M163.91,108.82c-13.45,2.29-29.6,3.42-43.46-0.14c-21.22-5.45-41.21-31.46-41.12-53.52
      c0.06-15.61,9.67-26.94,26.36-31.09c19.56-4.86,41.03,5.06,63.81,29.49c15.9,17.06,26.63,35.09,27.08,35.85l5.27,8.91l-9.76,3.44
      C191.17,102.08,179.3,106.21,163.91,108.82z M111.44,40.23c-0.56,0.09-1.1,0.21-1.65,0.35c-12.03,2.99-13.44,9.8-13.46,14.66
      c-0.06,14.42,14.52,33.44,28.35,36.99c16.56,4.26,38.53-0.06,51.31-3.4C161.88,68.26,134.47,36.32,111.44,40.23z"/>
    <rect x="91.33" y="141.22" transform="matrix(0.5177 -0.8555 0.8555 0.5177 -55.1094 201.6785)" class="st0" width="120" height="17"/>
    <rect x="235.48" y="67.89" transform="matrix(0.5177 -0.8555 0.8555 0.5177 8.2526 270.415)" class="st0" width="17" height="120"/>
  </svg>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '27px',
    },
  },
};
</script>

<style lang="scss">
  @import "../../../themes/variables";

  .giftcard-icon {
    fill: $spotlight-color;
  }
</style>
