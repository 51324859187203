<template>
  <div class="tutorial-page-component">
    <ct-banner :banner="banner" />
    <ct-tutorial-services-images />
  </div>
</template>

<script>
import CtBanner from '@/components/Banner/Banner';
import CtTutorialServicesImages from '@/components/Services/TutorialServicesImages';
import config from '@/config';

export default {
  name: 'Tutorial',
  data () {
    return {
      banner: {
        textAlt: 'Use os seus bitcoins como quiser.',
        imgUrl: '/assets/img/banners/banner-nossos-servicos.jpg',
        imgUrlMobile: '/assets/img/banners/banner-nossos-servicos-mobile.jpg',
      },
      pageInfo: {
        title: 'Como usar nossos serviços',
        description: 'Usar a nossa plataforma é super simples, e explicamos tudo nesse tutorial detalhado.',
      },
    };
  },
  metaInfo () {
    return {
      title: this.pageInfo.title,
      meta: [
        { name: 'description', content: this.pageInfo.description },
        { name: 'og:description', content: this.pageInfo.description },
        { name: 'og:image', content: config.app.url + this.banner.imgUrl },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.pageInfo.title },
        { name: 'twitter:description', content: this.pageInfo.description },
        { name: 'twitter:image', content: config.app.url + this.banner.imgUrl },
      ],
    };
  },
  components: {
    CtBanner,
    CtTutorialServicesImages,
  },
};
</script>

<style>

</style>
