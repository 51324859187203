<template>
  <div class="faq-content">
    <div class="container">

      <ct-faq-service-content/>

      <ct-faq-support-content/>

      <ct-faq-pague-com-bitcoin-content/>

      <ct-faq-general-info-content/>

    </div>
  </div>
</template>

<script>
import CtFaqServiceContent from '@/components/ContentFaq/FaqCategories/FaqServiceContent';
import CtFaqSupportContent from '@/components/ContentFaq/FaqCategories/FaqSupportContent';
import CtFaqPagueComBitcoinContent from '@/components/ContentFaq/FaqCategories/FaqPagueComBitcoinContent';
import CtFaqGeneralInfoContent from '@/components/ContentFaq/FaqCategories/FaqGeneralInfoContent';

export default {
  name: 'FaqContent',
  components: {
    CtFaqServiceContent,
    CtFaqSupportContent,
    CtFaqPagueComBitcoinContent,
    CtFaqGeneralInfoContent,
  },
};
</script>

<style lang="scss" scoped>
  .faq-content{
    padding: 0 15px;
  }
</style>
