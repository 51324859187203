<template>
<div class="menu-component">
  <div class="page-menu">
    <nav>
        <div class="row menu">
          <div class="menu-item" v-if="enabledBilletService">
            <div :class="{ 'is-active': menuActive === 'billet'  }" class="new-item" :title="$t('services.title.payment')" @click="addBillet">
              <div class="item-content">
                <ct-billet-icon class="icon"/>
                <span class="item-text">{{ $t('services.title.payment') }}</span>
              </div>
            </div>
          </div>
          <div class="menu-item menu-item-shipment" v-if="enabledShipmentService">
            <div :class="{ 'is-active': menuActive === 'shipment'  }" class="new-item" :title="$t('services.title.btc')" @click="addSellBtc">
              <div class="item-content">
                <ct-btc-icon class="icon"/>
                <span class="item-text text-btc">{{ $t('services.title.btc') }}</span>
              </div>
            </div>
          </div>
          <div class="menu-item menu-item-pix" v-if="enabledPixService">
            <div :class="{ 'is-active': menuActive === 'pix'  }" class="new-item" :title="$t('services.title.pix')" @click="addPix">
              <div class="item-content">
                <ct-pix-icon class="icon"/>
                <span class="item-text text-pix">{{ $t('services.title.pix') }}</span>
              </div>
            </div>
          </div>
          <div class="menu-item menu-item-gift-card" v-if="enabledGiftcardService">
            <div :class="{ 'is-active': menuActive === 'giftCard'  }" class="new-item" :title="$t('services.title.giftcard')" @click="addGiftCard">
              <div class="item-content">
                <ct-gift-card-icon class="icon icon-giftcard"/>
                <span class="item-text text-giftcard">{{ $t('services.title.giftcard') }}</span>
              </div>
            </div>
          </div>
          <div class="menu-item" v-if="enabledRechargeService">
            <div :class="{ 'is-active': menuActive === 'cellphone'  }" class="new-item" :title="$t('services.title.recharge')" @click="addRecharge">
              <div class="item-content">
                <ct-cellphone-icon class="icon"/>
                <span class="item-text text-recharge">{{ $t('services.title.recharge') }}</span>
              </div>
            </div>
          </div>
          <div class="menu-item" v-if="notEnabledServices">
            <div class="is-active new-item" :title="$t('services.title.notFoundServices')">
              <div class="item-content">
                <span class="item-text text-recharge">{{ $t('services.title.notFoundServices') }}</span>
              </div>
            </div>
          </div>
        </div>
    </nav>
  </div>
</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import CtBilletIcon from './../Shared/Icons/BilletIcon';
import CtBtcIcon from './../Shared/Icons/BtcIcon';
import CtPixIcon from './../Shared/Icons/PixIcon';
import CtGiftCardIcon from './../Shared/Icons/GiftCardIcon';
import CtCellphoneIcon from './../Shared/Icons/CellphoneIcon';

export default {
  name: 'Menu',
  components: {
    CtBilletIcon,
    CtBtcIcon,
    CtPixIcon,
    CtGiftCardIcon,
    CtCellphoneIcon,
  },
  data () {
    return {
      menuActive: 'service',
      services: [
        'service-billet',
        'service-cell-phone',
        'service-shipment',
        'service-pix',
        'service-gift-card',
      ],
    };
  },
  computed: {
    ...mapState({
      invoice: (state) => state.services.invoice,
    }),
    ...mapGetters([
      'enabledPixService',
      'enabledShipmentService',
      'enabledRechargeService',
      'enabledBilletService',
      'enabledGiftcardService',
      'notEnabledServices',
    ]),
  },
  mounted () {
    if (this.services.includes(this.$route.name)) {
      this.addServiceDefault(this.$route.name);
    }
  },
  methods: {
    ...mapActions([
      'addNewBillet',
      'addNewBtc',
      'addNewPix',
      'addNewRecharge',
      'addNewGiftCard',
      'clearEmptyBillets',
      'clearEmptyBtcs',
      'clearEmptyRecharges',
      'clearEmptyPix',
      'clearEmptyGiftCards',
    ]),
    addServiceDefault (service) {
      this.clearEmptyItems();
      switch (service) {
        case 'service-billet':
          if (this.invoice.billets.length === 0) {
            this.addNewBillet();
          }
          this.scrollToSelector('.billet-payment-item');
          break;
        case 'service-cell-phone':
          if (this.invoice.recharges.length === 0) {
            this.addNewRecharge();
          }
          this.scrollToSelector('.recharge-item');
          break;
        case 'service-shipment':
          if (this.invoice.sellBtc.length === 0) {
            this.addNewBtc();
          }
          this.scrollToSelector('.sell-btc-item');
          break;
        case 'service-pix':
          if (this.invoice.pix.length === 0) {
            this.addNewPix();
          }
          this.scrollToSelector('.pix-item');
          break;
        case 'service-gift-card':

          if (this.invoice.giftCards.length === 0) {
            this.addNewGiftCard();
          }
          this.scrollToSelector('.giftcard-item');
          break;
      }
    },
    clearEmptyItems () {
      this.clearEmptyBtcs();
      this.clearEmptyBillets();
      this.clearEmptyRecharges();
      this.clearEmptyPix();
    },
    addBillet () {
      this.trackInvoiceEvent('billet-service-inserted');
      this.addNewBillet();
      this.scrollToSelector('.billet-payment-item');
      this.menuActive = 'billet';
    },
    addSellBtc () {
      this.trackInvoiceEvent('shipment-service-inserted');
      this.addNewBtc();
      this.scrollToSelector('.sell-btc-item');
      this.menuActive = 'shipment';
    },
    addPix () {
      this.trackInvoiceEvent('pix-service-inserted');
      this.addNewPix();
      this.scrollToSelector('.pix-item');
      this.menuActive = 'pix';
    },
    addRecharge () {
      this.trackInvoiceEvent('recharge-service-inserted');
      this.addNewRecharge();
      this.scrollToSelector('.recharge-item');
      this.menuActive = 'cellphone';
    },
    addGiftCard () {
      this.trackInvoiceEvent('giftcard-service-inserted');
      this.addNewGiftCard();
      this.scrollToSelector('.giftcard-item');
      this.menuActive = 'giftCard';
    },
    scrollToSelector (selector) {
      setTimeout(() => {
        document.querySelector(selector).scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    },

    async trackInvoiceEvent (event) {
      if (event && this.$gtm && this.$gtm.enabled()) {
        await this.$gtm.trackEvent({
          category: 'invoice',
          action: event,
          label: event,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
.menu-component {
  text-align: center;
  width: 100%;
  display: flex;
  flex: 1;
  align-content: center;
    align-items: center;
    justify-content: center;

  /* flex-flow: column; */
  .page-menu {
    width: 100%;
    max-width: 1200px;
    padding: 0 25px;

    margin-bottom: 40px;
    align-items: center;
    nav {
      .menu {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 0 0;
        list-style: none;
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        .menu-item {
          display: inline-block;
          flex-grow: 1;
          /* align-self: center; */
          /* display: flex; */
          min-width: 100px;
          width: 16.66%;
          height: 66px;
          &.menu-item-shipment {
            min-width: 132px;
            @media only screen and (min-width: $grid-md-max) and (max-width: $grid-lg-max) {
              width: 18%;
            }
          }
          &.menu-item-gift-card {
            @media only screen and (min-width: $grid-md-max) and (max-width: $grid-lg-max) {
              width: 12%;
            }
          }
          @media only screen and (max-width: $grid-md-max) {
            width: 50%;
            padding: 5px;
          }
          .icon-giftcard {
            @media only screen and (max-width: $grid-sm-max) {
              top: 6px;
            }
          }
          .text-giftcard {
            @media only screen and (max-width: $grid-sm-max) {
              padding-bottom: 11px;
            }
          }
        }

        .new-item {
          color: $white-color;
          background-color: $spotlight-color;
          font-family: Oswald, sans-serif;
          text-align: center;
          /* font-size: 1.3em; */
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 14px 5px;
          cursor: pointer;
          text-transform: uppercase;
          width: 100%;
          height: 100%;
          /* line-height: 1.7em;

          @media only screen and (max-width: $grid-md-max) { */
            font-size: 1.1em;
            margin: 9px 0px;
            padding: 7px 5px;
            line-height: 2.2em;
          /* } */

          @media only screen and (max-width: $grid-md-max) {
            max-height: 60px;
          }

          @media only screen and (max-width: $grid-xs-max) {
            line-height: 0;
          }
          &.is-active{
            border-bottom: 5px solid $secondary-color ;
          }

          &:hover{
            background-color: $spotlight-dark-color;

          }

          &:active{
            background-color: $spotlight-light-color;
            color: $spotlight-dark-color;
            .item-content{
              .icon{
                fill: $spotlight-dark-color;
              }
            }
          }

          .item-content{
            position: relative;

            .item-text{
              text-align: left;
              /* display: inline-block;
              @media only screen and (max-width: $grid-md-max) { */
                display: block;
              /* } */

              @media only screen and (max-width: $grid-md-max) {
                display: inline-block;
              }

              @media only screen and (max-width: $grid-xs-max) {
                max-width: 80px;
                line-height: 1.3em;
              }
            }

            .icon {
              fill: $white-color;
              position: relative;
              top: 5px;
              @media only screen and (max-width: $grid-xs-max) {
                margin-right: 10px;
                top: 0;
              }

              @media only screen and (max-width: $grid-md-max) {
                min-height: 25px;
                margin-right: 10px;
              }

              &.icon-payment{
                min-width: 19px;
              }

              &.icon-recharge{
                min-width: 18px;
              }

              &.icon-btc{
                min-width: 35px;
              }

              &.icon-pix{
                min-width: 35px;
              }

              &.icon-giftcard {
                @media only screen and (max-width: $grid-sm-max) {
                  top: 6px;
                }
              }
            }
          }

        }
      }
    }
  }
}

</style>
