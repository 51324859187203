<template>
  <div class="about-component">
    <ct-banner :banner="banner" />
    <ct-splash-content />
    <ct-barcode-content />
    <ct-service-content />
    <ct-badges-content />
    <ct-order-content />
  </div>
</template>

<script>
import CtBanner from '@/components/Banner/Banner';
import CtBarcodeContent from '@/components/ContentApp/BarCode';
import CtOrderContent from '@/components/ContentApp/Orders';
import CtServiceContent from '@/components/ContentApp/Services';
import CtSplashContent from '@/components/ContentApp/Splash';
import CtBadgesContent from '@/components/ContentApp/Badges';
import config from '@/config';

export default {
  name: 'Apps',
  data () {
    return {
      banner: {
        textAlt: 'Quem  somos, conheça a paguecombitcoin',
        imgUrl: '/assets/img/banners/banner-aplicativo.jpg',
        imgUrlMobile: '/assets/img/banners/banner-aplicativo-mobile.jpg',
      },
      pageInfo: {
        title: 'Conheça a PagueComBitcoin',
        description: 'Pagamento de boletos, PIX, conversão de bitcoins e recarga de celular em um só lugar. Conheça a pague com bitcoin.',
      },
    };
  },
  metaInfo () {
    return {
      title: this.pageInfo.title,
      meta: [
        { name: 'description', content: this.pageInfo.description },
        { name: 'og:description', content: this.pageInfo.description },
        { name: 'og:image', content: config.app.url + this.banner.imgUrl },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.pageInfo.title },
        { name: 'twitter:description', content: this.pageInfo.description },
        { name: 'twitter:image', content: config.app.url + this.banner.imgUrl },
      ],
    };
  },
  components: {
    CtBanner,
    CtBarcodeContent,
    CtOrderContent,
    CtServiceContent,
    CtSplashContent,
    CtBadgesContent,
  },
};
</script>

<style lang="scss" scoped>

.about-component {}

</style>
