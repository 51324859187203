<template>
  <div class="register-success-component">
    <h1>{{ $t('register.steps.success.title') }}</h1>

    <p>{{ $t('register.steps.success.welcomeTo') }} <strong>PAGUE COM BITCOIN</strong>!</p>

    <p> {{ $t('register.steps.success.registerComplete') }} </p>

    <button @click.prevent @click="closeRegisterModal" class="btn btn--secondary">
      {{ $t('register.steps.success.buttons.continue') }}
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'RegisterSuccess',
  methods: {
    ...mapActions(['closeRegisterModal']),
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.register-success-component {
  padding: 25px;
  margin-bottom: 30px;
  @media screen and (max-width: $grid-xs-max) {
    padding: 0px;
  }

  h1, p {
    color: $tertiary-color;
    line-height: 1;
  }

  h1 {
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 25px;
    text-transform: uppercase;
    @media screen and (max-width: $grid-xs-max) {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }
  p {
    margin-bottom: 25px;
  }

  .btn-action {
    background: $tertiary-color;
    color: $spotlight-color;
    font-size: 23px;
    margin: auto;
    width: 50%;
    @media only screen and (max-width: $grid-xs-max) {
      font-size: 13px;
    }
  }
}

</style>
