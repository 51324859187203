export default (value) => {
  if (typeof value === 'undefined') {
    return value;
  }

  const newValue = value.replace(/[^0-9|*]/g, '');

  const maskCPF = (newValue) => newValue
    .replace(/([0-9|*]{3})([0-9|*]{3})([0-9|*]{3})([0-9|*]{2})/g, '$1.$2.$3-$4');

  const maskCNPJ = (newValue) => newValue
    .replace(/([0-9|*]{2})([0-9|*]{3})([0-9|*]{3})([0-9|*]{4})([0-9|*]{2})/g, '$1.$2.$3/$4-$5');

  return newValue.length > 11 ? maskCNPJ(newValue) : maskCPF(newValue);
};
