<template>
<div class="home-component">
  <ct-billet v-if="enabledBilletService" style="margin-top: 60px"/>
  <ct-pix v-if="enabledPixService"/>
  <ct-giftcard v-if="enabledGiftcardService"/>
  <ct-recharge v-if="enabledRechargeService"/>
  <ct-sale-bitcoin v-if="enabledShipmentService"/>
  <ct-call />
  <ct-gateway />
  <ct-newsletter />
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import CtRecharge from './Recharge';
import CtPix from './Pix';
import CtBillet from './Billet';
import CtGiftcard from './Giftcard';
import CtSaleBitcoin from './SaleBitcoin';
import CtGateway from './Gateway';
import CtNewsletter from './Newsletter';
import CtCall from './Call';

export default {
  name: 'Home',
  components: {
    CtPix,
    CtRecharge,
    CtBillet,
    CtGiftcard,
    CtSaleBitcoin,
    CtCall,
    CtGateway,
    CtNewsletter,
  },
  computed: {
    ...mapGetters([
      'enabledPixService',
      'enabledShipmentService',
      'enabledRechargeService',
      'enabledBilletService',
      'enabledGiftcardService',
    ]),
  },
};
</script>

<style lang="scss">
.home-component {}
</style>
