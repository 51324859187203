import AuthService from '../../../services/AuthService';

export default {
  async loginUserToken ({ commit, dispatch }, userData) {
    const response = await AuthService.loginToken(userData.email, userData.password);
    dispatch('setUserData', response.data);
  },
  async setUserData ({ commit }, userData) {
    localStorage.setItem('user_data', btoa(JSON.stringify(userData)));
    commit('SET_USER', userData);
    commit('SET_IS_LOGGED', true);
  },
  setUserTokenAuthData ({ commit }) {
    const isLogged = localStorage.hasOwnProperty('user_data');
    const userData = isLogged ? JSON.parse(atob(localStorage.getItem('user_data'))) : {};
    commit('SET_USER', userData);
    commit('SET_IS_LOGGED', isLogged);
  },
  async logoutUserToken ({ dispatch }) {
    try {
      await AuthService.logoutToken();
    } catch (err) {
    }

    dispatch('removeUserToken');
  },
  removeUserToken ({ dispatch }) {
    localStorage.removeItem('user_data');
    dispatch('setUserTokenAuthData');
  },
};
