<template>
  <div class="faq-component">
    <ct-banner :banner="banner" />
    <div class="container">
      <h1>Dúvidas frequentes</h1>
      <p class="faq-description">Tire todas suas dúvidas em nosso FAQ abaixo com as perguntas mais frequentes respondidas da PagueComBitcoin.</p>
      <ct-category-index/>
      <ct-faq-content />
    </div>
  </div>
</template>

<script>
import CtBanner from '@/components/Banner/Banner';
import CtFaqContent from '@/components/ContentFaq/FaqContent';
import CtCategoryIndex from '@/components/Shared/IndexBar/CategoryIndex';
import config from '@/config';

export default {
  name: 'Faq',
  components: {
    CtBanner,
    CtFaqContent,
    CtCategoryIndex,
  },
  data () {
    return {
      banner: {
        textAlt: 'Dúvidas sobre a PagueComBitcoin?',
        imgUrl: '/assets/img/banners/banner-duvidas-frequentes.jpg',
        imgUrlMobile: '/assets/img/banners/banner-duvidas-frequentes-mobile.jpg',
      },
      pageInfo: {
        title: 'Dúvidas Frequentes',
        description: 'Está com dúvidas? Veja aqui algumas informações básicas que geram dúvidas na maioria das pessoas.',
      },
    };
  },
  metaInfo () {
    return {
      title: this.pageInfo.title,
      meta: [
        { name: 'description', content: this.pageInfo.description },
        { name: 'og:description', content: this.pageInfo.description },
        { name: 'og:image', content: config.app.url + this.banner.imgUrl },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.pageInfo.title },
        { name: 'twitter:description', content: this.pageInfo.description },
        { name: 'twitter:image', content: config.app.url + this.banner.imgUrl },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/themes/variables';

.faq-component {
  h1{
    text-align: center;
  }

  .newsletter-component {
    background: $white-color;
  }
}
p{
  &.faq-description{
    text-align: center;
    margin-bottom: 2em;
  }
}
</style>
