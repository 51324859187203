<template>
  <section class="billet-component" :class="{'first-item': isFirstItem }">
    <div class="container billet-container">
      <div class="row billet-row">
        <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12 billet">
          <card-image title="Como Funciona?" color="yellow">
            <template slot="img">
              <img src="/assets/img/home/billet.png" class="image-card"  alt="Boleto do seu bitcoin" />
            </template>
            <template slot="body">
              <p class="title-4">Tipos de boletos pagos<p>
              <div class="card-sub-title title-5">
                Todos
              </div>
              <p class="title-4">Tempo de execução<p>
              <div class="card-sub-title title-5">
                Minutos <br />
                <span>nos dias úteis</span>
              </div>
              <p class="title-4">Valor mínimo</p>
              <div class="card-sub-title title-5">R$ {{ minBilletValue }}</div>
            </template>
            <template slot="action">
              <router-link :to="{ name: 'service-billet' }" class="btn btn--secondary">Pagar Boleto agora</router-link>
            </template>
          </card-image>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12 content">
          <h1 class="text-primary-color">Pagar boletos <br /> com <span class="emphasis-variant">bitcoin</span>.</h1>
          <div class="body">
            <p>
              Comece a Pagar com Bitcoin com todo suporte e segurança em cada passo da transação.
            </p>
            <p>
              Aceitamos boletos de cobrança, convênio, concessionárias e  recolhimento de taxas  como:
              cartão de crédito, compras online, financiamentos, água, luz, telefone, gás, DAS, DARF, etc.
            </p>
            <p>
               Após a confirmação da transação pela rede Bitcoin, seu boleto será processado e compensado em
               até 12 horas em dias úteis ou no próximo dia útil com notificação do pagamento realizado por e-mail.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import currency from '@/filters/currency';
import CardImage from '../Shared/CardImage';

export default {
  name: 'Billet',
  components: {
    CardImage,
  },
  computed: {
    ...mapGetters(['currentRestriction', 'enabledRechargeService']),
    minBilletValue () {
      return this.currentRestriction.minBilletValue ? currency(this.currentRestriction.minBilletValue) : '?';
    },
    isFirstItem () {
      return !this.enabledRechargeService;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';
@import "../../themes/mixins/layout";

.billet-component {
  &.first-item {

    @media only screen and (min-width: $grid-md-min) {
      margin-top: 200px;
      margin-bottom: 100px;
    }
  }
  .billet-container {
    max-width: 1000px;
    @media only screen and (max-width: $grid-md-max) {
      min-height: 700px;
      display: flex;
      margin-bottom: 100px
    }
    @media only screen and (max-width: $grid-xs-max) {
      min-height: 0;
      margin: 20px 0 0
    }
    .billet-row {
      margin: 0;
      .billet {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        @media only screen and (max-width: $grid-xs-max) {
          margin-top: 60px;
          align-items: center
        }

        .image-card {
          position: absolute;
          /* bottom: -31px; */
          bottom: -120px;
          right: -47px;
          z-index: -1;
          width: 839px;

          @media only screen and (max-width: $grid-md-max) {
            bottom: -200px;
            right: -150px
          }
          @media only screen and (max-width: $grid-sm-max) {
            right: -135px
          }
          @media only screen and (max-width: $grid-xs-max) {
            height: auto;
            width: 500px;
            right: -8px;
            bottom: -12px;
          }
        }

        .card {
          @media only screen and (max-width: $grid-xs-max) {
            padding: 10px 0 20px 20px;
            width: 220px;
            margin: 20px 0;
          }
        }
      }

      .content {
        @media only screen and (max-width: $grid-md-max) {
          order: -1;
        }
        h1 {
          min-width: 358px;
          text-align: right;
          color: $primary-color;
          margin: 50px 0 20px auto;
          @media only screen and (max-width: $grid-md-max) {
            margin: 10px auto 20px;
            text-align: center
          }
          @media only screen and (max-width: $grid-xs-max) {
            font-size: 25px;
            min-width: 0;
          }
        }
        .body {
          width: 100%;
          p {
            text-align: right;
            min-width: 306px;
            @media only screen and (max-width: $grid-md-max) {
              margin: 20px auto;
              min-width: 248px;
              text-align: center
            }
            @media only screen and (max-width: $grid-xs-max) {
              margin: 10px auto;
            }
          }
        }
        .body-action {
          display: flex;
          justify-content: flex-end;
          @media only screen and (max-width: $grid-md-max) {
            justify-content: center;
            width: 100%;
          }
          @media only screen and (max-width: $grid-xs-max) {
            margin: 15px 0
          }

          .btn {
            min-height: 37px;
            min-width: 130px;
            @media only screen and (max-width: $grid-xs-max) {
              min-height: 0;
              min-width: 0;
            }
          }
        }
      }
    }
  }
}
</style>
