<template>
  <div class="pix-item" :id="`pix-form-${this.uuid}`">
    <div>
      <form role="form">
        <div class="row type-select">
          <div class="col-xs-12 type-options">
            <input id="type-key" type="radio" :checked="item.type === 'KEY'" @change.prevent="changeType('KEY')" value="KEY"><label for="type-key">Chave</label>
            <input id="type-brcode" type="radio" :checked="isBrCode" @change.prevent="changeType('BRCODE')" value="BRCODE"><label for="type-brcode">Copia e Cola</label>
          </div>
        </div>
        <div class="row" style="padding: 0 8px 8px 8px; margin-top: -10px; margin-botton: 10px" v-if="isBrCode">
          <div class="col-xs-12" style="background: #ffecec; line-height: 18px; max-width: 100%; font-size: 13px; padding: 8px; " >
            <div style="font-weight: bolder; margin-bottom: 4px">Atenção!</div>
            O pix será realizado somente após a confirmação da rede. Caso sua a chave pix tenha prazo para expirar,
            certifique-se da taxa de transação aplicada para aumentar a possibilidade de confirmação rápida. <br />
            Recomendamos que o pagamento seja feito por boleto bancário sempre que sua  chave pix tenha prazo para
            expirar menor que 1 hora. <br />

            Para mais informações, consulte nossa FAQ.
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-9" v-if="isBrCode">
            <input
              type="text"
              class="form-control"
              :id="`brcode${this.uuid}`"
              name="brcode"
              v-model="item.brcode"
              placeholder="Copia e Cola"
              v-validate="brcodeRules"
              @change="changeBrCode"
              maxlength="2000"
              minlength="50"
              :class="{'is-invalid': errors.has('brcode')}"
              :data-vv-as="$t('services.pix.fields.brcode')"
            />
            <div class="invalid-feedback">{{ errorMessages.brcode || errors.first('brcode') || (!item.brCodeValid ? 'Código inválido ou não reconhecido' : '') }}</div>
            <div class="" style="margin-top: 0px; margin-botton: 30px;" v-if="item.brCodeDetails.merchantName">
              {{ item.brCodeDetails.merchantName }}<br /><br />
            </div>
          </div>
          <div class="col-xs-12 col-sm-3" v-if="isBrCode">
            <a @click="pasteClipboard" class="btn btn--secondary block-center">
              <i class="icon icon-paste"></i> Colar
            </a>
          </div>

        </div>
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6" v-if="!isBrCode">
            <label :for="`keyType${this.uuid}`">
              {{ $t('services.pix.fields.keyType') }}
            </label>
            <ct-select
              name="keyType"
              :id="`keyType${this.uuid}`"
              class="from-control"
              v-model="item.keyType"
              :reduce="i => i.value"
              :placeholder="$t('services.pix.placeholder.keyType')"
              v-validate="{ required: true }"
              :class="{'is-invalid': errors.has('keyType')}"
              :data-vv-as="$t('services.pix.fields.keyType')"
              :options="pixKeyTypeOptions"
              @input="changeValue('keyType')"
              :disabled="isBrCode"
            >
              <p slot="no-options">
                Nenhum valor disponível
              </p>
            </ct-select>
            <div class="invalid-feedback">{{ errorMessages.keyType || errors.first('keyType') }}</div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6">
            <label class="key" :for="`key${this.uuid}`">
              {{ $t('services.pix.fields.key') }}
            </label>
            <input
              type="text"
              class="form-control"
              :id="`key${this.uuid}`"
              name="key"
              v-model="item.key"
              :placeholder="item.brCodeDetails.merchantName || $t('services.pix.placeholder.key')"
              v-validate="keyRules"
              @change="changeKey"
              maxlength="200"
              minlength="3"
              :class="{'is-invalid': errors.has('key')}"
              :data-vv-as="$t('services.pix.fields.key')"
              v-if="!keyMask || isBrCode"
              :disabled="isBrCode"
            />
            <the-mask
              type="text"
              class="form-control"
              :id="`key${this.uuid}`"
              name="key"
              v-model="item.key"
              :placeholder="$t('services.pix.placeholder.key')"
              v-validate="keyRules"
              @change="changeKey"
              maxlength="200"
              minlength="3"
              :mask="keyMask"
              :masked="false"
              :class="{'is-invalid': errors.has('key')}"
              :data-vv-as="$t('services.pix.fields.key')"
              v-if="keyMask && item.type !== 'BRCODE'"
              :disabled="item.type === 'BRCODE'"
            />
            <div class="invalid-feedback">{{ errorMessages.key || errors.first('key') }}</div>
          </div>

          <div class="ml-lg-auto ml-md-auto col-lg-2 col-md-3 col-sm-6 col-xs-6">
            <label :for="`amount${this.uuid}`">
              {{ $t('services.sell.fields.amount') }}
            </label>
            <ct-input-numeric
              :thousand-separator="`.`"
              :decimal-separator="`,`"
              :precision="2"
              autocomplete="off"
              class="form-control"
              :id="`amount${this.uuid}`"
              name="amount"
              :readonly="disableAmount"
              :placeholder="$t('services.sell.placeholder.amount')"
              v-validate="{ required: true, decimal: 2 }"
              :data-vv-as="$t('services.sell.fields.amount')"
              :class="{'is-invalid': errors.has('amount')}"
              v-model="item.amount"
              @change="changeValue"
              :disabled="disableAmount"
            ></ct-input-numeric>
            <div class="invalid-feedback">{{ errorMessages.amount || errors.first('amount') }}</div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 check-group">
            <div class="checkbox" :class="{'checked': item.isThirdParty}">
              <input type="checkbox" :id="`isThirdParty${this.uuid}`" :value="true" v-model="item.isThirdParty" />
            </div>
            <label :for="`isThirdParty${this.uuid}`">{{ $t('services.pix.fields.isThirdParty') }}</label>
          </div>
        </div>

        <div class="row third-party-data" v-if="item.isThirdParty">
          <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <label :for="`name${this.uuid}`">
                {{ $t('services.sell.fields.name') }}
              </label>
              <input type="text"
                class="form-control"
                :id="`name${this.uuid}`"
                name="name"
                v-model.trim="item.name"
                :placeholder="$t('services.sell.placeholder.name')"
                v-validate="{ required: true, fullName: true }"
                :class="{'is-invalid': errors.has('name')}"
                :data-vv-as="$t('services.sell.fields.name')"
                @change="changeName"
              />
              <div class="invalid-feedback">{{ errorMessages.name || errors.first('name') }}</div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12">
              <label :for="`document${this.uuid}`">
                {{ $t('services.sell.fields.document') }}
              </label>
              <the-mask type="text"
                class="form-control"
                :id="`document${this.uuid}`"
                name="document"
                :mask="documentMask"
                :masked="false"
                v-model.trim="item.document"
                :placeholder="$t('services.sell.placeholder.document')"
                v-validate="{ required: true, cpf: isCpf, cnpj: !isCpf }"
                :class="{'is-invalid': errors.has('document')}"
                :data-vv-as="$t('services.sell.fields.document')"
                @input="changeDocument"
              />
              <div class="invalid-feedback">{{ errorMessages.document || errors.first('document') }}</div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <label :for="`email${this.uuid}`">
                {{ $t('services.sell.fields.email') }}
              </label>
              <input type="text"
                class="form-control"
                :id="`email${this.uuid}`"
                name="email"
                v-model.trim="item.email"
                :placeholder="$t('services.sell.placeholder.email')"
                v-validate="{ required: true, email: true }"
                :class="{'is-invalid': errors.has('email')}"
                :data-vv-as="$t('services.sell.fields.email')"
                @change="changeEmail"
              />
              <div class="invalid-feedback">{{ errorMessages.email || errors.first('email') }}</div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <label :for="`purpose${this.uuid}`">
                {{ $t('services.sell.fields.purpose') }}
              </label>
              <ct-select
                name="purpose"
                :id="`purpose${this.uuid}`"
                class="from-control"
                v-model="item.purpose"
                :reduce="purpose => purpose.value"
                :placeholder="$t('services.sell.placeholder.purpose')"
                v-validate=""
                :class="{'is-invalid': errors.has('purpose')}"
                :data-vv-as="$t('services.sell.fields.purpose')"
                :options="purposes"
              />
              <div class="invalid-feedback">{{ errorMessages.purpose || errors.first('purpose') }}</div>
            </div>
          </div>
      </form>
    </div>
  </div>
</template>

<script>
import CtInputNumeric from '../Shared/InputNumeric';
import CtSelect from '../Shared/Select';
import { mapGetters, mapState, mapActions } from 'vuex';
import { TheMask } from 'vue-the-mask';
import BrCodeService from '../../services/BrCodeService';
import getKeyTypeHelper from '../../helpers/getKeyTypeHelper';

export default {
  name: 'PixForm',
  props: {
    uuid: {
      type: [ String, Number ],
      required: true,
    },
  },
  components: {
    CtInputNumeric,
    CtSelect,
    TheMask,
  },
  methods: {
    ...mapActions([]),
    async validate () {
      const isValid = await this.$validator.validate();
      return isValid;
    },
    async changeType (type) {
      this.item.type = type;
      await this.$emit('verifyError', { uuid: this.uuid, field: 'type' });
    },
    async changeValue () {
      await this.$emit('verifyError', { uuid: this.uuid, field: 'amount' });
    },
    async changeKey () {
      await this.$emit('verifyError', { uuid: this.uuid, field: 'key' });
    },
    async changeBrCode () {
      this.item.amount = '';
      this.item.key = '';
      this.item.keyType = '';
      this.item.brCodeDetails = {};
      this.item.brCodeValid = false;

      if (this.item.brcode.length < 50) {
        this.$emit('verifyError', { uuid: this.uuid, field: 'brcode' });
        return;
      }

      const details = await BrCodeService.getDetail(this.item.brcode);
      if (!details || !details.merchantName) {
        this.$emit('verifyError', { uuid: this.uuid, field: 'brcode' });
        return;
      }

      this.item.brCodeDetails = details;
      this.item.brCodeValid = true;
      this.item.amount = Number(details.transactionAmount || 0);
      this.item.key = details.pixKey || '';
      if (this.item.key) {
        this.item.keyType = getKeyTypeHelper(this.item.key);
      }

      await this.$emit('verifyError', { uuid: this.uuid, field: 'brcode' });
    },
    async changeName () {
      await this.$emit('verifyError', { uuid: this.uuid, field: 'name' });
    },
    async changeDocument () {
      await this.$emit('verifyError', { uuid: this.uuid, field: 'document' });
    },
    async changeEmail () {
      await this.$emit('verifyError', { uuid: this.uuid, field: 'email' });
    },

    pasteClipboard () {
      navigator.clipboard.readText().then((text) => {
        this.item.brcode = text;
        this.changeBrCode();
      });
    },
  },
  computed: {
    ...mapState({
      kindsAccount: (state) => state.select.kindsAccount,
      invoice: (state) => state.services.invoice,
      bankList: (state) => state.banks.bankList,
      itemsErrors: (state) => state.services.invoice.itemsErrors,
      purposes: (state) => state.select.purposes,
      pixKeyTypeOptions: (state) => state.select.pixKeyTypeOptions,
    }),
    ...mapGetters([
      'getPixByUUID',
    ]),
    item () {
      return this.getPixByUUID(this.uuid);
    },
    isBrCode () {
      return this.item.type === 'BRCODE';
    },
    disableAmount () {
      if (this.isBrCode && (this.item.brCodeValid && this.item.brCodeDetails.transactionAmount > 0)) return true;
      if (!this.isBrCode && !this.item.key) return true;
      return false;
    },
    errorMessages () {
      const messages = {
        key: '',
        amount: '',
      };
      for (const row of this.itemsErrors) {
        if (row.uuid.toString() === this.uuid.toString()) {
          messages[row.field] = row.message;
        }
      }
      return messages;
    },
    keyMask () {
      if (!this.item.keyType) return false;

      if (this.item.keyType === 'DOCUMENT') {
        return ['###.###.###-##', '##.###.###/####-##'];
      }

      if (this.item.keyType === 'PHONE') {
        return '(##) #####-####';
      }

      return false;
    },

    brCodeRules () {
      return { required: true };
    },

    keyRules () {
      const rules = { required: true };
      if (this.item.keyType === 'DOCUMENT') {
        const isCpf = (this.item.key && this.item.key.length <= 11);
        return { ...rules, cpf: isCpf, cnpj: !isCpf };
      }

      if (this.item.keyType === 'PHONE') {
        return { ...rules, cellPhoneWithDDD: true };
      }

      if (this.item.keyType === 'EMAIL') {
        return { ...rules, email: true };
      }

      if (this.item.keyType === 'UUID') {
        return { ...rules, uuid: true };
      }

      return { ...rules };
    },

    documentMask () {
      return ['###.###.###-##', '##.###.###/####-##'];
    },
    isCpf () {
      return this.item.document && this.item.document.length <= 11;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";
.pix-item {
  .icon {
    height: 17px;
    width: 25px;
    display: inline-flex;
    background: $white-color;
    margin-right: 10px;

    &.icon-paste {
      mask: url("/assets/img/icons/icon-paste.svg") no-repeat center;
      mask-size: 100% 100%;
    }
  }
  .type-select {
    padding-bottom: 10px;

    /* @media only screen and (max-width: $grid-lg-max) {
      padding: 0 20px;
    } */
    .type-title {
      color: #414042;
      text-transform: uppercase;
      font-size: 1.5em;
      font-weight: 200;
      margin: 0 0 15px 0;
      text-align: left;
    }

    .type-options {

      display: inline-block;
      padding-bottom: 10px;
      /* text-align: center; */
    }
    label {
      display: inline;
      font-size: 1.6em;
      margin-right: 20px;

    }
    input[type='radio'] {
      width: 17px;
      height: 17px;
      margin-right: 4px;
      display: inline-block;
    }
  }
  .pix-alert {
      @include flex-center();
      margin: 15px 0;
      padding: 2px 10px;
      background: $very-light-pink;
      text: {
        align: center;
        transform: uppercase
      }

      .alert-title {
        color: $secondary-color;
        font-size: 12px;
      }
      .alert-text {
        font-size: 10px;
        margin-left: 5px;
        a {
          color: $spotlight-color;
          text-decoration: underline;
        }
      }
    }
    .check-group {
      display: flex;
      margin: 5px 0;
      .checkbox {
        width: 25px;
        height: 25px;
        background-color: white;
        border: 1px solid #003058;
        @media only screen and (max-width: $grid-sm-max) {
          width: 18px;
          height: 18px;
        }
        input[type='checkbox'] {
          width: 15px;
          height: 15px;
          opacity: 0;
          &:hover {
            cursor: pointer;
          }
        }
        &.checked {
          background-color: #003058;
          background-image: url('/assets/img/check-icon-white.png');
          background-position: center center;
          background-size: 15px 15px;
          background-repeat: no-repeat;
          @media only screen and (max-width: $grid-sm-max) {
            background-size: 11px 11px;
          }
        }
      }
      label {
        color: $secondary-color;
        text-transform: uppercase;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    @media only screen and (max-width: $grid-md-max) {
      .check-group {
        label {
          margin-top: 0;
        }
      }
    }
    .third-party-data {
      margin: 5px 0 15px;
    }
  }
</style>
