<template>
  <div class="countdown-component">
    <div id="countdown-timer" v-if="timer >= 0">
      <span id="countdown-days" v-if="days > 0"> {{ days }} {{ daysString }}, </span>
      <span id="countdown-hours" v-if="hours > 0"> {{ hours }} {{ hoursString }}, </span>
      <span id="countdown-minutes" v-if="minutes > 0"> {{ minutes }} {{ minutesString }} e </span>
      <span id="countdown-seconds"> {{ seconds }} segundos </span>
    </div>
    <div id="countdown-over" v-if="timer < 0">
      <span>{{ endMessage }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Countdown',
  props: {
    startDate: { type: Number, required: true },
    finalDate: { type: String, required: true },
    endMessage: { type: String },
  },
  data () {
    return {
      endDate: 0,
      timer: 0,
      interval: null,
    };
  },
  computed: {
    days () { return Math.floor(this.timer / (1000 * 60 * 60 * 24)); },
    hours () { return Math.floor((this.timer % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); },
    minutes () { return Math.floor((this.timer % (1000 * 60 * 60)) / (1000 * 60)); },
    seconds () { return Math.floor((this.timer % (1000 * 60)) / 1000); },

    daysString () { return (this.days > 1) ? 'dias' : 'dia'; },
    hoursString () { return (this.hours > 1) ? 'horas' : 'hora'; },
    minutesString () { return (this.minutes > 1) ? 'minutos' : 'minuto'; },
  },
  methods: {
    initializeInterval () {
      this.interval = setInterval(() => {
        this.timer = this.timer - 1000;
        if (this.timer < 0) {
          clearInterval(this.interval);
        }
      }, 1000);
    },
  },
  created () {
    this.endDate = new Date(this.finalDate).getTime();
    this.timer = this.endDate - this.startDate;
    this.initializeInterval();
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
#countdown-component {
  #countdown-timer {
    span {
     margin: 0px 2px;
    }
  }
}
</style>
