export default class Pix {
  constructor (data = {}) {
    this.type = data.type || 'KEY';
    this.keyType = data.keyType;
    this.key = data.key;
    this.brcode = data.brcode || '';
    this.brCodeValid = data.brCodeValid || false;
    this.brCodeDetails = data.brCodeDetails || {};
    this.amount = data.amount || null;
    this.isThirdParty = data.isThirdParty || null;
    this.name = data.name || null;
    this.document = data.document || null;
    this.email = data.email || null;
    this.purpose = data.purpose || null;
  }
}
