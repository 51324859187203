export default (value) => {
  if (typeof value === 'undefined') {
    return value;
  }

  if (typeof value !== 'string' || value.length < 5) {
    return value;
  }

  const newValue = value.replace(/[^0-9]/g, '');

  const phone = (newValue) => newValue
    .replace(/([0-9]{5})([0-9]{4})/g, '$1-$2');

  const phoneWithDDD = (newValue) => newValue
    .replace(/([0-9]{2})([0-9]{5})([0-9]{4})/g, '($1) $2-$3');

  return newValue.length > 10 ? phoneWithDDD(newValue) : phone(newValue);
};
