<template>
  <div class="anchor-component">
    <router-link
      :to="{ name: page, hash: `#${anchor}`}"
      @click.native="anchorHashCheck"
    >
      <slot></slot>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Anchor',
  props: {
    page: {
      type: String,
      required: false,
    },
    anchor: {
      type: String,
    },
  },
  methods: {
    anchorHashCheck () {
      if (window.location.hash === this.$route.hash) {
        const el = document.getElementById(this.$route.hash.slice(1));
        if (el) {
          window.scrollTo({
            top: el.offsetTop - 105,
            behavior: 'smooth',
          });
        }
      }
    },
  },
  mounted () {
    this.anchorHashCheck();
  },
};
</script>

<style>

</style>
