<template>
  <div class="index-item-component">
    <a @click="anchorHashCheck(item.anchor)">
    {{ item.name }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'IndexItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    anchorHashCheck (anchor) {
      const el = document.getElementById(anchor);
      if (el) {
        history.pushState({}, '', '#' + anchor);
        if ('scrollBehavior' in document.documentElement.style) {
          window.scrollTo({ top: el.offsetTop, behavior: 'smooth' });
        } else {
          window.scrollTo(0, el.offsetTop);
        }
      };
    },
  },
  mounted () {
    const anchor = this.$route.hash.slice(1);
    this.anchorHashCheck(anchor);
  },
};
</script>

<style lang="scss">
@import '../../../themes/variables';

.index-item-component{
  display: flex;
  a {
    color: $white-color;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 20px;
    line-height: 28px;
    margin-right: 30px;
    cursor: pointer;
    @media only screen and (max-width: $grid-xs-max) {
      font-size: 10px;
      margin: 0 12px 0 0;
      line-height: 20px
    }

    &:before {
      content: '\25bc';
      font-size: 9px;
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
