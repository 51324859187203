export default class Billet {
  constructor (data = {}) {
    this.digitableLine = data.digitableLine;
    this.dueDate = data.dueDate || null;
    this.addition = data.addition || null;
    this.discount = data.discount || null;
    this.amount = data.amount || null;
    this.billetTotal = data.billetTotal || null;
  }
}
