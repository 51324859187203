import { render, staticRenderFns } from "./ServicesIntroCellPhone.vue?vue&type=template&id=a9be7960&scoped=true"
import script from "./ServicesIntroCellPhone.vue?vue&type=script&lang=js"
export * from "./ServicesIntroCellPhone.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9be7960",
  null
  
)

export default component.exports