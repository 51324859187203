import config from '../config';

export const getState = () => ({
  app: {
    name: config.name,
    version: config.version,
  },
  baseUrl: '',
  isLoading: false,
  selects: {
    languages: [
      { value: 'pt', label: 'Português' },
      { value: 'en', label: 'English' },
    ],
  },
  openContactForm: false,
  openContactModal: false,
  isReferralModalOpen: false,
});

export default Object.assign(getState(), { getState: getState });
