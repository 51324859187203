export default {
  dateFormat () {
    return 'DD/MM/YYYY';
  },

  getAppVersion (state) {
    return state.app.version;
  },

  currentReferralFee (state) {
    const settings = state.auth.user.settings || {};
    const settingsPlatform = state.settings && state.settings.settings ? state.settings.settings : {};
    return settings.referralFee || settingsPlatform.referralFee;
  },

  currentRestriction (state) {
    const settings = state.auth.user.settings || {};
    const userRestriction = settings.restriction || {};
    const settingsRestriction = state.settings.settings.restriction;
    return {
      ...settingsRestriction,
      ...userRestriction,
    };
  },
};
