<template>
  <section class="giftcard-component" :class="{'first-item': isFirstItem }">
    <div class="container giftcard-container">
      <div class="row giftcard-row">
        <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12 giftcard">
          <card-image title="Como Funciona?" color="yellow">
            <template slot="img">
              <img src="/assets/img/home/giftcard.png" class="image-card"  alt="Giftcard com Bitcoin" />
            </template>
            <template slot="body">
              <p class="title-4">Gift card virtual<p>
              <div class="card-sub-title title-5">Em média 5 minutos
                <br>
                <span>Após a confirmação</span>
              </div>

              <p class="title-4">Valor mínimo</p>
              <div class="card-sub-title title-5">R$ {{ minGiftcardValue }}</div>

              <p class="title-4">Pin para resgate no seu e-mail</p>
            </template>
            <template slot="action">
              <router-link :to="{ name: 'service-gift-card' }" class="btn btn--secondary">Comprar Agora</router-link>
            </template>
          </card-image>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12 content">
          <h1 class="text-primary-color">Gift Card <br /> com <span class="emphasis-variant">bitcoin</span>.</h1>
          <div class="body">
            <p>
              Use seus bitcoins para acessar serviços, streamings e games de forma prática e segura.
            </p>
            <p>
              Aqui você encontra uma variedade de gift cards dos principais provedores, tais como: Apple, Ifood, Google Play, lol, Netflix, Playstation, Razer, Steam, Uber, etc.
            </p>
            <p>
              Você pode comprar quantos gift cards quiser em um só pedido.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import currency from '@/filters/currency';
import CardImage from '../Shared/CardImage';

export default {
  name: 'Giftcard',
  components: {
    CardImage,
  },
  computed: {
    ...mapGetters(['currentRestriction', 'enabledRechargeService', 'enabledBilletService']),
    minGiftcardValue () {
      return this.currentRestriction.minGiftCardValue ? currency(this.currentRestriction.minGiftCardValue) : '?';
    },
    isFirstItem () {
      return !this.enabledRechargeService && !this.enabledBilletService;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';
@import "../../themes/mixins/layout";

.giftcard-component {
  &.first-item {
    @media only screen and (min-width: $grid-md-min) {
      margin-top: 200px;
      margin-bottom: 100px;
    }
  }
  .giftcard-container {
    max-width: 1000px;
    @media only screen and (max-width: $grid-md-max) {
      min-height: 700px;
      display: flex;
      margin-bottom: 100px
    }
    @media only screen and (max-width: $grid-xs-max) {
      min-height: 0;
      margin: 20px 0 0;
    }
    .giftcard-row {
      margin: 0;
      .giftcard {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin: 60px 0;

        @media only screen and (max-width: $grid-xs-max) {
          margin-top: 60px;
          align-items: center
        }

        .image-card {
          position: absolute;
          /* bottom: -31px; */
          bottom: -20px;
          right: 230px;
          z-index: -1;
          width: 420px;

          @media only screen and (max-width: $grid-md-max) {
            bottom: -50px;
            right: 200px;
          }
          @media only screen and (max-width: $grid-sm-max) {
            width: 500px;
            right: -135px;
            bottom: -12px;
          }
          @media only screen and (max-width: $grid-xs-max) {
            height: auto;
            width: 420px;
            right: 0px;
            bottom: -40px
          }
        }

        .card {
          @media only screen and (max-width: $grid-xs-max) {
            padding: 10px 0 20px 20px;
            width: 220px;
            margin: 20px 0;
          }
        }
      }

      .content {
        @media only screen and (max-width: $grid-md-max) {
          order: -1;
        }
        h1 {
          min-width: 358px;
          text-align: right;
          color: $primary-color;
          margin: 50px 0 20px auto;
          @media only screen and (max-width: $grid-md-max) {
            margin: 10px auto 20px;
            text-align: center
          }
          @media only screen and (max-width: $grid-xs-max) {
            font-size: 25px;
            min-width: 0;
          }
        }
        .body {
          width: 100%;
          p {
            text-align: right;
            min-width: 306px;
            @media only screen and (max-width: $grid-md-max) {
              margin: 20px auto;
              min-width: 248px;
              text-align: center
            }
            @media only screen and (max-width: $grid-xs-max) {
              margin: 10px auto;
            }
          }
        }
        .body-action {
          display: flex;
          justify-content: flex-end;
          @media only screen and (max-width: $grid-md-max) {
            justify-content: center;
            width: 100%;
          }
          @media only screen and (max-width: $grid-xs-max) {
            margin: 15px 0
          }

          .btn {
            min-height: 37px;
            min-width: 130px;
            @media only screen and (max-width: $grid-xs-max) {
              min-height: 0;
              min-width: 0;
            }
          }
        }
      }
    }
  }
}
</style>
