const BLACKLIST = [
  '00000000',
  '11111111',
  '22222222',
  '33333333',
  '44444444',
  '55555555',
  '66666666',
  '77777777',
  '88888888',
  '99999999',
];

export const zipCodeValidator = (zipCode, args) => {
  const zipCodeSanitized = zipCode.replace(/[^\d]/g, '');
  if (BLACKLIST.includes(zipCodeSanitized)) {
    return false;
  }
  const zipCodeRegex = /^[0-9]{8}$/gi;
  return zipCodeRegex.test(zipCodeSanitized);
};

export default {
  zipCodeValidator,
};
