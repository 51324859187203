<template>
  <div class="action-button-component">
    <div class="add-item" v-if="type === 'ADD'">
      <div class="icon">+</div>
      <div class="text">{{ $t(`services.addButton.${this.kind}`) }}</div>
    </div>
    <div class="remove-item" v-if="type === 'REMOVE'">
      <div class="icon">-</div>
      <div class="text">{{ $t('services.removeButton') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServicesActionButton',
  props: {
    type: {
      type: String,
      required: true,
    },
    kind: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";

.action-button-component {
  width: max-content;
  margin: auto;

  .add-item, .remove-item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .text {
    display: inline-block;
    color: $spotlight-color;
    text-transform: uppercase;
  }

  .icon {
    display: inline-block;
    background: $secondary-color;
    color: $white-color;
    font-size: 20px;
    font-weight: bold;
    padding: 1px 3px;
    margin-right: 7px;
    line-height: 13px;
  }

  .remove-item .icon {
    padding: 0px 3px 2px;
  }
}

</style>
