import { boletoBancarioLinhaDigitavel } from 'boleto-brasileiro-validator';
import http from '@/services/HttpService';

export default {
  async getDetail (digitableLine) {
    const res = await http().get(`/api/v1/billets/${digitableLine}/detail`);
    return res.data.data;
  },
  checkForIncompleteDigitableLine (digitableLine) {
    if (
      !digitableLine.startsWith(8) &&
      digitableLine.length > 35 &&
      digitableLine.length < 47 &&
      !digitableLine.substring(33).match(/[^0]/)
    ) {
      const completeLine = digitableLine.toString().padEnd(47, '0');
      if (boletoBancarioLinhaDigitavel(completeLine)) {
        return completeLine;
      }
    }
    return digitableLine;
  },
};
