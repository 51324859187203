export default {
  SET_RESET_PASSWORD_MODAL_VISIBILITY (state, payload) {
    state.modalVisible = payload;
  },
  SET_RESET_PASSWORD_MODAL_STATUS (state, payload) {
    state.modalStatus = payload;
  },
  SET_RESET_PASSWORD_FORM_STATUS (state, payload) {
    state.formStatus = payload;
  },
};
