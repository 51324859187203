<template>
  <a class="twitter-button-component" @click="clicked" :href="url" :target="target">
    <svg version="1.1" id="twitter-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 107 107" style="enable-background:new 0 0 107 107;" xml:space="preserve">
    <path d="M106.89,52.38c0-0.46,0-0.93,0-1.39c-0.06-1.19-0.05-2.4-0.16-3.58c-0.62-6.31-2.45-12.29-5.29-17.94
      c-4.08-8.11-9.86-14.79-17.33-19.95c-8.34-5.76-17.54-9.08-27.74-9.36c-0.55-0.06-1.11,0.14-1.64-0.12c-0.08,0-0.16,0-0.24,0
      c-0.71,0.26-1.45,0.06-2.17,0.12c-0.52,0-1.05,0-1.57,0c-1.31,0.14-2.63,0.05-3.94,0.22C33.88,2.07,22.98,7.68,14.1,17.26
      c-4.17,4.5-7.47,9.54-9.87,15.18c-2.52,5.94-4.04,12.1-4.09,18.59c0,0.53,0,1.06,0,1.59c0,0.61,0,1.22,0,1.83c0,0.46,0,0.92,0,1.38
      c-0.03,1.24,0.03,2.48,0.16,3.7C1.66,71.99,6.93,82.61,15.69,91.5c3.23,3.27,6.88,6.07,10.9,8.36c7.89,4.5,16.3,7.07,25.43,7.04
      c0.8,0,1.6,0,2.41,0c0.46,0,0.92,0,1.38,0c4.05-0.02,8.02-0.63,11.92-1.71c9.62-2.67,17.82-7.64,24.69-14.88
      c4.19-4.42,7.53-9.38,10.02-14.94c2.76-6.16,4.36-12.57,4.44-19.34c0-0.46,0-0.93,0-1.39C106.89,53.88,106.89,53.13,106.89,52.38z
      M81.12,42.25c-0.55,0.42-0.69,0.81-0.68,1.47c0.34,16.45-10.53,34.3-30.02,38.26c-9.84,2-19.22,0.54-27.92-4.64
      c-0.24-0.14-0.49-0.25-0.73-0.38c7.04,0.32,13.52-1.26,19.43-5.69c-6.06-0.7-10.16-3.71-12.36-9.42c1.98,0.07,3.83,0.29,5.63-0.25
      c-6.87-2.57-10.59-7.39-10.24-13.31c0.89,0.33,1.77,0.65,2.65,0.97c0.9,0.32,1.81,0.53,2.98,0.43c-2.84-2.18-4.62-4.88-5.33-8.22
      c-0.7-3.32-0.16-6.48,1.56-9.56c0.78,0.83,1.51,1.65,2.27,2.44c5.99,6.17,13.28,9.84,21.74,11.24c0.94,0.16,1.9,0.18,2.85,0.27
      c0.54,0.05,0.81,0.09,0.67-0.72c-1.06-6.21,2.31-12.32,8.2-14.65c5.19-2.05,9.98-1.19,14.2,2.49c0.57,0.5,1,0.65,1.78,0.47
      c2.59-0.62,5-1.66,7.52-3.02c-0.98,3.1-2.78,5.33-5.36,7.2c0.95,0.18,1.66-0.18,2.4-0.34c0.81-0.17,1.61-0.4,2.4-0.66
      c0.74-0.24,1.46-0.53,2.37-0.87C85.45,38.4,83.44,40.48,81.12,42.25z"/>
    </svg>
  </a>
</template>

<script>
export default {
  name: 'TwitterButton',
  props: {
    url: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: '_blank',
    },
  },
  data () {
    return {};
  },
  methods: {
    clicked () {
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
  @import "../../themes/variables";
  .twitter-button-component{
    cursor: pointer;
    svg {
      width: 2.88em;
      height: 2.88em;
      fill: $white-color;
      &:hover {
        fill: $white-color;
      }
    }
  }
</style>
