export default [
  {
    text:
      'Ficou muito bom o sistema.  Parabéns pelo vosso trabalho! Como cliente antigo, deram uma grande evolução! Continuem o vosso fantástico trabalho, sem vocês nada era possível.',
    name: 'Reny Santos',
    company: 'Cliente PaguecomBitcoin',
  },
  {
    text:
      'Muito bom o serviço, efetuei o pagamento de um boleto e tudo ocorreu sem problemas. Muito prático e seguro. Vou pagar todas as minhas contas pela PagueComBitcoin. Recomendo!',
    name: 'Rafael Felício',
    company: 'P2P',
  },
  {
    text:
      'Já fiz vários pagamentos do carnezao do meu carro, haha, convenio de saúde aqui de casa e não teve uma falha. Já tem uns 3 meses que uso e recomendo! Ferramentas como essa que ajudam o bitcoin no BR (e nós usuários do btc) devem ser divulgadas!',
    name: 'João Paulo Luigi',
    company: 'Bitcoin Brasil Facebook',
  },
  {
    text:
      'Já efetuei várias recargas de celular e paguei boletos de Luz, internet e condomínio utilizando o serviço. Tudo funcionou perfeitamente. Recomendo!',
    name: 'Egídio Santos Filho',
    company: 'useCryptos.com',
  },
];
