<template>
  <svg version="1.1" class="btc-icon" :class="{type: true}" :style="{ height: size }" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 37.67 29.87" style="enable-background:new 0 0 37.67 29.87;" xml:space="preserve">
    <path d="M21.67,23.02v-2.18c0.35-0.07,0.65-0.15,0.91-0.25c0.71-0.29,1.26-0.7,1.65-1.24c0.39-0.54,0.59-1.14,0.59-1.78
      c0-0.54-0.13-1.03-0.39-1.47c-0.25-0.44-0.6-0.78-1.04-1.02c-0.3-0.18-0.74-0.34-1.31-0.48c0.53-0.18,0.96-0.41,1.28-0.68
      c0.32-0.28,0.57-0.6,0.75-0.96c0.17-0.36,0.26-0.74,0.26-1.14c0-0.56-0.16-1.06-0.49-1.52c-0.32-0.46-0.79-0.82-1.39-1.06
      c-0.23-0.1-0.51-0.17-0.82-0.23V6.85h-2.06v2h-1.32v-2h-2.06v2h-3.15v1.68h0.48c0.32,0,0.54,0.03,0.65,0.09
      c0.11,0.06,0.19,0.14,0.24,0.23c0.05,0.1,0.07,0.33,0.07,0.69v6.79c0,0.35-0.02,0.58-0.07,0.68c-0.05,0.1-0.13,0.18-0.24,0.24
      c-0.12,0.05-0.33,0.08-0.65,0.08h-0.48v1.68h3.15v2h2.06v-2h1.17c0.05,0,0.1,0,0.15,0v2H21.67z M17.53,10.82h1.34
      c0.78,0,1.32,0.05,1.62,0.14c0.3,0.1,0.54,0.26,0.7,0.48c0.16,0.23,0.24,0.5,0.24,0.82c0,0.31-0.08,0.58-0.25,0.82
      c-0.17,0.24-0.42,0.41-0.75,0.53c-0.34,0.12-0.85,0.18-1.55,0.18h-1.34V10.82z M19.15,19.07h-0.89c-0.3,0-0.48-0.02-0.54-0.05
      c-0.07-0.03-0.12-0.09-0.15-0.17c-0.02-0.06-0.04-0.28-0.04-0.65v-2.64h1.57c0.69,0,1.21,0.07,1.54,0.19
      c0.34,0.13,0.59,0.32,0.75,0.57c0.17,0.25,0.25,0.55,0.25,0.92c0,0.43-0.12,0.79-0.36,1.09c-0.24,0.3-0.53,0.5-0.87,0.6
      C20.09,19.03,19.67,19.07,19.15,19.07L19.15,19.07z M9.53,13.29l-4.19,4c-0.15,0.14-0.35,0.22-0.55,0.22H4.77
      c-0.21,0-0.41-0.09-0.56-0.25l-3.99-4.19c-0.31-0.32-0.29-0.83,0.02-1.13c0.32-0.31,0.83-0.29,1.13,0.03l2.61,2.74
      C4.08,8.41,8.13,2.79,14.18,0.77c6.45-2.16,13.55,0.33,17.25,6.05c0.24,0.37,0.13,0.87-0.24,1.1c-0.37,0.24-0.87,0.13-1.11-0.23
      c-3.3-5.1-9.64-7.33-15.4-5.4C9.25,4.1,5.63,9.17,5.6,14.83l2.83-2.7c0.32-0.3,0.82-0.29,1.13,0.03
      C9.86,12.47,9.85,12.98,9.53,13.29L9.53,13.29z M37.67,17.77c-0.31,0.32-0.82,0.32-1.13,0.01l-2.71-2.68
      c-0.06,6.32-4.11,11.97-10.18,14c-1.55,0.52-3.15,0.77-4.72,0.77c-4.96,0-9.72-2.48-12.53-6.82c-0.24-0.37-0.13-0.87,0.24-1.11
      c0.37-0.24,0.87-0.13,1.11,0.24c3.31,5.11,9.64,7.32,15.4,5.4c5.43-1.82,9.05-6.88,9.09-12.54l-2.74,2.77
      c-0.16,0.16-0.36,0.24-0.57,0.24c-0.2,0-0.41-0.08-0.56-0.23c-0.31-0.31-0.32-0.82,0-1.13l4.07-4.11c0.31-0.31,0.82-0.32,1.13,0
      l4.11,4.07C37.98,16.95,37.98,17.46,37.67,17.77L37.67,17.77z M37.67,17.77"/>
  </svg>

</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '27px',
    },
  },
};
</script>

<style lang="scss">
  @import "../../../themes/variables";

  .btc-icon{
    fill: $spotlight-color;
    height: 27px;
  }
</style>
