import axios from 'axios';

export default {
  async findAddress (cep) {
    cep = cep.replace(/( |\.|-)/g, '');
    if (cep.length === 8) {
      const res = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      return res.data;
    }

    return {};
  },
};
