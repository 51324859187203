export default class UserRegistration {
  constructor (data = {}) {
    this.name = data.name || '';
    this.country = data.country || 'BR';
    this.kind = data.kind || 'F';
    this.document = data.document || '';
    this.password = data.password || '';
    this.passwordConfirm = data.passwordConfirm || '';
    this.phoneCode = data.phoneCode || '55:BR';
    this.phone = data.phone || '';
    this.email = data.email || '';
    this.emailConfirm = data.emailConfirm || '';
    this.acceptNotification = data.acceptNotification || false;
    this.addresses = data.addresses || [{}];
  }
}
