export default class SellBtc {
  constructor (data = {}) {
    this.bank = data.bank;
    this.agency = data.agency || null;
    this.account = data.account || null;
    this.kind = data.kind || null;
    this.operation = data.operation || null;
    this.amount = data.amount || null;
    this.isThirdParty = data.isThirdParty || null;
    this.name = data.name || null;
    this.document = data.document || null;
    this.email = data.email || null;
    this.purpose = data.purpose || null;
  }
}
