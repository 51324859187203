<template>
<div>
  <div id="informacoes">
    <h2 class="info-content-tile">Informações Gerais</h2>

    <div id="bitcoins">
      <h3 class="info-content-tile">Bitcoins e criptomoedas</h3>
      <h4>O que é Bitcoin?</h4>
      <p>
        O Bitcoin é uma moeda virtual baseada em criptografia. Ao contrário das moedas fiduciárias como o Dólar, Real,
        Euro, o Bitcoin não possui representação física, a sua principal representação é como saldo em uma carteira
        digital. <router-link :to="{name: 'bitcoin'}">Conheça mais sobre Bitcoins clicando aqui</router-link> .
      </p>

      <h4>Como vender e comprar Bitcoins?</h4>
      <p>
        A venda ou compra de Bitcoins é realizada por meio da negociação da quantidade de Bitcoins que represente uma
        quantidade de Reais baseado numa cotação de quantos Reais equivalem a um Bitcoin, assim como acontece com o
        Dólar.
      </p>
      <p>
        Oferecemos o <router-link :to="{name: 'service-shipment'}">Serviços de Conversão de Bitcoin em Real</router-link>
        através de transferência bancária para sua conta ou de terceiros e por Pix, sem necessidade de exchange.
      </p>
    </div>

    <div id="receita-federal">
      <h3 class="info-content-tile">RECEITA FEDERAL</h3>
      <h4>A PagueComBitcoin declara suas operações à Receita Federal?</h4>
      <p>
        Sim! De acordo com a Instrução Normativa RFB nº 1.888 (IN 1888), modificada pela Instrução Normativa RFB nº
        1.899 (IN 1889), toda pessoa física ou jurídica, guardado os limites nelas determinadas, devem realizar o
        informe de suas operações a Receita Federal e realizar o devido recolhimento dos impostos.
      </p>

      <h4>Eu preciso declarar meus bitcoins no Imposto de Renda?</h4>
      <p>
        Sim. Todo bem e direito precisa ser declarado desde que o contribuinte esteja obrigado à entrega da Declaração
        de Ajuste Anual do Imposto de Renda da Pessoa Física ou Jurídica. Nesse caso, as criptomoedas devem ser
        declaradas na Ficha Bens e Direitos como “99-Outros Bens e Direitos”, uma vez que podem ser equiparadas a um
        ativo financeiro.
      </p>
      <p>
        Pessoas físicas são obrigadas a realizar o informe mensal de movimentação conforme o especificado na IN1888 e
        IN1899, quando movimentam volumes mensais superiores a R$ 30.000,00.
      </p>
      <p>
        O ganho de capital mensal de até R$ 35.000,00 é isento de imposto de renda. Já para ganho de capital excedente
        a esse valor deve gerar o recolhimento de 15% de imposto de renda. O recolhimento de imposto de renda, caso
        necessário, deve ser feito através do Programa de Apuração de Ganho de Capital (GCap) da Receita Federal.
      </p>
      <p>
        Todas as pessoas jurídicas brasileiras que realizam operações envolvendo criptomoedas devem prestar informações
        sobre sua origem e destino através de obrigação acessória disponível no e-cac.
      </p>

      <h4>Eu posso pagar Imposto de Renda com Bitcoin?</h4>
      <p>
        Somente fintechs e exchanges autorizadas pelo Banco Central (BC) como instituições de pagamentos (IP) e
        cadastradas na Câmara Interbancária de Pagamentos (CIP), integrante do Sistema de Pagamentos Brasileiro (SPB),
        podem pagar impostos com criptomoedas.
      </p>
    </div>

    <div id="seguranca">
      <h3 class="info-content-tile">Segurança e fraude</h3>

      <h4>A PagueComBitcoin tem outro website?</h4>
      <p>
        NÃO! A PagueCombitcoin opera somente pelo website oficial
        <router-link :to="{name: 'home'}">https://paguecombitcoin.com/</router-link> e pelo aplicativo
        mobile Android e IOs encontrados em <a target="_blank" href="https://paguecombitcoin.com/aplicativo">https://paguecombitcoin.com/aplicativo</a>.
      </p>
      <p>
        Existem websites fraudulentos que utilizam o um clone do layout da nossa antiga plataforma para aplicar golpes
        em clientes, como a “paguebtc.com”. Procuramos tomar todas as providências jurídicas possíveis para encerramento
        dessa atividade fraudulenta.
      </p>
      <p>
        Saiba mais em notícias publicadas dos principais portais do mercado de bitcoin:
      </p>
      <ul class="simple-list">
        <li>
          <a href="https://cointelegraph.com.br/news/brazilian-paguecombitcoin-alerts-users-about-one-companys-fake-platform">
            Cointelegraph - Brasileira PagueComBitcoin alerta usuários sobre plataforma falsa que tenta enganar clientes da empresa
          </a>
        </li>
        <li>
          <a href="https://www.criptofacil.com/hackers-clonam-plataforma-paguecombitcoin-empresa-alerta-usuarios/">
            CriptoFácil - Hackers clonam plataforma PagueComBitcoin e empresa alerta usuários
          </a>
        </li>
        <li>
          <a href="https://bitcoinnews.com.br/brl-btc/empresa-brasileira-paguecombitcoin-alerta-usuarios-sobre-clone-de-sua-antiga-plataforma/">
            Bitcoin News Brasil - Empresa brasileira PagueComBitcoin alerta usuários sobre clone de sua antiga plataforma
          </a>
        </li>
      </ul>

      <h4>Como posso me proteger de fraudes?</h4>
      <p>
        Algumas dicas de como se prevenir de golpes e fraudes:
      </p>
      <ul class="simple-list">
        <li>Verifique o endereço do website (url), caso ele NÃO seja <span class="weight-bold emphasis-variant">"paguecombitcoin.com"</span>
          é uma página falsa;</li>
        <li>Não forneça informações confidenciais a ninguém, seja pelo telefone, e-mail ou WhatsApp;</li>
        <li>
          Não publique informações sobre saldos de suas carteiras, essa prática é o mesmo que postar o saldo da sua conta bancária;
        </li>
        <li>Não forneça seus dados ou responda links de e-mails não solicitados ou de mensagens no Facebook;</li>
        <li>Não abra anexos contidos em e-mail que não foram solicitados;</li>
        <li>
          Não revele ou compartilhe suas senhas com terceiros e não permita que um terceiro utilize seu cadastro.
        </li>
      </ul>
    </div>

  </div>

  <ct-user-login-modal
      :isModalVisible="isModalLoginVisible"
      @close="closeLoginModal"
      @successLogin="closeLoginModal"
    />
</div>

</template>

<script>
import currency from '@/filters/currency';
import { mapActions, mapState } from 'vuex';
import CtUserLoginModal from '@/components/Navbar/UserLoginModal';

export default {
  name: 'FaqGeneralInfoContent',
  components: {
    CtUserLoginModal,
  },
  filters: {
    currency,
  },
  data () {
    return {
      isModalLoginVisible: false,
    };
  },
  methods: {
    ...mapActions(['openRegisterModal']),
    openLoginModal () {
      this.isModalLoginVisible = true;
    },
    closeLoginModal () {
      this.isModalLoginVisible = false;
    },
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.settings,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/themes/variables';

</style>
