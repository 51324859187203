<template>
  <div class="carousel-component">
    <carousel
      :loop="true"
      :per-page="1"
      :mouse-drag="false"
      :touch-drag="true"
      :autoplay="true"
      :autoplayTimeout="bannerInterval"
      paginationActiveColor="#fcb116"
      paginationColor="#003058"
      :paginationSize="12"
      :paginationPadding="5"
      >
      <slide>
        <router-link :to="{ name: 'app' }">
          <picture>
            <source media="(max-width: 991px)" srcset="/assets/img/banners/banner-aplicativo-home-mobile.jpg" alt="Novo app com leitor de código de barras">
            <img src="/assets/img/banners/banner-aplicativo-home.jpg" alt="Novo app com leitor de código de barras" />
          </picture>
        </router-link>
      </slide>
      <slide v-if="enabledGiftcardService">
        <router-link :to="{ name: 'service-gift-card' }">
          <picture>
            <source media="(max-width: 991px)" srcset="/assets/img/banners/banner-gift-card-home-mobile.jpg" alt="Gift card com bitcoin">
            <img src="/assets/img/banners/banner-gift-card-home.jpg" alt="Gift card com bitcoin" />
          </picture>
        </router-link>
      </slide>
      <slide>
        <a @click="onClickReferralBanner" @click.prevent>
          <picture>
            <source media="(max-width: 991px)" srcset="/assets/img/banners/banner-indicacao-mobile.jpg" alt="Programa de indicações, indique e ganhe">
            <img src="/assets/img/banners/banner-indicacao.jpg" alt="Programa de indicações, indique e ganhe" />
          </picture>
        </a>
      </slide>
      <slide v-if="enabledBilletService">
        <router-link :to="{ name: 'service-billet' }">
          <picture>
            <source media="(max-width: 991px)" srcset="/assets/img/banners/banner-pagamento-boleto-home-mobile.jpg" alt="Pagamento de boleto com bitcoin">
            <img src="/assets/img/banners/banner-pagamento-boleto-home.jpg" alt="Pagamento de boleto com bitcoin" />
          </picture>
        </router-link>
      </slide>
      <slide v-if="enabledShipmentService">
        <router-link :to="{ name: 'service-shipment' }">
          <picture>
            <source media="(max-width: 991px)" srcset="/assets/img/banners/banner-conversao-bitcoin-home-mobile.jpg" alt="Conversão de bitcoins em minutos">
            <img src="/assets/img/banners/banner-conversao-bitcoin-home.jpg" alt="Conversão de bitcoins em minutos" />
          </picture>
        </router-link>
      </slide>
      <slide v-if="enabledRechargeService">
        <router-link :to="{ name: 'service-cell-phone' }">
          <picture>
            <source media="(max-width: 991px)" srcset="/assets/img/banners/banner-recarga-celular-home-mobile.jpg" alt="Recarga celular com bitcoin">
            <img src="/assets/img/banners/banner-recarga-home.jpg" alt="Recarga celular com bitcoin" />
          </picture>
        </router-link>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Carousel, Slide } from 'vue-carousel';
import config from '../../config';

export default {
  name: 'CarouselHome',
  components: {
    Carousel,
    Slide,
  },
  computed: {
    ...mapGetters([
      'isLogged',
      'enabledGiftcardService',
      'enabledShipmentService',
      'enabledRechargeService',
      'enabledBilletService',
      'enabledGiftcardService',
    ]),
    bannerInterval () {
      return config.intervals.banners;
    },
  },
  methods: {
    ...mapActions(['openRegisterModal', 'openReferralModal']),
    async onClickReferralBanner () {
      if (this.isLogged) {
        this.openReferralModal();
      } else {
        this.openRegisterModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';

.carousel-component {
  margin-bottom: 50px;

  .VueCarousel {
    margin: 0 0 60px;
    @media only screen and (max-width: $grid-sm-max) {
      margin: 61px 0 20px
    }

    img {
      width: 100%;
      height: 500px;
      object-fit: cover;
      @media only screen and (max-width: $grid-md-max) {
      height: auto;
      }
    }
  }

}

</style>
