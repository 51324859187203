<template>
  <section class="gateway-component">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 content">
          <h1>
            GATEWAY DE <br />
            PAGAMENTO <span class="emphasis-variant">BITCOIN</span>
          </h1>
          <p>
            Receba pagamentos em bitcoins no seu Ecommerce com gerenciamento de saldos independentes para múltiplas
            unidades de negócio.
          </p>
          <p>
            A conversão do pagamento de Bitcoins para Reais é realizada imediatamente com base no câmbio do dia.
          </p>
          <p>
            Realize o Pré-Cadastro do Gateway de Pagamento Bitcoin da PagueComBitcoin para saber mais.
          </p>
          <div class="content-action">
            <router-link
              :to="{ name: 'gateway', hash: '#formGateway' }"
              class="btn btn--secondary">
              Pré-Cadastro
            </router-link>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 gateway">
          <card-image id="gateway-card" title="Vantagens" color="yellow" align="right" style="padding: 10px 55px 10px 10px;">
            <template slot="img">
              <img
                src="/assets/img/home/sacola-gateway.png"
                alt="Receba suas vendas em bitcoins, faça seu pré cadastro"
                class="image-card"
              />
            </template>
            <template slot="body">
              <p class="title-4">Integrações para e-commerce<p>
              <div class="card-sub-title title-5">
                Principais plataformas
              </div>
              <p class="title-4">Receba na moeda que quiser<p>
              <div class="card-sub-title title-5">
                Real ou Bitcoin
              </div>
              <p class="title-4">Transferência direta por</p>
              <div class="card-sub-title title-5">
                Pix ou Boleto
              </div>
            </template>
            <template slot="action">
              <router-link :to="{ name: 'gateway' }" class="btn btn--secondary">Saiba mais</router-link>
            </template>
          </card-image>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CardImage from '../Shared/CardImage';

export default {
  name: 'Gateway',
  components: {
    CardImage,
  },
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';
@import "../../themes/mixins/layout";

.gateway-component {
  min-height: 700px;
  @media only screen and (max-width: $grid-xs-max) {
    min-height: 600px
  }
  .container {
    max-width: 1000px;
    .row {
      margin: 0;
      .content {
        h1 {
          margin: 80px 0 20px;
          text-align: left;
          @media only screen and (max-width: $grid-lg-max) {
            text-align: center
          }
          @media only screen and (max-width: $grid-sm-max) {
            min-width: 498px;
            margin: 30px auto 20px auto
          }
          @media only screen and (max-width: $grid-xs-max) {
            font-size: 25px;
            min-width: 232px;
            margin: 30px auto 20px auto
          }
        }
        p {
          max-width: 303px;
          font-family: 'Roboto', sans-serif;
          @media only screen and (max-width: $grid-lg-max) {
            max-width: 100%;
            text-align: center
          }
          @media only screen and (max-width: $grid-sm-max) {
            margin: 0 auto 20px auto;
          }
        }
        .content-action {
          display: flex;
          margin: 10px 0;
          @media only screen and (max-width: $grid-lg-max) {
            justify-content: center
          }
        }
      }
      .gateway {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        min-height: 590px;
        @media only screen and (max-width: $grid-lg-max) {
          justify-content: center;
          align-items: center
        }
        @media only screen and (max-width: $grid-md-max) {
          min-height: 500px;
          margin-bottom: 60px;
        }
        @media only screen and (max-width: $grid-xs-max) {
          min-height: 300px;
          margin-bottom: 60px;
        }

        #gateway-card{
          @media only screen and (max-width: $grid-md-max) {
            left: -130px;
          }
           @media only screen and (max-width: $grid-sm-max) {
            left: 0;
          }
        }

        .image-card {
          position: absolute;
          left: 285px;
          bottom: -30px;
          @media only screen and (max-width: $grid-md-max) {
            left: 282px;
            height: 400px;
          }
          @media only screen and (max-width: $grid-sm-max) {
            left: 220px;
            height: 219px;
            bottom: -50px;
          }
          @media only screen and (max-width: $grid-xs-max) {
            height: 164px;
            left: 166px;
            bottom: -30px;
          }
        }

        .card {
          max-width: 302px;
          padding: 10px 34px 10px 10px;
          position: relative;
          right: 263px;
          @media only screen and (max-width: $grid-lg-max) {
            right: 166px
          }
          @media only screen and (max-width: $grid-md-max) {
            right: 90px;
            padding-right: 34px;
          }
          @media only screen and (max-width: $grid-sm-max) {
            right: 0;
            text-align: left;
            max-width: 401px;
            padding: 10px 50px 10px 30px;
          }
          @media only screen and (max-width: $grid-xs-max) {
            max-width: 301px;
          }
        }

      }
    }
  }
}
</style>
