<template>
  <div class="index-bar-component">
    <h1>{{ title }}</h1>
    <div class="index-bar">
      <div class="index-items">
        <ct-index-item v-for="item in items" :item="item" :key="item.id"/>
      </div>
    </div>
  </div>
</template>

<script>
import CtIndexItem from './IndexItem';
export default {
  name: 'IndexBar',
  components: {
    CtIndexItem,
  },
  props: {
    title: {
      type: String,
      default: 'Índice',
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import '../../../themes/variables';

.index-bar-component {
  max-width: 1200px;
  margin: 0 auto;
  h1 {
    text-align: center;
    margin: 20px 0;
    @media only screen and (max-width: $grid-xs-max) {
      font-size: 23px
    }
  }
  .index-bar {
    background: $secondary-color;
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding: 0 50px;
    @media only screen and (max-width: $grid-xs-max) {
      padding: 0 10px
    }

    .index-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 10px auto;
      text-align: center;
      @media only screen and (max-width: $grid-xs-max) {
        margin: 2px auto
      }
    }
  }
}
</style>
