<script>
import CtNavbar from '@/components/Navbar/Navbar';
import CtNavbarSimple from '@/components/Navbar/NavbarSimple';
import CtRegisterModal from '@/components/Register/Modal';
import CtResetPasswordModal from '@/components/ResetPassword/ResetPasswordModal';
import CtContactModal from '@/components/Contact/ContactModal';
import CtFooter from '@/components/Dashboard/Footer';
import CtBannerAcceptTerms from '@/components/Banner/BannerAcceptTerms';
import CtStickyButton from '@/components/Shared/StickyButton';
import CtShortcutMenu from '@/components/Navbar/ShortcutMenu';
import CtContactIntercom from '@/components/Contact/ContactIntercom';
import { mapActions, mapState } from 'vuex';
import { setReferral } from './helpers/referral.helper';
import TickerService from './services/TickerService';
import { debounce } from '@/helpers/debounce';
import config from '@/config';

export default {
  name: 'App',
  components: {
    CtNavbar,
    CtNavbarSimple,
    CtRegisterModal,
    CtResetPasswordModal,
    CtContactModal,
    CtFooter,
    CtStickyButton,
    CtShortcutMenu,
    CtContactIntercom,
    CtBannerAcceptTerms,
  },
  props: {},
  computed: {
    ...mapState({
      openContactForm: (state) => state.openContactForm,
      openContactModal: (state) => state.openContactModal,
    }),
  },
  created () {
    const isContactPage = this.$route.redirectedFrom === '/contato';
    if (isContactPage) {
      this.$intercom.show();
    }

    if (this.$route.redirectedFrom && this.$route.redirectedFrom.startsWith('/cadastro')) {
      this.$store.dispatch('openRegisterModal');
    }

    if (window.location.search !== '') {
      setReferral();
    }
    this.getCurrentSettings();
    this.getTicker();
    this.initializeInterval(config.intervals.ticker);
    this.debounceGetTicker = debounce(this.getTicker, 1000, false);
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions([
      'toggleContactForm',
      'closeContactModal',
      'getCurrentSettings',
      'setTickerValue',
    ]),
    changeContactTabStatus () {
      this.toggleContactForm();
    },
    initializeInterval (time) {
      this.interval = setInterval(async () => {
        await this.debounceGetTicker();
      }, time);
    },
    async getTicker () {
      if (!this.routersServices.includes(this.$route.name)) {
        const tickerData = await TickerService.getTicker(1, 'BRL');
        this.setTickerValue(tickerData);
      }
    },
  },
  data () {
    return {
      fluid: true,
      debounceGetTicker: () => {},
      routersServices: [
        'services',
        'service-billet',
        'service-cell-phone',
        'service-shipment',
        'service-gift-card',
      ],
    };
  },
};
</script>

<template>
  <div id="app" class="page-container wrapper" :class="{'container-fluid': fluid, 'container': !fluid}">
    <ct-navbar v-if="!$route.meta.showNavbarSimple"/>
    <ct-navbar-simple v-if="$route.meta.showNavbarSimple"/>
    <ct-register-modal />
    <ct-reset-password-modal />
    <ct-contact-modal
      v-if="openContactModal"
      @close="closeContactModal"
    />
    <router-view/>
    <ct-contact-intercom />
    <ct-footer />
    <ct-sticky-button/>
    <ct-shortcut-menu v-if="!$route.meta.hideShortcutMenu"/>
    <ct-banner-accept-terms />
  </div>
</template>

<style lang="scss">
@import 'themes/style';

</style>
