<template>
  <section>
    <div class="user-menu-component" @mouseleave="close">
      <div class="navbar-info open">
        <div class="nav-items">
          <ul v-if="!isLogged" @mouseover.self="close">
            <li><a href="#" class="btn btn--primary btn-register" @click.prevent="openRegisterModal">Cadastre-se</a></li>
            <li><a href="#" @mouseover="open" class="text"><ct-person-icon class="icon" /> Login</a></li>
          </ul>
          <ul class="nav-item" v-if="isLogged" @mouseover="open">
            <li><a href="#"><ct-person-icon class="icon" /> {{ userData.name }}</a></li>
          </ul>
        </div>

        <div class="nav-login" :class="{ 'open': isFormOpen }">
          <div class="content">
            <form v-if="!isLogged">
              <div class="form-group">
                <input
                  type="email"
                  v-validate="'required|email'"
                  name="email"
                  v-model="user.email"
                  placeholder="e-mail"
                  :class="{'is-invalid': errors.has('email')}"
                  @focus="addFocus"
                  @blur="removeFocus"
                />
                <span class="msg-error" v-show="errors.has('email')">{{ errors.first('email') }}</span>
              </div>
              <div class="form-group">
                <input
                  name="password"
                  id="password"
                  data-vv-name="senha"
                  type="password"
                  placeholder="Senha"
                  v-model="user.password"
                  autocomplete="off"
                  v-validate="'required'"
                  :class="{'is-invalid': errors.has('senha')}"
                  @focus="addFocus"
                  @blur="removeFocus"
                />
                <span class="msg-error" v-show="errors.has('senha')">{{ errors.first('senha') }}</span>
              </div>
              <div class="form-action">
                <div><a href="#" @click="openResetPasswordModal" class="btn-link">Esqueceu a senha?</a></div>
                <div>
                  <a href="" @click.prevent @click="openReferralModal(); $emit('close');" class="btn-link">
                  Conheça nosso Programa de Indicação
                  </a>
                </div>
                <div class="form-action-btn">
                  <button @click="login" class="btn btn--primary">Login</button>
                </div>
                <div class="form-group" v-if="hasError">
                  <span class="msg-error-default">
                    {{ errorMessage }}
                  </span>
                </div>
              </div>
            </form>

            <div class="user-info" v-if="isLogged" @mouseover="addFocusInfo" @mouseleave="removeFocusInfo">
              <div class="form-group">
                <span>{{ userData.phone | phoneMasked }}</span>
              </div>
              <div class="form-group">
                <span>{{ userData.email | emailMasked }}</span>
              </div>
              <div class="form-action">
                <router-link :to="{ name: 'checkout-last' }" class="btn btn-action-secundary">Último pedido </router-link>
                <a @click.prevent @click="openReferralModal(); $emit('close');" class="btn btn--primary">Indique e ganhe</a>

                <a href="#" @click="logoutToken" class="logout">Sair dessa conta</a>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

    <ct-user-login-modal
      :isModalVisible="isModalLoginVisible"
      @close="closeLoginModal"
      @successLogin="closeLoginModal"
    />

    <ct-referral-modal
      v-if="isReferralModalOpen"
      @close="closeReferralModal"
    />
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import CtReferralModal from './ReferralModal';
import CtUserLoginModal from './UserLoginModal';
import phoneMasked from './../../filters/phoneMasked';
import emailMasked from './../../filters/emailMasked';
import CtPersonIcon from './../Shared/Icons/PersonIcon';

export default {
  name: 'UserMenu',
  data () {
    return {
      isModalLoginVisible: false,
      isOpenUserForm: true,
      lastScrollPx: 0,
      isTopPage: true,
      isFormOpen: false,
      hasFocus: false,
      hasFocusInfo: false,
      hasError: false,
      errorMessage: '',
      user: {},
    };
  },
  filters: {
    emailMasked,
    phoneMasked,
  },
  methods: {
    ...mapActions([
      'loginUserToken',
      'setUserTokenAuthData',
      'logoutUserToken',
      'openRegisterModal',
      'openResetPasswordModal',
      'openReferralModal',
      'closeReferralModal',
    ]),
    clickRoute ($e, nameRoute) {
      $e.preventDefault();
      this.$router.push({ name: nameRoute });
    },
    addFocus () {
      this.hasFocus = true;
    },
    removeFocus () {
      this.hasFocus = false;
    },
    addFocusInfo () {
      this.hasFocusInfo = true;
    },
    removeFocusInfo () {
      this.hasFocusInfo = false;
    },
    openLoginModal () {
      this.isModalLoginVisible = true;
    },
    closeLoginModal () {
      this.isModalLoginVisible = false;
    },
    open () {
      this.isFormOpen = true;
    },
    close () {
      if (!this.hasFocus) {
        this.isFormOpen = false;
      }
    },
    async logoutToken (e) {
      e.preventDefault();
      try {
        await this.logoutUserToken();
        this.hasError = false;
        this.errorMessage = '';
      } catch (e) {
        this.errorMessage = 'Error ao tentar deslogar, contate o suporte!';
        this.hasError = true;
      }
    },
    async login (e) {
      e.preventDefault();
      try {
        const isValid = await this.$validator.validate();
        if (isValid) {
          await this.loginUserToken(this.user);
          this.user = {};
          if (this.$intercom) {
            this.$intercom.update({
              user_id: this.user.email,
              name: this.user.name,
              email: this.user.email,
            });
          }
        }
      } catch (err) {
        this.errorMessage = err.message;
        if (err.response && err.response.status === 401) {
          this.errorMessage = 'E-mail e/ou senha inválidos. Tente novamente.';
        }

        if (err.response && err.response.status === 429) {
          this.errorMessage = 'Você já realizou muitas tentativas. Tente novamente em alguns minutos.';
        }

        this.hasError = true;
      }
    },
  },
  components: {
    CtReferralModal,
    CtUserLoginModal,
    CtPersonIcon,
  },
  computed: {
    ...mapGetters(['isLogged', 'userData']),
    ...mapState({
      isReferralModalOpen: state => state.isReferralModalOpen,
    }),
  },
  created () {
    this.setUserTokenAuthData();
  },
};
</script>

<style lang="scss" scoped>
  @import "../../themes/variables";
  @import "../../themes/mixins/layout";

  .user-menu-component {
    display: flex;
    justify-content: flex-end;
    @media print {
      display: none;
    }

    @media only screen and (max-width: $grid-md-max) {
      display: none;
    }
    .navbar-info {
      width: 100%;
      display: none;
      transition: all ease .2s;
      &.open {
        display: block;
      }

      .icon {
        margin-right: 10px;
        align-self: center;
      }

      .nav-items {
        position: relative;
        z-index: 2;
        transition: all 0.3s ease;
        .btn-register {
          @media only screen and (max-width: $grid-md-max) {
            font-size: 0.9em;
          }
        }

        ul {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          list-style: none;
          padding: 19px 0px 0px;

          @media only screen and (max-width: $grid-md-max) {
            padding: 30px 0px 0px;
          }

          li {
            display: flex;
            & + li {
              margin-left: 27px;
            }

            a {
              align-self: center;
              display: flex;
              color: $white-color;
              text-decoration: none;
              font-size: 16px;
              font-family: Oswald;
              line-height: normal;

              @media only screen and (max-width: $grid-md-max) {
                font-size: 13px;
              }
            }
          }

        }
      }

      .nav-login {
        background: $spotlight-color;
        padding: 25px;
        width: 255px;
        transition: all ease 0.2s;
        z-index: 0;
        display: none;
        &:active, &.open {
          display: flex;
          float: right;
          margin-right: -25px;
        }
        .content {
          display: block;
          align-items: flex-end;
          width: 100%;
          form {
            width: 100%;
            .form-group {
              width: 100%;
              padding: 8px 0 22px;
              position: relative;
              input {
                width: 100%;
                padding: 0 10px;
                height: 28px;
                border: none;
                &::placeholder {
                  text-align: right;
                  font-weight: 300;
                  color: $dark-grey-color;
                }
              }

              span {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin: 4px 0;
                font-size: 10px;
                color: $secondary-color;
                &.msg-error {
                  position: absolute;
                }
              }
            }

          }
          .user-info {
            display: block;
            width: 100%;
            .form-group {
              padding: 2px 0;
              span {
                color: $white-color;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 15px;
                font-size: 14.5px;
              }
            }

            .btn-action-secundary {
              padding-right: 0;
            }

          }
          span {
            &.msg-error-default {
              background: $light-grey-color;
              width: 100%;
              display: flex;
              padding: 5px;
              justify-content: center;
              align-items: center;
              min-height: 28px;
              margin-top: 5px;
              font-size: 10px;
              color: $secondary-color;
            }
          }
        }
      }
    }

    .form-action {
      display: block;
      text-align: right;
      .btn-action-secundary {
        margin: 0 0 15px;
      }
      .logout {
        color: $white-color;
        display: block;
        margin: 25px 0 0;
        font-size: 11px;
      }
      .form-action-btn {
        margin-top: 20px;
      }
    }
  }
</style>
