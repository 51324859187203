import uuid from 'uuid/v4';

export default {
  CLEAR_INVOICE (state, _) {
    state.invoice = {
      billets: [],
      sellBtc: [],
      recharges: [],
      giftCards: [],
      pix: [],
      itemsErrors: [],
      network: 'BTC',
    };
  },
  SET_BILLET (state, payload) {
    state.invoice.billets.splice(payload.index, 1, payload.billet);
  },
  SET_PIX (state, payload) {
    state.invoice.pix.splice(payload.index, 1, payload.pix);
  },
  SET_BTC (state, payload) {
    state.invoice.sellBtc.splice(payload.index, 1, payload.sellBtc);
  },
  SET_RECHARGE (state, payload) {
    state.invoice.recharges.splice(payload.index, 1, payload.recharge);
  },
  SET_GIFTCARDS (state, payload) {
    state.invoice.giftCards = payload;
  },
  ADD_BILLET (state, payload) {
    payload.uuid = uuid();
    state.invoice.billets.push(payload);
  },
  ADD_BTC (state, payload) {
    payload.uuid = uuid();
    state.invoice.sellBtc.push(payload);
  },
  ADD_PIX (state, payload) {
    payload.uuid = uuid();
    state.invoice.pix.push(payload);
  },
  ADD_RECHARGE (state, payload) {
    payload.uuid = uuid();
    state.invoice.recharges.push(payload);
  },
  ADD_GIFT_CARD (state, payload) {
    payload.uuid = uuid();
    state.invoice.giftCards.push(payload);
  },
  REMOVE_BILLET (state, key) {
    state.invoice.billets.splice(key, 1);
  },
  REMOVE_BTC (state, key) {
    state.invoice.sellBtc.splice(key, 1);
  },
  REMOVE_PIX (state, key) {
    state.invoice.pix.splice(key, 1);
  },
  REMOVE_RECHARGE (state, key) {
    state.invoice.recharges.splice(key, 1);
  },
  REMOVE_GIFT_CARD (state, key) {
    state.invoice.giftCards.splice(key, 1);
  },
  SET_ITEMS_ERRORS (state, payload) {
    state.invoice.itemsErrors = payload.errors || payload;
  },
  SET_INVOICE_NETWORK (state, network) {
    state.invoice.network = network;
  },
};
