const getServiceStatus = (state, serviceName) => {
  const services = (state && state.settings && state.settings.status && state.settings.services) ? state.settings.services : false;
  return services && services.status && services.status[serviceName] ? services.status[serviceName] || false : false;
};

export default {
  enabledPixService: (state) => {
    return getServiceStatus(state, 'pix');
  },
  enabledShipmentService: (state) => {
    return getServiceStatus(state, 'shipment');
  },
  enabledRechargeService: (state) => {
    return getServiceStatus(state, 'recharge');
  },
  enabledBilletService: (state) => {
    return getServiceStatus(state, 'billet');
  },
  enabledGiftcardService: (state) => {
    return getServiceStatus(state, 'giftcard');
  },
  notEnabledServices: (_, getters) => {
    return !getters.enabledPixService &&
           !getters.enabledShipmentService &&
           !getters.enabledRechargeService &&
           !getters.enabledBilletService &&
           !getters.enabledGiftcardService;
  },
};
