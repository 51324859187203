<template>
      <form>
      <div class="form-group">
        <input
          type="text"
          name="name"
          placeholder="Nome Completo"
          v-model="contact.name"
          v-validate="'required|alpha_spaces'"
          data-vv-as="nome"
        />
        <span class="msg-error" v-show="errors.has('name')">{{ errors.first('name') }}</span>
      </div>

      <div class="form-group">
        <input
          type="email"
          name="email"
          placeholder = "e-mail"
          v-model="contact.email"
          v-validate="'required|email'"
          data-vv-as="email"
        />
        <span class="msg-error" v-show="errors.has('email')">{{ errors.first('email') }}</span>
      </div>

      <div class="form-group">
        <input
          name="title"
          type="text"
          placeholder="Assunto"
          autocomplete="off"
          v-model="contact.subject"
          v-validate="'required|alpha_spaces'"
          data-vv-as="assunto"
        />
        <span class="msg-error" v-show="errors.has('title')">{{ errors.first('title') }}</span>
      </div>
      <div class="form-group">
        <textarea
          class="message"
          name="message"
          type="text"
          maxlength="5000"
          placeholder="Sua mensagem"
          v-model="contact.message"
          v-validate="'required'"
          data-vv-as="mensagem"
        />
        <span class="msg-error" v-show="errors.has('message')">{{ errors.first('message') }}</span>
      </div>

      <vue-recaptcha
      :loadRecaptchaScript="true"
      sitekey="6LdF-a0UAAAAAMi7f8zgckY4inTNiE5_9bfYKl48"
      ref="invisibleRecaptcha"
      size="invisible"
      @verify="onCaptchaVerified"
      @expired="onCaptchaExpired"
      />

      <div class="form-action">
        <a class="btn btn--primary" @click="postContact()">Enviar</a>
      </div>

      <div class="alert" v-if="hasError.status" :class="{'alert-error': hasError.status}" >
        {{hasError.message}}
      </div>
      <div class="alert" v-if="hasSuccess.status" :class="{'alert-success': hasSuccess.status}" >
        {{hasSuccess.message}}
      </div>

      <div class="recaptcha">
        <small class="recaptcha-policy">
          Este site está protegido por reCAPTCHA e Google
          <a href="https://policies.google.com/privacy">Politica de privacidade</a> e
          <a href="https://policies.google.com/terms">Termos de uso</a>
        </small>
      </div>
    </form>
</template>

<script>
import ContactService from '@/services/ContactService';
import { mapGetters } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'FormContact',
  data: () => {
    return {
      contact: {
        name: '',
        email: '',
        subject: '',
        message: '',
        recaptchaToken: '',
      },
      hasError: {
        status: false,
        message: 'Erro ao enviar sua mensagem. Tente novamente em alguns minutos.',
      },
      hasSuccess: {
        status: false,
        message: 'Sua mensagem foi enviada. Em breve retornaremos seu contato.',
      },
    };
  },
  components: {
    VueRecaptcha,
  },
  computed: {
    ...mapGetters(['isLogged', 'userData']),
  },
  created () {
    if (this.isLogged) {
      this.contact.name = this.userData.name;
      this.contact.email = this.userData.email;
    }
  },
  methods: {
    resetForm () {
      this.contact = {
        name: '',
        email: '',
        subject: '',
        message: '',
        recaptchaToken: '',
      };
      this.$nextTick()
        .then(() => {
          this.$validator.reset();
          this.errors.clear();
        });
      this.onCaptchaExpired();
    },
    async onCaptchaVerified (recaptchaToken) {
      try {
        const localContact = {
          origin: 'pague',
          name: this.contact.name,
          email: this.contact.email,
          subject: this.contact.subject,
          message: this.contact.message,
          recaptcha: recaptchaToken,
        };
        this.post = await ContactService.sendContact(localContact);
        this.hasSuccess.status = true;
        this.resetForm();
      } catch (err) {
        console.error(err);
        this.hasError.status = true;
      }
    },
    onCaptchaExpired () {
      this.$refs.invisibleRecaptcha.reset();
    },
    async postContact () {
      const isValid = await this.$validator.validate();
      if (isValid) {
        this.$refs.invisibleRecaptcha.execute();
      };
    },
  },

};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

 .form-group{
    padding: 10px 0;
    input, textarea{
      width: 100%;
      padding: 0 10px;
      height: 28px;
      border: none;
      &.message{
        min-height: 100px;
      }
    }
    textarea{
      resize: vertical;
      max-height: 300px;
      padding-top: 5px;

    }
    .msg-error{
      color: $secondary-color;
    }
  }

  .alert{
    padding: 5px 3px;
    height: auto;

    &.alert-success{
      color: $green-color;
      background-color: $ice-color;
    }
  }

  .recaptcha {
    padding-top: 10px;
    .recaptcha-policy {
      font-size: 10px;
      text-align: right;
      color: #737c84;
      a {
        color: #2377af;
      }
    }
  }

</style>
