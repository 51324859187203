import Vue from 'vue';
import App from './App.vue';
import router from './router';
import config from './config';
import store from './store/store';
import VueI18n from 'vue-i18n';
import pt from './languages/pt';
import VeeValidate, { Validator } from 'vee-validate';
import VueGtm from 'vue-gtm';
import VueGtag from 'vue-gtag';
import ptValidation from 'vee-validate/dist/locale/pt_BR';
import enValidation from 'vee-validate/dist/locale/en';
import { cpfValidator, cnpjValidator } from './validators/document.validator';
import { emailValidator } from './validators/email.validator';
import { fullNameValidator } from './validators/name.validator';
import { cellPhoneValidator, cellPhoneWithDDDValidator } from './validators/cellphone.validator';
import { zipCodeValidator } from './validators/zipcode.validator';
import { uuidValidator } from './validators/uuid.validator';
import './registerServiceWorker';

import VueMeta from 'vue-meta';
import VueIntercom from 'vue-intercom';

Validator.extend('cpf', cpfValidator);
Validator.extend('cnpj', cnpjValidator);
Validator.extend('fullName', fullNameValidator);
Validator.extend('cellPhone', cellPhoneValidator);
Validator.extend('cellPhoneWithDDD', cellPhoneWithDDDValidator);
Validator.extend('zipCode', zipCodeValidator);
Validator.extend('email', emailValidator);
Validator.extend('uuid', uuidValidator);

Vue.config.productionTip = config.isProduction;

Vue.use(VueI18n);
const i18n = new VueI18n({ locale: 'pt', messages: { pt: pt } });

Vue.use(VeeValidate, {
  i18nRootKey: 'validations',
  i18n,
  dictionary: {
    en: enValidation,
    pt: ptValidation,
  },
});
Vue.use(VueMeta);

if (config.analytics.id) {
  Vue.use(VueGtag, {
    config: { id: config.analytics.id },

    // id: config.analytics.id,
    // router: router,
    // debug: {
    //   sendHitTask: config.isProduction,
    // },
  });
}

if (config.intercom && config.intercom.id) {
  Vue.use(VueIntercom, { appId: config.intercom.id });
}

if (config.gtm.id) {
  Vue.use(VueGtm, {
    id: config.gtm.id,
    enabled: true,
    debug: false,
    vueRouter: router,
    ignoredViews: [],
  });
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

window.OneSignal = window.OneSignal || [];
window.OneSignal.push(function () {
  window.OneSignal.init({
    appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
    allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production',
    autoResubscribe: true,
    notifyButton: {
      enable: true,
    },
  });
});
