<template>
  <svg version="1.1" class="cell-phone-icon" :class="{type: true}" :style="{ height: size }" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 248.8 389.6" style="enable-background:new 0 0 248.8 389.6;" xml:space="preserve">
    <g>
      <path d="M116,181.56v52.23H99.84c-4.64,0-8.4,3.76-8.4,8.4c0,4.64,3.76,8.4,8.4,8.4H116v7.73c0,4.64,3.76,8.4,8.4,8.4
        c4.64,0,8.4-3.76,8.4-8.4v-8.57c19.42-3.91,34.1-21.1,34.1-41.66c0-20.56-14.68-37.75-34.1-41.67V114.2h13.96
        c4.64,0,8.4-3.76,8.4-8.4c0-4.64-3.76-8.4-8.4-8.4H132.8v-7.73c0-4.64-3.76-8.4-8.4-8.4c-4.64,0-8.4,3.76-8.4,8.4v8.57
        c-19.43,3.91-34.1,21.1-34.1,41.66C81.9,160.46,96.57,177.65,116,181.56L116,181.56z M150.1,208.1c0,11.23-7.24,20.79-17.3,24.28
        v-48.56C142.86,187.31,150.1,196.87,150.1,208.1L150.1,208.1z M116,115.62v48.56c-10.06-3.49-17.3-13.05-17.3-24.28
        C98.7,128.67,105.94,119.11,116,115.62L116,115.62z M116,115.62"/>
      <path d="M224.4,0h-200C10.94,0,0,10.95,0,24.4v340.8c0,13.45,10.94,24.4,24.4,24.4h200c13.46,0,24.4-10.95,24.4-24.4V24.4
        C248.8,10.95,237.86,0,224.4,0L224.4,0z M24.4,16.8h200c4.19,0,7.6,3.41,7.6,7.6v276.4H16.8V24.4C16.8,20.21,20.21,16.8,24.4,16.8
        L24.4,16.8z M224.4,372.8h-200c-4.19,0-7.6-3.41-7.6-7.6v-47.6H232v47.6C232,369.39,228.59,372.8,224.4,372.8L224.4,372.8z
        M224.4,372.8"/>
      <path d="M135.2,333.59h-21.6c-4.64,0-8.4,3.76-8.4,8.4c0,4.64,3.76,8.4,8.4,8.4h21.6c4.64,0,8.4-3.76,8.4-8.4
        C143.6,337.35,139.84,333.59,135.2,333.59L135.2,333.59z M135.2,333.59"/>
    </g>
  </svg>

</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '27px',
    },
  },
};
</script>

<style lang="scss">
  @import "../../../themes/variables";

  .cell-phone-icon {
    fill: $spotlight-color;
  }
</style>
