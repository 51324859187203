const getState = () => ({
  modalVisible: false,
  registeringUser: {},
  registerFormErrors: [],
  verificationToken: '',
  verificationCode: '',
  registrationStep: 'REGISTER', // 'REGISTER', 'DUPLICATE', 'VERIFY', 'VERIFY-ERROR', 'SUCCESS', 'ERROR'
});

export default Object.assign(getState(), { getState: getState });
