import http from '@/services/HttpService';
import { getReferral } from './../helpers/referral.helper';

export default {
  async registerUser (userData) {
    try {
      const phoneCode = userData.phoneCode.split(':')[0];
      const data = {
        country: userData.country,
        document: userData.document.replace(/[^0-9]+/g, ''),
        kind: userData.kind,
        phoneCode: phoneCode,
        phone: userData.phone.replace(/[^0-9]+/g, ''),
        email: userData.email,
        name: userData.name,
        password: userData.password,
        addresses: userData.addresses,
        acceptNotification: userData.acceptNotification || false,
      };
      const referralId = getReferral();
      if (referralId) {
        data.referralId = referralId;
      }

      const res = await http().post('/api/v1/users/register', data);
      return { status: 'SUCCESS', token: res.data.data.token };
    } catch (error) {
      if (error.response && error.response.data.errors['DUPLICATED_USER']) {
        return { status: 'DUPLICATE' };
      }
      if (error.response && error.response.data.errors['DUPLICATED_USER_PHONE']) {
        return { status: 'DUPLICATE_PHONE' };
      }
      if (error.response && error.response.data.errors['INVALID_USER_PHONE']) {
        return { status: 'INVALID_PHONE' };
      }
      if (error.response && error.response.data.errors['VALIDATION_ERROR']) {
        return { status: 'INVALID', errors: error.response.data.errors.fields };
      }
      if (error.response && error.response.data.errors['INVALID_USER_TEMP_PHONE']) {
        return { status: 'INVALID_TEMP_PHONE', message: error.response.data.message };
      }
      if (error.response && error.response.data.errors['INVALID_USER_TEMP_EMAIL']) {
        return { status: 'INVALID_TEMP_EMAIL', message: error.response.data.message };
      }
      return { status: 'ERROR' };
    }
  },
  async hasUser (userData) {
    try {
      const data = {
        document: userData.document.replace(/[^0-9]+/g, ''),
        kind: userData.kind,
        email: userData.email,
        name: userData.name,
        password: userData.password,
      };
      const res = await http().post('/api/v1/users/register/hasUser', data);
      return res.data;
    } catch (error) {
      console.error(error);
      return { status: 'ERROR' };
    }
  },
  async verifyUser (token, code) {
    try {
      const res = await http().post('/api/v1/users/register/verify', { token, code });
      const tokenReturn = res && res.data && res.data.data && res.data.data.token ? res.data.data.token : false;
      if (!tokenReturn) {
        return { status: 'VALIDATING' };
      }
      return { status: 'SUCCESS', token: tokenReturn };
    } catch (error) {
      if (error.response &&
          (error.response.data.errors['INVALID_VERIFICATION_TOKEN'] ||
           error.response.data.errors['INVALID_VERIFICATION_CODE'] ||
           error.response.data.errors['VALIDATION_ERROR'])) {
        return { status: 'INVALID' };
      }
      console.error(error);
      return { status: 'ERROR' };
    }
  },
};
