import http from '@/services/HttpService';

export default {
  async getOperatorsForSelect () {
    try {
      const res = await http().get('/api/v1/giftcards/operators');
      const operators = res.data && res.data.data ? res.data.data : [];
      operators.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
      return operators;
    } catch (e) {
      return [];
    }
  },
  async getAmountsForSelect (operator) {
    try {
      const res = await http().get(`/api/v1/giftcards/values/${operator}`);
      const amounts = res.data && res.data.data ? res.data.data : [];
      amounts.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
      return amounts;
    } catch (e) {
      return [];
    }
  },
};
