<template>
  <div class="error-404-component">
    <div class="container">
      <ct-errors-page
        :status="status"
        :details="details"
        :instruction="instruction"
        :icon="icons"
      />
    </div>
  </div>
</template>

<script>
import CtErrorsPage from '@/components/Shared/Errors';

export default {
  name: 'Error404',
  metaInfo: {
    title: 'Página não encontrada',
    meta: [
      { name: 'description', content: 'Página não encontrada. O endereço informado não existe ou não foi informado corretamente.' },
    ],
  },
  data () {
    return {
      status: '404',
      details: 'Página não encontrada',
      instruction: 'Verifique se o endereço digitado está correto.',
      icons: {
        font: 'computer',
        destiny: 'cloud',
      },
    };
  },
  components: {
    CtErrorsPage,
  },
};
</script>

<style lang="scss" scoped>
@import "../themes/variables";

.error-404-component {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: $grid-lg-max) {
      min-height: calc(100vh - 124px)
    }
    @media only screen and (max-width: $grid-sm-max) {
      min-height: calc(100vh - 62px)
    }
  }
}
</style>
