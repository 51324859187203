<template>
  <div class="dashboard-component">
    <ct-carousel-home />
    <ct-home />
    <ct-depositions :depositions="depositions" />
  </div>
</template>

<script>
import CtCarouselHome from '@/components/ContentHome/CarouselHome';
import CtDepositions from '@/components/Depositions/Depositions';
import CtHome from '@/components/ContentHome/Home';
import config from '@/config';
import depositions from '@/config/depositions';

export default {
  name: 'Dashboard',
  components: {
    CtCarouselHome,
    CtDepositions,
    CtHome,
  },
  data () {
    return {
      depositions,
      pageInfo: {
        title: 'Bem-vindo à PagueComBitcoin',
        description: 'Pagamento de boletos, Pix, conversão de bitcoins, recarga de celular e gift cards em um só lugar. Bem-vindo à PagueComBitcoin.',
        image: '/assets/img/banners/banner-pagamento-boleto-home.jpg',
      },
    };
  },
  metaInfo () {
    return {
      title: this.pageInfo.title,
      meta: [
        { name: 'title', content: this.pageInfo.title },
        { name: 'description', content: this.pageInfo.description },
        { name: 'image', content: config.app.url + this.pageInfo.image },
        { property: 'og:title', content: this.pageInfo.title },
        { property: 'og:description', content: this.pageInfo.description },
        { property: 'og:image', content: config.app.url + this.pageInfo.image },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.pageInfo.title },
        { name: 'twitter:description', content: this.pageInfo.description },
        { name: 'twitter:image', content: config.app.url + this.pageInfo.image },
      ],
    };
  },
};
</script>

<style>

</style>
