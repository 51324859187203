<template>
<div>
  <div id="servicos">
    <h2 class="info-content-tile">Serviços</h2>
      <div id="servicos-pagamento">
        <h3 class="info-content-tile">Serviços de pagamento</h3>
        <h4 class="title-4">Quais serviços são oferecidos pela paguecombitcoin?</h4>
        <p>
          Serviços de intermediação de pagamentos com Bitcoin::
          <router-link :to="{ name: 'service-billet' }" >pagamento de boleto bancário</router-link>,
          <router-link :to="{ name: 'service-shipment' }">conversão de bitcoins em reais</router-link>,
          <router-link :to="{ name: 'service-pix' }">bitcoin por pix</router-link>,
          <router-link :to="{ name: 'service-cell-phone' }">recarga de celular pré-pago</router-link> e
          <router-link :to="{ name: 'service-gift-card' }">compra de gift card (cartão presente)</router-link> e
          <router-link :to="{ name: 'gateway' }">gateway de pagamento em bitcoins para e-commerce</router-link>.
        </p>
        <p>
          Somos o primeiro meio de pagamento cripto da América Latina e o segundo
          negócio cripto com integração Binance Smart Chain (BEP 20).
        </p>
        <p>
          Todos os serviços da Pague Com Bitcoin estão automatizados, 24 horas e 7
          dias por semana.
        </p>
        <p>
          Para operações em grandes volumes, oferecemos atendimento personalizado.
          Solicite mais informações pelo email
          <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>
          ou entre em contato pelo nosso chat.
        </p>

        <h4 class="title-4">Como utilizar os serviços da PagueComBitcoin?</h4>
        <p>
          Para utilizar os serviços da PagueComBitcoin, siga os passos abaixo:
        </p>

        <ul class="simple-list number-list">
          <li>Faça seu <a href="#" @click.prevent="openLoginModal" class="text weight-bold">Login</a> ou
            <a class="weight-bold" @click="openRegisterModal">Cadastre-se aqui</a>;
          </li>
          <li>
           Selecione, no <span class="weight-bold">Menu de Serviços</span>, os itens que deseja solicitar:
           Pagamento de Boleto, Gift Card, Recarga de Celular, Conversão de Bitcoin ou
           Bitcoin por Pix;
          </li>
          <li>Escolha a rede em que deseja enviar seus btcs (Bitcoin ou BEP20)</li>
          <li>Preencha todos os campos do item selecionado corretamente;</li>
          <li>Clique no botão Finalizar Pedido para ser direcionado à página de Checkout;</li>
          <li>
            Realize a transação no valor solicitado para o endereço de carteira indicado
            em até {{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }}
            minutos;
          </li>
          <li>
            Após a confirmação da transação, realizamos o processamento dos itens do seu
            pedido automaticamente;
          </li>
          <li>
            Pronto! Você pode acompanhar seu pedido em tempo real pela página de checkout
            e/ou pelas atualizações enviadas para o seu e-mail.
          </li>
        </ul>

        <h4>Posso solicitar mais de um serviço no mesmo pedido?</h4>
        <p>
          Claro! Não existe limite na quantidade de itens e ou serviços.
          Por exemplo: em um único pedido você pode fazer pagamento
          de boletos, realizar uma conversão de bitcoins em reais para sua conta ou
          terceiros por  <span class="weight-bold">TED  ou PIX</span>, fazer a recarga
          de celular pré-pago e comprar gift cards.
        </p>

        <h4>A PagueComBitcoin cobra taxa de serviço?</h4>
        <p>
          Cobramos uma taxa fixa de conveniência para cada um dos serviços que disponibilizamos. Consulte mais em
          <router-link :to="{ name: 'taxes' }">Taxas e Informações</router-link>.
        </p>
      </div>

      <div id="pagamento-boleto" v-if="enabledBilletService">
        <h3 class="info-content-tile">Pagamento de boleto</h3>
        <h4>Como fazer o pagamento de boleto com bitcoin?</h4>
        <p>Para realizar o pagamento de boleto com bitcoin, siga os passos abaixo:</p>
        <ul class="simple-list number-list">
          <li>Faça seu <a href="#" @click.prevent="openLoginModal" class="text weight-bold">Login</a> ou
            <a class="weight-bold" @click="openRegisterModal">Cadastre-se aqui</a>;
          </li>
          <li>Acesse no menu <router-link :to="{ name: 'service-billet' }">Pagamento de Boleto</router-link></li>
          <li>
            Preencha todos os campos corretamente: Linha Digitável, data de vencimento,
            valor de desconto ou juros/multa (caso aplicável) e valor total do boleto;
          </li>
          <li>Escolha a rede em que deseja enviar seus btcs (Bitcoin ou BEP20)</li>
          <li>Preencha todos os campos do item selecionado corretamente;</li>
          <li>Clique no botão Finalizar Pedido para ser direcionado à página de Checkout;</li>
          <li>
            Realize a transação no valor solicitado para o endereço de carteira indicado
            em até {{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }}
            minutos;
          </li>
          <li>
            Após a confirmação da transação, será realizado o processamento do seu pedido;
          </li>
          <li>
            Pronto! O pagamento de boleto será processado em alguns minutos, com prazo de
            compensação bancária de 01 a 03 dias úteis dependendo do banco emissor.
          </li>
        </ul>

        <h4>Quais são os tipos de boletos aceitos pela PagueComBitcoin?</h4>
        <p>
          Você pode pagar com bitcoin: boletos de cobrança (cartão de crédito, compras
          online, financiamentos, condomínio), boletos de consumo (água, luz, telefone,
          gás, etc) e boletos de recolhimento de taxas com código de barras, como DAS,
          DARF, etc. Os guias de recolhimento de imposto sem código de barras,
          infelizmente, não são suportados
        </p>
        <p>
          <span class="text-underline">
            Nossa plataforma não realiza validação de linhas digitáveis de boletos iniciadas com   dígito   “8”
          </span>
          , como de boletos de consumo (água, luz, telefone, gás, condomínio, etc) ou guias de pagamento de
          taxas e tributo, ou  seja, não  é possível   identificar   eventuais   erros   de   digitação,
          por isso recomendamos a atenção no preenchimento dos dados desses boletos para evitar erros
          de qualquer natureza
        </p>

        <h4>Posso pagar boletos com desconto ou juros/mora no valor?</h4>
        <p>
          Sim! Basta informar o valor exato do <span class="weight-bold">desconto ou juros/mora</span> no campo
          específico. É fundamental informar os valores corretamente para evitar qualquer erro durante o processamento
          do pagamento.
        </p>

        <h4>Existe um valor mínimo para pagamento de boletos? E valor máximo?</h4>
        <p>
          O valor mínimo para pagamento de boletos é de R$ {{ (settings.restriction && settings.restriction.minBilletValue ? settings.restriction.minBilletValue : 0) | currency }}.
          Não existe valor máximo.
        </p>

        <h4>Qual é o prazo de confirmação de pagamento de boleto?</h4>
        <p>
          Após a confirmação da rede bitcoin ou BSC, seu boleto será processado automaticamente. Pedidos finalizados
          após às 17h ou aos finais de semana e feriados  serão compensados somente no próximo dia útil bancário.
          O prazo médio de compensação bancária é de <span class="weight-bold">01 a 03 dias</span> úteis dependendo do
          banco emissor. Em caso de dúvidas, entre em contato com o banco.
        </p>

        <h4>Qual é a taxa de serviço para pagamento de boleto?</h4>
        <p>
          Cobramos uma taxa de conveniência de R$ {{ (settings.itemFees && settings.itemFees.billet ? settings.itemFees.billet : 0) | currency }} por cada Pagamento de Boleto.
        </p>

        <h4>Como obter comprovante de pagamento de boleto?</h4>
        <p>
          Basta solicitar respondendo um dos e-mails de status do pedido. Caso não localize, você também pode enviar
          sua solicitação para <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a> a partir do
          endereço eletrônico utilizado no cadastro. Atenção ao assunto: Comprovante Bancário e o Código do Pedido
          </p>
      </div>

      <div id="conversao-de-bitcoins" v-if="enabledShipmentService">
        <h3 class="info-content-tile">Conversão de bitcoin</h3>
        <h4>Como fazer a conversão de bitcoins em reais?</h4>
        <p>
          Para realizar uma conversão de bitcoins em reais sem necessidade de uma exchange, siga os passos abaixo:
        </p>
        <ul class="simple-list number-list">
          <li>Faça seu <a href="#" @click.prevent="openLoginModal" class="text weight-bold">Login</a> ou
              <a class="weight-bold" @click="openRegisterModal">Cadastre-se aqui</a>;
          </li>
          <li>Acesse no menu <router-link :to="{ name: 'service-shipment' }">Conversão de Bitcoins</router-link>;
          </li>
          <li>
           Preencha corretamente todos os campos da Conta Bancária para transferência em reais, seja ela sua ou de
           terceiros. Caso a conta pertença a um terceiro, informe os dados cadastrais do Titular da Conta (dados
           incorretos impossibilita a realização da transferência);
          </li>
          <li>
           Escolha a rede em que deseja enviar seus btcs (Bitcoin ou BEP20)
          </li>
          <li>Clique no botão Finalizar Pedido para ser direcionado à página de Checkout;</li>
          <li>
            Realize a transação do valor indicado em bitcoins para o endereço de carteira especificado em até
            {{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos;
          </li>
          <li>
            Após a confirmação da transação, a transferência bancária será executada automaticamente. O tempo de
            compensação da TED é possível ser verificado na instituição bancária de destino;
          </li>
        </ul>

        <h4>Para quais contas posso fazer a transferência da conversão de bitcoins em reais?</h4>
        <p>
          Você pode fazer a transferência para uma conta de sua titularidade ou de terceiros, seja pessoa física ou
          pessoa jurídica. No caso de <span class="weight-bold">transferência para Conta de Terceiros</span>, é
          necessário informar corretamente o nome completo ou Razão Social do titular da conta, CPF/ CNPJ do titular,
          um e-mail válido do terceiro e selecionar a finalidade da operação.
        </p>

        <h4>Existe um valor mínimo para fazer a conversão de bitcoin? E valor máximo?</h4>
        <p>
          Para garantia de agilidade da conversão de bitcoin em real, venda de bitcoins, o serviço é limitado em até
          R$ {{ (settings.restriction && settings.restriction.maxShipmentValue ? settings.restriction.maxShipmentValue : 0) | currency }} por
          conversão, limitado em até R$ {{ (settings.restriction && settings.restriction.maxShipmentDailyLimitValue ? settings.restriction.maxShipmentDailyLimitValue : 0) | currency }} por dia e em até
          R$ {{ (settings.restriction && settings.restriction.maxShipmentMonthlyLimitValue ? settings.restriction.maxShipmentMonthlyLimitValue : 0) | currency }} por mês.
        </p>
        <p>
          Precisa realizar TEDs maiores? Envia um e-mail para
          <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>.
        </p>

        <h4>Qual é o prazo de uma operação de conversão de bitcoin?</h4>
        <p>
          Assim que a transação de bitcoins é confirmada pela rede, realizamos a transferência bancária para a conta
          informada automaticamente. Pedidos finalizados em dias úteis após às 16h30 ou aos finais de semana e feriados
          serão processados somente no próximo dia útil devido encerramento do período bancário.
        </p>

        <h4>Qual é a taxa de serviço para conversão de bitcoin?</h4>
        <p>
          Cobramos uma taxa de conveniência de R$
          {{ (settings.itemFees && settings.itemFees.shipment ? settings.itemFees.shipment : 0) | currency }} para cada
          item de Conversão de Bitcoin em real. No caso de dados incorretos, será cobrada nova taxa de conversão para
          cada tentativa de transferências.
        </p>

        <h4>Como obter comprovante de transferência bancária?</h4>
        <p>
          Basta solicitar respondendo um dos e-mails de status do pedido. Caso não localize, você também pode enviar
          sua solicitação para <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a> a partir do
          endereço eletrônico utilizado no cadastro. Atenção ao assunto: Comprovante Bancário e o Código do Pedido (ID).
        </p>
      </div>

      <div id="bitcoin-por-pix" v-if="enabledPixService">
        <h3 class="info-content-tile">Bitcoin por Pix</h3>

        <h4>COMO FAZER A CONVERSÃO DE BITCOINS EM REAIS E TRANSFERIR POR PIX?</h4>
        <p>
          Para realizar a troca dos bitcoins em reais e receber por pix, siga os passos a seguir:
        </p>
        <ul class="simple-list number-list">
          <li>Faça seu <a href="#" @click.prevent="openLoginModal" class="text weight-bold">Login</a> ou
              <a class="weight-bold" @click="openRegisterModal">Cadastre-se aqui</a>;
          </li>
          <li>Acesse no menu <router-link :to="{ name: 'service-pix' }">Bitcoin por Pix</router-link>;
          </li>
          <li>
           Preencha corretamente todos os campos: sua Chave Pix e valor em reais que deseja transferir. Para conta de
           terceiros, é só informar: Chave PIX da pessoa ou empresa; titular da conta (nome completo ou razão social),
           documento de identificação (CPF ou CNPJ), e-mail, finalidade da transferência e valor em reais.
          </li>
          <li>
           Escolha a rede em que deseja enviar seus btcs (Bitcoin ou BEP20)
          </li>
          <li>Clique no botão Finalizar Pedido para ser direcionado à página de Checkout;</li>
          <li>
            Realize a transação do valor indicado em bitcoins para o endereço de carteira especificado em até
            {{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos;
          </li>
          <li>
            Após a confirmação da transação, a transferência bancária será executada automaticamente em instantes.
          </li>
        </ul>

        <h4>PARA QUAIS CONTAS POSSO FAZER A TRANSFERÊNCIA DA CONVERSÃO DE BITCOINS EM REAIS VIA PIX?</h4>
        <p>
          Você pode fazer a transferência com sua chave PIX ou chave de terceiros, seja pessoa física ou pessoa jurídica.
          No caso de <span class="weight-bold">PIX para Conta de Terceiros</span>, é necessário informar corretamente o
          Nome Completo ou Razão Social do Titular da Conta, CPF/ CNPJ do titular, um e-mail válido do terceiro e
          selecionar a finalidade da operação.
        </p>

        <h4>Existe um valor mínimo para fazer a transfrência por PIX? E valor máximo?</h4>
        <p>
          Para garantia de agilidade da conversão de bitcoin em real, venda de bitcoins, o serviço é limitado em até
          R$ {{ (settings.restriction && settings.restriction.maxShipmentValue ? settings.restriction.maxShipmentValue : 0) | currency }} por
          conversão, limitado em até R$ {{ (settings.restriction && settings.restriction.maxShipmentDailyLimitValue ? settings.restriction.maxShipmentDailyLimitValue : 0) | currency }} por dia e em até
          R$ {{ (settings.restriction && settings.restriction.maxShipmentMonthlyLimitValue ? settings.restriction.maxShipmentMonthlyLimitValue : 0) | currency }} por mês.
        </p>
        <p>
          Precisa realizar PIXs maiores? Envia um e-mail para
          <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>.
        </p>

        <h4>Qual é o prazo de uma operação de conversão de bitcoin por PIX?</h4>
        <p>
         Assim que a transação de bitcoins é confirmada pela rede, realizamos a transferência bancária por pix para a
         conta informada automaticamente. O tempo médio estimado é de 5 minutos e o prazo máximo é de 1 dia útil.
        </p>

        <h4>Qual é a taxa de serviço para conversão de bitcoin por PIX?</h4>
        <p>
          Cobramos uma taxa de conveniência de R$
          {{ (settings.itemFees && settings.itemFees.pix ? settings.itemFees.pix : 0) | currency }} para cada
          item de Conversão de Bitcoin por Pix.
        </p>

        <h4>Como obter comprovante do Pix?</h4>
        <p>
          Basta solicitar respondendo um dos e-mails de status do pedido. Caso não localize, você também pode enviar
          sua solicitação para <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a> a partir do
          endereço eletrônico utilizado no cadastro. Atenção ao assunto: Comprovante Bancário e o Código do Pedido (ID).
        </p>

        <h4>Pix Copia e Cola</h4>
        <p>O Pix Copia e Cola é uma das funcionalidades utilizadas pelo comércio físico e
          virtual. Ele basicamente serve para que todas as instituições que operam com o
          Pix consigam, de forma segura, estabelecer algumas limitações no pagamento do
          produto ou serviço.
        </p>
        <p>
          Este método, em geral, oferece prazo limitado para realizar o pagamento. Depois
          que o prazo expira, a loja pode não realizar a compensação do pagamento e
          cancelar o pedido.
        </p>
        <p>
          Aqui na PagueComBitcoin, oferecemos o Pix Copia e Cola para que você possa
          utilizar o Bitcoin no seu dia a dia. Porém, por se tratar de transações cripto, você
          precisa se atentar a alguns detalhes antes de  optar pelo Pix Copia e Cola como
          meio de pagamento.
        </p>
        <ul class="simple-list number-list">
          <li>
            O Pix aqui na PagueComBitcoin somente será realizado após a confirmação da
            rede Bitcoin ou Binance Smart Chain (BEP 20). Por isso, fique muito atento a taxa
            de transação (Fee) que será aplicada. Normalmente, fees mais altas geram
            confirmações mais rápidas da rede.
          </li>
          <li>
            Nós recomendamos que não opte pelo pix copia e cola sempre que o prazo
            oferecido pela loja for menor que 1 hora. As transações bitcoin podem demorar
            mais que isso para serem confirmadas. Quando isso ocorrer, sugerimos o
            pagamento por boleto bancário.
          </li>
          <li>
            A PagueComBitcoin não se responsabiliza por pagamentos realizados fora do
            prazo. A devolução dos reais será feita no prazo de cinco dias úteis após a
            devolução do valor da loja virtual para a nossa conta bancária. Confira os prazos
            que cada loja oferece para a devolução.
          </li>
        </ul>

        <p>Para fazer um pix copia e cola utilizando Bitcoin é bem simples:</p>
        <ul class="simple-list number-list">
          <li>
            Após realizar o login em nossa plataforma, acesse a opção Bitcoin por Pix no menu inicial.
          </li>
          <li>Selecione a opção copia e cola</li>
          <li> Insira a chave e confira se as informações estão corretas.</li>
          <li>Escolha por qual rede você deseja transferir seus Bitcoins: Rede Bitcoin ou BEP 20 (Binance Smart Chain)</li>
          <li>Clique em <span class="weight-bold">“Finalizar Pedido”</span></li>
        </ul>
        <p>Agora você tem 10 minutos com a cotação congelada para enviar seus bitcoins para o endereço informado.</p>
        <p>Não esqueça de conferir se a taxa de rede está somada ao valor selecionado para o saque.</p>
        <p>Você pode conferir todos os status do seu pedido pela tela de check out e pelos e-mails automáticos enviados durante toda a transação.</p>
      </div>

      <div id="comprar-gift-card" v-if="enabledGiftcardService">
        <h3 class="info-content-tile">Comprar Gift Card</h3>
        <h4>
          O que é Gift Card?
        </h4>
        <p>
          O cartão presente, ou gift card, é um voucher pré-pago para compra de serviços online como: aplicativos,
          streaming ou games. Na <span class="weight-bold">PagueComBitcoin</span>, oferecemos diversos cartões presente
          dos principais provedores de streaming, games e serviços. Você pode verificar a lista completa
          <router-link :to="{ name: 'service-gift-card' }">aqui</router-link>.

        </p>

        <h4>Como fazer a compra de Gift Card com bitcoins?</h4>
        <p>
          Para realizar uma compra de cartão presente, siga os passos abaixo:
        </p>
        <ul class="simple-list number-list">
         <li>Faça seu <a href="#" @click.prevent="openLoginModal" class="text weight-bold">Login</a> ou
              <a class="weight-bold" @click="openRegisterModal">Cadastre-se aqui</a>;
          </li>
          <li>Acesse no menu <router-link :to="{ name: 'service-gift-card' }">Gift Card</router-link>;
          </li>
          <li>
           Escolha o provedor, o gift card e a quantidade - o valor será incluído automaticamente;
          </li>
          <li>Para adicionar outros itens, clique em [+] Adicionar;</li>
          <li>Escolha a rede em que deseja enviar seus btcs (Bitcoin ou BEP20)</li>
          <li>Verifique o Resumo do Pedido com a Cotação BTC atual, taxas e o valor do pedido em bitcoins;</li>
          <li>Clique no botão Finalizar Pedido para ser direcionado à página de Checkout;</li>
          <li>
            Realize a transação do valor indicado em bitcoins para o endereço de carteira especificado em até
            {{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos;
          </li>
          <li>
            Após a confirmação da transação, será realizado o processamento do gift card junto à operadora;
          </li>
          <li>
            Pronto! Você receberá o Gift Card Digital, ou seja, o Código PIN para resgate em alguns minutos no seu
            e-mail.
          </li>
        </ul>

        <h4>Quantos cartões presente posso comprar com bitcoins?</h4>
        <p>
          Não existe limite máximo de quantidade de gift cards, ou seja, você pode comprar quantos cartões presente
          desejar em um ÚNICO PEDIDO independente do fornecedor. Após a finalização do pedido, você receberá um e-mail
          para cada gift card adquirido com o código PIN para resgate.
        </p>

        <h4>Qual é o prazo para recebimento do gift card?</h4>
        <p>
          Após a finalização do seu pedido, você receberá em alguns minutos um e-mail com código PIN para resgate do
          cartão presente independente do dia ou horário.
        </p>

        <h4>Qual é a taxa de serviço para compra de gift card?</h4>
        <p>Cobramos uma taxa de conveniência de R$ {{ (settings.itemFees && settings.itemFees.giftCard ? settings.itemFees.giftCard : 0) | currency }}
          por cada gift card comprado com bitcoins.
          <router-link :to="{ name: 'taxes' }">Mais informações sobre Taxas aqui</router-link>.
        </p>

        <h4>Como eu posso resgatar meu gift card?</h4>
        <p>
          Você receberá por email um <span class="weight-bold">Código PIN (Personal Identification Number)</span>,
          para realizar o resgate. Se a compra do Gift Card for um presente para um amigo/familiar, compartilhe o
          código para o próprio destinatário realizar o resgate.
        </p>
        <p>
          Cada provedor tem sua forma de resgate. Então, acesse o link do seu gift card e siga o passo-a-passo.
        </p>
        <p>Serviços Online e Streaming</p>
        <ul class="simple-list">
          <li>
            <a href="https://play.google.com/intl/pt-BR_br/about/giftcards/" target="_blank">
              Google Play
            </a>
          </li>
          <li>
            <a href="https://www.netflix.com/br-en/redeem" target="_blank">
              Netflix
            </a>
          </li>
          <li>
            <a href="https://support.spotify.com/br/account_payment_help/payment_help/spotify-gift-cards/" target="_blank">
              Spotify
            </a>
          </li>
          <li>
            <a href="https://www.uber.com/br/pt-br/gift-cards/" target="_blank">
              Uber / Uber eats
            </a>
          </li>
        </ul>

        <p>Games / Jogos</p>
        <ul class="simple-list">
          <li>
            <a href="https://giftcards.blizzard.com/pt-pt/" target="_blank">
              Blizzard
            </a>
          </li>
          <li>
            <a href="https://levelupgames.uol.com.br/levelup/compre-creditos/seuegiftcard.lhtml" target="_blank">
              Levelup
            </a>
          </li>
          <li>
            <a href="https://support-leagueoflegends.riotgames.com/hc/pt-br/articles/205063264-Comprando-RP-Cart%C3%B5es-Pr%C3%A9-Pagos" target="_blank">
              League of Legends
            </a>
          </li>
          <li>
            <a href="https://gold.razer.com/gold/reload" target="_blank">
              Razer Gold
            </a>
          </li>
          <li>
            <a href="https://store.steampowered.com/account/redeemwalletcode" target="_blank">
              Steam
            </a>
          </li>
          <li>
            <a href="https://support.xbox.com/help/subscriptions-billing/redeem-codes-gifting/redeem-prepaid-codes" target="_blank">
              Xbox
            </a>
          </li>
        </ul>
      </div>

      <div id="recarga-celular" v-if="enabledRechargeService">
        <h3 class="info-content-tile">Recarga de celular</h3>

        <h4>Como fazer a recarga de celular com bitcoins?</h4>
        <p>Para realizar uma recarga de créditos em um número de celular pré-pago, siga os passos abaixo:</p>
        <ul class="simple-list number-list">
          <li>Faça seu <a href="#" @click.prevent="openLoginModal" class="text weight-bold">Login</a> ou
              <a class="weight-bold" @click="openRegisterModal">Cadastre-se aqui</a>;
          </li>
          <li>
            Acesse no menu <router-link :to="{ name: 'service-cell-phone' }">Recarga de Celular</router-link>;
          </li>
          <li>
           Preencha todos os campos corretamente com o DDD, o Número do celular, a Operadora e o valor de recarga em reais.
          </li>
          <li>
           Escolha a rede em que deseja enviar seus btcs (Bitcoin ou BEP20)
          </li>
          <li>Clique no botão Finalizar Pedido para ser direcionado à página de Checkout;</li>
          <li>
            Realize a transação do valor indicado em bitcoins para o endereço de carteira especificado em até
            {{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos;
          </li>
          <li>
            Após a confirmação da transação a recarga será realizada de forma automática.
          </li>
        </ul>

        <h4>Para quais celulares posso fazer a recarga com bitcoins?</h4>
        <p>
          Você pode fazer a recarga de celular próprio ou de terceiros das principais operadoras nacionais.
          Acesse <router-link :to="{ name: 'service-cell-phone' }">aqui</router-link> a lista completa de operadoras.
        </p>

        <h4>Existe um valor mínimo para recarga de celular pré-pago? E valor máximo?</h4>
        <p>
          O valor mínimo e máximo de crédito variam de acordo com a operadora de telefonia e área DDD. Não existe
          limite de quantidade de recargas, ou seja, você pode realizar quantas recargas precisar em um único pedido.
        </p>

        <h4>Qual é o prazo para efetivação da recarga de celular pré-pago?</h4>
        <p>
          Após a confirmação da transação, realizamos o processamento automático da recarga junto à operadora. Assim
          que a operadora confirmar a operação, sua recarga será realizada e você receberá um e-mail de confirmação.
        </p>
        <p>
          O tempo médio de recarga é de 5 minutos independente do horário e do dia da semana. Em caso de
          indisponibilidade da operadora, algumas solicitações podem sofrer atraso até a normalização do serviço,
          nestes casos realizaremos a recarga de forma manual em horário comercial das 09h às 20h.
        </p>

        <h4>Qual é a taxa de serviço para recarga de celular pré-pago?</h4>
        <p>
          Cobramos uma taxa de conveniência de R$ {{ (settings.itemFees && settings.itemFees.recharge ? settings.itemFees.recharge : 0) | currency }} por Recarga de Celular.
          <router-link :to="{ name: 'taxes' }">Mais informações sobre Taxas aqui</router-link>.
        </p>
      </div>

      <div id="gateway-de-pagamento">
        <h3 class="info-content-tile">Gateway de pagamento</h3>
        <h4>O que é o Gateway de Pagamento?</h4>
        <p>
          O Gateway de Pagamento Bitcoin é um serviço que viabiliza a inclusão da opção de pagamento usando bitcoins em
          lojas virtuais por meio de API pública ou integração direta com plataformas como woocommerce e magento.
        </p>
        <p>
          O usuário pode optar em manter o saldo recebido em Bitcoins ou por convertê-lo total ou parcialmente em
          Reais. Os saques podem ser também programados com a possibilidade de realização de split.
        </p>

        <h4>Como solicitar o acesso ao Gateway de Pagamento?</h4>
        <p>
          Para solicitar o acesso aos serviços de Gateway de Pagamento, realize o Pré-cadastro para avaliação
          <router-link :to="{ name: 'gateway' }">aqui</router-link>.
        </p>

        <h4>Quais são os pré-requisitos para solicitar acesso ao Gateway de Pagamento Bitcoin?</h4>
        <p>
          Para solicitar o serviços de Gateway de Pagamento com Bitcoin, é necessário o envio dos seguintes documentos
          digitalizados:
        </p>

        <ul class="simple-list">
          <li>Cartão CNPJ - Comprovante de Inscrição ou Situação Cadastral.</li>
          <li>Contrato social.</li>
          <li>Documento de identificação com foto do sócio administrador responsável pelo cadastro.</li>
          <li>Comprovante de residência do(s) sócio(s)</li>
          <li>
            Prova de vida do sócio administrador - Como emitir? Tire uma foto (selfie) segurando seu documento e uma
            folha com a seguinte frase: "Cadastro para Gateway da PagueComBitcoin".
          </li>
        </ul>
      </div>

      <span class="divider-border"/>

    </div>

  <ct-user-login-modal
      :isModalVisible="isModalLoginVisible"
      @close="closeLoginModal"
      @successLogin="closeLoginModal"
    />
</div>

</template>

<script>
import currency from '@/filters/currency';
import { mapActions, mapState, mapGetters } from 'vuex';
import CtUserLoginModal from '@/components/Navbar/UserLoginModal';

export default {
  name: 'FaqServiceContent',
  components: {
    CtUserLoginModal,
  },
  filters: {
    currency,
  },
  data () {
    return {
      isModalLoginVisible: false,
    };
  },
  methods: {
    ...mapActions(['openRegisterModal']),
    openLoginModal () {
      this.isModalLoginVisible = true;
    },
    closeLoginModal () {
      this.isModalLoginVisible = false;
    },
  },
  computed: {
    ...mapGetters([
      'enabledPixService',
      'enabledShipmentService',
      'enabledRechargeService',
      'enabledBilletService',
      'enabledGiftcardService',
      'notEnabledServices',
    ]),
    ...mapState({
      settings: (state) => state.settings.settings,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/themes/variables';

</style>
