<template>
  <div class="bitcoin-page">
    <ct-banner :banner="banner" />
    <ct-bitcoin-content />
    <ct-call />
    <ct-depositions :depositions="depositions" />
  </div>
</template>

<script>
import CtBanner from '@/components/Banner/Banner';
import CtBitcoinContent from '@/components/ContentBitcoin/BitcoinContent';
import CtCall from '@/components/ContentHome/Call';
import CtDepositions from '@/components/Depositions/Depositions';
import config from '@/config';
import depositions from '@/config/depositions';

export default {
  name: 'Bitcoin',
  data () {
    return {
      banner: {
        textAlt: 'Saiba  mais sobre bitcoins e criptomoedas',
        imgUrl: '/assets/img/banners/banner-o-que-e-bitcoin.jpg',
        imgUrlMobile: '/assets/img/banners/banner-o-que-e-bitcoin-mobile.jpg',
      },
      pageInfo: {
        title: 'O que é bitcoin?',
        description: 'Um guia básico para conhecer e utilizar Bitcoins.',
      },
      depositions,
    };
  },
  metaInfo () {
    return {
      title: this.pageInfo.title,
      meta: [
        { name: 'description', content: this.pageInfo.description },
        { name: 'og:description', content: this.pageInfo.description },
        { name: 'og:image', content: config.app.url + this.banner.imgUrl },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.pageInfo.title },
        { name: 'twitter:description', content: this.pageInfo.description },
        { name: 'twitter:image', content: config.app.url + this.banner.imgUrl },
      ],
    };
  },
  components: {
    CtBanner,
    CtBitcoinContent,
    CtCall,
    CtDepositions,
  },

};
</script>

<style lang="scss" scoped>

</style>
