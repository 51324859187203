import { emailValidator } from '../validators/email.validator';
import { uuidValidator } from '../validators/uuid.validator';
import { cellPhoneWithDDDValidator } from '../validators/cellphone.validator';
import { cpfValidator, cnpjValidator } from '../validators/document.validator';

const getKeyTypeHelper = (keyReference) => {
  let key = String(keyReference || '');
  if (key.startsWith('+55')) {
    key = key.replace('+55', '');
  }

  if (emailValidator.validate(key)) return 'EMAIL';
  if (uuidValidator.validate(key)) return 'UUID';
  if (cellPhoneWithDDDValidator.validate(key)) return 'PHONE';
  if (cpfValidator.validate(key) || cnpjValidator.validate(key)) return 'DOCUMENT';

  return '';
};

export default getKeyTypeHelper;
