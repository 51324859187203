export default class Invoice {
  constructor (data = {}) {
    this._id = data._id || null;
    this.code = data.code || null;
    this.status = data.status || null;
    this.email = data.email || null;
    this.document = data.document || null;
    this.fiat = data.fiat || null;
    this.fiatAmount = data.fiatAmount || null;
    this.network = data.network || null;
    this.crypto = data.crypto || null;
    this.cryptoAmount = data.cryptoAmount || null;
    this.cryptoAmountOrig = data.cryptoAmountOrig || null;
    this.cryptoAmountPaid = data.cryptoAmountPaid || null;
    this.cryptoPrice = data.cryptoPrice || null;
    this.fee = data.fee || null;
    this.description = data.description || null;
    this.isExpired = data.isExpired || null;
    this.currentTime = data.currentTime || null;
    this.expiryAt = data.expiryAt || null;
    this.deletedAt = data.deletedAt || null;
    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
    this.events = data.events || [];
    this.items = data.items || [];
    this.transactions = data.transactions || [];
    this.user = data.user || {};
  }
}
