import axios from 'axios';
const baseUrlService = 'https://viacep.com.br';

export default {
  async getAddressByZipCode (zipCode) {
    zipCode = zipCode.replace('-', '');
    if (zipCode.length !== 8) {
      return false;
    }
    const res = await axios.get(`${baseUrlService}/ws/${zipCode}/json/`);
    return this.formatterAddressByViaCep(res.data);
  },

  formatterAddressByViaCep (data) {
    if (!data) return {};
    return {
      zipCode: data.cep || null,
      address: data.logradouro || null,
      district: data.bairro || null,
      complement: data.complemento || null,
      cityCode: data.ibge || null,
      city: data.localidade || null,
      uf: data.uf || null,
    };
  },
};
