<template>
  <svg version="1.1" id="support-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 445 449" style="enable-background:new 0 0 445 449;" xml:space="preserve">
  <g>
    <path class="st0" d="M444.91,210.76c-0.11-25.58-22.23-49.48-47.65-52.43c-8.22-0.95-16.41-0.64-24.61-0.67
      c-25.85-0.1-51.7-0.11-77.55,0.05c-3.55,0.02-4.45-0.95-4.44-4.46c0.14-32.13-0.42-64.26,0.23-96.38
      c0.58-28.25-19.8-52.52-46.52-56.87H47.07c-0.68,0.15-1.44,0.1-2.06,0.22C19.25,4.99-0.55,29-0.09,56.82
      c0.64,38.98,0.14,77.97,0.17,116.96c0.02,31.77,24.01,56.05,55.87,56.36c3.58,0.03,4.15,1.29,4.1,4.41
      c-0.16,9.71-0.01,19.42-0.06,29.13c-0.05,11.49,5.11,19.76,15.53,24.43c9.96,4.47,20.27,2.05,28.63-6.26
      c15.36-15.27,30.69-30.57,46.06-45.83c1.07-1.07,1.95-2.54,4.08-2.9c0,1.95,0,3.57,0,5.18c0,31.52,0.03,63.04-0.03,94.55
      c-0.01,5.72,0.71,11.29,2.42,16.73c7.39,23.52,28.89,39.19,54.03,39.26c24.65,0.07,49.29,0.08,73.94-0.07
      c3.28-0.02,5.51,0.99,7.78,3.28c12.91,13.07,25.97,26,38.99,38.96c7.12,7.09,13.6,15.03,23.82,18.01c2.39,0,4.78,0,7.17,0
      c6.54-2,12.93-4.2,17.27-10.05c3.49-4.71,5.41-9.85,5.37-15.77c-0.07-9.86,0.14-19.73-0.09-29.58c-0.08-3.55,0.69-4.79,4.58-4.88
      c26.42-0.59,47.66-17.53,53.99-42.76c0.87-3.46,1.44-6.99,1.44-10.6C444.95,293.84,445.09,252.3,444.91,210.76z M154.43,208.68
      c-4.8-0.02-8.53,1.51-11.94,4.93c-17.4,17.47-34.95,34.79-52.46,52.16c-1.66,1.65-3.2,3.89-6.05,2.7c-2.86-1.2-2.94-3.81-2.93-6.44
      c0.02-13.6,0.04-27.2-0.01-40.8c-0.03-8.57-4-12.32-12.67-12.53c-6.99-0.17-14.02,0.82-20.97-0.85
      c-14.18-3.41-24.99-15.68-25.74-30.19c-0.52-10.14-0.28-20.31-0.29-30.47c-0.03-29.89,0.48-59.79-0.17-89.66
      c-0.48-22.26,16.8-37.63,37.37-37.21c28.98,0.6,57.98,0.15,86.97,0.15c29.74,0,59.48-0.04,89.22,0.02
      c16.57,0.04,30.28,10.98,33.96,27.06c0.56,2.45,0.66,5.04,0.66,7.56c0.04,39.75,0.14,79.5-0.02,119.26
      c-0.07,17.2-10.81,30.05-27.55,33.88c-2.53,0.58-5.05,0.47-7.58,0.47C207.63,208.74,181.03,208.79,154.43,208.68z M423.31,336.44
      c-0.47,16.48-15.55,30.41-32.08,30.92c-4.78,0.15-9.56,0.03-14.34,0.04c-9.28,0.01-12.88,3.63-12.89,13.05
      c-0.01,13.3-0.02,26.59,0.01,39.89c0.01,2.77,0.01,5.56-3.11,6.77c-2.9,1.12-4.35-1.29-5.98-2.91
      c-17.42-17.24-34.86-34.45-52.14-51.81c-3.52-3.53-7.28-5.09-12.3-5.06c-26.59,0.17-53.19,0.09-79.78,0.07
      c-19.78-0.01-34.87-14.23-35.09-34.08c-0.36-33.16-0.08-66.33-0.19-99.5c-0.01-3.12,1.22-3.64,3.95-3.63
      c17.93,0.09,35.86,0.05,53.79,0.05c29.58,0,51.39-18.07,56.82-47.27c0.59-3.17,1.82-3.93,4.92-3.92
      c31.67,0.12,63.35,0.03,95.02,0.09c17.59,0.04,32.89,13.84,33.43,31.37c0.65,20.89,0.15,41.82,0.15,62.74c-0.01,0-0.02,0-0.04,0
      C423.45,294.32,423.91,315.4,423.31,336.44z"/>
    <path class="st0" d="M130.83,125.42c0.01-11.03,4.04-18.53,11.04-24.58c2.71-2.34,5.78-4.29,8.28-6.81
      c5.72-5.77,3.38-14.09-4.44-16.44c-5.79-1.74-11.19-0.66-16.34,2.18c-1.43,0.79-2.7,1.85-4.07,2.75
      c-6.21,4.08-11.49,3.91-15.25-0.48c-4.46-5.2-4.31-12.96,0.54-17.89c5.72-5.82,12.76-9.13,20.78-10.49
      c8.71-1.47,17.21-0.74,25.43,2.48c15.54,6.09,24.66,22.31,20.89,37.12c-1.6,6.3-5.36,11.25-10.33,15.29
      c-3.01,2.45-6.1,4.8-9.26,7.03c-5.71,4.03-9.41,9.42-11.32,16.11c-0.28,1-0.67,1.96-1.03,2.93c-1.13,2.99-2.99,5.42-6.37,5.44
      c-3.38,0.02-5.22-2.47-6.49-5.36C131.35,131.24,130.76,127.6,130.83,125.42z"/>
    <path class="st0" d="M155.39,163.34c-0.02,8.31-5.73,13.86-14.19,13.8c-7.9-0.05-13.66-5.97-13.66-14.04
      c0-8.03,5.67-13.39,14.15-13.35C150.26,149.78,155.41,154.89,155.39,163.34z"/>
    <path class="st0" d="M351.25,247.31c-0.07,3.3-1.34,6.05-3.67,8.38c-18.92,18.91-37.78,37.87-56.76,56.71
      c-5.35,5.31-11.69,5.17-17.03-0.11c-9.14-9.03-18.22-18.13-27.26-27.26c-5.01-5.06-5.17-11.68-0.55-16.28
      c4.54-4.52,11.34-4.31,16.35,0.61c5.76,5.65,11.56,11.27,17.07,17.15c2.25,2.41,3.43,2.01,5.54-0.13
      c15.23-15.41,30.61-30.67,45.89-46.03c2.66-2.67,5.55-4.34,9.46-4.21C346.57,236.36,351.33,241.1,351.25,247.31z"/>
  </g>
  </svg>
</template>

<script>
export default {

};
</script>

<style lang="scss">
</style>
