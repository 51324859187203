export const cellPhoneValidator = {
  validate (value, args) {
    const brazilianPhoneRegex = /^(\9)(\d{4}-?\d{4})$/gi;
    return RegExp(brazilianPhoneRegex).test(value);
  },
};

export const cellPhoneWithDDDValidator = {
  validate (value, args) {
    const brazilianPhoneRegex = /^(\(?[1-9]{1}[0-9]{1})\)?(\9)(\d{4}-?\d{4})$/gi;
    return RegExp(brazilianPhoneRegex).test(value);
  },
};
