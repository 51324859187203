<template>
<div>
  <div id="suporte">
    <h2 class="info-content-tile">Suporte</h2>

    <div id="cadastro">
      <h3 class="info-content-tile">Cadastro</h3>

      <h4 >Como me cadastrar na PagueComBitcoin?</h4>
      <p>Se cadastrar na PagueComBitcoin é muito simples e rápido. Siga as orientações abaixo:</p>
      <ul class="simple-list number-list">
        <li>Acesse o link
          <router-link :to="{ name: 'home' }">https://paguecombitcoin.com</router-link>;</li>
        <li>No canto superior direito da página, clique no botão
          <a class="weight-bold" @click="openRegisterModal">Cadastre-se</a> e um pop-up será aberto;
        </li>
        <li>
          Preencha as informações solicitadas: Nome Completo ou Razão Social, CPF/ CNPJ, Senha com no mínimo 6
          caracteres, e-mail válido e endereço;
        </li>
        <li>Informe um número de celular válido para receber um SMS com o código de verificação;</li>
        <li>Preencha no campo o código de 6 dígitos enviado por SMS;</li>
        <li>Clique em enviar para concluir seu cadastro;</li>
        <li>Pronto! Agora você pode acessar nossa plataforma com seu email e senha.</li>
      </ul>

      <p>Registering with PagueComBitcoin is very simple. Follow the guidelines below:</p>
      <ul class="simple-list number-list">
        <li>Access
          <router-link :to="{ name: 'home' }">https://paguecombitcoin.com</router-link>;</li>
        <li>In the upper right corner of the page, click on
          <a class="weight-bold" @click="openRegisterModal">Cadastre-se</a> button and a pop-up will open;
        </li>
        <li>
         Fill in the requested information: Full Name or Company Name, Pas / CNPJ, Password with at least 6 characters,
         valid email and address;
        </li>
        <li>Enter a valid mobile number to receive an SMS with the verification code;</li>
        <li>Fill in the 6-digit code sent by SMS in the field;</li>
        <li>Click send to complete your registration;</li>
        <li>You can now access our platform with your email and password.</li>
      </ul>

      <h4>Preciso fazer cadastro para usar os serviços da PagueComBitcoin?</h4>
      <p>
        SIM! Pela necessidade do atendimento a
        <a target="_blank" href="http://normas.receita.fazenda.gov.br/sijut2consulta/link.action?visao=anotado&idAto=100592">
          Instrução Normativa RFB nº 1.888
        </a>
        (IN 1888), modificada pela
        <a target="_blank" href="http://normas.receita.fazenda.gov.br/sijut2consulta/link.action?visao=anotado&idAto=102230">
          Instrução Normativa RFB nº 1.899
        </a>
        (IN 1889).
      </p>
      <p>
        Os dados cadastrais e as informações referentes a acessos realizados e interações com nossas plataformas não
        serão compartilhados ou comercializados sem sua autorização prévia, cumprimos as regras da LGPD.
      </p>

      <h4>Posso fazer cadastro como Pessoa Jurídica na PagueComBitcoin?</h4>
      <p>Pode sim! Confere aqui a o passo a passo <a href="#cadastro">aqui</a>.</p>

      <h4>Por que tenho que informar meu endereço no cadastro?</h4>
      <p>
        Devido a necessidade de atendimento às normas da Receita Federal, temos a obrigação de realizar a emissão de
        Notas Fiscais de todos os serviços efetuados em nossa plataforma e aplicativo mobile. Para emissão de NF é
        obrigatório preencher dados como Nome Completo / Razão Social, CPF/CNPJ e endereço.
      </p>
      <p>
        Os dados cadastrais, acessos e interações com nossas plataformas não serão compartilhados ou comercializados
        sem sua autorização prévia.
      </p>

      <h4>Como atualizar os meus dados de cadastro?</h4>
      <p>
        Caso desejar atualizar seus dados cadastrais (email, endereço ou número de celular), envie um email para
        <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>, com assunto: Alteração Cadastral.

      </p>

      <h4>Como alterar ou solicitar uma nova senha?</h4>
      <p>
        Você pode solicitar uma nova senha de acesso pela área de login, localizada no canto superior direito,
        clicando em "Esqueceu a senha?". Preencha seu e-mail e siga as instruções que serão enviadas automaticamente.
        Esta alteração não está disponível no App.
      </p>

      <h4>Fiz o cadastro mas não consigo logar, aparece a mensagem “ E-MAIL E/OU SENHA INVÁLIDOS. TENTE NOVAMENTE.”</h4>
      <p>
        Para concluir seu cadastro, é necessário realizar uma última etapa de validação. Após preencher seus dados,
        informe seu número de celular para envio de um SMS com código de verificação. Digite o código de 6 dígitos para
        concluir seu cadastro e acessar a plataforma.
      </p>
      <p>
        Caso não tenha recebido o código em seu celular ou ele esteja expirado, preencha novamente os dados de cadastro
        e clique em reenviar. Você receberá outro código por SMS.
      </p>

      <h4>Estou tentando fazer o cadastro mas deu Erro no Código de Verificação, o que fazer?</h4>
      <p>
        É provável que o código tenha expirado. Caso não tenha recebido a mensagem SMS com o código em seu celular ou
        ele esteja expirado, clique no botão Reenviar para receber novo código.
      </p>

      <h4>Não estou recebendo as notificações em meu e-mail, o que fazer?</h4>
      <p>
        Os serviços de e-mail disponíveis, como o Gmail ou o Hotmail, podem classificar as mensagens como SPAM ou
        direcioná-las para outras pastas que não sejam a caixa de entrada. O primeiro passo é verificar se nossos
        e-mails não estão sendo direcionados para o SPAM ou qualquer outra pasta. Uma dica: se encontrar nossos e-mails
        no SPAM, arraste ele para a caixa de entrada, assim o provedor de e-mail entenderá que este endereço eletrônico
        é confiável.
      </p>
      <p>
        Caso permaneça sem receber nossos e-mails, envie uma mensagem para
        <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a> ou direct no facebook ou instagram.
      </p>
    </div>

    <div id="meus-pedidos">
      <h3 class="info-content-tile">Meus Pedidos</h3>

      <h4>Como posso acessar meus pedidos realizados?</h4>
      <p>
        Você poderá acessar os dados do seu último pedido direto pela página de checkout ou ainda pelos links enviados
        por e-mail de atualização do seu pedido.
      </p>
      <p>
        Para ter acesso a todos os seus pedidos, baixe o app PagueComBitcoin, faça seu login e clique em "Meus Pedidos".
      </p>
      <p>
        Baixe agora e deixe seu comentário em <router-link :to="{name: 'app'}">paguecombitcoin.com/aplicativo</router-link>.
      </p>

      <h4>Como finalizar um pedido?</h4>
      <p>
        Na Página de Serviço, após preencher os campos com os dados do item e clique no botão Finalizar Pedido, você
        será direcionado para página de Checkout, onde deve realizar a transferência dos bitcoins para o endereço de
        carteira especificado.
      </p>
      <p>
        Para finalizar seu pedido, siga os passos abaixo:
      </p>
      <ul class="simple-list number-list">
        <li>Confira o valor total de bitcoins, valor equivalente em reais e a cotação BTC;</li>
        <li>
          Abra a sua carteira, selecione o valor em btc informado na tela de checkout.
        </li>
        <li>Copie endereço bitcoin informado na tela de checkout e cole na sua carteira ou utilize o qr code para.</li>
        <li>
          Confira se a taxa de rede ou taxa de saque serão subtraídas do valor em bitcoin selecionado para a
          transferência.
        </li>
        <li>
          Realize a transação do valor indicado em bitcoins para o endereço de carteira especificado em até 10 minutos;
        </li>
        <li>
          Após a confirmação da transação, realizamos o processamento de cada item do seu pedido; e
        </li>
        <li>
          Você pode acompanhar o status até a finalização do pedido pela tela de checkout ou ainda por e-mails que
          enviamos automaticamente.
        </li>
      </ul>
      <h4>
        MEU PEDIDO EXPIROU, O QUE FAZER?
      </h4>
      <p>
        Após inserir o(s) item(s) e clicar no botão ‘Finalizar Pedido’, você será direcionado para a página de
        pagamento, ou seja, para a transferência de bitcoins da sua carteira ou exchange para a PagueComBitcoin.
      </p>
      <p class="weight-bold">
        É necessário fazer a transação de bitcoin dentro do tempo limite de {{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos para conseguirmos identificar e
        confirmar o pedido.
      </p>
      <p>
        Assim, caso a transação não tenha sido realizada dentro destes 10 min, o pedido expira e outra ordem deve ser
        gerada. Caso tenha feito a transação DEPOIS do tempo limite, entre em contato com nosso suporte respondendo
        um dos e-mails de status do seu pedido.
      </p>

      <h4>
        COMO POSSO FAZER UM PEDIDO COM MÚLTIPLOS ITENS?
      </h4>
      <p>
        Para realizar um pedido com múltiplos itens, é só clicar nos botões [+]ADICIONAR NOVO localizado abaixo dos
        campos de cada item.
      </p>
      <p>
       Caso você queira adicionar mais de um tipo de serviço é só selecionar no menu principal todos os serviços que você deseja.
      </p>
      <p>
        Além da praticidade, uma grande vantagem é a economia na taxa de transação bitcoin da sua carteira ou exchange para a PagueComBitcoin.
      </p>
      <p>
        Como as taxas não estão relacionadas com o montante transferido, é possível enviar 1 BTC pela mesma taxa de 0,0001 BTC. Bem melhor economizar, não é?
      </p>
    </div>

    <div id="pagamentos">
      <h3 class="info-content-tile">Pagamento</h3>

      <h4>Como é feito o pagamento de um serviço?</h4>
      <p>
        O pagamento é realizado por transações de bitcoins para o endereço de Carteira da PagueComBitcoin. Após finalizar o pedido, você será redirecionado à página de Checkout para realizar a transferência do valor total em BTC.
      </p>
      <p>
        VALOR TOTAL = VALOR DO PEDIDO + Taxa administrativa
      </p>
      <p>
        Para garantir o valor de cotação, você deve realizar a transação de bitcoins em até
        {{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos na página de Checkout das
        seguintes formas:
      </p>
      <ul class="simple-list">
        <li>Copiar o endereço da carteira para realizar a transação;</li>
        <li>Clicar no botão Abrir Carteira, caso tiver uma carteira instalada no mesmo dispositivo;</li>
        <li>Realizar a leitura do QRCode a partir da carteira de onde a transação será disparada.</li>
      </ul>

      <h4>O QUE É A BINANCE SMART CHAIN (BEP20)</h4>
      <p>
        A Binance Smart Chain é o ecossistema blockchain desenvolvido pela Binance. Atualmente, a SmartChain é a forma mais barata e rápida de transferir Bitcoins de uma carteira para outra. A BEP20 é um padrão de token amigável que permite que qualquer wallet compatível possa utilizar a rede para transacionar criptomoedas a custo quase zero.
      </p>
      <p>
        A Pague Com Bitcoin já tem integração com a BSC para tornar ainda mais fácil, barata e rápida a transação bitcoin.
      </p>
      <p>
        Para utilizar a Binance Smart Chains, você deverá selecionar BEP 20 antes de finalizar seu pedido.
      </p>
      <p>
        É de extrema importância que a sua carteira bitcoin seja amigável a BEP 20 e que na transferência seja selecionada a rede correta tanto na PagueComBitcoin quanto na sua carteira, caso contrário, suas criptos serão perdidas.
      </p>

      <h4>Porque a transação de bitcoin deve ser realizada em {{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos?</h4>
      <p>
        O pagamento do serviço, ou seja, a transferência do valor total em bitcoin para o endereço de carteira
        especificado na plataforma da PagueComBitcoin, deve ser realizado no período máximo de
        {{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }}
        minutos para que seja garantida a cotação informada na abertura do pedido.
      </p>

      <h4>Fiz a transação de bitcoins depois do meu pedido ter expirado. O que fazer?</h4>
      <p>
        Solicitamos que a transação dos bitcoins sempre seja realizada no prazo de {{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos, para garantia do processamento do seu pedido.
      </p>
      <p>
        Caso a transferência seja realizada após o vencimento do prazo limite de {{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos,
        você receberá um e-mail de notificação com as orientações necessárias para o processamento da sua ordem OU estorno dos bitcoins com desconto da taxa de transação.*
      </p>
      <p>
        Em caso de estorno, dependendo do valor e do status do pedido, a devolução poderá ser feita por meio da transação em bitcoins, transferência em reais para uma conta bancária, recarga de celular pré-pago e/ou gift card de valor equivalente.
      </p>
      <p>
        Para qualquer um dos casos mencionados acima, será descontada uma taxa de transferência que pode sofrer alteração conforme o tipo do serviço solicitado. Já para estorno em bitcoins, será descontada uma taxa de transação para a carteira informada.
      </p>
      <p>
        A PagueComBitcoin se reserva o direito de escolher se o estorno será em reais ou em Bitcoins.
      </p>
      <p>
        Entre em contato com nosso suporte através do e-mail
        <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a> em caso de dúvidas adicionais
        sobre o processo de estorno.
      </p>

      <h4>
        DEU ERRO NO PAGAMENTO DE BOLETO, O QUE ACONTECEU?
      </h4>
      <p>
        Pode ter ocorrido erro no pagamento de boleto por algumas razões como linha digitável inválida, valor diferente
        do valor real do boleto e pelo boleto ainda não ter sido registrado pela instituição bancária.
      </p>
      <p>
        Após a emissão de qualquer boleto, há um prazo para efetivação do processo de registro na instituição bancária.
        O prazo para registro no SBP - Sistema Brasileiro de Pagamentos pode ser de até 24h dependendo do emissor.
      </p>
      <p>
        Caso haja uma tentativa de pagamento de boleto antes da conclusão do processo de registro, o banco pode retornar
         uma <span class="weight-bold">mensagem de erro como ‘Título não Encontrado, procure o beneficiário’, ‘título inválido’,
         ‘registro de título não encontrado’ ou algo semelhante.</span>
      </p>
      <p>
        Nossa plataforma não realiza validação de linhas digitáveis iniciadas com dígito “8”, como de boletos de
        consumo (água, luz, telefone, gás, condomínio, etc) ou guias de pagamento de taxas e tributo. Sendo assim,
        não será possível identificar eventuais erros de digitação. Dica: Use, sempre que possível, o leitor de código
        de barras pelo nosso App.
      </p>

      <h4>Como posso obter comprovante de uma transação Bitcoin?</h4>
      <p>
        A comprovação da execução de uma transação Bitcoin é composta por duas informações. A primeira é
        operação (ID - identificador da transação) A segunda é a quantidade de confirmações que a transação possui.
      </p>
      <p>
        A transação Bitcoin é considerada válida quando TXID possui ao menos uma confirmação.
      </p>
      <p>
        Para consultar se a transação Bitcoin foi finalizada e já está em posse do novo dono, acesse exploradores de
        blocos como o <span class="weight-bold">BLOCK CYPHER</span>, informe o TXID no campo de busca e clique no
        botão "Search". Caso a transação possua uma ou mais confirmações no campo "CONFIRMATIONS" a transação pode ser
        considerada como finalizada.
      </p>
      <h4>
        A PAGUECOMBITCOIN ACEITA PAGAMENTOS COM OUTRAS CRIPTOMOEDAS?
      </h4>
      <p>
        Por enquanto aceitamos somente pagamentos com bitcoins.
      </p>

      <h4>
        A PAGUECOMBITCOIN EMITE NOTA FISCAL DOS SERVIÇOS?
      </h4>
      <p>
        Sim! Emitimos e enviamos para seu e-mail cadastrado a Nota Fiscal Eletrônica (NFe) de todos os serviços
        prestados com valor referente à taxa de serviço cobrada por cada item processado do seu pedido.
      </p>
      <p>
        Em relação aos titulares da operação, na NFe constam as seguintes informações: nome da pessoa física ou
        jurídica, endereço, CPF ou CNPJ.
      </p>
    </div>

    <div id="cotacao">
      <h3 class="info-content-tile">COTAÇÃO BTC</h3>

      <h4>QUAL É A COTAÇÃO DO BTC USADA NA PAGUECOMBITCOIN</h4>
      <p>
        A cotação do BTC na PagueComBitcoin é calculada e atualizada automaticamente.
      </p>

      <h4>COMO É CALCULADA A COTAÇÃO BTC DA PAGUECOMBITCOIN?</h4>
      <p>
        A PagueComBitcoin utiliza como base a cotação média das últimas 24 horas dos livros de ofertas das maiores
        exchanges nacionais no mercado de bitcoins.
      </p>
      <p>
       Você pode encontrar a cotação atual no menu superior, no resumo do pedido e na página de Checkout/Pagamento. A
       cotação do bitcoin informada na página de Checkout, encontrada no resumo do pedido, permanecerá válida e
       imutável por 10 minutos até a expiração do pedido.
      </p>
      <p>
       Diferente das cotações de exchanges, a cotação e o valor em BTC permanecem válidos e imutáveis até a expiração do
       pedido, sem sofrer qualquer alteração ou adição de mais taxas.
      </p>
      <p>
       Nossa base de cálculo também leva em consideração:
      </p>

      <ul class="simple-list">
        <li>
          Prazo de transação e pagamentos: o prazo das exchanges tradicionais é invariavelmente mais lento, burocrático e não garante a prestação do serviço na cotação apresentada;
        </li>
        <li>
          Garantia da cotação: algumas exchanges exigem até três confirmações para a liberação do saldo em plataforma, podendo ocorrer modificação da cotação e volatilidade do mercado;
        </li>
        <li>
          A cotação média do total dos Bitcoins que serão liquidados: a maioria das exchanges apresentam a cotação
          do topo do livro de ofertas, independente da sua liquidez. Porém, dependendo do volume da transação, essa
          cotação recebe um acréscimo antes da liquidação;
        </li>
        <li>
          Taxa de serviço fixo: a maioria das exchanges cobram taxas adicionais de trade na ordem de 1% do valor
          negociado, além do spread;
        </li>
        <li>
          Taxa de saque e taxa de transferência bancária: a maioria das exchanges cobram até 1,99% do valor
          transacionado, além da taxa de transferência bancária que pode chegar a R$ 10,00.
        </li>
      </ul>
    </div>

    <span class="divider-border"/>

  </div>

  <ct-user-login-modal
      :isModalVisible="isModalLoginVisible"
      @close="closeLoginModal"
      @successLogin="closeLoginModal"
    />
</div>

</template>

<script>
import currency from '@/filters/currency';
import { mapActions, mapState } from 'vuex';
import CtUserLoginModal from '@/components/Navbar/UserLoginModal';

export default {
  name: 'FaqSupportContent',
  components: {
    CtUserLoginModal,
  },
  filters: {
    currency,
  },
  data () {
    return {
      isModalLoginVisible: false,
    };
  },
  methods: {
    ...mapActions(['openRegisterModal']),
    openLoginModal () {
      this.isModalLoginVisible = true;
    },
    closeLoginModal () {
      this.isModalLoginVisible = false;
    },
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.settings,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/themes/variables';

</style>
