import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import createPersistedState from 'vuex-persistedstate';

// Modules
import dashboard from './modules/dashboard/store';
import auth from './modules/auth/store';
import select from './modules/select/store';
import invoices from './modules/invoices/store';
import services from './modules/services/store';
import banks from './modules/banks/store';
import register from './modules/register/store';
import users from './modules/users/store';
import settings from './modules/settings/store';
import config from './../config';

Vue.use(Vuex);

const storeKey = `${config.name}-v${config.version}`;

const modules = { dashboard, auth, select, invoices, services, banks, register, settings, users };

mutations.CLEAR_STATE = (state) => {
  const modulesKey = Object.keys(modules);
  Object.keys(state).forEach((key) => {
    const defaultState = state.getState();
    if (key !== 'getState' && modulesKey.indexOf(key) < 0) {
      state[key] = defaultState[key] || null;
    }
  });
};

Object.keys(modules).forEach((key) => {
  modules[key].mutations[key.toUpperCase() + '_CLEAR_STATE'] = (state) => {
    const defaultState = state.getState();
    Object.keys(state).forEach((key) => {
      if (key !== 'getState') {
        state[key] = defaultState[key] || null;
      }
    });
  };
});

actions.clearState = ({ commit }) => {
  try {
    commit('CLEAR_STATE');
    Object.keys(modules).forEach((key) => {
      commit(key.toUpperCase() + '_CLEAR_STATE');
    });
  } catch (e) {
    console.error(e);
  }
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules,
  plugins: [
    createPersistedState({
      key: storeKey,
      storage: window.sessionStorage,
    }),
  ],
});
