export default {
  getBankNameByCode: state => code => state.bankList.find(bank => bank.code === code).name,
  getBanksForSelect: state => {
    if (!state.bankList || !Array.isArray(state.bankList)) {
      return {};
    }
    return state.bankList.map(bank => {
      return {
        value: bank.code,
        label: `[${bank.code}] ${bank.name}`,
      };
    });
  },
};
