import http from '@/services/HttpService';

export default {
  async getDetail (brcode) {
    try {
      const res = await http().get(`/api/v1/brcode?code=${brcode}`);
      return res.data.data;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
};
