import http from './HttpService';

export default {
  async loginToken (email, password) {
    const res = await http().post('/api/v1/auth/token/login', { email, password });
    return res.data;
  },

  async logoutToken () {
    const res = await http().delete('/api/v1/auth/token/logout');
    return res.data;
  },
};
