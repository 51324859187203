<template>
  <svg version="1.1" id="service-btc" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 445 510" style="enable-background:new 0 0 445 510;" xml:space="preserve">
  <g>
    <path class="st0" d="M296.07,0.48c7.04,2.77,8.87,8.57,7.81,15.18c-1.58,9.77,2.37,13.93,11.56,17.74
      c26.51,11.01,50.1,26.9,69.2,48.64c24.91,28.35,40.45,61.23,46.4,98.61c1.57,9.86,2.06,19.77,2.61,29.69
      c0.32,5.68-2.87,9.39-7.68,9.67c-4.28,0.25-7.55-3.32-7.64-9.14c-0.45-27.35-7.05-53.12-20.26-77.01
      c-18.81-34.02-45.9-58.88-82.56-72.75c-1.85-0.7-3.7-1.42-5.57-2.08c-1.69-0.6-3.41-1.13-5.94-1.97c0,4.15-0.33,7.9,0.07,11.57
      c0.93,8.66-7.41,12.23-14.22,8.38c-11.19-6.32-22.28-12.83-33.4-19.27c-5.56-3.22-11.09-6.49-16.68-9.65
      c-6.97-3.95-7.29-11.91-0.27-16.05c18.12-10.66,36.39-21.06,54.6-31.57C294.74,0.48,295.4,0.48,296.07,0.48z"/>
    <path class="st0" d="M148.23,492.98c0.04-10.93-0.09-10.64-10.2-14.99c-18.27-7.85-35.38-17.74-50.72-30.51
      c-28.18-23.46-47.65-52.83-58.99-87.6c-5.41-16.6-8.81-33.63-9.13-51.18c-0.06-3.14-0.57-6.27-0.55-9.4
      c0.03-4.88,3.07-8.31,7.22-8.56c4.27-0.26,7.84,3.14,7.97,8.31c0.29,11.46,1.4,22.81,3.92,34c5.98,26.57,17.8,50.16,35.21,71.19
      c19.02,22.98,43,38.24,70.81,48.14c3.91,1.39,4.94,0.4,4.5-3.48c-0.26-2.3-0.07-4.66-0.04-6.99c0.08-8.48,6.32-12.43,13.78-8.31
      c11.54,6.37,22.85,13.13,34.26,19.73c5.57,3.22,11.12,6.46,16.69,9.67c6.23,3.6,6.65,11.49,0.5,15.16
      c-14,8.37-28.16,16.5-42.27,24.69c-3.28,1.91-6.57,3.83-9.98,5.48c-6.8,3.29-12.79-0.39-12.98-7.87
      C148.17,497.97,148.23,495.48,148.23,492.98z"/>
    <path class="st0" d="M402.37,221.48c-29.1-29.01-64.07-44.51-105.52-44.21c-3.17,0.02-4.62-0.45-4.91-4.07
      c-3.18-39.85-19.77-73.31-49.93-99.32c-41.39-35.71-89.16-46.11-141.07-29.28C39.01,64.66-0.37,122.42-0.03,182.41
      c-0.19,13.93,1.52,26.62,5.02,38.98c18.24,64.38,75.49,107.91,142.12,108.02c3.91,0.01,4.93,1.17,5.19,4.86
      c1.24,18.1,5.63,35.51,13.95,51.61c25.3,48.94,65.04,78.6,120.28,82.86c58.68,4.53,104.49-20.31,135.97-69.66
      C458.34,342.91,449.45,268.4,402.37,221.48z M153.13,304.07c-1.06,9.28-1.06,9.28-10.27,8.96
      c-60.59-2.11-109.69-42.05-122.99-100.05C5.43,149.96,38.11,86.75,97.41,63.02c49.47-19.8,107.91-7.15,143.58,31.35
      c21.22,22.91,32.53,50.17,34.51,81.33c0.14,2.17-0.11,3.12-2.62,3.65c-10.02,2.12-9.99,2.26-10.86-7.81
      c-4.48-51.89-47.3-96.72-98.67-103.31c-58.14-7.46-109.17,23.57-127.85,78.9c-15.89,47.07-0.03,101.01,44.02,131.81
      c21.23,14.85,44.89,21.8,70.84,20.7c0.98-0.04,1.96-0.01,3.33-0.01C153.47,301.43,153.28,302.75,153.13,304.07z M156.15,288.55
      c-0.89,3.51-2.38,4.49-5.66,4.59c-27.36,0.77-51.96-7.12-73.04-24.49c-27.58-22.72-42.35-52.28-40.82-88.09
      c1.98-46.5,25.11-80.4,67.98-98.28c42.03-17.53,81.25-9.69,115.41,20.52c22.37,19.79,33.58,45.3,35.93,74.91
      c0.31,3.95-0.51,6.31-4.89,7.52c-7.84,2.16-15.22,5.66-22.33,9.64c-7.09,3.97-13.76,8.59-20.87,13.76
      c-1.19-14.78-7.87-25.17-21.67-30.26c-0.71-1.93,1.62-1.06,1.91-2.07c6.63-3.48,9.47-9.34,10.67-16.43
      c2.53-14.9-3.11-26.93-15.61-32.89c-5.52-2.63-11.4-1.84-17.19-1.99c-6.87-0.17-6.88-0.14-6.88-6.96c0-4.33,0.01-8.66,0.01-12.99
      c0-0.67,0.18-1.4-0.04-1.99c-2.49-6.87-8.23-1.49-12.21-3.12c-2.4-0.98-3.88,0.32-3.75,3.36c0.23,5.65-0.21,11.34,0.16,16.98
      c0.26,3.87-1.09,4.69-4.7,4.66c-6.4-0.06-6.4,0.19-6.4-6.22c0-5.16-0.1-10.33,0.04-15.49c0.06-2.27-0.54-3.51-2.99-3.28
      c-0.16,0.02-0.33-0.02-0.5-0.03c-12.52-1.1-12.52-1.1-12.5,11.29c0.01,3.5-0.15,7,0.06,10.49c0.16,2.71-0.93,3.39-3.46,3.35
      c-7.82-0.13-15.66,0.17-23.47-0.14c-3.86-0.15-5.1,1.18-4.62,4.81c0.2,1.47,0.04,2.99,0.03,4.49c-0.05,8.16-0.05,8.16,8.4,8.16
      c10.55,0,12.73,2.29,12.71,12.97c-0.05,18.65,0.01,37.3-0.02,55.95c-0.01,8.33-2.78,11.06-11.08,11.11
      c-10.01,0.06-10.01,0.06-10.01,10.08c0,7.9,0,7.9,8.11,7.9c6.66,0,13.33,0.1,19.98-0.05c2.65-0.06,3.47,0.88,3.42,3.46
      c-0.14,6.49,0.08,12.99-0.1,19.48c-0.08,2.78,0.72,3.74,3.61,3.77c12.39,0.14,12.39,0.22,12.39-12.05c0-3,0.3-6.03-0.07-8.98
      c-0.57-4.61,1.12-5.82,5.7-5.82c4.48,0,5.79,1.24,5.46,5.63c-0.39,5.3-0.09,10.65-0.09,15.98c0,0.83-0.31,1.84,0.05,2.47
      c3.19,5.55,8.51,1.13,12.66,2.56c2.14,0.74,3.32-0.31,3.27-2.86c-0.13-6.66,0.08-13.33-0.1-19.98c-0.08-2.88,0.92-3.77,3.73-3.67
      c4.8,0.18,9.61,0.05,15.17,0.05c-1.86,2.97-3.41,5.45-4.96,7.92C165.12,260.76,159.75,274.32,156.15,288.55z M131.99,155.05
      c0.32-4.02-1.9-9.77,0.76-12.59c2.92-3.09,8.8-0.78,13.39-1.01c3.47-0.17,6.99-0.16,10.45,0.19c7.96,0.81,13.6,7.2,13.28,14.71
      c-0.31,7.32-6.18,13.04-14.1,13.31c-6.97,0.24-13.95,0.01-20.92,0.11c-2.13,0.03-2.96-0.66-2.89-2.83
      C132.08,163.12,131.99,159.3,131.99,155.05z M131.99,204.78c0.01,0,0.02,0,0.03,0c0-4.16,0.14-8.33-0.05-12.48
      c-0.13-2.66,0.82-3.4,3.41-3.34c7.49,0.18,14.99-0.11,22.47,0.18c9.22,0.35,16.21,7.52,16.27,16.22
      c0.06,7.83-7.63,15.66-16.21,16.01c-7.65,0.31-15.31,0-22.97,0.15c-2.56,0.05-3.05-1.02-2.98-3.25
      C132.08,213.77,131.99,209.27,131.99,204.78z M309.59,452.35c-73.74,6.2-134.29-46.63-140.52-116.86
      c-6.46-72.76,44.83-134.33,116.21-141.12c75.03-7.13,139.79,48.06,142.31,125C429.64,388.85,378.59,446.55,309.59,452.35z"/>
    <path class="st0" d="M188.08,176.3c-0.64,0.69-1.27,1.38-1.91,2.07c-0.96,0-1.92,0-3.92,0C184.66,176.97,186.2,176.19,188.08,176.3
      z"/>
    <path class="st0" d="M297.52,206.69c-65.89,0.82-116.6,53.79-115.99,117.49c0.63,66.18,54.19,116.13,117.14,115.68
      c65.8-0.48,116.44-53.59,115.93-117.34C414.08,257.38,361.46,206.7,297.52,206.69z M304.75,432.98
      c-59,4.35-112.02-42.18-116.25-102.71c-4.22-60.38,43.89-115.08,106.88-116.69c56.36-1.68,107.82,42.35,112.22,102.3
      C412.01,375.71,365.79,428.47,304.75,432.98z"/>
    <path class="st0" d="M287.49,397.31c-0.04-9.57-0.03-9.62-9.47-10.65c-10.59-1.15-21.03-2.94-31.12-6.47
      c-3.76-1.31-5.33-2.67-2.87-7.03c5.35-9.48,5.02-9.48,15.45-6.21c16.74,5.24,33.72,7.82,50.99,2.64
      c6.11-1.83,11.69-4.78,15.07-10.65c2.79-4.85,2.15-10.51-1.42-14.83c-4.86-5.89-11.46-8.94-18.36-11.48
      c-12.62-4.65-25.94-7.2-38.24-12.8c-6.53-2.97-12.91-6.25-17.47-11.97c-9.71-12.2-5.83-29.05,8.36-38.07
      c8.61-5.47,18.12-8.19,28-9.76c2.61-0.41,3.25-1.27,3.17-3.75c-0.15-4.49,0.19-8.99,0.08-13.48c-0.07-2.65,0.5-3.98,3.54-3.83
      c4.15,0.21,8.33,0.13,12.48-0.08c2.72-0.14,3.41,1,3.31,3.5c-0.16,3.99,0.25,8.02-0.11,11.98c-0.37,3.96,1.37,4.63,4.84,4.72
      c10.34,0.28,20.42,2.18,30.25,5.53c3.1,1.06,3.63,2.27,1.97,4.98c-1.99,3.25-3.02,7.65-5.82,9.68c-2.95,2.13-6.7-1.38-10.15-2.22
      c-15.2-3.69-30.35-5.91-45.7-0.88c-3.55,1.16-6.62,3.03-9.2,5.72c-5.84,6.11-5.18,13.93,1.76,18.88
      c9.65,6.89,21.1,9.41,32.14,12.89c11.57,3.64,22.95,7.49,33.13,14.52c14.22,9.81,18.05,32.16-0.7,46.09
      c-8.85,6.58-19.06,10.05-29.92,11.4c-3.58,0.44-4.8,1.77-4.54,5.37c0.33,4.47-0.04,8.99,0.12,13.48c0.09,2.44-0.81,3.18-3.16,3.1
      c-4.16-0.15-8.33-0.21-12.48,0.02c-3,0.17-4.35-0.67-4-3.87C287.67,401.66,287.49,399.48,287.49,397.31z"/>
  </g>
  </svg>
</template>

<script>
export default {

};
</script>

<style lang="scss">
</style>
