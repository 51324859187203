<template>
  <svg version="1.1" id="info-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 311 312" style="enable-background:new 0 0 311 312;" xml:space="preserve">
  <g>
    <path d="M155.68,311.03c-85.47,0-155-69.53-155-155c0-85.47,69.53-155,155-155c85.47,0,155,69.53,155,155
      C310.68,241.5,241.15,311.03,155.68,311.03z M155.68,21.03c-74.44,0-135,60.56-135,135s60.56,135,135,135s135-60.56,135-135
      S230.12,21.03,155.68,21.03z"/>
  </g>
  <g>
    <path d="M142.85,69.02h25.66v26.86h-25.66V69.02z M142.85,116.51h25.66v138.63h-25.66V116.51z"/>
  </g>
  </svg>
</template>

<script>
export default {

};
</script>

<style lang="scss">
</style>
