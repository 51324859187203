<template>
  <div class="invoice-checkout-component">
    <button class="title-bar" @click="scrollToSummary">
      <span>{{ $t('services.checkout.title') }}</span>
      <div class="arrow-up"></div>
    </button>
    <div class="main-bar">
      <div class="container">

        <div class="row">
          <div class="col-xs-6 col-login">

            <div class="checkout-anonymous" v-if="!isUserLogged">
              <div class="logout">
                <button class="btn btn-login" @click="showModal">{{ $t('services.checkout.button.login') }}</button>
                <button class="btn btn-create" @click="openRegisterModal">{{ $t('services.checkout.button.create') }}</button>
              </div>
            </div>

            <div class="checkout-logged" v-if="isUserLogged">
              <p class="username hidden-xs hidden-sm">{{ userData.name }}</p>
              <p class="username hidden-md hidden-lg">{{ userData.name.split(' ')[0] }}</p>
              <div class="logout">
                <span>{{ $t('services.checkout.wrongUser') }}</span>
                <button class="btn btn-create" @click="logoutToken">{{ $t('services.checkout.logout') }}</button>
              </div>
            </div>

          </div>

          <div class="col-xs-6 col-finish">
            <button class="btn btn--primary btn-finish" @click="finishInvoice">
              {{ $t('services.checkout.finishButton') }}
            </button>
          </div>
        </div>

      </div>
    </div>
    <ct-user-login-modal
      :isModalVisible="isModalVisible"
      @close="closeModal"
      @successLogin="successLogin"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CtUserLoginModal from './../Navbar/UserLoginModal';

export default {
  name: 'InvoiceCheckout',
  components: {
    CtUserLoginModal,
  },
  data: () => ({
    isModalVisible: false,
    isFinishInvoice: false,
    network: 'BTC',
  }),
  computed: {
    ...mapGetters(['isLogged', 'userData']),
    isUserLogged () {
      return this.isLogged;
    },
  },
  methods: {
    ...mapActions(['logoutUserToken', 'openRegisterModal', 'removeUserToken']),
    showModal () {
      this.isModalVisible = true;
    },
    closeModal () {
      this.isModalVisible = false;
    },
    async successLogin () {
      this.isModalVisible = false;
      if (this.isFinishInvoice) {
        this.isFinishInvoice = false;
        await this.finishInvoice();
      }
    },
    async finishInvoice (event) {
      const isValid = await this.$validator.validate();
      if (this.isUserLogged && isValid) {
        const callbackReturn = await new Promise((resolve) => this.$emit('finishInvoice', event, resolve));
        if (!callbackReturn) {
          await this.removeUserToken();
          this.isModalVisible = true;
          this.isFinishInvoice = true;
          return false;
        }
        return true;
      }
      if (!this.isLogged) {
        this.isModalVisible = true;
        this.isFinishInvoice = true;
      }
    },
    async logoutToken () {
      await this.logoutUserToken();
    },
    scrollToSummary () {
      document
        .querySelector('.invoice-summary-component')
        .scrollIntoView({ behavior: 'smooth', block: 'end' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";

.invoice-checkout-component {
  @media only screen and (max-width: $grid-sm-max) {
    position: fixed;
    width: 100%;
    bottom: 0px;
  }
}

.main-bar {
  background-color: $spotlight-color;
}
.title-bar {
  background-color: $spotlight-color;
  display: block;
  width: max-content;
  margin: auto;
  text-align: center;
  padding: 2px 15px 0px;
  border-radius: 5px 5px 0 0;

  &:focus {
    outline: none;
  }

  @media only screen and (min-width: $grid-md-min) {
    display: none;
  }

  span {
    color: $white-color;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }
}

.container {
  padding: 10px 0px;

  @media only screen and (max-width: $grid-lg-max) {
    padding: 12px 0;
  }

  @media only screen and (max-width: $grid-xs-max) {
    padding: 10px 5px;
  }
}

.col-login {
  display: flex;
  align-items: center;
}

.col-finish {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.checkout-anonymous {
  .btn {
    background-color: transparent;
    padding: 0px 20px;
    font-size: 1.8em;
    margin-left: 15px;

    &:first-child {
      margin-left: 0px;
    }

    @media only screen and (max-width: $grid-sm-max) {
      font-size: 1.6em;
    }

    @media only screen and (max-width: $grid-xs-max) {
      padding: 5px 15px;
      font-size: 1.2em;
    }

    @media only screen and (max-width: $grid-md-max) {
      padding: 0px 10px;
      margin-left: 0;
    }
  }
  .btn-login {
    padding-left: 0px;
    color: $white-color;
  }

  .btn-create {
    color: $secondary-color;
    padding-right: 0px;
    border-left: 2px solid $secondary-color;
  }
}

.checkout-logged {
  .username {
    margin: 0 0 10px;
    color: $white-color;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    @media only screen and (max-width: $grid-md-max) {
      margin: 0;
    }
  }

  .logout {
    @media only screen and (max-width: $grid-sm-max) {
      padding: 0px 10px;
    }

    span {
      font-size: 11px;
      line-height: 0.89;
      color: $white-color;
      text-transform: none;

      @media only screen and (max-width: $grid-md-max) {
        display: none;
      }
    }

    .btn {

      background-color: transparent;
      font-size: 14px;
      line-height: 1;
      display: inline;
      color: $secondary-color;
      padding-left: 10px;
      margin-left: 10px;
      text-transform: uppercase;
      border-left: 2px solid $secondary-color;

      @media only screen and (max-width: $grid-md-max) {
        border: none;
        padding-left: 0px;
        margin-left: 0px;
      }
    }
  }
}

.btn-finish {
  margin-right: 50px;
  font-size: 1.4em;

  @media only screen and (max-width: $grid-sm-max) {
    margin-right: 0px;
  }
}

.arrow-up {
  display: inline-block;
  margin-left: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
}

</style>
