<template>
  <section class="invoice-status-component">
    <div class="container">
      <div class="print-logo">
        <img src="/assets/img/icons/apple-touch-icon-76x76.png" />
      </div>

      <h1 class="invoice-status-title">
        Informações do pedido
      </h1>

      <div class="alert-invoice" v-if="invoiceStatus.name && invoiceStatus.icon">
        <div class="icon-invoice">
          <span :class="`icon-status ${invoiceStatus.icon}`" />
        </div>

        <div class="alert-title-mobile">
          <div class="alert-title">{{ invoiceStatus.name }}</div>
          <div class="icon-invoice">
            <span :class="`icon-status icon-mobile ${invoiceStatus.icon}`" />
          </div>
        </div>

        <div class="alert-content">
          <div class="alert-title">
            {{ invoiceStatus.name }}
          </div>
          <div class="alert-text">
            {{ invoiceStatus.message }}
          </div>
        </div>

      </div>

      <div class="row invoice-values">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 value-invoice">
          <h2 class="invoice-title">
            Valor do Pedido
            <div class="invoice-value">
              <span>{{ invoice.cryptoAmountOrig | cryptocurrency }} BTC</span>
            </div>
          </h2>

          <div class="invoice-details">
            <div class="details">
              <span>Cotação BTC</span>
              R$ {{ invoice.cryptoPrice | currency }}
            </div>
            <div class="details">
              <span>Valor em Real</span>
              R$ {{ invoice.fiatAmount | currency }}
            </div>
          </div>

        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 value-invoice-send">
          <div class="invoice-content">

            <h2 v-if="!isNotConfirmed" class="invoice-title">
              Valor Confirmado
              <div class="invoice-value">
                <span>{{ invoice.cryptoAmountPaid | cryptocurrency }} BTC</span>
              </div>
            </h2>
            <h2 v-if="isNotConfirmed" class="invoice-title pay-wait">
              Aguardando confirmação...
            </h2>
            <span v-if="isUnderPaidValue" class="icon-status icon-underpaid" />
            <span v-if="isOverPaidValue" class="icon-status icon-overpaid" />

          </div>

          <div v-if="showInvoiceDifference && !isUnderPaidExpiredValue" class="invoice-details">
            <div class="details-content">
              <div v-if="isUnderPaidValue" class="details">
                <span>Você deve enviar:</span>
                {{ differenceValue | cryptocurrency }} BTC
              </div>
              <div v-if="isUnderPaidValue && address " class="details small">
                <span>carteira: </span>
                <a :href="linkAddress" class="address-link">{{ address }}</a>
              </div>
            </div>
            <span v-if="isUnderPaidValue" class="icon-status icon-small icon-send-btc" />
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Invoice from '@/model/Invoice';
import currency from '@/filters/currency';
import cryptocurrency from '@/filters/cryptocurrency';

export default {
  name: 'InvoiceStatus',
  data () {
    return {
      statusList: {
        EXPIRED: {
          name: 'Pedido Expirado',
          icon: 'icon-expired',
          message: 'Realize novamente seu pedido e faça a transação de bitcoins até o tempo limite. Caso tenha feito a transação após expiração, confirme a operação na sua carteira ou exchange. Assim que confirmado, responda pelo e-mail do seu pedido com o TXID, de preferência.',
        },
        PENDING: {
          name: 'Pedido Pendente',
          icon: 'icon-pending',
          message: 'A transação de bitcoins foi identificada e aguardamos a confirmação da rede. Verifique seu e-mail para acompanhar o status do seu pedido.',
        },
        PAID: {
          name: 'Pedido Pago',
          icon: 'icon-paid',
          message: 'A transação de bitcoins foi confirmada e começamos a processar o seu pedido! Verifique seu e-mail para acompanhar o status do seu pedido.',
        },
        OVERPAID: {
          name: 'Pedido Pago - Valor Excedente',
          icon: 'icon-overpaid',
          message: 'A transação de bitcoins foi confirmada e começamos a processar o seu pedido! Identificamos o pagamento com um valor excedente que deve ser estornado. Verifique seu e-mail e retorne com seus dados para estorno.',
        },
        UNDERPAID: {
          name: 'Pedido Pago - Valor Parcial',
          icon: 'icon-underpaid',
          message: 'A transação de bitcoins foi confirmada, porém identificamos pagamento de valor parcial. Verifique seu e-mail e responda com as informações solicitadas para darmos andamento ao seu pedido.',
        },
        UNDERPAID_EXPIRED: {
          name: 'Pedido Expirado - Valor Parcial',
          icon: 'icon-underpaid',
          message: 'Apesar da expiração do tempo limite, a transação de bitcoins foi confirmada, porém identificamos um valor parcial. Verifique seu e-mail e responda com as informações solicitadas para darmos andamento ao seu pedido.',
        },
        PROCESSING: {
          name: 'Pedido em processamento',
          icon: 'icon-processing',
          message: 'Seu pedido está sendo processado. Verifique seu e-mail para acompanhar o status do seu pedido.',
        },
        FINISHED: {
          name: 'Pedido Finalizado',
          icon: 'icon-finalized',
          message: 'Todos os itens do seu pedido foram processados com sucesso! Informações sobre prazos de compensação ou recarga, verifique abaixo do Resumo do Pedido ou pelo nosso FAQ - Dúvidas Frequentes.',
        },
        REVERSAL: {
          name: 'Pedido Estornado',
          icon: 'icon-reversal',
          message: 'O estorno foi realizado com sucesso! Em caso de dúvidas, entre em contato com nosso suporte pelo e-mail: info@paguecombitcoin.com',
        },
        CANCELLED: {
          name: 'Pedido Cancelado',
          icon: 'icon-canceled',
          message: 'Infelizmente seu pedido foi cancelado. Em caso de dúvidas, entre em contato com nosso suporte pelo e-mail: info@paguecombitcoin.com',
        },
      },
    };
  },
  filters: {
    currency,
    cryptocurrency,
  },
  props: {
    invoice: {
      type: Invoice,
    },
  },
  computed: {
    showInvoiceDifference () {
      return this.isUnderPaidValue;
    },
    isNotConfirmed () {
      return this.invoice.cryptoAmountPaid <= 0 && this.invoice.status === 'PENDING';
    },
    isUnderPaidValue () {
      return this.invoice.status === 'UNDERPAID';
    },
    isOverPaidValue () {
      return this.invoice.status === 'OVERPAID';
    },
    address () {
      const firstTransaction = this.invoice.transactions[0] || {};
      return firstTransaction.address || '';
    },
    linkAddress () {
      if (!this.address) {
        return '';
      }
      return `bitcoin:${this.address}?amount=${this.differenceValue}`;
    },
    invoiceStatus () {
      let status = this.invoice.status;
      if (this.invoice.status === 'NEW' && this.invoice.isExpired) {
        status = 'EXPIRED';
      }

      return this.statusList[status] || {
        name: '',
        icon: '',
        message: '',
      };
    },

    isUnderPaidExpiredValue () {
      return this.isUnderPaidValue && this.invoice.isExpired;
    },

    differenceValue () {
      if (this.invoice.status === 'UNDERPAID') {
        return this.invoice.cryptoAmount - this.invoice.cryptoAmountPaid;
      }
      return this.invoice.cryptoAmountPaid - this.invoice.cryptoAmount;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';
@import '../../themes/mixins/layout';

.invoice-status-component {
  font-family: 'Roboto', sans-serif;

  @media only screen and (max-width: $grid-sm-max) {
    margin: 0 10px 25px
  }

  .container {

    .print-logo {
      display: none;
      img {
          margin-bottom: 40px;
      }
      @media print {
        display: block;
      }
    }

    .invoice-status-title {
      font-size: 34px;
      margin-bottom: 35px;
      text-align: center;

      @media only screen and (max-width: $grid-sm-max) {
        display: none
      }

      @media print {
        display: none;
      }
    }

    .invoice-values {
      @media print {
        display: none;
      }
      margin-bottom: 15px;
    }

    .alert-invoice {
      background: $secondary-color;
      color: $white-color;
      padding: 35px 10px;
      font-size: 16px;
      margin: 0 0 35px;
      display: flex;

      @media only screen and (max-width: $grid-md-max) {
        flex-wrap: wrap
      }

      @media only screen and (max-width: $grid-sm-max) {
        margin: 10px 0;
      }

      @media print {
        margin: 0 0 20px 0;
        padding: 6px;
        background: $white-color;
        color: $text-color;
        border: 1px $primary-color solid;
        .alert-title {
          color: $primary-color;
        }
      }

      .icon-invoice {
        display: flex;
        .icon-status {
          margin: 0 30px;

          @media only screen and (max-width: $grid-md-max) {
            display: none;
          }

          @media print {
            display: none;
          }

          &.icon-mobile {
            display: none;
            @media only screen and (max-width: $grid-md-max) {
              display: flex;
              margin: 0 10px 10px 0
            }
          }
        }
      }

      .alert-title-mobile {
        display: none;
        @media only screen and (max-width: $grid-md-max) {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          flex-direction: row-reverse;
        }

        .alert-title {
          text-transform: uppercase;
          margin-bottom: 8px;
          font: {
            weight: bold;
            size: 18px
          }
        }
      }

      .alert-content {
        @media only screen and (max-width: $grid-md-max) {
          display: flex;
          padding: 0 10px
        }
        .alert-title {
          text-transform: uppercase;
          margin-bottom: 8px;
          font: {
            weight: bold;
            size: 18px
          }
          @media only screen and (max-width: $grid-md-max) {
            display: none
          }
        }

        .alert-text {
          @media only screen and (max-width: $grid-md-max) {
            display: flex;
            text-align: center;
            width: 100%
          }
        }
      }
    }

    .row {
      @media only screen and (max-width: $grid-sm-max) {
        margin-bottom: 25px
      }
      .value-invoice {
        background: $light-grey-color;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: $grid-sm-max) {
          min-height: 0
        }

        .invoice-title {
          font-size: 30px;
          text-align: center;
          line-height: 1.1;

          .invoice-value {
            @media only screen and (max-width: $grid-sm-max) {
              margin-bottom: 12px
            }
            span {
              color: $secondary-color
            }
          }
        }

        .invoice-details {
          display: inline-flex;
          text-align: center;
          font-size: 16px;
          margin-bottom: 35px;

          .details {
            margin-right: 17px;

            &:last-child {
              margin: 0 0 0 17px
            }
            span {
              display: flex;
              font-weight: bold;
              margin-bottom: 10px;
            }
          }
        }
      }

      .value-invoice-send {
        background: $spotlight-color;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .invoice-content {
          height: 100%;
          @include flex-center();
          .invoice-title {
            font-size: 30px;
            color: $white-color;
            text-align: center;
            line-height: 1.1;

            @media only screen and (max-width: $grid-md-max) {
              margin: 45px 10px;
              padding: 0 10px;
            }

            &.pay-wait{
              @media only screen and (max-width: $grid-sm-max) {
              font-size: 20px;
            }
            }
            .invoice-value {
              span {
                color: $primary-color
              }
            }
          }
          .icon-status {
            margin: 25px 0 0 30px
          }
        }

        .invoice-details {
          background: $primary-color;
          text-align: center;
          font-size: 16px;
          padding: 8px 0;
          @include flex-center();

          .details-content {
            .details {
              color: $spotlight-color;
              line-height: 1.5;
              text-transform: uppercase;
              .address-link {
                text-transform: none;
              }
              font: {
                family: 'Oswald', sans-serif;
                weight: 500;
                size: 18px
              }

              @media only screen and (max-width: $grid-sm-max) {
                font-size: 16px
              }

              &.small {
                font-size: 12px;
                @media only screen and (max-width: $grid-sm-max) {
                  font-size: 10px
                }

                a {
                  color: $spotlight-color
                }
              }

              span {
                color: $white-color
              }
            }

          }
          .icon-status {
            margin-left: 30px;

            @media only screen and (max-width: $grid-sm-max) {
              margin-left: 5px
            }
          }
        }
      }
    }
  }
}

</style>
