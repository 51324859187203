<template>
  <section class="tutorial-services-images-component">
    <div class="container">
      <h1 class="title-principal">Como usar nossos serviços</h1>

      <p class="info-text">
        Você deseja saber como começar a comprar e pagar com bitcoins? É bem simples utilizar nossa plataforma e o
        aplicativo da PAGUECOMBITCOIN! Veja o passo-a-passo no tutorial abaixo:
      </p>

      <h3> <span class="link-action" @click="openRegisterModal">Cadastre-se</span></h3>
      <p>
        Caso tiver dúvidas sobre Como fazer seu Cadastro na PagueComBitcoin, acesse nosso FAQ
        <router-link :to="{ name: 'faq', hash: '#cadastro' }">aqui.</router-link>
      </p>
      <picture>
        <source media="(max-width: 767px)" srcset="/assets/img/tutorial/tutorial-cadastro-mobile.jpg">
        <img src="/assets/img/tutorial/tutorial-cadastro.jpg" alt="Realize seu cadastro" />
      </picture>

      <h3 class="only-desktop">
        NO MENU PRINCIPAL, CLIQUE NO SERVIÇO QUE DESEJA SOLICITAR
      </h3>
      <h3 class="only-mobile">
        NO MENU INFERIOR, CLIQUE NO SERVIÇO QUE DESEJA SOLICITAR
      </h3>
      <p class="only-desktop">
        Você pode acessar todos os serviços pelo <b>MENU PRINCIPAL</b> ou pelo <b>MENU DE SERVIÇOS</b> encontrado em todas as páginas específicas.
      </p>
      <p class="only-mobile">
        Você pode acessar todos os serviços pelo MENU INFERIOR ou pelo MENU DE SERVIÇOS encontrado em todas as páginas específicas.
      </p>
      <p>
        Em todas as páginas relacionadas abaixo é possível acessar o “CARRINHO DE COMPRAS”, ou seja,  inserir qualquer
        um dos itens de serviços, sem a necessidade de navegação em outra página.
      </p>
      <ul class="simple-list">
        <li>
          <router-link :to="{ name: 'service-billet' }">
            Pagamento de boleto bancário
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'service-shipment' }">
            Conversão de bitcoins em reais
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'service-pix' }">
            Bitcoin por Pix
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'service-gift-card' }">
            Compra de gift card (cartão presente)
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'service-cell-phone' }">
            Recarga de celular pré-pago
          </router-link>
        </li>
      </ul>

      <picture>
        <source media="(max-width: 767px)" srcset="/assets/img/tutorial/tutorial-services-menu-mobile.jpg">
        <img src="/assets/img/tutorial/tutorial-services-menu.jpg" alt="Menu principal de serviços" />
      </picture>

      <h3>
        SELECIONE OU PREENCHA OS CAMPOS COM OS DADOS NECESSÁRIOS
      </h3>

      <p>
        Ao escolher um tipo de serviço, preencha os campos com os dados corretamente ou escolha uma das opções disponíveis.
      </p>

      <p>
        <b>Exemplo: Pagamento de Boleto.</b>
      </p>

      <p>
        Você pode pagar com bitcoins: boletos de cobrança (cartão de crédito, compras online,
        financiamentos, condomínio), boletos de consumo (água, luz, telefone, gás, etc) e boletos de recolhimento de
        taxas com código de barras, como DAS, DARF, etc. Guias de recolhimento de imposto sem código de barras
        infelizmente não são suportados.
      </p>

      <p>
        <u>Nossa plataforma não realiza validação de linhas digitáveis de boletos iniciadas com dígito “8”</u>, como de
        boletos de consumo (água, luz, telefone, gás, condomínio, etc) ou guias de pagamento de taxas e
        tributo, ou seja, não é possível identificar eventuais erros de digitação, por isso recomendamos a atenção
        no preenchimento dos dados desses boletos para evitar erros de qualquer natureza.
      </p>

      <p class="info-text">
        É possível incluir no seu pedido quantos serviços forem necessários, mas só é possível fazer a finalização dele
        caso todas as informações necessárias para o processamento de todos os serviços estiverem preenchidas.
      </p>

      <picture>
        <source media="(max-width: 767px)" srcset="/assets/img/tutorial/tutorial-services-menu-page-mobile.jpg">
        <img src="/assets/img/tutorial/tutorial-services-menu-page.jpg"
          alt="Serviço de pagamento de boleto"
        />
      </picture>

      <h3>
        ADICIONE MÚLTIPLOS ITENS DE SERVIÇOS NO MESMO PEDIDO
      </h3>

      <p>
        Você pode incluir múltiplos serviços clicando no link <b>[+] ADICIONAR NOVO</b> localizado abaixo do item de cada
        serviço ou [-] Excluir.
      </p>

      <p>
        A maior vantagem de realizar um pedido com diversos itens é a economia com a cobrança de uma única taxa de
        transação dos bitcoins realizada pela sua carteira (wallet) ou exchange.
      </p>

      <h3>
        VERIFIQUE TODOS OS DADOS E RESUMO DO PEDIDO
      </h3>

      <p>
        Após incluir todos os itens do seu pedido, verifique a Cotação Atual, a quantidade de itens, o valor da Taxa
        de Serviços, valor total em reais e o valor total em bitcoins.
      </p>

      <p>
        Clique no botão <b>Finalizar Pedido</b> para ser direcionado à página de Checkout para realizar o pagamento.
      </p>

      <p>
        Cobramos uma taxa de serviços para cada item do seu pedido.
        <router-link :to="{ name: 'taxes' }">Mais informações em Taxas.</router-link>
      </p>

      <picture>
        <source media="(max-width: 767px)" srcset="/assets/img/tutorial/tutorial-services-resume-mobile.jpg">
        <img src="/assets/img/tutorial/tutorial-services-resume.jpg"
          alt="Resumo do pedido"
        />
      </picture>

      <h3>
        REALIZE A TRANSAÇÃO EM BITCOINS PARA PAGAMENTO
      </h3>

      <p>
        Na Página de Checkout, realize a transação de bitcoins para o endereço de Carteira da PagueComBitcoin.
        Caso sua carteira ou exchange não incluir a taxa de rede automaticamente, inclua o valor integral da taxa ao valor do pedido.
      </p>

      <p>
        <b>VALOR TOTAL = VALOR DO PEDIDO + VALOR DA TAXA DE REDE DA SUA EXCHANGE/CARTEIRA</b>
      </p>

      <p>
        Realize a transação de bitcoins em até {{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos para garantia do valor de Cotação fechada no pedido e congelada durante esse período:
      </p>

      <ul class="simple-list">
        <li>
          Copie o endereço da carteira para realizar a transação;
        </li>
        <li>
          Clique no botão Abrir Carteira, caso tiver uma carteira instalada no mesmo dispositivo;
        </li>
        <li>
          Realize a leitura do QRCode a partir da carteira de onde a transação será disparada.
        </li>
      </ul>

      <picture>
        <source media="(max-width: 767px)" srcset="/assets/img/tutorial/tutorial-checkout-mobile.jpg">
        <img src="/assets/img/tutorial/tutorial-checkout.jpg"
          alt="Checkout do seu pedido"
        />
      </picture>

      <p>
        Pode ocorrer alguma diferença entre a cotação apresentada no RESUMO DO PEDIDO e no CHECKOUT devido a
        atualização da Cotação BTC baseada na cotação média das últimas 24 horas dos livros de ofertas das maiores
        exchanges nacionais no mercado de bitcoins.
      </p>

      <p>
        Após esse período, seu pedido será expirado, sendo necessário a criação de um novo pedido.
      </p>

      <h3>
        ACOMPANHE SEU PEDIDO
      </h3>
      <p>
        Após a realizar a transação em bitcoins, aguardamos a identificação e a confirmação da transação pela rede,
        como também, o valor em bitcoins transferido.
      </p>
      <p>
        Acompanhe seu pedido na mesma página de Checkout, pelo link
        <router-link :to="{ name: 'checkout-last' }">ÚLTIMO PEDIDO </router-link>
        ou pelas notificações enviadas para seu e-mail cadastrado.
      </p>
      <p>
        <b>Segue abaixo possíveis STATUS de pedido:</b>
      </p>
      <p>
        <b>Pedido Finalizado</b>: O pedido foi finalizado, ou seja, o(s) serviço(s) solicitados no pedido foram processados,
        como por exemplo o pagamento do boleto. Mais informações sobre Prazos e confirmações, acesse nosso
        <router-link :to="{ name: 'faq', hash: '#pagamentos' }">FAQ - Pagamentos e Comprovantes.</router-link>
      </p>
      <picture>
        <source media="(max-width: 767px)" srcset="/assets/img/tutorial/tutorial-finalizado-mobile.jpg">
        <img src="/assets/img/tutorial/tutorial-finalizado.jpg" alt="Pedido finalizado">
      </picture>

      <p>
        <b>Pedido Expirado</b>: O pedido expirou pois a transação não foi identificada até o tempo limite de expiração do pedido.
      </p>
      <picture>
        <source media="(max-width: 767px)" srcset="/assets/img/tutorial/tutorial-expirado-mobile.jpg">
        <img src="/assets/img/tutorial/tutorial-expirado.jpg" alt="Pedido expirado">
      </picture>

      <p>
        <b>Pedido Estornado</b>: O pedido foi estornado devido a transação em bitcoins ser realizada abaixo do valor total do
        pedido ou por não possibilidade de processamento do item de serviço solicitado, como por exemplo um boleto ainda
        não registrado ou algo semelhante.
      </p>
      <picture>
        <source media="(max-width: 767px)" srcset="/assets/img/tutorial/tutorial-estornado-mobile.jpg">
        <img src="/assets/img/tutorial/tutorial-estornado.jpg" alt="Pedido expirado">
      </picture>

      <h3>
        INDIQUE E GANHE
      </h3>
      <p>
        A PagueComBitcoin oferece um Programa de Indicação para todos os usuários cadastrados na plataforma.
        Cada usuário tem um link de indicação único para compartilhar com seus amigos não cadastrados na PagueComBitcoin.
      </p>
      <p>
        Faça o login, acesse a área do usuário canto superior à direito) e clique no botão INDIQUE E GANHE.
      </p>
      <picture>
        <source media="(max-width: 767px)" srcset="/assets/img/tutorial/tutorial-indique-mobile.jpg">
        <img src="/assets/img/tutorial/tutorial-indique.jpg" alt="Pedido expirado">
      </picture>
    </div>

  </section>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import currency from '@/filters/currency';

export default {
  name: 'TutorialServicesImages',
  methods: {
    ...mapActions([
      'openRegisterModal',
    ]),
  },
  computed: {
    ...mapGetters(['currentRestriction']),
    maxShipmentValue () {
      return this.currentRestriction.maxShipmentValue ? currency(this.currentRestriction.maxShipmentValue) : '?';
    },
    minShipmentValue () {
      return this.currentRestriction.minShipmentValue ? currency(this.currentRestriction.minShipmentValue) : '?';
    },
    minBilletValue () {
      return this.currentRestriction.minBilletValue ? currency(this.currentRestriction.minBilletValue) : '?';
    },
    ...mapState({
      settings: (state) => state.settings.settings,
    }),
  },

};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";

.tutorial-services-images-component {
  margin-bottom: 75px;

  .only-mobile {
    display: none;

    @media only screen and (max-width: $grid-xs-max) {
      display: block;
    }
  }

  .only-desktop {
    display: block;

    @media only screen and (max-width: $grid-xs-max) {
      display: none;
    }
  }

  .container {
    width: 100%;
    max-width: 1200px;

    .link-action {
      &:hover {
        cursor: pointer;
      }
    }

    @media only screen and (max-width: $grid-xl-max) {
      padding-right: 30px;
      padding-left: 30px
    }

    .title-principal {
      text-align: center;
      margin-bottom: 50px;
      @media only screen and (max-width: $grid-xs-max) {
        font-size: 24px;
        margin-bottom: 30px;
      }
    }

    .title-services {
      font-size: 30px;
      color: $primary-color;
      margin-bottom: 20px;
      margin-top: 35px;

      @media only screen and (max-width: $grid-xs-max) {
        font-size: 22px;
      }
    }

    img {
      max-width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      display: block
    }

    p {
      font: {
        family: 'Roboto', sans-serif;
      }

      a {
        color: $secondary-color;
      }

    }
  }
}

</style>
