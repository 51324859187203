<template>
  <div class="register-error-component">
    <h1>{{ $t('register.steps.error.title') }}</h1>

    <p v-html="$t('register.steps.error.errorMessage')"></p>

    <h2 v-html="$t('register.steps.error.goBack')"></h2>

    <button @click.prevent @click="returnToRegisterStep" class="btn btn--primary block-center">
      {{ $t('register.steps.error.buttons.back') }}
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'RegisterError',
  methods: {
    ...mapActions(['returnToRegisterStep']),
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.register-error-component {
  padding: 25px;
  @media screen and (max-width: $grid-xs-max) {
    padding: 0px;
  }

  h1, h2, p {
    color: $tertiary-color;
  }

  h1 {
    font-size: 38px;
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 25px;
    text-transform: uppercase;
    @media screen and (max-width: $grid-xs-max) {
      font-size: 20px;
      margin-bottom: 15px;
      line-height: 24px;
    }
  }
  h2 {
    font-size: 19px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 25px;
    text-transform: uppercase;
    text-align: center;
    @media screen and (max-width: $grid-xs-max) {
      font-size: 16px;
    }
  }
  p {
    margin-bottom: 111px;
  }

  .btn-action {
    background: $tertiary-color;
    color: $spotlight-color;
    font-size: 23px;
    margin: auto;
    width: 50%;
    @media only screen and (max-width: $grid-xs-max) {
      font-size: 13px;
    }
  }
}

</style>
