<script>
import VSelect from 'vue-select';

export default {
  extends: VSelect,

  props: {
    /**
     * Tells vue-select what key to use when generating option
     * values when each `option` is an object.
     * @type {String}
     */
    index: {
      type: String,
      default: 'value',
    },
  },
};
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';
@import "../../themes/variables";

.v-select {
  height: 38px;
  background-color: #ebebeb;
  border-radius: 0px;

  .vs__actions {
    *:hover {
      cursor: pointer;
    }
  }

  &.vs-select-modal {
    padding-left: 25px;
    @media only screen and (max-width: $grid-xs-max) {
      height: 23px;
    }
    .vs__dropdown-toggle {
      padding: 0;

      @media only screen and (max-width: $grid-xs-max) {
        height: 23px;
      }
    }

    .vs__selected-options {
      .vs__selected {
        @media only screen and (max-width: $grid-xs-max) {
          font-size: 11px;
        }
      }
    }

    .vs__actions {
      padding: 0 5px;
      .vs__clear {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .vs__dropdown-menu {
      height: 130px;
      border-radius: 0
    }
  }

  .vs__dropdown-toggle {
    height: 38px;
    border: none;
  }

  input[type=search] {
    position: absolute;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    text-indent: 10px;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #757575 !important;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #757575 !important;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #757575 !important;
    }
  }

  span.selected-tag {
    /*pointer-events: none;*/
  }

  span.selected-tag + input[type=search] {
    width: 0 !important;
  }

  &.open input[type=search] {
    width: auto !important;
  }

  &.is-invalid {
    .dropdown-toggle {
      border: 1px solid red;
    }
  }
  .dropdown-toggle {
    min-height: 34px;
  }

  .dropdown-toggle::after {
    content: none;
  }

  &.form-control-lg {
    padding: 0;
    min-height: 40px;

    .dropdown-toggle {
      min-height: 40px;
      input[type=search] {
        margin: 0;
      }
    }
  }
  .vs__selected {
    margin: 0;
  }
}
.v-select.disabled, .v-select.readonly {
  background-color: #f8f8f8;
  input[type=search] {
    background-color: transparent;
  }
}
</style>
