import InvoiceService from './../../../services/InvoiceService';

export default {
  async getInvoiceDetail ({ commit }, id) {
    try {
      const invoice = await InvoiceService.getInvoiceDetail(id);
      if (invoice.response && invoice.response.status && invoice.response.status === 500) {
        return false;
      }
      commit('SET_CURRENT_INVOICE', invoice);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async getLastInvoice ({ commit }, user = {}) {
    try {
      const invoice = await InvoiceService.getLastInvoice(user);
      if (!invoice || (invoice.response && invoice.response.status && invoice.response.status !== 200)) {
        return false;
      }
      commit('SET_CURRENT_INVOICE', invoice);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};
