<template>
  <div class="about-content-component">

    <div class="container">
      <h1 class="about-title">
        Conheça a PagueComBitcoin
      </h1>
      <p class="about-info">
        A <b>PagueComBitcoin</b> é o primeiro meio de pagamento cripto do Brasil.
      </p>
      <p class="about-info">
        Presente desde 2013 no mercado, a PagueComBitcoin é o terceiro negócio cripto da América Latina.
      </p>
      <p class="about-info">
        Surgimos quando tudo ainda era mato. A nossa solução de pagamentos trouxe para nossos clientes, a possibilidade de fazer do Bitcoin sua moeda do dia a dia.
      </p>
      <p class="about-info">
        Integrar o mundo digital ao mundo real de forma dinâmica e simples é o que nos move. Aqui nós quebramos as barreiras de quem acha que bitcoin só serve para investimento. A verdade é que com o bitcoin a gente pode mais, inclusive, usar ele como dinheiro.
      </p>
      <p class="about-info">
        Atualmente a PagueComBitcoin está presente em diversos lugares do mundo, ajudando pessoas e empresas a pagar e receber tudo com bitcoin, e assim, dando ao Bitcoin seu propósito original escrito no seu White paper, "...um sistema de pagamento eletrônico baseado em prova criptográfica em vez de confiança."
      </p>
      <p class="about-info">
        Nossa plataforma atualmente conta com 7 serviços:
      </p>
      <ul class="simple-list">
        <li>Pagamento de Boletos</li>
        <li>Saque por TED</li>
        <li>Saque por Pix</li>
        <li>Recarga de Celular</li>
        <li>Compra de giftcards</li>
        <li>Gateway de pagamento por API</li>
        <li>Aplicativo para celular IOS e Android para ser usado como POS</li>
      </ul>

      <p class="about-info">
        <b>PagueComBitcoin</b> é simples até no nome. Te convidamos a entrar na nova economia digital começando a pagar tudo com bitcoin.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutContent',
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';

.about-content-component {
  .container {
    .about-title {
      max-width: 1055px;
      text-align: center;
      margin-bottom: 65px;
      @media only screen and (max-width: $grid-xs-max) {
        max-width: 100%;
        font-size: 23px;
        margin: 0 auto 15px
      }
    }

    .about-info {
      max-width: 964px;
      text-align: center;

      @media only screen and (max-width: $grid-sm-max) {
        margin: 0 15px 30px
      }
      @media only screen and (max-width: $grid-xs-max) {
        margin: 0 10px 15px;
      }

      &:last-child {
        margin-bottom: 60px;
        @media only screen and (max-width: $grid-xs-max) {
          margin-bottom: 30px
        }
      }

      b {
        color: $primary-color;
        font-weight: bold;
        text-transform: uppercase
      }
    }

    .simple-list {
      max-width: 964px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

</style>
