<template>
  <div class="banner-component">
    <picture>
      <source media="(max-width: 991px)" :srcset="banner.imgUrlMobile">
      <img :src="banner.imgUrl" :alt="banner.textAlt">
    </picture>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    banner: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";

.banner-component {
  @media print {
    display: none;
  }

  width: 100%;
  margin-bottom: 50px;
  @media only screen and (max-width: $grid-sm-max) {
    margin: 61px 0 30px
  }

  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    @media only screen and (max-width: $grid-lg-max) {
      height: auto;
    }
  }
}

</style>
