<template>
  <div class="checkout-component">
    <ct-invoice-checkout :invoice="invoice" v-if="isValid" />
    <ct-invoice-status :invoice="invoice" v-if="!isValid" />
    <ct-invoice-summary :invoice="invoice" />
  </div>
</template>

<script>
import CtInvoiceCheckout from './InvoiceCheckout';
import CtInvoiceStatus from './InvoiceStatus';
import CtInvoiceSummary from './InvoiceSummary';
import Invoice from '@/model/Invoice';
import { mapActions, mapState, mapGetters } from 'vuex';
import config from '@/config';

export default {
  name: 'Checkout',
  components: {
    CtInvoiceCheckout,
    CtInvoiceStatus,
    CtInvoiceSummary,
  },
  data: () => ({
    interval: null,
  }),
  props: {
    invoiceId: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions([
      'getInvoiceDetail',
    ]),
    addIntervals () {
      if (this.invoice.status === 'NEW') {
        this.initializeInterval(config.intervals.invoices.new);
      };
      if (this.invoice.status === 'PENDING' || this.invoice.status === 'PROCESSING') {
        this.initializeInterval(config.intervals.invoices.default);
      };
    },
    initializeInterval (time) {
      this.interval = setInterval(async () => {
        const oldStatus = this.invoice.status;
        await this.getInvoiceDetail(this.invoiceId);
        const paidStatus = ['PENDING', 'PROCESSING', 'PAID', 'OVERPAID', 'UNDERPAID'];
        if (oldStatus && this.invoice.status !== oldStatus) {
          if (this.invoice.status === 'EXPIRED') {
            this.trackInvoiceEvent('invoice-expired');
          } else if (paidStatus.includes(this.invoice.status) && !paidStatus.includes(oldStatus)) {
            this.trackInvoiceEvent('invoice-received');
          }
        }
      }, time);
    },

    async trackInvoiceEvent (event) {
      if (event && this.$gtm && this.$gtm.enabled()) {
        await this.$gtm.trackEvent({
          category: 'invoice',
          action: event,
          label: event,
        });
      }
    },
  },
  computed: {
    ...mapState({
      invoice: (state) => new Invoice(state.invoices.currentInvoice),
    }),
    ...mapGetters(['isLogged']),
    isValid () {
      return this.invoice.status === 'NEW' && !this.invoice.isExpired;
    },
  },
  async mounted () {
    this.addIntervals();
  },
  beforeDestroy () {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>

<style>

</style>
