<template>
  <a class="instagram-button-component" @click="clicked" :href="url" :target="target">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.25 7.288v1.269c0 .297-.241.539-.538.539h-1.269c-.299 0-.538-.242-.538-.539v-1.269c0-.297.239-.538.538-.538h1.269c.297 0 .538.241.538.538zm-5.25 7.403c1.486 0 2.693-1.205 2.693-2.692s-1.207-2.69-2.693-2.69c-1.487 0-2.691 1.204-2.691 2.691s1.204 2.691 2.691 2.691zm4.261-3.291c.028.196.046.396.046.599 0 2.38-1.928 4.308-4.307 4.308s-4.307-1.928-4.307-4.307c0-.204.018-.403.046-.599.027-.194.066-.383.118-.567h-1.107v5.879c0 .297.241.538.538.538h9.424c.297 0 .538-.241.538-.538v-5.879h-1.107c.05.184.09.373.118.566zm7.739.6c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-5.385c0-.892-.723-1.615-1.615-1.615h-10.77c-.892 0-1.615.723-1.615 1.615v10.769c0 .893.723 1.616 1.615 1.616h10.77c.892 0 1.615-.723 1.615-1.616v-10.769z"/></svg>
  </a>
</template>

<script>
export default {
  name: 'FacebookButton',
  props: {
    url: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: '_blank',
    },
  },
  data () {
    return {
    };
  },
  methods: {
    clicked () {
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
  @import "../../themes/variables";
  .instagram-button-component {
    cursor: pointer;
    border-radius: 50%;
    svg {
      width: 2.88em;
      height: 2.88em;
      fill: $white-color;
      &:hover {
        fill: $white-color;
      }
    }
  }
</style>
