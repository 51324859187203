<template>
  <svg version="1.1"  class="billet-icon" :class="{type: true}" :style="{ height: size }" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 238.21 337.77" style="enable-background:new 0 0 238.21 337.77;" xml:space="preserve">
    <g>
      <path d="M217.84,0H20.37C9.14,0,0,9.14,0,20.37v297.04c0,11.23,9.14,20.37,20.37,20.37h161.37c1.88,0,3.68-0.75,5.01-2.08
        l49.38-49.38c1.33-1.33,2.08-3.13,2.08-5.02V20.37C238.21,9.14,229.07,0,217.84,0L217.84,0z M188.83,313.56v-18.98
        c0-3.41,2.77-6.19,6.19-6.19H214L188.83,313.56z M20.37,14.18h197.47c3.41,0,6.19,2.77,6.19,6.19v21.91H14.18V20.37
        C14.18,16.95,16.96,14.18,20.37,14.18L20.37,14.18z M14.18,317.41V56.46h209.84v217.75h-29.01c-11.23,0-20.37,9.14-20.37,20.37
        v29.01H20.37C16.96,323.59,14.18,320.82,14.18,317.41L14.18,317.41z M14.18,317.41"/>
      <path d="M43.37,108.96h92.85c3.92,0,7.09-3.18,7.09-7.09c0-3.91-3.18-7.09-7.09-7.09H43.37c-3.92,0-7.09,3.18-7.09,7.09
        C36.28,105.79,39.45,108.96,43.37,108.96L43.37,108.96z M43.37,108.96"/>
      <path d="M164.58,108.96h23.97c3.92,0,7.09-3.18,7.09-7.09c0-3.91-3.18-7.09-7.09-7.09h-23.97c-3.92,0-7.09,3.18-7.09,7.09
        C157.49,105.79,160.67,108.96,164.58,108.96L164.58,108.96z M164.58,108.96"/>
      <path d="M43.37,165.26h92.85c3.92,0,7.09-3.18,7.09-7.09c0-3.92-3.18-7.09-7.09-7.09H43.37c-3.92,0-7.09,3.18-7.09,7.09
        C36.28,162.09,39.45,165.26,43.37,165.26L43.37,165.26z M43.37,165.26"/>
      <path d="M164.58,165.26h23.97c3.92,0,7.09-3.18,7.09-7.09c0-3.92-3.18-7.09-7.09-7.09h-23.97c-3.92,0-7.09,3.18-7.09,7.09
        C157.49,162.09,160.67,165.26,164.58,165.26L164.58,165.26z M164.58,165.26"/>
      <path d="M143.31,214.47c0-3.92-3.18-7.09-7.09-7.09H43.37c-3.92,0-7.09,3.18-7.09,7.09c0,3.91,3.17,7.09,7.09,7.09h92.85
        C140.14,221.56,143.31,218.39,143.31,214.47L143.31,214.47z M143.31,214.47"/>
      <path d="M164.58,221.56h23.97c3.92,0,7.09-3.18,7.09-7.09c0-3.92-3.18-7.09-7.09-7.09h-23.97c-3.92,0-7.09,3.18-7.09,7.09
        C157.49,218.39,160.67,221.56,164.58,221.56L164.58,221.56z M164.58,221.56"/>
      <path d="M188.55,262.08c3.92,0,7.09-3.18,7.09-7.09c0-3.92-3.18-7.09-7.09-7.09h-67.53c-3.92,0-7.09,3.18-7.09,7.09
        c0,3.91,3.17,7.09,7.09,7.09H188.55z M188.55,262.08"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '27px',
    },
  },
};
</script>

<style lang="scss" >
  @import "../../../themes/variables";

  .billet-icon{
    fill: $spotlight-color;
    height: 27px;
  }

</style>
