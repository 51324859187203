<template>
  <section class="depositions-component">
    <div class="container">
      <h1 class="depositions-title">{{ title }}</h1>
      <div class="depositions-detail">
        <transition-group name="depositions" tag="div">
          <ct-deposition-item
            :deposition="depositions[current]"
            class="item"
            :key="current"
          />
        </transition-group>
      </div>
        <div class="bullet-group">
          <span
            v-for="(deposition, index) in depositions"
            :key="index"
            class="bullet"
            :class="{'active': index === current}"
            @click="changeCurrentDeposition(index)"
          />
        </div>
    </div>
  </section>
</template>

<script>
import CtDepositionItem from './DepositionItem';
import config from '@/config';

export default {
  name: 'Depositions',
  components: {
    CtDepositionItem,
  },
  props: {
    title: {
      type: String,
      default: 'O que falam em nossas redes!',
    },
    depositions: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      current: 0,
      interval: null,
    };
  },
  methods: {
    changeCurrentDeposition (index) {
      this.current = index;
      clearInterval(this.interval);
      this.initializeInterval();
    },
    setNextDeposition () {
      const next = this.current + 1 >= this.depositions.length ? 0 : this.current + 1;
      this.changeCurrentDeposition(next);
    },
    setPrevDeposition () {
      const prev = this.current - 1 < 0 ? this.depositions.length - 1 : this.current - 1;
      this.changeCurrentDeposition(prev);
    },
    initializeInterval () {
      this.interval = setInterval(
        this.setNextDeposition.bind(this),
        config.intervals.depositions,
      );
    },
  },
  mounted () {
    this.initializeInterval();
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.depositions-component {
  overflow-x: hidden;
  text-align: center;
  position: relative;
  .container {
    width: 100%;
    max-width: 833px;
    min-height: 450px;
    @media only screen and (max-width: $grid-sm-max) {
      max-width: 700px;
    }
    @media only screen and (max-width: $grid-xs-max) {
      min-height: 0px
    }

    .depositions-title {
      padding: 40px 0 60px 0;
      @media only screen and (max-width: $grid-xs-max) {
        max-width: 100%;
        margin: auto;
        padding: 28px 0;
        font-size: 25.5px
      }
    }
    .depositions-detail {
      min-height: 240px;
      @media only screen and (max-width: $grid-xs-max) {
        min-height: 140px
      }
      .depositions-enter-active,
      .depositions-leave-active {
        transition: 1s;
      }
      .depositions-enter {
        transform: translate(100%, 0);
      }
      .depositions-leave-to {
        transform: translate(-200%, 0);
      }
    }
    .bullet-group {
      min-height: 120px;
      @include flex-center();
      @media only screen and (max-width: $grid-xs-max) {
        min-height: 80px
      }
      .bullet {
        transition: all ease 0.2s;
        margin-right: 9px;
        width: 12px;
        height: 12px;
      }
    }
  }
}
</style>
