<template>
  <section class="services-intro">
    <div class="container">
      <h1>
        {{ $t('services.title.page.shipment.titleOne') }}
        <span class="emphasis-variant">
          {{ $t('services.title.page.shipment.crypto') }}
        </span>
        {{ $t('services.title.page.shipment.titleTwo') }}
      </h1>

      <p>
        Deseja vender seus bitcoins em poucos minutos de forma simples, segura e sem burocracia? Faça a Conversão de
        Bitcoins em Reais em apenas 3 passos, sem necessidade de uma exchange.
      </p>

      <p>
        <span class="weight-bold">Cotação PagueComBitcoin atual:
        <span class="emphasis-variant">BTC {{ (quotation.BTCBRL && quotation.BTCBRL.buy ? quotation.BTCBRL.buy : 0) | currency }}.</span></span>
      </p>

      <h2>Como converter <span class="emphasis-variant">bitcoins</span> em reais?</h2>

      <p>
        Faça seu
        <a href="#" @click.prevent="openLoginModal" class="text weight-bold">Login</a> com email e senha ou
        <a class="weight-bold" @click="openRegisterModal">Cadastre-se aqui</a>.
      </p>

      <p>
        Selecione no <span class="weight-bold">Menu de Serviços</span> acima o item Conversão de Bitcoins. Você pode
        "+ Adicionar" a quantidade de itens        que desejar no mesmo pedido ou “- Excluir” um item de serviço.
      </p>
      <p>
        <span class="weight-bold">Preencha todos os campos</span> corretamente com os dados da Conta Bancária que deseja
        realizar a transferência em reais.
      </p>
      <p>
        No caso de <span class="weight-bold">Transferência para Conta de Terceiros</span>, é necessário informar
        corretamente o Nome Completo ou Razão Social do Titular da Conta, CPF/ CNPJ do titular, um email válido do
        terceiro e selecionar a finalidade da
        operação.
      </p>
      <p>
        Verifique as informações do <span class="weight-bold">Resumo do Pedido</span>: Cotação BTC recente, a
        quantidade de itens adicionados, o total da taxa de serviço, o valor total em Reais e o valor total em BTC a
        ser pago e clique em <span class="weight-bold">Finalizar Pedido</span> para ser direcionado à página de
        <span class="weight-bold">Checkout</span>.
      </p>
      <p>
        Caso ainda não estiver logado, será solicitado a realização do login ou cadastro para finalizar o pedido.
      </p>
      <p>
        Na página de Checkout/ pagamento , confira as informações do
        <span class="weight-bold">Valor do Pedido em BTC</span>, Cotação Bitcoin e Valor
        em Real. O <span class="weight-bold">Valor Confirmado</span> BTC será atualizado após confirmação.
      </p>
      <p>
        Para garantirmos o valor da cotação, você deve <span class="weight-bold">realizar a transação de bitcoins em
        até <span class="emphasis-variant">{{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos</span></span> por meio das seguintes
        opções:
      </p>
      <ul class="simple-list">
        <li><span class="weight-bold">Copiar e colar  o código</span> para realizar o envio;</li>
        <li>Clicar no botão <span class="weight-bold">Abrir Carteira</span>, caso tiver uma carteira bitcoin instalada
        no mesmo dispositivo para realizar o envio;</li>
        <li>Realizar <span class="weight-bold">leitura do QRCode</span>, pela carteira instalada no seu dispositivo
        móvel.</li>
      </ul>
      <p>
        Após realizar a transação do <span class="weight-bold">Valor do Pedido em BTC</span> da sua carteira para a
        carteira da PagueComBitcoin, você será comunicado por e-mail com o processamento e confirmação do pagamento.
      </p>
      <p class="weight-bold">
        Você também pode acompanhar o status do seu
        <router-link class="weight-bold emphasis-variant" :to="{ name: 'checkout-last' }">último pedido</router-link>
        pelo link ou atalho da área de usuário logada.
      </p>
      <p class="weight-bold">
        Verifique seu e-mail e acompanhe as mensagens de cada etapa do seu pedido.
      </p>

      <h2>Prazos e Valores</h2>
      <p>
        <span class="weight-bold">O valor mínimo para conversão de bitcoins é de
        <span class="emphasis-variant">R$ {{ (settings.restriction && settings.restriction.minShipmentValue ? settings.restriction.minShipmentValue : 0) | currency }}</span></span> e o
        máximo é de
        <span class="weight-bold emphasis-variant">R$ {{ (settings.restriction && settings.restriction.maxShipmentValue ? settings.restriction.maxShipmentValue : 0) | currency }}</span>.
        O limite para conversão de bitcoins é compartilhado entre movimentações Pix e TED, sendo diário de
        <span class="weight-bold emphasis-variant">R$ {{ (settings.restriction && settings.restriction.maxShipmentDailyLimitValue ? settings.restriction.maxShipmentDailyLimitValue : 0) | currency }}</span>,
        totalizando até
        <span class="weight-bold emphasis-variant">R$ {{ (settings.restriction && settings.restriction.maxShipmentMonthlyLimitValue ? settings.restriction.maxShipmentMonthlyLimitValue : 0) | currency }}</span>
        mensais. Para operações em grandes volumes, consulte nosso serviço exclusivo de Mesa OTC  pelo email
        <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>.
      </p>

      <p>
        Assim que a transação de bitcoins for confirmada, realizamos a transferência bancária para a conta informada
        automaticamente. Pedidos finalizados em dias úteis após às 16h30 ou aos finais de semana e feriados serão
        processadas somente no próximo dia útil devido encerramento do período de processamento bancário.
      </p>
      <p>
        Cobramos uma <span class="weight-bold">taxa de conveniência de
          <span class="emphasis-variant">{{ (settings.itemFees && settings.itemFees.shipment ? settings.itemFees.shipment : 0) | currency }}</span></span> para cada item de
          Conversão de Bitcoin, ou seja, cada operação. Em caso de necessidade de estorno, será realizado a
          transferência em bitcoins ou em reais dependendo do status do pedido com desconto da taxa de transferência.
          Mais em <router-link :to="{ name: 'taxes' }">Taxas e Informações</router-link>.
      </p>
    </div>

    <ct-user-login-modal
      :isModalVisible="isModalLoginVisible"
      @close="closeLoginModal"
      @successLogin="closeLoginModal"
    />
  </section>
</template>

<script>
import currency from './../../filters/currency';
import { mapActions, mapState } from 'vuex';
import CtUserLoginModal from '../Navbar/UserLoginModal';

export default {
  name: 'ServicesIntroShipment',
  filters: {
    currency,
  },
  components: {
    CtUserLoginModal,
  },
  data () {
    return {
      isModalLoginVisible: false,
    };
  },
  methods: {
    ...mapActions(['openRegisterModal']),
    openLoginModal () {
      this.isModalLoginVisible = true;
    },
    closeLoginModal () {
      this.isModalLoginVisible = false;
    },
  },
  computed: {
    ...mapState({
      quotation: state => state.settings.ticker,
      settings: (state) => state.settings.settings,
    }),
  },
};
</script>

<style lang="scss" scoped>

</style>
