import countries from './../../../config/countries';

const getState = () => ({
  operatorsRecharge: [],
  amountsRecharge: [],
  operatorsGiftCard: [],
  amountsGiftCard: {},
  kindsAccount: [
    {
      value: 'CC',
      label: 'Conta Corrente',
    },
    {
      value: 'CP',
      label: 'Conta Poupança',
    },
  ],
  documentKinds: [
    {
      value: 'F',
      label: 'Pessoa Física',
    },
    {
      value: 'J',
      label: 'Pessoa Jurídica',
    },
  ],
  countriesCodes: countries.map(c => ({
    value: c.code,
    label: `${c.name} (${c.code})`,
  })),
  countriesPhones: countries.map(c => ({
    value: `${c.phone}:${c.code}`,
    label: `${c.name} (${c.phone})`,
  })),
  states: [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ],
  blacklistedDDDs: [94],
  ddd: [
    {
      value: 11,
      label: '11',
    },
    {
      value: 12,
      label: '12',
    },
    {
      value: 13,
      label: '13',
    },
    {
      value: 14,
      label: '14',
    },
    {
      value: 15,
      label: '15',
    },
    {
      value: 16,
      label: '16',
    },
    {
      value: 17,
      label: '17',
    },
    {
      value: 18,
      label: '18',
    },
    {
      value: 19,
      label: '19',
    },
    {
      value: 21,
      label: '21',
    },
    {
      value: 22,
      label: '22',
    },
    {
      value: 24,
      label: '24',
    },
    {
      value: 27,
      label: '27',
    },
    {
      value: 28,
      label: '28',
    },
    {
      value: 31,
      label: '31',
    },
    {
      value: 32,
      label: '32',
    },
    {
      value: 33,
      label: '33',
    },
    {
      value: 34,
      label: '34',
    },
    {
      value: 35,
      label: '35',
    },
    {
      value: 37,
      label: '37',
    },
    {
      value: 38,
      label: '38',
    },
    {
      value: 41,
      label: '41',
    },
    {
      value: 42,
      label: '42',
    },
    {
      value: 43,
      label: '43',
    },
    {
      value: 44,
      label: '44',
    },
    {
      value: 45,
      label: '45',
    },
    {
      value: 46,
      label: '46',
    },
    {
      value: 47,
      label: '47',
    },
    {
      value: 48,
      label: '48',
    },
    {
      value: 49,
      label: '49',
    },
    {
      value: 51,
      label: '51',
    },
    {
      value: 53,
      label: '53',
    },
    {
      value: 54,
      label: '54',
    },
    {
      value: 55,
      label: '55',
    },
    {
      value: 61,
      label: '61',
    },
    {
      value: 62,
      label: '62',
    },
    {
      value: 63,
      label: '63',
    },
    {
      value: 64,
      label: '64',
    },
    {
      value: 65,
      label: '65',
    },
    {
      value: 66,
      label: '66',
    },
    {
      value: 67,
      label: '67',
    },
    {
      value: 68,
      label: '68',
    },
    {
      value: 69,
      label: '69',
    },
    {
      value: 71,
      label: '71',
    },
    {
      value: 73,
      label: '73',
    },
    {
      value: 74,
      label: '74',
    },
    {
      value: 75,
      label: '75',
    },
    {
      value: 77,
      label: '77',
    },
    {
      value: 79,
      label: '79',
    },
    {
      value: 81,
      label: '81',
    },
    {
      value: 82,
      label: '82',
    },
    {
      value: 83,
      label: '83',
    },
    {
      value: 84,
      label: '84',
    },
    {
      value: 85,
      label: '85',
    },
    {
      value: 86,
      label: '86',
    },
    {
      value: 87,
      label: '87',
    },
    {
      value: 88,
      label: '88',
    },
    {
      value: 89,
      label: '89',
    },
    {
      value: 91,
      label: '91',
    },
    {
      value: 92,
      label: '92',
    },
    {
      value: 93,
      label: '93',
    },
    {
      value: 94,
      label: '94',
    },
    {
      value: 95,
      label: '95',
    },
    {
      value: 96,
      label: '96',
    },
    {
      value: 97,
      label: '97',
    },
    {
      value: 98,
      label: '98',
    },
    {
      value: 99,
      label: '99',
    },
  ],
  purposes: [
    {
      label: 'Pagamento de Aluguel / Condomínio',
      value: 'HOUSING_PAYMENT',
    },
    {
      label: 'Pagamento de Duplicatas / Títulos',
      value: 'DUPLICATE_PAYMENT',
    },
    {
      label: 'Pagamento de Fornecedores',
      value: 'SUPPLIERS_PAYMENT',
    },
    {
      label: 'Arrecadação / Repasse de Tributos',
      value: 'TAXES_PAYMENT',
    },
    {
      label: 'Pagamento de Salários',
      value: 'SALARY_PAYMENT',
    },
    {
      label: 'Pagamento de Dividendos',
      value: 'DIVIDEND_PAYMENT',
    },
    {
      label: 'Pagamento de Mensalidade Escolar',
      value: 'TUITION_PAYMENT',
    },
    {
      label: 'Pensão Alimentícia',
      value: 'ALIMONY_PAYMENT',
    },
    {
      label: 'Depósito Judicial',
      value: 'JUDICIAL_DEPOSIT',
    },
    {
      label: 'Crédito em Conta',
      value: 'ACCOUNT_CREDIT',
    },
  ],

  pixKeyTypeOptions: [
    {
      label: 'CPF/CNPJ',
      value: 'DOCUMENT',
    },
    {
      label: 'E-mail',
      value: 'EMAIL',
    },
    {
      label: 'Telefone',
      value: 'PHONE',
    },
    {
      label: 'Aleatória',
      value: 'UUID',
    },
  ],
});

export default Object.assign(getState(), { getState: getState });
