<template>
  <section class="splash-app-component">
    <div class="container splash-app-container">
      <div class="row splash-app-row">
        <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12 content">
          <h1>APLICATIVO MOBILE <br /> <span class="emphasis-variant">PAGUECOMBITCOIN</span></h1>
          <p>
            Com o Aplicativo Mobile PagueComBitcoin, você paga seus boletos com bitcoins de forma mais simples com a
            mesma segurança da nossa plataforma web.
          </p>
          <p>
            Além disso, você tem na palma da sua mão todos os demais serviços disponíveis: PIX, Conversão de bitcoins em
            reais (venda de bitcoins), compra de gift cards, recarga de celular.
          </p>
          <p>
            Disponível para celulares Android (Google Play) e iOS (App Store).
          </p>
          <div class="badges">
            <ios-badge />
            <android-badge />
          </div>
        </div>
        <div class="col-lg-6 col-lg-offset-1 col-md-12 col-sm-12 col-xs-12 splash-app">
          <img src="/assets/img/app/app-splash.png" class="img-responsive" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IosBadge from './IosBadge';
import AndroidBadge from './AndroidBadge';

export default {
  name: 'SplashApp',
  components: {
    AndroidBadge,
    IosBadge,
  },
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';
@import "../../themes/mixins/layout";

.splash-app-component {
  img {
    max-width: 100%;
  }

  div.badges {
    display: flex;
    margin-left: -10px;

    @media only screen and (max-width: $grid-md-max) {
      margin-left: 0px;
      justify-content: center;
      margin-bottom: 40px;
    }
  }

  @media only screen and (max-width: $grid-md-max) {
    p {
      text-align: center;
    }
  }
}
</style>
