<template>
  <section class="gateway-content-component">
    <div class="container gateway-container">
      <h1 class="gateway-title">
        Gateway de pagamento <span>bitcoin</span>.
      </h1>

      <h2 class="gateway-title sub-title">O que é um <span>gateway</span> de pagamento bitcoin?</h2>

      <div class="content-icons-info">
        <div class="content-icon">
          <span class="icon icon-payments"></span>
          <p>Múltiplos métodos de recebimento</p>
        </div>
        <span><p>+</p></span>
        <div class="content-icon">
          <span class="icon icon-values"></span>
          <p>Unificação dos valores recebidos</p>
        </div>
        <span><p>+</p></span>
        <div class="content-icon">
          <span class="icon icon-adm"></span>
          <p>Facilidade em administrar o seu negócio</p>
        </div>
      </div>

      <h2 class="gateway-title sub-title">
        Vantagens do <span>gateway bitcoin</span> da pague.
      </h2>
      <h3 class="topic-title">
        <span>1</span>
        Múltiplas unidades de negócio.
      </h3>
      <ul class="topic-list">
        <li class="topic-item default-text">Plataforma para diversas empresas ou filiais.</li>
        <li class="topic-item default-text">Diversas unidades de negócios.</li>
        <li class="topic-item default-text">Saldos independentes.</li>
      </ul>
      <h3 class="topic-title">
        <span>2</span>
        Múltiplos perfis de usuários.
      </h3>
      <ul class="topic-list">
        <li class="topic-item default-text">Acessos distintos em grupos de controle.</li>
        <li class="topic-item default-text">Controle e segurança para operações.</li>
      </ul>
      <h3 class="topic-title">
        <span>3</span>
        Escolha criptoativos ou reais.
      </h3>
      <ul class="topic-list">
        <li class="topic-item default-text">Conversão do pagamento em tempo real.</li>
        <li class="topic-item default-text">Sem exposição a volatidade.</li>
        <li class="topic-item default-text">Definição arbitrária por tipo de pagamento.</li>
        <li class="topic-item default-text">Conversão de saldo para criptoativos ou reais a qualquer momento.</li>
      </ul>
      <h3 class="topic-title">
        <span>4</span>
        Saques avulsos e automatizados com função “split”.
      </h3>
      <ul class="topic-list">
        <li class="topic-item default-text">
          Escolha durante o pedido de saque o destino do saldo.
          <ul class="topic-list-item">
            <li class="topic-item default-text">Sua conta bancária.</li>
            <li class="topic-item default-text">Contas bancárias de terceiros.</li>
            <li class="topic-item default-text">Endereço de carteira de seu criptoativo.</li>
          </ul>
        </li>
        <li class="topic-item default-text">
          Selecione o valor de saque e efetue a divisão “split”.
          <ul class="topic-list-item">
            <li class="topic-item default-text">Para diversas contas bancárias ou endereços de carteiras.</li>
            <li class="topic-item default-text">Facilidade em pagamento de comissões e divisões de lucros.</li>
          </ul>
        </li>
        <li class="topic-item default-text">
          Criação de perfis de saques automatizados.
          <ul class="topic-list-item">
            <li class="topic-item default-text">
              Efetuados com planejamento de cotas de valores atingidos suportando a função split.
            </li>
          </ul>
        </li>
      </ul>
      <h3 class="topic-title">
        <span>5</span>
        Quem pode usar nosso gateway?
      </h3>
      <ul class="topic-list">
        <li class="topic-item default-text">Disponível para e-commerces, para pessoas jurídicas e para pessoas físicas.</li>
        <li class="topic-item default-text">Não atende estabelecimentos físicos.</li>
      </ul>
      <h3 class="topic-title">
        <span>6</span>
        Integrações com o nosso gateway.
      </h3>
      <ul class="topic-list">
        <li class="topic-item default-text">Integração com woocommerce ou magento.</li>
        <li class="topic-item default-text">Utilização de addons, oficiais da pague.</li>
        <li class="topic-item default-text">Integração por API pública disponível em link.</li>
      </ul>
      <h2 class="gateway-title sub-title">
        Pré-requisitos para <span>Cadastro</span>.
      </h2>
      <ul class="topic-list">
        <li class="topic-item default-text">Cartão CNPJ - Comprovante de Inscrição ou Situação Cadastral.</li>
        <li class="topic-item default-text">Contrato social.</li>
        <li class="topic-item default-text">Documento de identificação com foto do sócio administrador responsável pelo cadastro.</li>
        <li class="topic-item default-text">Prova de vida do sócio administrador - Como emitir? Tire uma foto (selfie) segurando seu
          documento e uma folha com a seguinte frase: "Cadastro para Gateway da PagueComBictoin".
        </li>
      </ul>
      <span class="divider divider-mobile"></span>
      <h2 class="gateway-title sub-title">
        Formulário de Cadastro
      </h2>
    </div>
  </section>
</template>

<script>
export default {
  name: 'GatewayContent',
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';

.gateway-content-component {
  padding: 0 20px;
  @media only screen and (max-width: $grid-sm-max) {
    padding: 0 30px;
  }
  @media only screen and (max-width: $grid-xs-max) {
    padding: 0 25px;
  }

  .gateway-container {
    .gateway-title {
      margin: 70px 0;
      text-align: center;
      line-height: 1;

      @media only screen and ( max-width: $grid-xs-max) {
        margin-top: 55px;
        margin-bottom: 55px;
      }

      &.sub-title {
        margin-bottom: 50px;
        @media only screen and ( max-width: $grid-xs-max) {
          font-size: 20px;
        }
      }

      @media only screen and ( max-width: $grid-xs-max) {
        font-size: 24px;
        margin: 25px 0
      }

      &:first-child {
        line-height: 70px;

        @media only screen and ( max-width: $grid-xs-max) {
          margin-top: 35px;
          line-height: 1;
        }
      }
      small {
        font-size: 60%;
        display: block;
        line-height: 1.1;
      }
      span {
        color: $primary-color;
      }
    }

    .divider {
      width: 90%;
      margin: auto;
      display: block;
      border-bottom: 1px solid $spotlight-color;

      &.divider-mobile {
        display: none;
        @media only screen and (max-width: $grid-xs-max) {
          display: block;
        }
      }

      @media only screen and (max-width: $grid-xs-max) {
        width: 100%;
      }
    }

    .content-icons-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 75%;
      margin: 40px auto;

      @media only screen and (max-width: $grid-md-max) {
        width: 100%;
      }
      @media only screen and (max-width: $grid-sm-max) {
        flex-direction: column;
      }

      .content-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 207px;

        .icon {
          background-size: 100% 100%;
          background-repeat: no-repeat;
          display: block;

          &.icon-payments {
            width: 81px;
            height: 83px;
            background: url('/assets/img/icons/payments-icon.png');

            @media only screen and (max-width: $grid-xs-max) {
              width: 58px;
              height: 60px;
              background-size: 100% 100%;
            }
          }
          &.icon-values {
            width: 79px;
            height: 78px;
            background: url('/assets/img/icons/values-icon.png');

            @media only screen and (max-width: $grid-xs-max) {
              width: 56px;
              height: 56px;
              background-size: 100% 100%;
            }
          }
          &.icon-adm {
            width: 88px;
            height: 64px;
            background: url('/assets/img/icons/adm-icon.png');

            @media only screen and (max-width: $grid-xs-max) {
              width: 62px;
              height: 46px;
              background-size: 100% 100%;
            }
          }
        }
        p {
          color: $spotlight-color;
          margin-top: 20px;
          margin-bottom: 0;
          line-height: 1.1;
          text: {
            align: center;
            transform: uppercase;
          }
          font: {
            weight: 500;
            size: 18px;
          }
          @media only screen and (max-width: $grid-xs-max) {
            font-size: 14px;
          }
        }
      }

      span {
        padding-bottom: 30px;
        p {
          color: $primary-color;
          font: {
            weight: 500;
            size: 73px;
          }
          @media only screen and (max-width: $grid-xs-max) {
            font-size: 52px;
          }
        }
        @media only screen and (max-width: $grid-sm-max) {
          padding-top: 30px;
        }
        @media only screen and (max-width: $grid-xs-max) {
          padding-top: 25px;
          padding-bottom: 25px;
        }
      }
    }

    .topic-title {
      margin-bottom: 25px;

      @media only screen and (max-width: $grid-xs-max) {
        font-size: 18px;
        line-height: 29px;
        margin-bottom: 15px;
      }

      span {
        font-size: 55px;
        color: $primary-color;

        @media only screen and (max-width: $grid-xs-max) {
          font-size: 24px;
        }

      }
    }
    .topic-list {
      margin: 0 0 55px 85px;

      @media only screen and (max-width: $grid-xs-max) {
        margin-left: 40px;
        margin-bottom: 30px;
      }

      .topic-item {
        .topic-list-item {
          margin-left: 40px;
          margin-bottom: 30px;
          list-style: none;

          @media only screen and (max-width: $grid-xs-max) {
            margin-bottom: 20px;
          }

          .topic-item {
            text-transform: none;
            font-weight: 400;

            &:before {
              content: "> "
            }
          }
        }
      }
    }
  }
}
</style>
