<template>
  <div class="popover-component">
    <div class="popover-container">
      <slot></slot>
      <div class="popover">
        <div class="popover-content">
          <span class="popover-simbol"></span>
          <h6 class="popover-title">{{ item.title }}</h6>
          <p class="popover-detail">{{ item.detail }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popover',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';

.popover-component {

  .popover-container {
    position: relative;
    display: flex;
    align-items: center;

    @media only screen and (max-width: $grid-sm-max) {
      justify-content: center;
      align-items: flex-start;
    }

    &:hover {
      .popover {
        display: flex;
      }
    }

    .popover {
      display: none;
      position: absolute;
      max-width: 220px;
      min-width: 220px;
      background: $spotlight-color;
      color: $white-color;
      padding: 15px;
      font-family: 'Oswald', sans-serif;
      transition: opacity 0.3s ease;
      left: calc(100% + 20px);

      @media only screen and (max-width: $grid-sm-max) {
        left: auto;
        top: calc(100% + 20px);
      }

      .popover-content {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media only screen and (max-width: $grid-sm-max) {
          justify-content: flex-start;
          align-items: center
        }
        .popover-simbol {
          position: absolute;
          width: 20px;
          height: 20px;
          background: $spotlight-color;
          left: -25px;
          transform: rotate(45deg);

          @media only screen and (max-width: $grid-sm-max) {
            left: auto;
            top: -25px;
          }
        }
        .popover-title {
          color: $primary-color;
          font-size: 15px;
          margin-bottom: 10px;

          @media only screen and (max-width: $grid-sm-max) {
            font-size: 13px;
          }
        }

        .popover-detail {
          margin-bottom: 0;
          font: {
            weight: 300;
            size: 13px
          }

          @media only screen and (max-width: $grid-sm-max) {
            text-align: center;
            font-size: 11px;
          }
        }
      }
    }
  }
}

</style>
