import http from '@/services/HttpService';

export default {
  async create (data) {
    if (!data.referral_id) {
      data.referral_id = null;
    }
    const res = await http().post('/api/v1/gateway/register', data);
    return res.data;
  },
  async uploadFile (file) {
    const formData = new FormData();
    formData.append('file', file);
    const res = await http().post('/api/v1/gateway/assets', formData);
    return res.data;
  },
};
