<template>
  <div class="user-login-mobile-component">
    <ct-modal v-if="isModalVisible">
      <div slot="button">
        <div class="btn-close" @click="$emit('close')">
          <span class="icon icon-close"></span>
        </div>
      </div>
      <div class="card-modal" v-if="!isLogged">
        <div class="modal-title">Faça seu login</div>
        <div class="form-group">
          <input
            type="email"
            v-validate="'required|email'"
            name="email"
            v-model="user.email"
            placeholder="e-mail"
            :class="['form-control placeholder-center', { 'is-invalid': errors.has('email') }]"
            />
            <span class="msg-error">{{ errors.first('email') }}</span>
        </div>
        <div class="form-group">
          <input
            name="password"
            id="password"
            data-vv-name="senha"
            type="password"
            v-model="user.password"
            v-validate="'required'"
            autocomplete="off"
            placeholder="senha"
            :class="['form-control placeholder-center', { 'is-invalid': errors.has('senha') }]"
          />
          <span class="msg-error">{{ errors.first('senha') }}</span>
        </div>
        <div class="form-action">
          <a href="#" @click="openResetPasswordModal(); $emit('close');" class="btn-link">Esqueceu a senha?</a>
          <button @click.prevent @click="login" class="btn btn--primary">Login</button>
        </div>
        <div class="form-group" v-if="hasError">
          <span class="msg-error-default">
            {{ errorMessage }}
          </span>
        </div>
        <div class="register">
          Não tem sua conta? <a href="#" @click="openRegisterModal(); $emit('close');">Cadastre-se</a>
        </div>
      </div>
      <div class="card-modal" v-if="isLogged">
        <div class="modal-title">Deseja realmente sair da sua conta?</div>
        <div class="form-group flex">
          <span class="user-info">{{ userData.name }}</span>
        </div>
        <div class="form-group flex">
          <span class="user-info">{{ userData.email | emailMasked }}</span>
        </div>
        <button @click.prevent @click="logoutToken" class="btn btn--primary btn-logout">Logout</button>
      </div>
    </ct-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import phoneMasked from './../../filters/phoneMasked';
import emailMasked from './../../filters/emailMasked';
import CtModal from '../Shared/Modal';

export default {
  name: 'UserLoginMobile',
  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    user: { email: '', password: '' },
    errorMessage: '',
    hasError: false,
  }),
  created () {
    this.setUserTokenAuthData();
  },
  methods: {
    ...mapActions(['loginUserToken', 'setUserTokenAuthData', 'logoutUserToken', 'openRegisterModal', 'openResetPasswordModal']),
    close () {
      this.$emit('close');
    },
    async logoutToken () {
      try {
        await this.logoutUserToken();
        if (this.$intercom) {
          this.$intercom.update({
            user_id: null,
            name: null,
            email: null,
          });
        }
      } catch (e) {
        this.errorMessage = 'Error ao tentar deslogar, contate o suporte!';
        this.hasError = true;
      }
    },
    async login () {
      try {
        const isValid = await this.$validator.validate();
        if (isValid) {
          await this.loginUserToken(this.user);
          this.user = { email: '', password: '' };
          await this.$emit('successLogin');
          if (this.$intercom) {
            this.$intercom.update({
              user_id: this.user.email,
              name: this.userData.name,
              email: this.user.email,
            });
          }
        }
      } catch (err) {
        this.errorMessage = err.message;
        if (err.response && err.response.status === 401) {
          this.errorMessage = 'E-mail e/ou senha inválidos. Tente novamente.';
        }

        if (err.response && err.response.status === 429) {
          this.errorMessage = 'Você já realizou muitas tentativas. Tente novamente em alguns minutos.';
        }

        this.hasError = true;
      }
    },
  },
  components: {
    CtModal,
  },
  computed: {
    ...mapGetters(['isLogged', 'userData']),
  },
  filters: {
    emailMasked,
    phoneMasked,
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.user-login-mobile-component {
  .modal {
    .register {
      color: $white-color;
      margin-top: 15px;
      text: {
        align: center;
        transform: none;
      }
      a {
        color: $secondary-color;
      }
    }
    .btn-logout {
      display: block;
      margin: 10px auto;
    }
    .btn-close {
      background: $primary-color;
      position: absolute;
      cursor: pointer;
      z-index: 3000;
      @include flex-center();
      width: 51px;
      height: 51px;
      top: -25px;
      left: 162px;
      @media only screen and (max-width: $grid-xs-max) {
        width: 34px;
        height: 34px;
        top: -17px;
        left: 112px;
      }

      .icon {
        display: flex;
        background: $spotlight-color;
        width: 18px;
        height: 18px;
        @media only screen and (max-width: $grid-xs-max) {
          width: 12px;
          height: 12px;
        }
        &.icon-close {
          mask: url('/assets/img/icons/icon-close.svg')no-repeat center;
          mask-size: 100% 100%;
        }
      }
    }
    .modal-content {
      .card-modal {
        width: 380px;
        background: $spotlight-color;
        padding-top: 25px;
        padding-bottom: 25px;
        @media only screen and (max-width: $grid-xs-max) {
          padding: 15px;
          width: 260px;
        }

        .modal-title {
          color: $primary-color;
          text-align: center;
          margin: 10px 30px 25px;
          line-height: 1;
          text-transform: uppercase;
          font: {
            size: 23px;
            weight: 400
          }
          @media screen and (max-width: $grid-xs-max) {
            margin: 0 0 15px;
            font-size: 14px;
          }
        }

        .form-group {
          &.flex {
            display: flex;
          }

          .form-control {
            max-width: 240px;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 3px;
            display: flex;
          }
          .user-info {
            width: 100%;
            color: $white-color;
            margin: 10px 0;
            font-size: 14px;
            @include flex-center();
            @media screen and (max-width: $grid-xs-max) {
              font-size: 10px;
              margin: 5px 0;
              justify-content: flex-start
            }
          }

          .msg-error-default {
            max-width: 240px;
            color: $secondary-color;
            background: $light-grey-color;
            padding: 8px;
            margin: 15px auto 0;
            display: block;
            text-align: center;
            @media only screen and (max-width: $grid-xs-max) {
              font-size: 10px;
              padding-top: 4px;
              padding-bottom: 4px;
            }
          }
        }

        .form-action {
          max-width: 240px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }
}

</style>
