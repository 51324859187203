const formatValue = (value) => {
  if (typeof value === 'undefined') return value;

  if (typeof value !== 'number') {
    value = parseFloat(value);
  }

  value = Number(value);
  if (isNaN(value)) {
    return 0;
  }

  return value
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+,)/g, '$1.');
};

export default formatValue;
