<template>
  <section class="order-app-component">
    <div class="container order-app-container">
      <div class="row order-app-row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 order-app">
          <img src="/assets/img/app/app-history.png" class="img-responsive" />
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 content">
          <h1>ACOMPANHE <br /> SEUS PEDIDOS</h1>
          <p>
            Em MEUS PEDIDOS, você também pode consultar o histórico de  todos os seus pedidos realizados com o
            status atualizado, a data de emissão, o valor em BTC e o valor em real.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ServicesApp',
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';
@import "../../themes/mixins/layout";

.order-app-component {
  margin-top: 80px;

  .order-app {
    display: flex;
    justify-content: center;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: $grid-md-max) {
      p {
        text-align: center;
      }
    }
    h1 {
      color: $secondary-color;
      margin-top: 0;
    }
  }
}
</style>
