import http from '@/services/HttpService';

export default {
  async getTicker (amount = 1, currency = 'BRL') {
    if (amount <= 0 || !amount) {
      amount = 1;
    }
    try {
      const res = await http().get(`/api/v1/ticker?currency=${currency.toUpperCase()}&amount=${amount.toString()}`);
      return res.data.data || {};
    } catch (e) {
      console.error(e);
      return {};
    }
  },
};
