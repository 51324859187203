<template>
  <section class="barcode-app-component">
    <div class="container barcode-app-container">
      <div class="row barcode-app-row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 barcode-app">
          <img src="/assets/img/app/app-billet.png" class="img-responsive" />
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 content">
          <h1>APLICATIVO MOBILE <br /> PAGUECOMBITCOIN</h1>
          <p>
            Com o Aplicativo, você pode escanear o código de barras do seu boleto pela câmera do seu celular no
            botão Digitalizar ou se preferir informar manualmente a linha digitável.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BarccodeApp',
};
</script>

<style lang="scss" scope>
@import '../../themes/variables';
@import "../../themes/mixins/layout";

.barcode-app-component {
  margin-top: 106px;
  background-color: $spotlight-color;
  padding-top: 70px;
  padding-bottom: 87px;

  .barcode-app {
    display: flex;
    justify-content: center;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;

    h1 {
      color: $primary-color;
      margin-top: 0;
    }

    p {
      color: $white-color;
      @media only screen and (max-width: $grid-md-max) {
        text-align: center;
      }
    }
  }
}
</style>
