<template>
<div class="billet-payment-item" :id="`billet-form-${this.uuid}`">
  <div>
    <form role="form" class="row">
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
        <label :for="`digitableLine${this.uuid}`">
          {{ $t('services.payment.fields.digitableLine') }}
        </label>
        <input
          class="form-control"
          :id="`digitableLine${this.uuid}`"
          name="digitableLine"
          v-mask="digitableLineMask"
          v-model="billet.digitableLine"
          :placeholder="$t('services.payment.placeholder.digitableLine')"
          :class="{'is-invalid': errors.has('digitableLine')}"
          v-validate="{ required: true }"
          :data-vv-as="$t('services.payment.fields.digitableLine')"
          @input="onDigitableLineChange"
          @paste="onDigitableLinePaste"
        />
        <div class="invalid-feedback">{{ errorMessages.digitableLine || errors.first('digitableLine') }}</div>
        <span class="field-info field-info-alert" v-if="isDigitableLineDuplicated">
          {{ $t('services.payment.duplicatedBillet') }}
        </span>
        <span class="field-info" v-if="billet.bank && !isDigitableLineDuplicated">
          {{ billet.bank }}
        </span>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-6 col-xs-6">
        <label :for="`dueDate${this.uuid}`">
          {{ $t('services.payment.fields.dueDate') }}
        </label>
        <the-mask
          class="form-control"
          :id="`dueDate${this.uuid}`"
          name="dueDate"
          mask="##/##/####"
          v-model="billet.dueDate"
          :masked="true"
          :readonly="disableDueDateField"
          :placeholder="$t('services.payment.placeholder.dueDate')"
          :class="{'is-invalid': errors.has('dueDate')}"
          v-validate="{ required: true, date_format: 'dd/mm/yyyy' }"
          :data-vv-as="$t('services.payment.fields.dueDate')"
          @input="changeValue('dueDate')"
        ></the-mask>
        <div class="invalid-feedback">{{ errorMessages.dueDate || errors.first('dueDate') }}</div>
        <span class="due-date-alert" v-if="expiredToday && !errors.has('dueDate')">
          {{ $t('services.payment.dueDateAlert.expiredToday') }}
        </span>
        <span class="due-date-alert" v-if="expired && !errors.has('dueDate')">
          {{ $t('services.payment.dueDateAlert.expired') }}
        </span>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-6 col-xs-6">
        <label :for="`discount${this.uuid}`" >
          {{ $t('services.payment.fields.discount') }}
        </label>
        <ct-input-numeric
          :thousand-separator="`.`"
          :decimal-separator="`,`"
          :precision="2"
          autocomplete="off"
          class="form-control"
          :id="`discount${this.uuid}`"
          name="discount"
          :readonly="!isDigitableLineFilled"
          :placeholder="$t('services.payment.placeholder.discount')"
          v-validate="{ decimal: 2 }"
          :data-vv-as="$t('services.payment.fields.discount')"
          v-model="billet.discount"
          @change="changeValue('discount')"
        ></ct-input-numeric>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-6 col-xs-6">
        <label :for="`addition${this.uuid}`" >
          {{ $t('services.payment.fields.addition') }}
        </label>
        <ct-input-numeric
          :thousand-separator="`.`"
          :decimal-separator="`,`"
          :precision="2"
          autocomplete="off"
          class="form-control"
          :id="`addition${this.uuid}`"
          name="addition"
          :readonly="!isDigitableLineFilled"
          :placeholder="$t('services.payment.placeholder.addition')"
          v-validate="{ decimal: 2 }"
          :data-vv-as="$t('services.payment.fields.addition')"
          v-model="billet.addition"
          @change="changeValue('addition')"
        ></ct-input-numeric>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-6 col-xs-6">
        <label :for="`amountBillet${this.uuid}`" >
          {{ $t('services.payment.fields.amount') }}
        </label>
        <ct-input-numeric
          :thousand-separator="`.`"
          :decimal-separator="`,`"
          :precision="2"
          autocomplete="off"
          class="form-control"
          :readonly="!isDigitableLineFilled"
          :id="`amountBillet${this.uuid}`"
          name="amount"
          :placeholder="$t('services.payment.placeholder.amount')"
          v-validate="{required: true}"
          :data-vv-as="$t('services.payment.fields.amount')"
          :class="{'is-invalid': errors.has('amount')}"
          v-model="billet.amount"
          @change="changeValue('amount')"
        ></ct-input-numeric>
        <div class="invalid-feedback">{{ errorMessages.amount || errors.first('amount') }}</div>
        <span class="field-info" v-if="billetTotalValue !== billet.amount && billetTotalValue > 0">
          {{ $t('services.payment.totalValue') }}: {{ billetTotalValue | currency(2) }}
        </span>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="alert-billet" v-if="expiredToday">
          <div class="alert-title">{{ $t('services.payment.alert.title') }}</div>
          <div class="alert-text">
            {{ $t('services.payment.alert.expiredToday') }}
            <router-link :to="{ name: 'faq' }">
              {{ $t('services.payment.alert.learnMore') }}
            </router-link>
          </div>
        </div>
        <div class="alert-billet" v-if="expired">
          <div class="alert-title">{{ $t('services.payment.alert.title') }}</div>
          <div class="alert-text">
            {{ $t('services.payment.alert.expired') }}
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import { TheMask, mask } from 'vue-the-mask';
import { mapGetters, mapActions, mapState } from 'vuex';
import CtInputNumeric from '../Shared/InputNumeric';
import currency from '../../filters/currency';

export default {
  name: 'Billet',
  props: {
    uuid: {
      type: [ String, Number ],
      required: true,
    },
  },
  components: {
    CtInputNumeric,
    TheMask,
  },
  directives: {
    mask,
  },
  filters: {
    currency,
  },
  data: () => ({
    isFilledWithoutDueDate: false,
  }),
  methods: {
    ...mapActions([
      'autoCompleteBillet',
      'clearBillet',
    ]),
    async validate () {
      if (this.isDigitableLineDuplicated) {
        return false;
      }
      return this.$validator.validate();
    },
    async changeValue (field) {
      await this.$emit('verifyError', { uuid: this.uuid, field });
    },
    async onDigitableLineChange (event) {
      if (event instanceof InputEvent) {
        const isDeletion = event.inputType === 'deleteContentBackward' ||
                           event.inputType === 'deleteContentForward';
        this.checkDigitableLine(isDeletion);
      }
    },
    async onDigitableLinePaste (event) {
      if (event.target.selectionStart === 0 && event.target.selectionEnd === event.target.value.length) {
        const content = (event.clipboardData || window.clipboardData).getData('text');
        this.billet.digitableLine = content;
        this.checkDigitableLine();
        event.preventDefault();
      }
    },
    async checkDigitableLine (isDeletion = false) {
      await this.$emit('verifyError', { uuid: this.uuid, field: 'digitableLine' });
      if (this.billet.digitableLine.length === 0) {
        this.clearBillet(this.uuid);
        this.isFilledWithoutDueDate = false;
      } else {
        await this.autoCompleteBillet({ uuid: this.uuid, isDeletion });
        this.isFilledWithoutDueDate = (this.kind === 'cobrança' && this.billet.dueDate === '' && this.billet.amount === 0);
      }
    },
  },
  computed: {
    ...mapGetters(['getBilletByUUID', 'getBilletsByLine']),
    ...mapState({
      itemsErrors: (state) => state.services.invoice.itemsErrors,
    }),
    billet () {
      return this.getBilletByUUID(this.uuid);
    },
    billetTotalValue () {
      if (!this.billet) {
        return 0;
      }
      return (this.billet.amount || 0) + (this.billet.addition || 0) - (this.billet.discount || 0);
    },
    kind () {
      if (!this.billet.digitableLine) {
        return false;
      }
      return this.billet.digitableLine.startsWith('8') ? 'convênio' : 'cobrança';
    },
    expiredToday () {
      const now = moment();
      const dueDate = moment(this.billet.dueDate, 'DD/MM/YYYY').hours(20);
      const nextDay = moment(this.billet.dueDate, 'DD/MM/YYYY').add({ days: 1 });
      return now.isBetween(dueDate, nextDay, 'minute');
    },
    expired () {
      const now = moment();
      const dueDate = moment(this.billet.dueDate, 'DD/MM/YYYY');
      return now.isAfter(dueDate, 'day');
    },
    digitableLineMask () {
      return this.billet.digitableLine && this.kind === 'cobrança'
        ? '##### ##### ##### ###### ##### ###### # ##############'
        : '############ ############ ############ ############';
    },
    isDigitableLineDuplicated () {
      return this.billet.digitableLine && this.getBilletsByLine(this.billet.digitableLine).length > 1;
    },
    isDigitableLineFilled () {
      if (!this.billet.digitableLine) {
        return false;
      }
      const line = this.billet.digitableLine.replace(/[^0-9]+/g, ''); // Remove non-numbers
      return ((line.length === 47 || line.length === 44) && this.kind === 'cobrança') ||
             ((line.length === 48 || line.length === 44) && this.kind === 'convênio');
    },
    disableDueDateField () {
      return (this.kind === 'cobrança' && !this.isFilledWithoutDueDate) || !this.isDigitableLineFilled;
    },
    errorMessages () {
      const messages = {
        digitableLine: '',
        dueDate: '',
        amount: '',
      };
      for (const row of this.itemsErrors) {
        if (row.uuid.toString() === this.uuid.toString()) {
          messages[row.field] = row.message;
        }
      }
      return messages;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../themes/variables";
  @import "../../themes/mixins/layout";

  .billet-payment-item {
    .row {
      @media only screen and (max-width: $grid-sm-max) {
        margin: 0;
        padding: 0;
      }
    }

    .field-info {
      font-family: 'Oswald', sans-serif;
      font-size: 12.5px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.92;
      letter-spacing: 0.6px;
      text-align: left;
      padding-left: 10px;
      text-transform: uppercase;
      color: $spotlight-color;
      position: relative;
      top: 8px;
      @media only screen and (max-width: $grid-lg-min) {
        display: block;
        margin-bottom: 20px;
      }
    }
    .field-info-alert {
      color: $secondary-color;
    }

    .due-date-alert {
      font-size: 12.5px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.92;
      letter-spacing: 0.6px;
      text-align: left;
      padding-left: 10px;
      text-transform: uppercase;
      color: $secondary-color;
      position: relative;
      top: 8px;
      @media only screen and (max-width: $grid-lg-min) {
        display: block;
        margin-bottom: 20px;
      }
    }

    .alert-billet {
      @include flex-center();
      margin: 15px 0;
      padding: 2px;
      background: $very-light-pink;
      text: {
        align: center;
        transform: uppercase
      }

      .alert-title {
        color: $secondary-color;
        font-size: 12px;
      }
      .alert-text {
        font-size: 10px;
        margin-left: 5px;
        a {
          color: $spotlight-color;
          text-decoration: underline;
        }
      }
    }
  }

</style>
