<template>
  <div>
    <div id="accept-use-terms"></div>
    <ct-banner-accept
      :ref="'acceptTerms'"
      :elementId="'accept-use-terms'"
      :debug="false"
      :type="'floating'"
      :disableDecline="true"
      :transitionName="'slideFromBottom'"
      :showPostponeButton="false"
    >

      <div slot="message">
        Ao navegar neste site, você aceita os cookies que usamos para melhorar sua experiência.
        <br />Confira nossa
        <router-link :to="{ name: 'terms', hash: '#politica-de-privacidade' }">politica de privacidade</router-link>.
      </div>

      <div slot="acceptContent">
        <a href="#" class="btn btn--secondary">Entendi</a>
      </div>
    </ct-banner-accept>
  </div>
</template>

<script>
import CtBannerAccept from '../Shared/BannerAccept';
export default {
  components: { CtBannerAccept },
  data () {
    return {
      status: null,
    };
  },
  methods: {

  },
};
</script>

<style>
</style>
