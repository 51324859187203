<template>
  <div class="container top-navbar-component">
    <div class="row">
      <div class="col-xs-12">
        <div class="quotation" v-if="!$route.meta.hideTicker" >
          <small>
            Cotação
          </small>
          R$ {{ (quotation.BTCBRL && quotation.BTCBRL.buy ? quotation.BTCBRL.buy : 0) | currency }}
        </div>
        <ul class="menu">
          <li>
           <router-link :to="{ name: 'about' }">{{ $t('pages.aboutTop') }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'faq' }">{{ $t('pages.faqTop') }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import currency from './../../filters/currency';

export default {
  name: 'TopNavbarMenu',
  filters: {
    currency,
  },
  computed: {
    ...mapState({
      quotation: state => state.settings.ticker,
    }),
  },
};
</script>

<style lang="scss">
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.top-navbar-component {
  .row {
    padding-top: 6px;
    .col-xs-12 {
      display: flex;
      justify-content: flex-end;

      .quotation {
        margin-right: 45px;
        font-family: Oswald;
        font-size: 18px;
        font-weight: 500;
        color: $white-color;
        small {
          font-size: 14px;
          color: $primary-color;
        }
      }
    }

    .menu {
      list-style: none;
      display: inherit;
      li {
        a {
          display: inline;
          color: #fff;
          font-family: Oswald;
          font-size: 14px;
          text-transform: uppercase;
          text-decoration: none;
          font-weight: 300;
        }
        & + li {
          margin-left: 15px;
        }
      }
    }

  }
}
</style>
