<template>
  <div class="terms-component">
    <ct-banner :banner="banner" />
    <ct-index-bar class="terms-index" route="terms" title="Termos de uso" :items="anchors" />
    <ct-terms-content />
  </div>
</template>

<script>
import CtBanner from '@/components/Banner/Banner';
import CtIndexBar from '@/components/Shared/IndexBar/IndexBar';
import CtTermsContent from '@/components/ContentTerms/TermsContent';
import config from '@/config';

export default {
  name: 'Terms',
  components: {
    CtBanner,
    CtIndexBar,
    CtTermsContent,
  },
  data () {
    return {
      banner: {
        textAlt: 'Termos e condições de uso da plataforma.',
        imgUrl: '/assets/img/banners/banner-termos-de-uso.jpg',
        imgUrlMobile: '/assets/img/banners/banner-termos-de-uso-mobile.jpg',
      },
      pageInfo: {
        title: 'Termos e condições de uso da plataforma',
        description: 'Regras e condições de uso aceitável dos serviços disponíveis no site PagueComBitcoin',
      },
      anchors: [
        {
          name: 'Responsabilidades',
          anchor: 'responsabilidades',
          id: 0,
        },
        {
          name: 'estorno',
          anchor: 'estorno',
          id: 1,
        },
        {
          name: 'Limites, Prazos e Exceções',
          anchor: 'limites-prazos-e-excecoes',
          id: 2,
        },
        {
          name: 'Programa de Indicação',
          anchor: 'programa-de-indicacao',
          id: 3,
        },
        {
          name: 'Politica de Privacidade de Dados',
          anchor: 'politica-de-privacidade',
          id: 4,
        },
        {
          name: 'Privacidade',
          anchor: 'privacidade',
          id: 5,
        },
        {
          name: 'Segurança',
          anchor: 'seguranca',
          id: 6,
        },
        {
          name: 'Suporte',
          anchor: 'suporte',
          id: 7,
        },
        {
          name: 'Foro',
          anchor: 'foro',
          id: 8,
        },
      ],
    };
  },
  metaInfo () {
    return {
      title: this.pageInfo.title,
      meta: [
        { name: 'description', content: this.pageInfo.description },
        { name: 'og:description', content: this.pageInfo.description },
        { name: 'og:image', content: config.app.url + this.banner.imgUrl },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.pageInfo.title },
        { name: 'twitter:description', content: this.pageInfo.description },
        { name: 'twitter:image', content: config.app.url + this.banner.imgUrl },
      ],
    };
  },
};
</script>

<style lang="scss">
@import '../themes/variables';

.terms-component {
  .terms-index {
    .index-bar {
      background-color: $spotlight-color;
    }
  }
}
</style>
