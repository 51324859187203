import axios from 'axios';
import getFingerprint from '../helpers/fingerprintHelper';
import config from '../config';
import store from '../store/store';

const getStoredFingerprint = () => {
  if (!localStorage.hasOwnProperty('fingerprint')) {
    const fingerprint = getFingerprint();
    localStorage.setItem('fingerprint', getFingerprint());
    return fingerprint;
  }

  return localStorage.getItem('fingerprint');
};

const createAxios = () => {
  const fingerprint = getStoredFingerprint();
  const userDataStorage = localStorage.getItem('user_data');
  const userData = JSON.parse(atob(userDataStorage || btoa('{}')));

  const headers = {
    'Content-Type': 'application/json',
    'x-access-origin': fingerprint,
  };

  if (userData && userData.token) {
    headers['x-access-token'] = userData.token;
  }

  const http = axios.create({ baseURL: config.app.apiUrl, headers });

  http.interceptors.response.use((response) => response, (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch('removeUserToken');
    }
    return Promise.reject(error);
  });
  return http;
};

export const contactHttp = () => {
  return axios.create({
    baseURL: config.app.contactUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export default createAxios;
