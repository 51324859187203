<template>
  <div class="checkout-page-component" >

    <ct-checkout v-if="invoiceFound" :invoiceId="currentInvoice._id" />
    <ct-invoice-not-found v-if="!invoiceFound" />
    <ct-dead-line/>
  </div>
</template>

<script>
import CtCheckout from '@/components/Checkout/Checkout';
import CtInvoiceNotFound from '@/components/Checkout/InvoiceNotFound';
import CtDeadLine from '@/components/Shared/DeadLine';
import { mapActions, mapState, mapGetters } from 'vuex';
import config from '@/config';

export default {
  name: 'CheckoutPage',
  data () {
    return {
      banner: {
        textAlt: 'Pague com Bitcoins agora mesmo.',
        imgUrl: '/assets/img/banners/banner-servicos.jpg',
        imgUrlMobile: '/assets/img/banners/banner-servicos-mobile.jpg',
      },
      pageInfo: {
        title: 'Pedido',
        description: 'Descrição completa de pedidos realizados. É necessário ter uma conta e estar logado para ver todas as informações.',
      },
      invoiceFound: false,
    };
  },
  metaInfo () {
    return {
      title: this.pageInfo.title,
      meta: [
        { name: 'description', content: this.pageInfo.description },
        { name: 'og:description', content: this.pageInfo.description },
        { name: 'og:image', content: config.app.url + this.banner.imgUrl },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.pageInfo.title },
        { name: 'twitter:description', content: this.pageInfo.description },
        { name: 'twitter:image', content: config.app.url + this.banner.imgUrl },
      ],
    };
  },
  components: {
    CtCheckout,
    CtInvoiceNotFound,
    CtDeadLine,
  },
  computed: {
    ...mapState({
      currentInvoice: state => state.invoices.currentInvoice,
      user: state => state.auth.user,
    }),
    ...mapGetters([
      'isLogged',
    ]),
  },
  methods: {
    ...mapActions([
      'getInvoiceDetail',
      'getLastInvoice',
    ]),
  },
  async created () {
    if (this.$route.name === 'checkout') {
      if (!this.$route.params.id) {
        this.$router.push({ name: 'home' });
      }

      const invoiceFound = await this.getInvoiceDetail(this.$route.params.id);
      this.invoiceFound = !!invoiceFound;
      if (!this.invoiceFound) {
        this.$router.push({ name: 'error404' });
      }
    }

    if (this.$route.name === 'checkout-last') {
      if (!this.isLogged) {
        this.$router.push({ name: 'home' });
      }
      const invoiceFound = await this.getLastInvoice(this.user);
      this.invoiceFound = !!invoiceFound;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../themes/variables";

.checkout-page-component {
  margin-top: 120px;
  .newsletter-component {
    background: $white-color
  }
  @media only print {
    margin-top: -80px;
  }
}

</style>
