<template>
  <div class="invoices">
    <div class="container" v-if="!notEnabledServices">
      <div class="row row-content-service" v-if="billets.length > 0">
        <div class="title-item">
          <ct-billet-icon class="title-icon"/>
          <h3>{{ $t('services.title.payment') }}</h3>
        </div>
        <div class="invoice-item-container col-lg-12 col-md-12 col-sm-12 col-xs-12" v-for="(billet, index) in billets" :key="billet.uuid">
          <ct-invoice-item
            ref="items"
            :kind="'billet'"
            :uuid="billet.uuid"
            :isLastItem="index === (billets.length - 1)"
            @addNewItem="addNewItem"
            @removeItem="removeItem"
            @verifyError="removeErrorMessage"
          />
        </div>
      </div>
      <div class="row row-content-service" v-if="sellBtc.length > 0">
        <div class="title-item">
          <ct-btc-icon class="title-icon"/>
          <h3 class="title-item">{{ $t('services.title.btc') }}</h3>
        </div>
        <div class="invoice-item-container col-lg-12 col-md-12 col-sm-12 col-xs-12" v-for="(sell, index) in sellBtc" :key="sell.uuid">
          <ct-invoice-item
            ref="items"
            :kind="'shipment'"
            :uuid="sell.uuid"
            :isLastItem="index === (sellBtc.length - 1)"
            @addNewItem="addNewItem"
            @removeItem="removeItem"
            @verifyError="removeErrorMessage"
          />
        </div>
      </div>
      <div class="row row-content-service" v-if="pix.length > 0">
        <div class="title-item">
          <ct-pix-icon class="title-icon"/>
          <h3 class="title-item">{{ $t('services.title.pix') }}</h3>
        </div>
        <div class="invoice-item-container col-lg-12 col-md-12 col-sm-12 col-xs-12" v-for="(item, index) in pix" :key="item.uuid">
          <ct-invoice-item
            ref="items"
            :kind="'pix'"
            :uuid="item.uuid"
            :isLastItem="index === (pix.length - 1)"
            @addNewItem="addNewItem"
            @removeItem="removeItem"
            @verifyError="removeErrorMessage"
          />
        </div>
      </div>
      <div class="row row-content-service" v-if="recharges.length > 0">
        <div class="title-item">
          <ct-cellphone-icon class="title-icon"/>
          <h3 class="title-item">{{ $t('services.title.recharge') }}</h3>
        </div>
        <div class="invoice-item-container col-lg-12 col-md-12 col-sm-12 col-xs-12" v-for="(recharge, index) in recharges" :key="recharge.uuid">
          <ct-invoice-item
            ref="items"
            :kind="'recharge'"
            :uuid="recharge.uuid"
            :isLastItem="index === (recharges.length - 1)"
            @addNewItem="addNewItem"
            @removeItem="removeItem"
            @verifyError="removeErrorMessage"
          />
        </div>
      </div>
      <div class="row row-content-service" v-if="giftCards.length > 0">
        <div class="title-item">
          <ct-gift-card-icon class="title-icon"/>
          <h3 class="title-item">{{ $t('services.title.giftCardTitle') }}</h3>
        </div>
        <div class="invoice-item-container col-lg-12 col-md-12 col-sm-12 col-xs-12" v-for="(giftCard, index) in giftCards" :key="giftCard.uuid">
          <ct-invoice-item
            ref="items"
            :kind="'giftCard'"
            :uuid="giftCard.uuid"
            :isLastItem="index === (giftCards.length - 1)"
            @addNewItem="addNewItem"
            @removeItem="removeItem"
            @verifyError="removeErrorMessage"
          />
        </div>
      </div>
    </div>
    <div class="container-fluid service-empty" v-if="isInvoiceEmpty">
      <div class="row">
        <div class="col-xs-12">
          <img src="/assets/img/icons/service-arrow.svg" class="icon-empty-service" />
          <p class="text-empty-service">
            Você ainda não selecionou nenhum serviço. <br />
            <span>Clique</span> em algum dos serviços no menu para utiliza-lo
          </p>
          <span class="divider"></span>
        </div>
      </div>
    </div>
    <ct-invoice-summary v-if="!isInvoiceEmpty"
      @finishInvoice="finishInvoice"
      :hasError="hasError"
      :errorMessage="errorMessage"
      :tickerData="tickerData"
      @change-network="changeInvoiceNetwork"
    />
  </div>
</template>

<script>
import CtInvoiceItem from './InvoiceItem';
import CtInvoiceSummary from './InvoiceSummary';
import TickerService from './../../services/TickerService';
import CtBilletIcon from './../Shared/Icons/BilletIcon';
import CtBtcIcon from './../Shared/Icons/BtcIcon';
import CtPixIcon from './../Shared/Icons/PixIcon';
import CtCellphoneIcon from './../Shared/Icons/CellphoneIcon';
import CtGiftCardIcon from './../Shared/Icons/GiftCardIcon';
import { mapGetters, mapActions, mapState } from 'vuex';
import config from '@/config';
import { debounce } from '@/helpers/debounce';

export default {
  name: 'Invoice',
  components: {
    CtInvoiceItem,
    CtInvoiceSummary,
    CtBilletIcon,
    CtBtcIcon,
    CtPixIcon,
    CtCellphoneIcon,
    CtGiftCardIcon,
  },
  async created () {
    await this.getBanks();
    await this.getTicker();
    this.initializeInterval(config.intervals.ticker);
    this.debounceGetTicker = debounce(this.getTicker, 1000, false);
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
  data: () => ({
    hasError: false,
    errorMessage: '',
    actionsForDispatch: [],
    debounceGetTicker: () => {},
  }),
  methods: {
    ...mapActions([
      'getBanks',
      'finishOrder',
      'setErrorItems',
      'addNewBillet',
      'addNewBtc',
      'addNewPix',
      'addNewRecharge',
      'addNewGiftCard',
      'removeBillet',
      'removeBtc',
      'removePix',
      'removeRecharge',
      'removeGiftCard',
      'setTickerValue',
      'decomposeGiftcardItemsQuantity',
      'changeInvoiceNetwork',
    ]),
    initializeInterval (time) {
      this.interval = setInterval(async () => {
        await this.debounceGetTicker();
      }, time);
    },
    async validateItems () {
      await this.$nextTick();
      let isValid = true;

      for (let item of this.$refs.items) {
        const valid = await item.validate();
        if (!valid) {
          isValid = false;
        }
      }
      return isValid;
    },
    async dispatchGtmEvents () {
      this.actionsForDispatch.forEach(async (event) => {
        if (this.$gtm && this.$gtm.enabled()) {
          await this.$gtm.trackEvent({
            category: 'invoice',
            action: event,
            label: event,
          });
        }
      });
    },
    checkEventsForDispatch () {
      this.actionsForDispatch = [];
      if (this.billets.length > 0) {
        this.actionsForDispatch.push('billet-service-finished');
      }
      if (this.recharges.length > 0) {
        this.actionsForDispatch.push('recharge-service-finished');
      }
      if (this.sellBtc.length > 0) {
        this.actionsForDispatch.push('shipment-service-finished');
      }
      if (this.giftCards.length > 0) {
        this.actionsForDispatch.push('giftcard-service-finished');
      }
      if (this.pix.length > 0) {
        this.actionsForDispatch.push('pix-service-finished');
      }

      this.actionsForDispatch.push('invoice-created');
    },
    async finishInvoice (event, resolve) {
      this.decomposeGiftcardItemsQuantity();

      await this.checkHasInvoiceError();
      if (this.hasError) {
        return;
      }

      this.checkEventsForDispatch();
      const result = await this.finishOrder();
      if (result && result.errorCode && result.errorCode.toString() === '401') {
        if (resolve) resolve(false);
        return;
      }

      if (result && result.error) {
        this.hasError = true;
        this.errorMessage = '';
        if (!result.errors || Object.keys(result.errors).length < 1) {
          this.errorMessage = result.message;
        }
        const uuidErrors = Array.isArray(result.errors) ? result.errors : [];
        let uuids = [];
        for (const e of uuidErrors) {
          if (e.uuid !== undefined) {
            uuids.push(e);
          }
          if (e[0] && e[0].uuid !== undefined) {
            uuids.push(e[0]);
          }
        }
        await this.setErrorItems(uuids || []);
        const firstError = uuids[0] && uuids[0].uuid ? uuids[0].uuid : '';
        if (firstError) {
          this.scrollToSelector(`.invoice-item-${firstError}`);
        }

        return;
      }

      this.dispatchGtmEvents();
      if (resolve) resolve(true);
    },
    async addNewItem (data) {
      this.trackInvoiceEvent(`${data.kind.toLowerCase()}-service-inserted`);
      switch (data.kind) {
        case 'billet':
          if (this.enabledBilletService) {
            this.addNewBillet();
          }
          break;
        case 'shipment':
          if (this.enabledShipmentService) {
            this.addNewBtc();
          }
          break;
        case 'recharge':
          if (this.enabledRechargeService) {
            this.addNewRecharge();
          }
          break;
        case 'giftCard':
          if (this.enabledGiftcardService) {
            this.addNewGiftCard();
          }
          break;
        case 'pix':
          if (this.enabledPixService) {
            this.addNewPix();
          }
          break;
      }
    },
    async removeItem (data) {
      this.trackInvoiceEvent(`${data.kind.toLowerCase()}-service-removed`);
      switch (data.kind) {
        case 'billet':
          this.removeBillet(data.uuid);
          break;
        case 'shipment':
          this.removeBtc(data.uuid);
          break;
        case 'recharge':
          this.removeRecharge(data.uuid);
          break;
        case 'giftCard':
          this.removeGiftCard(data.uuid);
          break;
        case 'pix':
          this.removePix(data.uuid);
          break;
      }
      await this.checkHasInvoiceError();
    },
    async trackInvoiceEvent (event) {
      if (event && this.$gtm && this.$gtm.enabled()) {
        await this.$gtm.trackEvent({
          category: 'invoice',
          action: event,
          label: event,
        });
      }
    },
    async checkHasInvoiceError () {
      this.hasError = false;

      const isValid = await this.validateItems();
      if (!isValid) {
        this.hasError = true;
      }
    },
    async getTicker () {
      const tickerData = await TickerService.getTicker(this.getInvoiceTotal, 'BRL');
      this.setTickerValue(tickerData);
    },
    async removeErrorMessage (data) {
      if (!data.uuid || !data.field) return;
      const errors = this.itemsErrors.filter(item => !(item.uuid.toString() === data.uuid.toString() && item.field.toString() === data.field.toString()));
      await this.setErrorItems(errors || []);
      if (this.itemsErrors.length === 0) {
        this.hasError = false;
      }
    },
    scrollToSelector (selector) {
      setTimeout(() => {
        document.querySelector(selector).scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    },
  },
  watch: {
    getInvoiceTotal () {
      if (this.getInvoiceTotal) {
        this.debounceGetTicker();
      }
    },
  },
  computed: {
    ...mapGetters([
      'isInvoiceEmpty',
      'getInvoiceTotal',
      'enabledPixService',
      'enabledShipmentService',
      'enabledRechargeService',
      'enabledBilletService',
      'enabledGiftcardService',
      'notEnabledServices',
    ]),
    ...mapState({
      invoice: (state) => state.services.invoice,
      billets: (state) => state.services.invoice.billets,
      sellBtc: (state) => state.services.invoice.sellBtc,
      recharges: (state) => state.services.invoice.recharges,
      pix: (state) => state.services.invoice.pix,
      giftCards: (state) => state.services.invoice.giftCards,
      itemsErrors: (state) => state.services.invoice.itemsErrors,
      tickerData: state => state.settings.ticker,
    }),
  },
};
</script>

<style lang="scss" scoped>
  @import "../../themes/variables";

  .invoices {
    margin-top: -15px;

    .container {
      width: 100%;
      max-width: 1200px;
      padding: 0 25px;
      @media only screen and (max-width: $grid-lg-max) {
        padding: 0 30px;
      }
      @media only screen and (max-width: $grid-sm-max) {
        padding: 0 20px
      }

      .row-content-service {
        padding: 0px 7px 7px;
        margin: 0;

        @media only screen and (max-width: $grid-md-max) {
          padding: 0px 0px 7px;
        }

        &:last-child {
          padding-bottom: 0px;
          margin-bottom: 0px;
        }

        .title-icon{
          margin-right: 10px;
          top: 5px;
          position: relative;
        }
        .title-item {
          margin-bottom: 20px;
          h3{
            font-weight: 400;
            display: inline-block;
            white-space: nowrap;
            @media only screen and (max-width: $grid-sm-max) {
              font-size: 1.6em;
            }
          }

        }

        .invoice-item-container {
          padding: 0 0 0px;
          margin: 0 0 15px;

          &:last-child {
            border-bottom: 1px solid $dark-grey-color;
          }

          @media only screen and (max-width: $grid-md-max) {
            border-bottom: 1px solid $dark-grey-color;
          }
        }

        &:last-child .invoice-item-container:last-child {
          border-bottom: none;
        }

        @media only screen and (max-width: $grid-md-max) {
          div:nth-last-child(3) {
            margin: 0 0 10px;
          }
        }

      }
    }

    .service-empty {
      .row {
        margin-right: 0;
        margin-left: 0;

        .col-xs-12 {
          padding-left: 0;
          padding-right: 0;

          .icon-empty-service {
            margin: 60px auto 86px;
            display: block;
          }

          .text-empty-service {
            display: block;
            margin: 0 auto 60px;
            font-family: Oswald, sans-serif;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            color: #58585a;
            font-weight: 200;

            span {
              font-weight: bold;
              color: $spotlight-color;
            }
          }

          .divider {
            background: url('/assets/img/services/services-divider.png') no-repeat;
            height: 194px;
            width: auto;
            display: block;
          }
        }
      }
    }

    ::v-deep {
      input, input.form-control {
        width: 100%;
        display: block;
        height: 38px;
        background-color: #ebebeb;
        outline: transparent;
        box-shadow: none;
        border: 0;
        text-indent: 10px;
        border: none;
      }

      .v-select {
        input {
          background: none;
        }
        .vs__dropdown-toggle {
          border-radius: 0px;
          padding-right: 3px;
        }
        .vs__selected-options {
          overflow: hidden;
        }
      }
      .v-select.vs--disabled {
        .vs__dropdown-toggle {
          background-color: $warm-grey-color !important;
          color: white;
          -webkit-text-fill-color: white;
          opacity: 1;
          -webkit-opacity: 1;
          cursor: not-allowed;
        }
        .vs__open-indicator {
          background: none;
        }
        .vs__clear {
          background: none;
        }
        .vs__actions *:hover {
          cursor: not-allowed;
        }
      }

      input:read-only {
        background-color: $warm-grey-color !important;
        color: white;
        -webkit-text-fill-color: white;
        opacity: 1;
        -webkit-opacity: 1;
        cursor: not-allowed;
      }

      label {
        width: 100%;
        display: block;
        margin-bottom: 5px;
        line-height: 1.34;
        color: $spotlight-color;
        text: {
          align: left;
          transform: uppercase;
        }
        font: {
          weight: 500;
          size: 1.5em;
        }

        @media only screen and (max-width: $grid-md-max) {
          font-size: 21px;
          margin-top: 15px;
        }
        @media only screen and (max-width: $grid-sm-max) {
          font-size: 14px;
        }
      }
    }
  }

</style>
