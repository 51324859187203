import http from './HttpService';

const storeSettingsKey = 'pague:storage:settings';
const setStoredSettings = (settings) => {
  try {
    localStorage.setItem(storeSettingsKey, JSON.stringify(settings));
  } catch (e) {
    return {};
  }
};
const getStoredSettings = () => {
  try {
    if (localStorage.hasOwnProperty(storeSettingsKey)) {
      const data = localStorage.getItem(storeSettingsKey);
      return JSON.parse(data);
    }
  } catch (e) {
    return {};
  }
};

export default {
  async getCurrentSettings () {
    try {
      const res = await http().get('/api/v1/settings/current');
      const data = res.data.data || false;
      if (!data) {
        return getStoredSettings();
      }
      setStoredSettings(data);
      return data;
    } catch (e) {
      return getStoredSettings();
    }
  },
};
