<template>
  <section class="bitcoin-content-component">
    <div class="container">
      <h1 class="bitcoin-content-title">
        O que é Bitcoin
      </h1>

      <div class="bitcoin-info">
        O objetivo é criar a noção básica do que é o bitcoin, como ele funciona e quais são os significados para os
        principais termos usados por seus usuários e indicar um ponto de partida para os seus estudos.
      </div>

      <div class="bitcoin-info-text">
        <h3>O que é Bitcoin</h3>

        <p>
          O Bitcoin é uma moeda virtual baseada em criptografia, ao contrário das moedas fiduciárias como o Dólar, Real,
          Euro, o Bitcoin não possui representação física impressa através de papel-moeda, a sua principal representação
          é como saldo em uma carteira digital.
        </p>
        <p>
          A principal diferença entra moedas fiduciárias como o Real e o Bitcoin é quem são os responsáveis pelas moedas
          e como decisões sobre elas são tomadas.
        </p>

        <h3>Quem controla?</h3>

        <p>
          Moedas fiduciárias são controladas por Estados como o Brasil, onde o governo e suas instituições determinam o
          grau de sua disponibilidade, as regras e as formas de utilização, quem pode ter acesso a ela, etc. Atualmente
          as moedas fiduciárias lastreadas (tem seu valor ligado) a divida pública do país emissor e a confiança do
          mercado financeiro na capacidade do pagamento dessa dívida por parte do país e de seus cidadãos.
        </p>
        <p>
          Já o Bitcoin é controlado pelos membros de sua comunidade, como os utilizadores, desenvolvedores e mineradores,
          através de um sistema de votação chamado BIP - Bitcoin Improvement Proposal ou Propostas de Melhoria do
          Bitcoin que tem o potencial de mudar, remover ou incluir novas características. A moeda e seu valor é dado
          pela confiança na segurança de seu banco de dados, do protocolo, da imutabilidade das transferências
          realizadas com ela, da adoção de meio de pagamento praticamente imune a fronteiras governamentais, etc.
        </p>

        <h3>Quem emite moedas fiduciárias e bitcoins? </h3>

        <p>
          Ao contrário da moeda fiduciária que tem sua emissão/disponibilidade controlada pelo Estado, o Bitcoin possui
          uma regra rígida de quantidade total de emissão de novas moedas bem como o seu escalonamento, permitindo que
          os usuários saibam qual é o comportamento esperado da moeda no decorrer do tempo.
        </p>
        <p>
          O Bitcoin funciona um pouco diferente de uma moeda fiduciária, quando um conjunto de transferências entre seus
          usuários é validado como verdadeiro, uma quantidade de novos bitcoins são emitidos como pagamento pelo serviço
          de verificação realizado. A emissão desses novos bitcoins é escalonado e decrescente no decorrer do tempo até
          que seja atingido o volume total de 21 milhões.
        </p>
        <p>
          Isso é importante porque a política adotada para a emissão de novas moedas define em parte a liquidez dessa
          moeda, a capacidade de retenção/criação de valor e a inflação dessa moeda no decorrer do tempo.
        </p>
        <p>
          Tentando exemplificar da forma mais simples possível, se uma moeda como o Real passa por um forte período de
          emissão de novas moedas num período de tempo é natural que ela perca valor de face frente ao dólar fazendo
          com que a cotação do dólar frente ao real tenha uma subida expressiva.
        </p>
        <p>
          O comportamento esperado com o Bitcoin é o inverso, como a taxa de emissão já é previamente conhecida e a
          adoção da moeda por novos utilizadores tende a aumentar num nível muito mais acelerado do que o da emissão
          (eles são inversos), é esperado um comportamento deflacionário, o Bitcoin carrega em si a característica de
          passar a valer mais no médio e longo prazo ao contrário das moedas fiduciárias.
        </p>

        <h3>Como o Bitcoin funciona?</h3>

        <p>
          A forma mais simples de buscar a compreensão do funcionamento do Bitcoin é traçar um paralelo com alguma coisa
          que faça parte do dia a dia das pessoas, e a melhor forma de fazer isso é através de uma coisa que muitos
          brasileiros possuem em suas carteiras, o cartão de débito.
        </p>
        <p>
          O cartão de débito é a representação de todo o saldo que você possui na sua conta bancária, quando você paga
          uma conta em uma lanchonete, uma série incrivelmente complexa de eventos é desencadeada num curtíssimo
          espaço de tempo.
        </p>
        <p>
          O caixa da lanchonete cria uma cobrança com o valor a ser pago usando a maquininha, ao inserir o seu cartão
          na maquininha e digitar a sua senha, ela identifica que você está autorizando que aquele pagamento seja feito
          pelo seu banco.
        </p>
        <p>
          A bandeira do seu cartão (Visa, Master, Elo, Amex, etc.) entra em contato com o sistema do banco com as
          informações da cobrança e com a assinatura criptografada que você criou no momento em que digitou a sua senha.
        </p>
        <p>
          Caso você tenha saldo ou crédito em conta, o banco autoriza a transferência e o saldo sai da sua conta e vai
          para a conta da bandeira do cartão. A partir daí a bandeira do cartão realiza uma série de verifcações para
          certificar que o pagamento é legítimo e que o cliente realmente autorizou o gasto, ele é o agente que confirma
          a transação.
        </p>
        <p>
          Uma vez que a transação é confirmada o saldo fica disponível na conta da bandeira e é transferido para a conta
          bancária da lanchonete no mesmo dia ou no próximo dia útil descontando uma taxa pela intermediação do
          pagamento, que vamos chamar de taxa de transação.
        </p>
        <p>
          Caso a bandeira do cartão identifique alguma fraude, seja por parte do dono do cartão ou pelo dono da
          lanchonete, ou ainda caso seja verificado que o pagamento foi realizado sem a autorização do dono do cartão, o
          pagamento pode ser estornado ou descontado de valores a serem recebidos no futuro, também conhecido como
          chargeback.
        </p>
        <p>
          A estrutura do uso do Bitcoin é bem próxima a descrita, até nos papéis, mas possui algumas características
          próprias.
        </p>
        <p>
          O Bitcoin foi desenvolvido com a ideia de você controlar e custodiar o seu dinheiro, então parte-se do
          princípio que você está com a custódia completa do seu dinheiro, se o Bitcoin fosse um papel moeda seria como
          se você guardasse todo o seu dinheiro no bolso, no cofre ou até mesmo de baixo do colchão, então não existe
          uma instituição bancária guardando os seus Bitcoins por você.
        </p>
        <p>
          Seus Bitcoins ficam em uma carteira digital (wallet). Nela você pode verificar o seu saldo e receber ou enviar
          Bitcoins para qualquer um, em qualquer lugar no mundo, desde que essa pessoa também tenha uma carteira digital.
        </p>
        <p>
          Ao você fazer um pagamento com Bitcoin o dono da lanchonete indica o valor a ser pago e fornece um endereço da
          carteira da lanchonete, para realizar o pagamento você indica o valor a ser transferido para o endereço
          endereço de carteira especificado e valida essa transação com a sua chave privada que age como uma senha que
          certifica que você autoriza a transferência, assim como o cartão.
        </p>
        <p>
          A partir daí sua transação é empacotada com várias outras (que vamos chamar de bloco) para serem
          validadas/confirmadas juntas.
        </p>
        <p>
          Ao contrário do que acontece no cartão, o responsável pela confirmação da transação não é uma empresa e sim os
          próprios usários do Bitcoin. A confirmação é feita através da descoberta de um desafio criptográfico, uma vez
          esse desafio respondido corretamente o bloco de transações é confirmado e o seu pagamento para a lanchonete
          pode ser considerado feito.
        </p>
        <p>
          No Bitcoin, como você tem total controle sobre o seu dinheiro parte-se do princípio que as transações, uma vez
          confirmadas são imutáveis, ou seja, não existe a possibilidade de uma agente externo, como a bandeira no caso
          do cartão, agir para desfazer a operação unilateralmente. A única forma é o próprio recebedor do valor optar
          por fazer a devolução.
        </p>

        <h3>O que é Blockchain?</h3>

        <p>
          Em termos simples a Blockchain é o banco de dados responsável pelo registro das transações realizadas entre os
          seus usuários, ele guarda toda a cadeia de transações desde o início da existência do Bitcoin.
        </p>
        <p>
          A Blockchain é organizada como um livro contábil, onde as informações das transações entre os endereços de
          carteira podem ser consultados por qualquer um. Como não existe a identificação do proprietário da carteira
          que criou o endereço podemos dizer que o Bitcoin é pseudo anônimo, pense como sendo próximo ao seu sigilo
          bancário.
        </p>
        <p>
          O interessante do banco de dados ser completamente aberto para consulta é a possibilidade de auditar a
          integridade de todas as transações realizadas, o que não é possível fazer em outras moedas e formas de
          pagamento.
        </p>

        <h3>O que é minerador?</h3>

        <p>
          O minerador é o usuário de Bitcoin que realiza a confirmação/validação das transações através da resolução de
          desafios computacionais. Assim como a bandeira do cartão ele é remunerado por esse trabalho, isso é feito
          através da emissão de novos Bitcoins e através do recebimento da somatória das taxas de cada uma das
          transações contidas no bloco confirmado.
        </p>
        <p>
          Atualmente minerar Bitcoins é uma atividade de escala industrial, é necessário realizar altíssimos
          investimentos em equipamentos e por se tratar de uma atividade que depende de alto poder de processamento
          para a resolução de desafios computacionais, o consumo de energia elétrica é altíssimo.
        </p>

        <h3>O que é essa taxa de transação?</h3>

        <p>
          Pense na taxa de transação como a taxa de TED que você normalmente paga para realizar uma transferência
          bancária entre contas de bancos diferentes ou a taxa paga por um lojista (para aceitar pagamentos através de
          cartões de débito ou crédito). Toda vez que você faz uma transação de um endereço de carteira para outro
          você paga a taxa.
        </p>
        <p>
          Ela é definida por quem faz o disparo da transação, o valor que você determina define a prioridade que a sua
          transação terá para ser confirmada, se você pagar uma taxa maior a sua transação será confirmada mais
          rapidamente e caso você colocar uma taxa muita baixa existe a possibilidade da transação nunca ser confirmada
          e acabar sendo cancelada.
        </p>

        <h3>Referências</h3>

        <p>
          Essa foi uma pequena introdução do que é e como funciona o Bitcoin, se você se interessou sobre o assunto é
          importante que você estude mais até se sentir apto para ter a sua primeira carteira, adquirir sua primeira
          fração e fazer sua primeira transação. Você pode continuar o seu aprendizado lendo os nossos outros artigos e
          acessando a página <a href="https://bitcoin.org" target="_blank">bitcoin.org</a>.
        </p>

      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'BitcoinContent',
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';

.bitcoin-content-component {

  .container {
    width: 100%;
    max-width: 1200px;

    .bitcoin-content-title {
      text-align: center;

      @media only screen and (max-width: $grid-xs-max) {
        font-size: 23px
      }
    }

    .bitcoin-info {
      max-width: 981px;
      margin: 65px auto;
      line-height: 25px;
      color: $text-color;
      text-align: center;
      font: {
        family: 'Roboto', sans-serif;
        size: 20px;
        weight: 300
      }
      @media only screen and (max-width: $grid-xl-max) {
        padding: 0 20px
      }
      @media only screen and (max-width: $grid-xs-max) {
        font-size: 12px;
        line-height: 16px;
        max-width: 100%;
        margin: 15px auto 35px
      }
    }
    .bitcoin-info-text {
      margin-bottom: 65px;

      @media only screen and (max-width: $grid-xl-max) {
        padding: 0 20px
      }

      @media only screen and (max-width: $grid-xs-max) {
        padding: 0 12px
      }
      h3 {
        margin-top: 10px;
        margin-bottom: 30px;
        @media only screen and (max-width: $grid-xs-max) {
          font-size: 16px
        }
      }

      a {
        color: $primary-color;
        text-decoration: none;

        &:hover {
          text-decoration: underline
        }
      }
    }
  }
}

</style>
