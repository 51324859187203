<template>
  <svg version="1.1" class="person-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 30 34" style="enable-background:new 0 0 30 34;" xml:space="preserve">
    <g>
      <path class="st0" d="M15.17,34.23c-3.83,0-7.67,0.01-11.5,0c-2.3-0.01-3.6-1.35-3.65-3.65c-0.06-2.72,0.55-5.18,2.35-7.33
        c1.75-2.1,5.59-3.58,8.2-2.76c3.08,0.97,6.04,0.82,9.16,0.02c4.42-1.14,9.29,2.02,10.27,7.01c0.22,1.1,0.26,2.22,0.23,3.34
        c-0.06,1.98-1.43,3.36-3.44,3.37C22.92,34.24,19.04,34.23,15.17,34.23z M15.04,31.06c3.67,0,7.35,0.01,11.02-0.01
        c0.38,0,0.92,0.24,0.99-0.49c0.48-4.92-3.74-8.05-7.27-6.85c-3.11,1.06-6.26,0.99-9.4,0c-0.88-0.28-1.77-0.37-2.7-0.1
        c-2.86,0.82-4.49,3.17-4.52,6.42c-0.01,0.87,0.27,1.06,1.09,1.05C7.85,31.04,11.45,31.06,15.04,31.06z"/>
      <path class="st0" d="M24.76,9.49c-0.02,5.43-4.34,9.64-9.7,9.6c-5.45-0.05-9.61-4.4-9.56-9.74C5.55,4,9.92-0.35,15.2-0.29
        C20.53-0.23,24.79,4.13,24.76,9.49z M21.6,9.51c0.03-3.59-2.97-6.63-6.5-6.59c-3.47,0.04-6.42,2.97-6.44,6.41
        c-0.03,3.59,2.97,6.63,6.5,6.59C18.62,15.88,21.57,12.94,21.6,9.51z"/>
    </g>
    </svg>
</template>

<script>
export default {

};
</script>

<style lang="scss">
  @import "../../../themes/variables";

  .person-icon {
    fill: $white-color;
    height: 20px;
  }
</style>
