<template>
  <svg version="1.1" id="about-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 387 449" style="enable-background:new 0 0 387 449;" xml:space="preserve">
  <g>
    <path d="M193.03,448.9c-62.47,0-124.94-0.04-187.41,0.09C2,449,0.85,448.04,1.06,444c0.33-6.26,0.24-12.56,0.02-18.83
      c-0.12-3.31,0.6-4.46,3.87-4.41c14.38,0.21,28.77-0.07,43.15,0.21c3.69,0.07,4.28-1.19,4.27-4.87
      c-0.09-118.16-0.05-236.32-0.16-354.48c0-4.23,0.68-5.54,4.88-5.53c90.57,0.16,181.15,0.16,271.72,0.01
      c4.03-0.01,4.97,1.04,4.97,5.45c-0.12,118.01-0.07,236.03-0.21,354.04c-0.01,4.57,1.16,5.44,5.04,5.37
      c14.25-0.26,28.5-0.02,42.75-0.18c2.81-0.03,3.64,0.8,3.54,3.88c-0.22,6.56-0.26,13.15,0.01,19.71c0.15,3.57-0.67,4.66-4.09,4.62
      c-19.45-0.2-38.89-0.09-58.34-0.09C279.35,448.9,236.19,448.9,193.03,448.9z M77.89,252.75c0,54.62,0.03,109.24-0.07,163.87
      c-0.01,3.44,0.81,4.37,3.95,4.32c14.25-0.19,28.5-0.21,42.75,0.01c3.52,0.05,4.65-0.78,4.63-4.92
      c-0.17-34.32-0.04-68.64-0.18-102.96c-0.02-3.74,0.96-4.5,4.21-4.49c39.96,0.12,79.92,0.11,119.87,0c3.07-0.01,4,0.76,3.99,4.28
      c-0.13,34.47,0,68.94-0.18,103.4c-0.02,4.04,1.09,4.73,4.45,4.68c14.25-0.2,28.5-0.21,42.75,0.01c3.35,0.05,4.14-0.94,4.14-4.57
      c-0.09-109.24-0.1-218.49,0.01-327.73c0-3.76-1-4.47-4.22-4.47c-73.92,0.1-147.84,0.11-221.77,0c-3.4-0.01-4.43,0.72-4.42,4.71
      C77.94,143.5,77.89,198.12,77.89,252.75z M154.63,378.33c0,12.85,0.07,25.7-0.05,38.54c-0.03,2.82,0.33,4.08,3.36,4.06
      c23.43-0.14,46.87-0.11,70.3-0.03c2.36,0.01,3.2-0.56,3.19-3.36c-0.1-25.84-0.1-51.68,0-77.52c0.01-2.79-0.8-3.38-3.18-3.37
      c-23.43,0.09-46.87,0.12-70.3-0.03c-3-0.02-3.41,1.2-3.38,4.04C154.7,353.22,154.63,365.78,154.63,378.33z"/>
    <path d="M192.58,28.16c-53.81,0-107.62-0.05-161.43,0.1c-3.75,0.01-4.66-1.17-4.5-5.06c0.29-6.7,0.16-13.43,0.04-20.15
      c-0.04-2.29,0.6-3.26,2.71-2.95c0.65,0.1,1.33,0.01,2,0.01C139.15,0.11,246.9,0.14,354.66,0c3.95,0,4.94,1.13,4.71,5.29
      c-0.34,6.26-0.26,12.56-0.02,18.83c0.13,3.28-0.73,4.12-3.74,4.11c-34.76-0.12-69.53-0.08-104.29-0.08
      C231.74,28.16,212.16,28.16,192.58,28.16z"/>
    <path d="M116.9,112.31c3.46,0.42,9.07-2.02,11.46,0.94c2.07,2.58,0.63,8.59,0.68,13.08c0.04,3.64-0.13,7.3,0.05,10.93
      c0.13,2.58-0.77,3.18-2.97,3.14c-6.65-0.14-13.3-0.12-19.95-0.01c-2.03,0.04-2.8-0.56-2.76-2.92c0.12-7.43,0.13-14.87,0-22.31
      c-0.04-2.38,0.76-2.99,2.77-2.9C109.52,112.41,112.84,112.31,116.9,112.31z"/>
    <path d="M193,112.31c3.32,0,6.66,0.15,9.97-0.05c2.33-0.14,2.91,0.82,2.87,3.24c-0.13,7.29-0.11,14.58-0.01,21.87
      c0.03,2.2-0.47,3.08-2.65,3.04c-6.78-0.14-13.56-0.14-20.34,0c-2.15,0.04-2.7-0.79-2.66-3.02c0.1-7.29,0.12-14.58-0.01-21.87
      c-0.04-2.4,0.5-3.4,2.86-3.26C186.34,112.46,189.67,112.31,193,112.31z"/>
    <path d="M282.53,126.54c0,3.65-0.12,7.3,0.04,10.93c0.1,2.37-0.71,2.96-2.75,2.93c-6.78-0.12-13.56-0.1-20.35-0.01
      c-1.84,0.02-2.59-0.51-2.56-2.68c0.1-7.58,0.09-15.17,0-22.75c-0.03-2.14,0.68-2.72,2.54-2.7c6.78,0.1,13.57,0.15,20.34-0.02
      c2.45-0.06,2.85,1.07,2.77,3.35C282.44,119.24,282.54,122.89,282.53,126.54z"/>
    <path d="M116.66,168.4c3.19-0.01,6.39,0.15,9.57-0.06c2.34-0.15,2.91,0.84,2.86,3.25c-0.12,7.29-0.11,14.58-0.01,21.87
      c0.03,2.21-0.48,3.08-2.65,3.03c-6.78-0.14-13.56-0.14-20.34,0c-2.16,0.04-2.69-0.81-2.66-3.03c0.1-7.29,0.12-14.58-0.01-21.87
      c-0.04-2.41,0.52-3.39,2.86-3.25C109.74,168.56,113.2,168.41,116.66,168.4z"/>
    <path d="M193.4,168.41c3.19,0,6.39,0.16,9.57-0.05c2.35-0.15,2.91,0.83,2.87,3.24c-0.12,7.29-0.11,14.58-0.01,21.87
      c0.03,2.21-0.48,3.08-2.65,3.04c-6.78-0.14-13.56-0.14-20.34,0c-2.16,0.04-2.69-0.81-2.66-3.03c0.1-7.29,0.12-14.58-0.01-21.87
      c-0.04-2.41,0.51-3.39,2.86-3.25C186.48,168.56,189.94,168.41,193.4,168.41z"/>
    <path d="M269.85,168.41c3.32,0,6.65,0.11,9.97-0.04c2.03-0.09,2.8,0.54,2.76,2.91c-0.13,7.43-0.13,14.87,0,22.31
      c0.04,2.38-0.75,2.94-2.77,2.91c-6.78-0.11-13.56-0.1-20.34-0.01c-1.85,0.02-2.58-0.54-2.55-2.69c0.1-7.58,0.1-15.16,0-22.75
      c-0.03-2.16,0.72-2.74,2.56-2.68C262.93,168.49,266.39,168.41,269.85,168.41z"/>
    <path d="M115.64,252.55c-3.47-0.41-9.08,2.02-11.47-0.94c-2.08-2.57-0.63-8.58-0.69-13.08c-0.05-3.64,0.13-7.3-0.06-10.93
      c-0.13-2.56,0.76-3.2,2.96-3.15c6.65,0.14,13.3,0.12,19.95,0.02c2.01-0.03,2.81,0.52,2.77,2.91c-0.13,7.43-0.12,14.87,0,22.31
      c0.04,2.36-0.72,3.01-2.76,2.92C123.02,252.44,119.69,252.55,115.64,252.55z"/>
    <path d="M193,252.55c-3.32,0-6.66-0.15-9.97,0.05c-2.35,0.14-2.9-0.84-2.86-3.25c0.12-7.29,0.11-14.58,0.01-21.87
      c-0.03-2.22,0.5-3.07,2.66-3.03c6.78,0.13,13.56,0.13,20.34-0.01c2.17-0.04,2.68,0.83,2.65,3.04c-0.1,7.29-0.12,14.58,0.01,21.87
      c0.04,2.41-0.52,3.39-2.86,3.25C199.66,252.4,196.33,252.55,193,252.55z"/>
    <path d="M282.53,238.6c0,3.65-0.09,7.29,0.03,10.93c0.08,2.17-0.42,3.11-2.62,3.07c-6.78-0.14-13.56-0.14-20.34,0
      c-2.13,0.04-2.72-0.73-2.69-3c0.11-7.29,0.11-14.58,0-21.87c-0.04-2.37,0.45-3.35,2.83-3.29c6.65,0.17,13.3,0.16,19.94,0
      c2.3-0.05,3,0.76,2.89,3.22C282.41,231.31,282.53,234.96,282.53,238.6z"/>
  </g>
  </svg>
</template>

<script>
export default {

};
</script>

<style lang="scss">
</style>
