<template>
  <div class="register-form-component">
    <div class="step-one row" v-show="step === 1">

      <div class="col-xs-12 form-title">
        {{ $t('register.steps.form.title') }}
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.country') }}</label>
          <ct-select
            :class="['form-control', {'is-invalid': errors.has('pais')}]"
            name="pais"
            id="pais"
            :placeholder="$t('register.steps.fields.country')"
            v-model="registerData.country"
            :options="countriesCodes"
            :reduce="c => c.value"
            v-validate="{ required: true }"
            data-vv-name="pais"
          />
          <span class="msg-error">{{ errors.first('pais') }}</span>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.kind') }}</label>
          <ct-select
            :class="['form-control', {'is-invalid': errors.has('kind')}]"
            name="kind"
            id="kind"
            :placeholder="$t('register.steps.fields.kind')"
            v-model="registerData.kind"
            :options="documentKinds"
            :reduce="c => c.value"
            v-validate="{ required: true }"
            data-vv-name="kind"
          />
          <span class="msg-error">{{ errors.first('kind') }}</span>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.name') }}</label>
          <input
            :class="['form-control', {'is-invalid': errors.has('nome')}]"
            type="text"
            :placeholder="$t('register.steps.fields.name')"
            name="name"
            maxlength="70"
            v-model.trim="registerData.name"
            v-validate="{ required: true, max: 70, fullName: true }"
            data-vv-name="nome"
          />
          <span class="msg-error">{{ errors.first('nome') }}</span>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <label>{{ documentLabel }}</label>
          <the-mask
            :class="['form-control', {'is-invalid': errors.has('documento')}]"
            :mask="documentMask"
            v-model.trim="registerData.document"
            type="text"
            id="documento"
            name="documento"
            :masked="false"
            :placeholder="documentLabel"
            v-validate="documentValidator"
            data-vv-name="documento"
          />
          <span class="msg-error">{{ errors.first('documento') }}</span>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.email') }}</label>
          <input
            :class="['form-control', {'is-invalid': errors.has('email')}]"
            type="email"
            :placeholder="$t('register.steps.fields.email')"
            name="email"
            v-model="registerData.email"
            v-validate="'required'"
            ref="email"
          />
          <span class="msg-error">{{ errors.first('email') }}</span>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.emailComfirm') }}</label>
          <input
            :class="['form-control', {'is-invalid': errors.has('confirmação de email')}]"
            type="email"
            :placeholder="$t('register.steps.fields.emailComfirm')"
            name="emailConfirm"
            v-model="registerData.emailConfirm"
            v-validate="'required|confirmed:email'"
            data-vv-name="confirmação de email"
          />
          <span class="msg-error">{{ errors.first('confirmação de email') }}</span>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.password') }}</label>
          <input
            :class="['form-control', {'is-invalid': errors.has('senha')}]"
            type="password"
            :placeholder="$t('register.steps.fields.password')"
            name="password"
            autocomplete="new-password"
            v-model="registerData.password"
            v-validate="{ required: true, min: 6 }"
            data-vv-name="senha"
            ref="senha"
          />
          <span class="msg-error">{{ errors.first('senha') }}</span>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.password') }}</label>
          <input
            :class="['form-control', {'is-invalid': errors.has('confirmação de senha')}]"
            type="password"
            placeholder="Confirmação de Senha"
            name="passwordConfirm"
            v-model="registerData.passwordConfirm"
            v-validate="'required|confirmed:senha'"
            data-vv-name="confirmação de senha"
          />
          <span class="msg-error">{{ errors.first('confirmação de senha') }}</span>
        </div>
      </div>

      <div class="form-group" style="display: flex; justify-content: center;" @click="registerData.acceptNotification = !registerData.acceptNotification">
        <div class="check-group" >
          <div class="checkbox" :class="{'checked': registerData.acceptNotification}">
            <input type="checkbox" id="accept-terms" :value="false" v-model="registerData.acceptNotification" />
          </div>
          <label for="accept-terms" @click="registerData.acceptNotification = !registerData.acceptNotification">
            Eu concordo em receber notificações e ofertas personalizadas de acordo com os meus interesses.
          </label>
        </div>
      </div>
    </div>

    <div class="step-two row" v-show="step === 2">
      <div class="col-xs-12 form-title">
        {{ $t('register.steps.form.titleStepTwo') }}
      </div>

      <div class="col-xs-12">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.country') }}</label>
          <ct-select
            :class="['form-control', {'is-invalid': errors.has('pais')}]"
            name="pais"
            :placeholder="$t('register.steps.fields.country')"
            v-model="registerData.country"
            :options="countriesCodes"
            :reduce="c => c.value"
            v-validate="{ required: true }"
            data-vv-name="pais"
            disabled
          />
          <span class="msg-error">{{ errors.first('pais') }}</span>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.zipCode') }}</label>
          <the-mask
            :class="['form-control', {'is-invalid': errors.has('cep')}]"
            :mask="zipCodeMask"
            :masked="false"
            :placeholder="$t('register.steps.fields.zipCode')"
            name="zipCode"
            v-model.trim="registerData.addresses[0].zipCode"
            v-validate="zipCodeValidator"
            data-vv-name="cep"
            autocomplete="zipCode"
            @input="onChangeZipCode"
          />
          <span class="msg-error">{{ errors.first('cep') }}</span>
        </div>
      </div>
      <div class="col-xs-12 col-sm-8">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.address') }}</label>
          <input
            :class="['form-control', {'is-invalid': errors.has('logradouro')}]"
            type="text"
            :placeholder="$t('register.steps.fields.address')"
            name="address"
            id="address"
            v-model.trim="registerData.addresses[0].address"
            data-vv-name="logradouro"
            v-validate="{required: true}"
            required="required"
          />
          <span class="msg-error">{{ errors.first('logradouro') }}</span>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.number') }}</label>
          <input
            :class="['form-control', {'is-invalid': errors.has('número')}]"
            type="text"
            :placeholder="$t('register.steps.fields.number')"
            name="number"
            id="number"
            v-model.trim="registerData.addresses[0].number"
            v-validate="{required: true, min: 1}"
            data-vv-name="número"
            required="required"
          />
          <span class="msg-error">{{ errors.first('número') }}</span>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.district') }}</label>
          <input
            :class="['form-control', {'is-invalid': errors.has('bairro')}]"
            type="text"
            :placeholder="$t('register.steps.fields.district')"
            name="district"
            id="district"
            v-model.trim="registerData.addresses[0].district"
            v-validate="{required: true}"
            data-vv-name="bairro"
          />
          <span class="msg-error">{{ errors.first('bairro') }}</span>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.complement') }}</label>
          <input
            :class="['form-control', {'is-invalid': errors.has('complemento')}]"
            type="text"
            :placeholder="$t('register.steps.fields.complement')"
            name="complement"
            v-model="registerData.addresses[0].complement"
          />
          <span class="msg-error">{{ errors.first('complemento') }}</span>
        </div>
      </div>
      <div class="col-xs-12 col-sm-8">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.city') }}</label>
           <input
            :class="['form-control', {'is-invalid': errors.has('cidade')}]"
            type="text"
            :placeholder="$t('register.steps.fields.city')"
            name="city"
            v-model.trim="registerData.addresses[0].city"
            v-validate="'required'"
            data-vv-name="cidade"
          />
          <span class="msg-error">{{ errors.first('cidade') }}</span>
        </div>
      </div>

      <div class="col-xs-12 col-sm-4">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.uf') }}</label>
          <input
            v-if="registerData.country && registerData.country !== 'BR'"
            :class="['form-control', {'is-invalid': errors.has('estado')}]"
            type="text"
            :placeholder="$t('register.steps.fields.uf')"
            name="state"
            maxlength="2"
            v-model.trim="registerData.addresses[0].uf"
            v-validate="'required'"
            data-vv-name="estado"
          />
          <ct-select
            v-if="!registerData.country || registerData.country === 'BR'"
            :class="['form-control', {'is-invalid': errors.has('estado'), 'select-state' : registerData.addresses[0].uf }]"
            name="state"
            id="state"
            :placeholder="$t('register.steps.fields.uf')"
            v-model="registerData.addresses[0].uf"
            :options="states"
            :reduce="state => state.value"
            v-validate="{ required: true }"
            data-vv-name="estado"
          />
          <span class="msg-error">{{ errors.first('estado') }}</span>
        </div>
      </div>
    </div>

    <div class="step-three row" v-show="step >= 3">
      <div class="col-xs-12 form-title">
        {{ $t('register.steps.form.titleStepThree') }}
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.phoneCode') }}</label>
          <ct-select
            :class="['form-control', {'is-invalid': errors.has('código do país')}]"
            name="phoneCode"
            id="phoneCode"
            :placeholder="$t('register.steps.fields.phoneCode')"
            v-model="registerData.phoneCode"
            :options="countriesPhones"
            :reduce="c => c.value"
            v-validate="{ required: true }"
            data-vv-name="código do país"
          />
          <span class="msg-error">{{ errors.first('código do país') }}</span>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-8">
        <div class="form-group">
          <label>{{ $t('register.steps.fields.phone') }}</label>
          <the-mask
            :class="['form-control', {'is-invalid': errors.has('número de celular')}]"
            :mask="phoneMask"
            type="text"
            :placeholder="$t('register.steps.fields.phone')"
            name="phoneNumber"
            v-model="registerData.phone"
            v-validate="'required'"
            data-vv-name="número de celular"
          />
          <span class="msg-error">{{ errors.first('número de celular') }}</span>
        </div>
      </div>

      <div class="col-xs-12 form-subtitle">
        {{ $t('register.steps.form.subTitleStepThree', { type: settings && settings.providerMessages ? settings.providerMessages : 'SMS' }) }}
      </div>
    </div>

    <button @click.prevent="registerUser" class="btn btn--secondary block-center">
      {{ step >= 3 ? $t('register.steps.form.buttons.register') : $t('register.steps.form.buttons.next') }}
    </button>

    <div class="form-group back-step">
      <span @click="backStep" v-if="step > 1 && step <= 3" class="back">
        <img src="/assets/img/icons/prev-icon.svg" class="icon icon-back" /> Voltar
      </span>
      <p class="step-text">{{ stepLabel }}/3</p>
    </div>
  </div>
</template>

<script>
import CtSelect from '../Shared/Select';
import { TheMask } from 'vue-the-mask';
import UserRegistration from '@/model/UserRegister';
import { mapState } from 'vuex';
import ZipCodeService from '../../services/ZipCodeService';

export default {
  name: 'RegisterForm',
  data: () => ({
    step: 1,
    validationsFields: {
      1: ['nome', 'pais', 'documento', 'senha', 'confirmação de senha', 'email', 'confirmação de email'],
      2: ['cep', 'logradouro', 'número', 'complemento', 'bairro', 'cidade', 'estado'],
      3: ['código do país', 'número de celular'],
    },
    registerData: new UserRegistration(),
  }),
  components: {
    CtSelect,
    TheMask,
  },
  computed: {
    ...mapState({
      states: state => state.select.states || {},
      countriesCodes: state => state.select.countriesCodes || {},
      countriesPhones: state => state.select.countriesPhones || {},
      documentKinds: state => state.select.documentKinds || {},
      settings: (state) => state.settings.settings || {},
    }),
    stepLabel () {
      return this.step <= 3 ? this.step : 3;
    },
    phoneMask () {
      if (!this.registerData.phoneCode || this.registerData.phoneCode.toString() === '55:BR') {
        return '(##) #####-####';
      }
      return ['(##) ####-####', '(###) ####-####'];
    },
    documentMask () {
      if (!this.registerData.country || this.registerData.country === 'BR') {
        return ['###.###.###-##', '##.###.###/####-##'];
      }
      return ['XXXXXXXXXXXXXXXXXXXX'];
    },
    zipCodeMask () {
      if (!this.registerData.country || this.registerData.country === 'BR') {
        return '#####-###';
      }
      return '##########';
    },
    documentLabel () {
      if (!this.registerData.country || this.registerData.country === 'BR') {
        return this.$t('register.steps.fields.document');
      }
      return this.$t('register.steps.fields.documentExternal');
    },
    documentValidator () {
      if (!this.registerData.country || this.registerData.country === 'BR') {
        const document = this.registerData.document;
        if (document && document.length > 11) {
          return 'required|min:14|cnpj';
        }

        return 'required|min:11|cpf';
      }
      return 'required|min:5';
    },
    zipCodeValidator () {
      if (!this.registerData.country || this.registerData.country === 'BR') {
        return { required: true, zipCode: true };
      }
      return { required: true };
    },
  },
  methods: {
    backStep () {
      this.step--;
    },
    async registerUser () {
      const fields = this.validationsFields[this.step];
      const isValid = await this.$validator.validateAll(fields);

      if (isValid && this.step === 1) {
        await this.$store.dispatch('hasUserForData', this.registerData);
      }

      if (isValid && this.step === 3) {
        this.step++;
        await this.$store.dispatch('registerUser', this.registerData);
        return;
      }

      if (isValid && this.step < 3) {
        this.step++;
      }
    },
    populateUserByViaCepResponse (response) {
      const addressItem = {
        address: response.address,
        district: response.district,
        complement: response.complement,
        city: response.city,
        uf: response.uf,
        cityCode: response.cityCode,
      };

      this.$set(this.registerData.addresses, 0, {
        ...this.registerData.addresses[0],
        ...addressItem,
      });
    },
    async onChangeZipCode () {
      if (this.registerData.addresses[0].zipCode && this.registerData.country === 'BR') {
        const response = await ZipCodeService.getAddressByZipCode(this.registerData.addresses[0].zipCode);
        if (response) {
          this.populateUserByViaCepResponse(response);
        }
      }
    },
  },
  mounted () {
    this.registerData = new UserRegistration(this.$store.state.register.registeringUser);
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.register-form-component {
  .row {
    margin-left: 0;
  }
  label {
    color: #ffffff;
  }
  padding: 30px;
  .form-title {
    color: $tertiary-color;
    text-align: center;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 30px;
    font: {
      size: 23px;
      weight: 400
    }
    @media screen and (max-width: $grid-xs-max) {
      margin: 0 0 20px;
      font-size: 16px;
    }
  }

  .form-subtitle {
    font-family: 'Oswald', sans-serif;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    color: $tertiary-color;
    display: block;
    margin: 0 auto;
    line-height: 27px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;

    @media only screen and (max-width: $grid-xs-max) {
      font-size: 14px;
      line-height: 1;
    }
  }

  .back-step {
    display: flex;
    position: relative;

    .back {
      font-family: 'Oswald', sans-serif;
      font-size: 10px;
      color: $white-color;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      position: absolute;

      @media only screen and (max-width: $grid-xs-max) {
        margin-left: 0;
      }

      .icon-back {
        width: 8px;
        height: 10px;
        margin-right: 3px;
        @media only screen and (max-width: $grid-xs-max) {
          background-size: 100%
        }
      }
      &:hover {
        cursor: pointer;
      }
    }

    .step-text {
      font-size: 14px;
      text-align: center;
      color: $white-color;
      margin: auto;
    }
  }

  .btn-action {
    background: $tertiary-color;
    color: $spotlight-color;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    @media only screen and (max-width: $grid-xs-max) {
      font-size: 13px;
      margin-bottom: 15px;
    }
  }

  .check-group {
    /* max-width: 240px; */
    display: flex;
    margin: 15px;
    .checkbox {
      min-width: 20px;
      width: 20px;
      height: 20px;
      background-color: white;
      border: 1px solid white;
      flex-grow: 0;
      @media only screen and (max-width: $grid-sm-max) {
          width: 18px;
          height: 18px;
      }
      input[type='checkbox'] {
        min-width: 15px;
        width: 15px;
        height: 15px;
        opacity: 0;
        &:hover {
            cursor: pointer;
        }
      }
      &.checked {
        background-color: white;
        background-image: url('/assets/img/check-icon-primary.png');
        background-position: center center;
        background-size: 15px 15px;
        background-repeat: no-repeat;
        @media only screen and (max-width: $grid-sm-max) {
          background-size: 11px 11px;
        }
      }
    }
    label {
      color: $secondary-color;
      text-transform: uppercase;
      margin-left: 10px;
      cursor: pointer;
      flex-grow: 1;
    }
  }
  @media only screen and (max-width: $grid-md-max) {
      .check-group {
        label {
          margin-top: 0;
        }
      }
    }
}

</style>
