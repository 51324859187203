<template>
  <div class="services-page-component">
    <ct-banner :banner="banner" />
    <div class="container">
      <h1>
        {{ $t('services.title.page.shipment.titleOne') }}
        <span class="emphasis-variant">
          {{ $t('services.title.page.shipment.crypto') }}
        </span>
        {{ $t('services.title.page.shipment.titleTwo') }}
      </h1>
    </div>
    <ct-menu-service />
    <ct-invoice-service />
    <ct-services-intro-shipment />
  </div>
</template>

<script>
import CtBanner from '@/components/Banner/Banner';
import CtMenuService from '@/components/Services/Menu';
import CtInvoiceService from '@/components/Services/Invoice';
import CtServicesIntroShipment from '@/components/Services/ServicesIntroShipment';
import config from '@/config';

export default {
  name: 'ServiceShipment',
  data () {
    return {
      banner: {
        textAlt: 'Conversão de bitcoins em minutos',
        imgUrl: '/assets/img/banners/banner-conversao-bitcoin.jpg',
        imgUrlMobile: '/assets/img/banners/banner-conversao-bitcoin-mobile.jpg',
      },
      pageInfo: {
        title: 'Serviços - Use seus bitcoins como quiser',
        description: 'Pagamento de boletos, PIX, conversão de bitcoins, recarga de celular e gift card em um só lugar. Monte seu pedido e pague com bitcoin',
      },
    };
  },
  metaInfo () {
    return {
      title: this.pageInfo.title,
      meta: [
        { name: 'description', content: this.pageInfo.description },
        { name: 'og:description', content: this.pageInfo.description },
        { name: 'og:image', content: config.app.url + this.banner.imgUrl },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.pageInfo.title },
        { name: 'twitter:description', content: this.pageInfo.description },
        { name: 'twitter:image', content: config.app.url + this.banner.imgUrl },
      ],
    };
  },
  components: {
    CtBanner,
    CtMenuService,
    CtInvoiceService,
    CtServicesIntroShipment,
  },
};
</script>

<style lang="scss" scoped>
h1{
  text-align: center;
}
</style>
