<template>
  <div class="taxes-content-component">
    <div class="container">
      <h1 class="taxes-title">
        TAXAS DOS SERVIÇOS
      </h1>

      <h2 class="taxes-info-title">
        NOVA POLÍTICA DE TAXAS - COVID-19
      </h2>
      <p class="taxes-info update-text">
        Atualizado em 23 de março de 2020.
      </p>
      <p class="taxes-info">
        Em 30 de janeiro de 2020, a Organização Mundial de Saúde (OMS) declarou uma
        <a target="_blank" href="https://www.who.int/news-room/detail/30-01-2020-statement-on-the-second-meeting-of-the-international-health-regulations-(2005)-emergency-committee-regarding-the-outbreak-of-novel-coronavirus-(2019-ncov)">
        Emergência de Saúde Pública de Âmbito Internacional</a>
        (Public Health Emergency of International Concern, ou PHEIC) para o atual surto da doença
        do coronavírus, conhecida como COVID-19. Desde então, o surto evoluiu rapidamente com governos ao redor do
        mundo, tomando medidas rápidas para retardar a disseminação da COVID-19.
      </p>
      <p class="taxes-info">
        No Brasil, o Ministério da Saúde regulamentou os critérios de isolamento e quarentena que deverão ser aplicados
        pelas autoridades de saúde local, com destaque para o Estado de São Paulo com decreto de fechamento de
        estabelecimentos comerciais até 30 de abril de 2020, exceto bares, lanchonetes, restaurantes e padarias.
      </p>
      <p class="taxes-info">
        Na tentativa de amenizar o impacto econômico devido ao período de quarentena, estamos reduzindo nossas taxas de
        serviços conforme abaixo:
      </p>
      <h2 class="taxes-info-title">
        Taxa de conveniência dos serviços
      </h2>
      <p class="taxes-info">
        Cobramos uma taxa de conveniência para a utilização de cada item contratado, que pode variar para cada tipo de serviço:
      </p>

      <table>
        <tr>
          <th>Pagamento de boletos
              <span class="spacer"></span>
          </th>
          <td>R$ {{ (settings.itemFees && settings.itemFees.billet ? settings.itemFees.billet : 0) | currency }}</td>
        </tr>
        <tr>
          <th>Recarga de celular
              <span class="spacer"></span>
          </th>
          <td>R$ {{ (settings.itemFees && settings.itemFees.recharge ? settings.itemFees.recharge : 0) | currency }}</td>
        </tr>
        <tr>
          <th>Conversão de bitcoins
              <span class="spacer"></span>
          </th>
          <td>R$ {{ (settings.itemFees && settings.itemFees.shipment ? settings.itemFees.shipment : 0) | currency }}</td>
        </tr>
        <tr>
          <th>Gift Card
              <span class="spacer"></span>
          </th>
          <td>R$ {{ (settings.itemFees && settings.itemFees.giftCard ? settings.itemFees.giftCard : 0) | currency }}</td>
        </tr>
      </table>

      <p class="taxes-info">
        Em caso de estorno do pedido, será descontada a taxa de transação de rede, taxa de transferência bancária ou
        taxas referentes aos serviços opcionais como recarga de celular pré-pago e gift card.
      </p>

      <p class="taxes-info">
        Dúvidas ou mais informações, entre em contato pelo e-mail
        <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>.
      </p>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import currency from '@/filters/currency';

export default {
  name: 'TaxesContent',
  computed: {
    ...mapState({
      settings: (state) => state.settings.settings,
    }),
  },
  filters: {
    currency,
  },
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';

.taxes-content-component {
  .container {
    max-width: 1200px;

    .taxes-title {
      max-width: 925px;
      text-align: center;
      margin: 0 auto 95px;

      @media only screen and (max-width: $grid-sm-max) {
        font-size: 25px;
        margin: 0 30px 30px
      }
      @media only screen and (max-width: $grid-xs-max) {
        margin: 0 15px 20px
      }

    }
    .taxes-info-title {
      font-size: 30px;
      margin: 50px 0 35px 0;

      @media only screen and (max-width: $grid-sm-max) {
        text-align: center;
        font-size: 20px
      }
    }
    .taxes-info {
      &:last-child {
        margin-bottom: 50px;
      }

      a {
        font-weight: bold;
        color: $charcoal-grey-color;

        &:hover {
          text-decoration: underline
        }
      }

      &.update-text{
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
      }
    }
    table{
      text-align: left;
      width: 30%;
      margin: 5px 0 30px 0;
      margin-bottom: 20px;
      font-size: 14px;
      border-collapse: collapse;
      padding: 0 15px;

      @media only screen and (max-width: $grid-sm-max) {
        width: 100%;
      }
      tr{
        height: 33px;
        td, th{
          border-bottom: $disabled-color solid 1px ;
          @media only screen and (max-width: $grid-sm-max) {
            padding: 15px;
          }
        }
        th{
          color: $spotlight-color;
        }
        td{
          color: $dark-color;
        }
      }
    }
  }
}
</style>
