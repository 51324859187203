<template>
  <div class="invoice-item">
    <div class="row item">
      <div class="col-lg-11 col-md-12 col-sm-12 col-xs-12" :class="`invoice-item-${this.uuid}`">
        <ct-billet-form v-if="kind === 'billet'"
          ref="item"
          :uuid="uuid"
          @verifyError="verifyError"
        />
        <ct-recharge-form v-if="kind === 'recharge'"
          ref="item"
          :uuid="uuid"
          @verifyError="verifyError"
        />
        <ct-sell-bitcoin-form v-if="kind === 'shipment'"
          ref="item"
          :uuid="uuid"
          @verifyError="verifyError"
        />
        <ct-gift-card-form v-if="kind === 'giftCard'"
          ref="item"
          :uuid="uuid"
          @verifyError="verifyError"
        />
        <ct-pix-form v-if="kind === 'pix'"
          ref="item"
          :uuid="uuid"
          @verifyError="verifyError"
        />
      </div>
      <div class="col-lg-1 col-xs-12 col-extra"
           :class="{ 'col-sm-6 col-md-6': isLastItem, 'col-sm-12 col-md-12': !isLastItem, 'remove-shipment': kind === 'shipment' }">
        <ct-services-action-button :type="'REMOVE'" @click.native="removeItem(uuid)" />
      </div>
      <div class="col-lg-12 col-md-6 col-sm-6 col-xs-12 add-item" v-if="isLastItem">
        <ct-services-action-button :type="'ADD'" :kind="kind" @click.native="addNewItem" />
      </div>
      <div class="col-xs-12 item-fee " v-if="isLastItem">
        {{ $t('services.itemFee.title') }}: R$ {{ (settings.itemFees && settings.itemFees[kind] ? settings.itemFees[kind] : 0) | currency }} {{ $t('services.itemFee.perItem') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CtBilletForm from './BilletForm';
import CtSellBitcoinForm from './SellBitcoinForm';
import CtRechargeForm from './RechargeForm';
import CtGiftCardForm from './GiftCardForm';
import CtPixForm from './PixForm';
import CtServicesActionButton from './ActionButton';
import currency from '@/filters/currency';

export default {
  name: 'InvoiceItem',
  components: {
    CtBilletForm,
    CtSellBitcoinForm,
    CtRechargeForm,
    CtGiftCardForm,
    CtPixForm,
    CtServicesActionButton,
  },
  filters: {
    currency,
  },
  props: {
    kind: {
      type: String,
      required: true,
    },
    uuid: {
      type: [ String, Number ],
      required: true,
    },
    isLastItem: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.settings,
    }),
  },
  methods: {
    async addNewItem () {
      await this.$emit('addNewItem', { kind: this.kind });
    },
    async removeItem (uuid) {
      await this.$emit('removeItem', { kind: this.kind, uuid });
    },
    async validate () {
      const isValid = await this.$refs.item.validate();
      return isValid;
    },
    async verifyError (data) {
      await this.$emit('verifyError', data);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../themes/variables";
  @import "../../themes/mixins/layout";

  .invoice-item {
    .item {
      border-bottom: thin dashed #f1f1f1;
      @media only screen and (max-width: $grid-md-max) {
        padding: 0;
      }
      @media only screen and (max-width: $grid-sm-max) {
        padding: 0;
      }
      .col-extra {
        @media only screen and (max-width: $grid-md-max) {
          margin-top: 10px;
        }

        @media only screen and (min-width: $grid-lg-min) {
          height: 100%;
          align-self: flex-start;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-top: 50px;

          &.remove-shipment {
            padding-top: 90px;
          }
        }
      }
    }
    .add-item {
      @media only screen and (max-width: $grid-md-max) {
        margin-top: 10px;
      }
      @media only screen and (min-width: $grid-lg-min) {
        margin-top: 20px;
      }
    }

    .item-fee {
      text-align: right;
      color: $warm-grey-color;

      @media only screen and (max-width: $grid-md-max) {
        margin-top: 10px;
        text-align: center;
      }
    }
  }

</style>
