<template>
  <div class="errors-page-component">
    <div class="error-status">{{ status }}</div>
    <div class="error-description">
      <div class="icon">
        <img :src="`/assets/img/icons/icon-${ icon.font }.png`" />
      </div>
      <div class="connection"></div>
      <div class="error-details">{{ details }}</div>
      <div class="finale-connection">
        <div class="connection"></div>
        <div class="error-connection" v-if="showError">X</div> 
      </div>
      <div class="icon">
        <img :src="`/assets/img/icons/icon-${ icon.destiny }.png`" />
      </div>
    </div>
    <div class="error-instruction">{{ instruction }}</div>
    <div class="mobile-connection">
      <div class="connection"></div>
      <div class="error-connection" v-if="showError">X</div>
      <div class="icon-mobile">
        <img :src="`/assets/img/icons/icon-${ icon.destiny }.png`" />
      </div>
    </div>
  </div>  
</template>

<script>
export default {
  name: 'Errors-Page',
  props: {
    status: {
      type: String,
      required: true,
      default: '404',
    },
    details: {
      type: String,
      required: true,
      default: 'Página não encontrada',
    },
    instruction: {
      type: String,
      required: true,
      default: 'Verifique se o endereço digitado está correto.',
    },
    icon: {
      type: Object,
      required: true,
      font: {
        type: String,
        required: true,
        default: 'computer',
      },
      destiny: {
        type: String,
        required: true,
        default: 'cloud',
      },
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";

.errors-page-component {
  font-family: 'Oswald', sans-serif;
  width: 100%;
  margin: 0 0 50px;
  @media only screen and (max-width: $grid-sm-max) {
    margin: 70px 0 50px
  }

  .error-status {
    display: flex;
    justify-content: center;
    color: $secondary-color;
    line-height: 1;
    margin-bottom: 10px;
    font: {
      size: 238px;
      weight: 700;
    }
    @media only screen and (max-width: $grid-sm-max) {
      font-size: 180px;
    }
    @media only screen and (max-width: $grid-xs-max) {
      font-size: 100px;
    }
  }

  .error-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: $grid-md-max) {
      justify-content: center;
    }

    .icon {
      @media only screen and (max-width: $grid-md-max) {
        display: none;
      }
    }
    .connection {
      border-bottom: 12px dotted $spotlight-color;
      flex-grow: 1;
      height: 12px;
      margin: 0 25px;
      @media only screen and (max-width: $grid-md-max) {
        display: none;
      }
    }
    .finale-connection {
      flex-grow: 1;
      display: flex;
      align-items: center;
      position: relative;
      @media only screen and (max-width: $grid-md-max) {
        display: none;
      }

      .error-connection {
        color: $secondary-color;
        position: absolute;
        right: 11px;
        top: -6px;
        display: block;
        font: {
          size: 76px;
          weight: 700;
        }
        @media only screen and (max-width: $grid-md-max) {
          display: none;
        }
      }
    }

    .error-details {
      text-align: center;
      color: $spotlight-color;
      text-transform: uppercase;
      max-width: 434px;
      letter-spacing: -2.5px;
      padding: 32px 0;
      line-height: 1;
      border-top: 13px solid $secondary-color;
      border-bottom: 13px solid $secondary-color;
      font: {
        size: 84px;
        weight: 700;
      }
      @media only screen and (max-width: $grid-sm-max) {
        max-width: 320px;
        font-size: 60px;
        padding: 15px 0;
        line-height: 1
      }
      &::first-line {
        letter-spacing: 0.71px;
        @media only screen and (max-width: $grid-xs-max) {
          letter-spacing: inherit;
        }
      }
      @media only screen and (max-width: $grid-xs-max) {
        width: 100%;
        min-width: 185px;
        padding: 7px;
        margin: 0 65px;
        font-size: 35px;
        letter-spacing: inherit;
        line-height: 1;
        border-top-width: 6px;
        border-bottom-width: 6px;
      }
    }
  }

  .error-instruction {
    color: $spotlight-color;
    font-size: 43px;
    line-height: 1;
    display: flex;
    max-width: 434px;
    letter-spacing: -1.5px;
    margin: 33px auto 0 auto;
    text: {
      transform: uppercase;
      align: center;
    }
    @media only screen and (max-width: $grid-sm-max) {
      margin: 20px auto 0 auto;
      font-size: 30px;
      max-width: 320px;
    }
    @media only screen and (max-width: $grid-xs-max) {
      width: 100%;
      margin-top: 14px;
      font-size: 19px;
      min-width: 194px;
      letter-spacing: inherit;
    }
  }
  .mobile-connection {
    display: none;
    @media only screen and (max-width: $grid-md-max) {

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      .connection {
        border-left: 12px dotted $spotlight-color;
        min-height: 100px;
        margin: 20px 0 10px;        
        @media only screen and (max-width: $grid-xs-max) {

          min-height: 60px;
          border-width: 5px;
          margin-bottom: 5px;     
        }
      }
      .error-connection {
        margin: 15px 0 30px;
        color: $secondary-color;
        font: {
          size: 76px;
          weight: 700;
        }
        @media only screen and (max-width: $grid-xs-max) {
          margin: 5px 0;
          font-size: 32px;
        }
      }
      .icon-mobile {
        margin-top: 10px;

        @media only screen and (max-width: $grid-xs-max) {
          margin-top: 5px;
        }
        img {
          @media only screen and (max-width: $grid-xs-max) {
            width: 60px;
            height: auto;
            margin-top: 10px
          }
        }
      }
    }
  }
}
</style>
