<template>
  <section class="call-component">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12 col about">
          <router-link :to="{ name: 'about' }" class="box-link">
            <p class="title">Quem somos</p>
          </router-link>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12 col fees">
          <router-link :to="{ name: 'taxes' }" class="box-link">
            <p class="title">Taxas <span>&</span> Informações</p>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Call',
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.call-component {
  @media print {
    display: none;
  }

  .container-fluid {
    .row {
      margin: 0;
    }
  }

  .col {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box-link {
    @include flex-center;

    width: 100%;
    max-width: 300px;
    padding: 20px 0px;
    text-decoration: none;
    border: 4px solid;

    @media only screen and (min-width: $grid-lg-min) {
      border-width: 7px;
    }
  }

  .title {
    font-family: 'Oswald', 'Open Sans', Verdana, sans-serif;
    font-size: 2em;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 0;

    @media only screen and (min-width: $grid-lg-min) {
      font-size: 3em;
    }

    span {
      font-size: 30px;
      @media only screen and (min-width: $grid-lg-min) {
        font-size: 46px;
      }
    }
  }

  .col {
    &.about {
      background: $primary-color;

      @media only screen and (min-width: $grid-lg-min) {
        justify-content: flex-end;
      }
      .box-link {
        border-color: $spotlight-color;

        .title {
          color: $spotlight-color
        }
      }
    }
    &.fees {
      background: $spotlight-color;

      @media only screen and (min-width: $grid-lg-min) {
        justify-content: flex-start;
      }

      .box-link {
        border-color: $primary-color;

        @media only screen and (min-width: $grid-lg-min) {
          max-width: 500px;
          padding-top: 10px;
        }

        .title {
          color: $primary-color;
        }
      }
    }
  }
}

</style>
