export default {
  TOGGLE_CONTACT_FORM (state) {
    state.openContactForm = !state.openContactForm;
  },
  OPEN_CONTACT_FORM (state) {
    state.openContactForm = true;
  },
  OPEN_CONTACT_MODAL (state) {
    state.openContactModal = true;
  },
  CLOSE_CONTACT_MODAL (state) {
    state.openContactModal = false;
  },
  OPEN_REFERRAL_MODAL (state) {
    state.isReferralModalOpen = true;
  },
  CLOSE_REFERRAL_MODAL (state) {
    state.isReferralModalOpen = false;
  },
};
