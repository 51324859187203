import BilletModel from '@/model/Billet';
import BilletService from '@/services/BilletService';
import GiftCardModel from '@/model/GiftCard';
import InvoiceService from '@/services/InvoiceService';
import PixModel from '@/model/Pix';
import RechargeModel from '@/model/Recharge';
import SellBtcModel from '@/model/SellBtc';
import moment from 'moment';
import router from '@/router';
import uuid from 'uuid/v4';

export default {
  addNewBillet ({ commit }) {
    commit('ADD_BILLET', new BilletModel());
  },
  addNewPix ({ commit }) {
    commit('ADD_PIX', new PixModel());
  },
  addNewBtc ({ commit }) {
    commit('ADD_BTC', new SellBtcModel());
  },
  addNewRecharge ({ commit }) {
    commit('ADD_RECHARGE', new RechargeModel());
  },
  addNewGiftCard ({ commit }) {
    commit('ADD_GIFT_CARD', new GiftCardModel());
  },
  clearEmptyBillets ({ state, commit }) {
    const billets = state.invoice && state.invoice.billets ? state.invoice.billets : [];
    for (const k in billets) {
      const billet = billets[k];
      if (!billet.digitableLine) {
        commit('REMOVE_BILLET', k);
      }
    }
  },
  clearEmptyBtcs ({ state, commit }) {
    const sellBtcs = state.invoice && state.invoice.sellBtc ? state.invoice.sellBtc : [];
    for (const k in sellBtcs) {
      const sellBtc = sellBtcs[k];
      if (!sellBtc.bank) {
        commit('REMOVE_BTC', k);
      }
    }
  },
  clearEmptyPix ({ state, commit }) {
    const pix = state.invoice && state.invoice.pix ? state.invoice.pix : [];
    for (const k in pix) {
      const item = pix[k];
      if (!item.key) {
        commit('REMOVE_PIX', k);
      }
    }
  },
  clearEmptyRecharges ({ state, commit }) {
    const recharges = state.invoice && state.invoice.recharges ? state.invoice.recharges : [];
    for (const k in recharges) {
      const recharge = recharges[k];
      if (!recharge.ddd && !recharge.number) {
        commit('REMOVE_RECHARGE', k);
      }
    }
  },
  clearEmptyGiftCards ({ state, commit }) {
    const giftCards = state.invoice && state.invoice.giftCards ? state.invoice.giftCards : [];
    for (const k in giftCards) {
      const giftCard = giftCards[k];
      if (!giftCard.operator && !giftCard.card && !giftCard.amount) {
        commit('REMOVE_GIFT_CARD', k);
      }
    }
  },
  removeBillet ({ commit, getters }, uuid) {
    const index = getters.getBilletIndexByUUID(uuid);
    commit('REMOVE_BILLET', index);
  },
  removeBtc ({ commit, getters }, uuid) {
    const index = getters.getSellBtcIndexByUUID(uuid);
    commit('REMOVE_BTC', index);
  },
  removePix ({ commit, getters }, uuid) {
    const index = getters.getPixIndexByUUID(uuid);
    commit('REMOVE_PIX', index);
  },
  removeRecharge ({ commit, getters }, uuid) {
    const index = getters.getRechargeIndexByUUID(uuid);
    commit('REMOVE_RECHARGE', index);
  },
  removeGiftCard ({ commit, getters }, uuid) {
    const index = getters.getGiftCardIndexByUUID(uuid);
    commit('REMOVE_GIFT_CARD', index);
  },
  clearBillet ({ commit, getters }, uuid) {
    const index = getters.getBilletIndexByUUID(uuid);
    commit('SET_BILLET', { index, billet: { ...new BilletModel(), uuid } });
  },
  decomposeGiftcardItemsQuantity ({ commit, state }) {
    const giftCards = state.invoice.giftCards || [];
    const newGiftCards = [];
    giftCards.map((giftCard) => {
      const quantity = parseInt(giftCard.quantity, 10);
      if (quantity === 1) {
        newGiftCards.push(giftCard);
      } else if (quantity < 1) {
        giftCard.quantity = 1;
        newGiftCards.push(giftCard);
      } else {
        for (let i = 0; i < quantity; i++) {
          const newGiftCard = { ...giftCard };
          newGiftCard.uuid = uuid();
          newGiftCard.quantity = 1;
          newGiftCards.push(newGiftCard);
        }
      }
    });
    commit('SET_GIFTCARDS', newGiftCards);
  },
  async finishOrder ({ commit, state, getters }) {
    const response = await InvoiceService.createInvoice(state.invoice, getters.userData);
    switch (response.status) {
      case 'SUCCESS':
        router.push({ name: 'checkout', params: { id: response.id } });
        commit('CLEAR_INVOICE');
        break;
      case 'ERROR':
        return {
          error: true,
          message: response.message,
          errors: response.errors,
          errorCode: response.errorCode,
        };
    }
  },
  async autoCompleteBillet ({ commit, getters }, { uuid, isDeletion }) {
    let billet = getters.getBilletByUUID(uuid);
    try {
      let digitableLine = billet.digitableLine.replace(/[^0-9]+/g, ''); // Remove non-numbers

      if (!isDeletion) {
        digitableLine = BilletService.checkForIncompleteDigitableLine(digitableLine);
      }

      if (digitableLine.length === 47 || digitableLine.length === 48 || digitableLine.length === 44) {
        const details = await BilletService.getDetail(digitableLine);

        billet.dueDate = moment(details.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
        if (details.dueDate === '1997-10-07' && details.value === 0) {
          billet.dueDate = '';
        }

        billet.amount = details.value;
        billet.bank = 'Banco não identificado';
        if (details.kind === 'convênio') {
          billet.bank = 'Convênio';
        }
        if (details.bank && details.bank) {
          billet.bank = details.bank;
        }
        billet.digitableLine = digitableLine;
      } else {
        billet.dueDate = '';
        billet.amount = '';
        billet.bank = '';
      }
    } catch (err) {
      billet.dueDate = '';
      billet.amount = '';
      const data = err.response && err.response.data ? err.response.data : {};
      billet.bank = data.message || 'Banco não identificado';
    } finally {
      const index = getters.getBilletIndexByUUID(uuid);
      commit('SET_BILLET', { index, billet });
    }
  },
  setErrorItems ({ commit }, errors) {
    commit('SET_ITEMS_ERRORS', { errors });
  },

  async changeInvoiceNetwork ({ commit }, payload) {
    commit('SET_INVOICE_NETWORK', payload);
  },
};
