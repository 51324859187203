export default {
  getBilletByUUID: (state) => {
    return (uuid) => {
      return state.invoice.billets.find(billet => billet.uuid === uuid);
    };
  },
  getBilletIndexByUUID: (state) => {
    return (uuid) => {
      return state.invoice.billets.findIndex(billet => billet.uuid === uuid);
    };
  },
  getPixByUUID: (state) => {
    return (uuid) => {
      return state.invoice.pix.find(item => item.uuid === uuid);
    };
  },
  getSellBtcByUUID: (state) => {
    return (uuid) => {
      return state.invoice.sellBtc.find(sell => sell.uuid === uuid);
    };
  },
  getSellBtcIndexByUUID: (state) => {
    return (uuid) => {
      return state.invoice.sellBtc.findIndex(sell => sell.uuid === uuid);
    };
  },
  getRechargeByUUID: (state) => {
    return (uuid) => {
      return state.invoice.recharges.find(recharge => recharge.uuid === uuid);
    };
  },
  getRechargeIndexByUUID: (state) => {
    return (uuid) => {
      return state.invoice.recharges.findIndex(recharge => recharge.uuid === uuid);
    };
  },
  getGiftCardByUUID: (state) => {
    return (uuid) => {
      return state.invoice.giftCards.find(giftCard => giftCard.uuid === uuid);
    };
  },
  getPixIndexByUUID: (state) => {
    return (uuid) => {
      return state.invoice.pix.findIndex(pix => pix.uuid === uuid);
    };
  },
  getGiftCardIndexByUUID: (state) => {
    return (uuid) => {
      return state.invoice.giftCards.findIndex(giftCard => giftCard.uuid === uuid);
    };
  },

  getBilletsByLine: (state) => {
    return (line) => {
      return state.invoice.billets.filter(billet => billet.digitableLine === line);
    };
  },

  getInvoiceItemsCount: (state) => {
    const totalGiftCards = state.invoice.giftCards.reduce((a, giftcard) => Number(giftcard.quantity || 1), 0);
    return state.invoice.billets.length +
      state.invoice.recharges.length +
      state.invoice.pix.length +
      totalGiftCards +
      state.invoice.sellBtc.length;
  },

  isInvoiceEmpty: (state, getters) => {
    return getters.getInvoiceItemsCount === 0;
  },

  getInvoiceTotal: (state, getters) => {
    const total = getters.getBilletTotal +
          getters.getSellBtcTotal +
          getters.getPixTotal +
          getters.getRechargesTotal +
          getters.getGiftCardsTotal;
    return Math.max(total + getters.getInvoiceFee, 0);
  },

  getInvoiceFee: (state, getters, rootState) => {
    const totalGiftCards = state.invoice.giftCards.reduce((a, giftcard) => giftcard.quantity, 0);
    const total = (state.invoice.billets.length * rootState.settings.settings.itemFees.billet) +
          (state.invoice.sellBtc.length * rootState.settings.settings.itemFees.shipment) +
          (state.invoice.pix.length * rootState.settings.settings.itemFees.pix) +
          (state.invoice.recharges.length * rootState.settings.settings.itemFees.recharge) +
          (totalGiftCards * rootState.settings.settings.itemFees.giftCard);
    return Math.max(total, 0);
  },

  getBilletTotal: (state) => {
    return state.invoice.billets.reduce((acc, x) => {
      return acc + x.amount + x.addition - x.discount;
    }, 0);
  },

  getSellBtcTotal: (state) => {
    return state.invoice.sellBtc.reduce((acc, x) => {
      return acc + x.amount;
    }, 0);
  },

  getPixTotal: (state) => {
    return state.invoice.pix.reduce((acc, x) => {
      return acc + x.amount;
    }, 0);
  },

  getRechargesTotal: (state) => {
    return state.invoice.recharges.reduce((acc, x) => {
      return acc + x.amount;
    }, 0);
  },

  getGiftCardsTotal: (state) => {
    return state.invoice.giftCards.reduce((acc, x) => {
      return acc + (x.amount * x.quantity);
    }, 0);
  },
};
