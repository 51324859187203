
export default {
  SET_MODAL_VISIBILITY (state, payload) {
    state.modalVisible = payload;
  },
  SET_REGISTERING_USER (state, payload) {
    state.registeringUser = payload;
  },
  SET_REGISTER_FORM_ERRORS (state, payload) {
    state.registerFormErrors = payload;
  },
  SET_VERIFICATION_TOKEN (state, payload) {
    state.verificationToken = payload;
  },
  SET_REGISTRATION_STEP (state, payload) {
    state.registrationStep = payload;
  },
};
