<template>
  <div class="register-modal-component">
    <ct-modal v-if="modalVisible">
      <div slot="button">
        <div class="btn-close" @click="close">
          <span class="icon icon-close"></span>
        </div>
      </div>
      <div class="card-modal">
        <ct-register-form v-if="registrationStep === 'REGISTER'" />
        <ct-register-duplicate v-if="registrationStep === 'DUPLICATE'" />
        <ct-register-temp-email v-if="registrationStep === 'TEMPEMAIL'" />
        <ct-register-verify v-if="registrationStep === 'VERIFY' || registrationStep === 'VERIFY-ERROR'" />
        <ct-register-success v-if="registrationStep === 'SUCCESS'" />
        <ct-register-validating v-if="registrationStep === 'VALIDATING'" />
        <ct-register-error v-if="registrationStep === 'ERROR'" />
        <ct-register-error-temp v-if="registrationStep === 'ERROR-TEMP'" />
        <ct-register-phone-error
          v-if="
            registrationStep === 'PHONE-DUPLICATE' ||
            registrationStep === 'PHONE-INVALID'
          "
          :kind="registrationStep"
        />
        <ct-register-validation-error v-if="registrationStep === 'FIELDS-INVALID'" />
      </div>
    </ct-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CtModal from '../Shared/Modal';
import CtRegisterForm from '@/components/Register/Form';
import CtRegisterDuplicate from '@/components/Register/Duplicate';
import CtRegisterVerify from '@/components/Register/Verify';
import CtRegisterSuccess from '@/components/Register/Success';
import CtRegisterValidating from '@/components/Register/Validating';
import CtRegisterTempEmail from '@/components/Register/TempEmail';
import CtRegisterError from '@/components/Register/Error';
import CtRegisterPhoneError from '@/components/Register/PhoneError';
import CtRegisterValidationError from '@/components/Register/ValidationError';
import CtRegisterErrorTemp from '@/components/Register/ErrorTemp';

export default {
  name: 'RegisterModal',
  methods: {
    close () {
      this.$store.dispatch('closeRegisterModal');
    },
  },
  components: {
    CtModal,
    CtRegisterForm,
    CtRegisterDuplicate,
    CtRegisterVerify,
    CtRegisterSuccess,
    CtRegisterValidating,
    CtRegisterError,
    CtRegisterPhoneError,
    CtRegisterValidationError,
    CtRegisterTempEmail,
    CtRegisterErrorTemp,
  },
  computed: {
    ...mapState({
      registrationStep: state => state.register.registrationStep,
      modalVisible: state => state.register.modalVisible,
    }),
  },
};
</script>

<style lang="scss">
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.register-modal-component {
  .modal {
    .modal-action {
      @media only screen and (max-width: $grid-sm-max) {
        width: 80%;
      }
      .btn-close {
        background: $tertiary-color;
        position: absolute;
        cursor: pointer;
        z-index: 3000;
        @include flex-center();
        width: 51px;
        height: 51px;
        top: -25px;
        left: 372px;
        @media only screen and (max-width: $grid-sm-max) {
          width: 34px;
          height: 34px;
          top: 0px;
          left: 100%;
        }

        .icon {
          display: flex;
          background: $spotlight-color;
          width: 18px;
          height: 18px;
          @media only screen and (max-width: $grid-sm-max) {
            width: 12px;
            height: 12px;
          }
          &.icon-close {
            mask: url('/assets/img/icons/icon-close.svg')no-repeat center;
            mask-size: 100% 100%;
          }
        }
      }
    }

    .modal-content {
      @media only screen and (max-width: $grid-sm-max) {
        width: 100%;
        overflow-y: auto;
        background: $spotlight-color;
      }
      .card-modal {
        width: 800px;
        background: $spotlight-color;
        @media only screen and (max-width: $grid-sm-max) {
          width: 100%;
        }
      }
    }

    .form-group {
      .form-control {
        text-align: left;
        color: $spotlight-color;
        @media only screen and (max-width: $grid-sm-max) {

        }

        &::placeholder {
          color: $dark-grey-color;
          text-align: left;
        }

        &.is-invalid {
          background-color: $very-light-pink;
        }

        &.v-select {
          padding-left: 10px;
          background-color: #ffffff;

          &.vs--single {
            .vs__selected-options {
              .vs__selected {
                height: 100%;
              }
            }
          }

          .vs__dropdown-toggle {
            height: 100%;
            padding-bottom: 0;

            .vs__selected-options {
              padding: 0;
              display: flex;
              justify-content: left;

              .vs__search {
                font-size: 14px;
                @media only screen and (max-width: $grid-sm-max) {
                  font-size: 12px;
                }
              }

              .vs__selected {
                border: none;
                border-radius: 0;
                padding: 0;
                margin: 0;
                color: $spotlight-color;
                text-transform: uppercase;

                @media only screen and (max-width: $grid-xs-max) {
                  font: {
                    weight: 500;
                    size: 11px
                  }
                }
              }
            }

            .vs__actions {
              padding: 0 5px;

              .vs__clear {
                display: flex;
                justify-content: center;
              }
            }
          }

          span {
            text-align: left;
            color: $spotlight-color;
          }
        }
      }

      .msg-error {
        text-align: left;
        font-size: 11px;
        @media screen and (max-width: $grid-xs-max) {
          min-height: 17px;
        }
      }

      .msg-error-default {
        color: $secondary-color;
        background: $light-grey-color;
        font-size: 10px;
        min-height: 30px;
      }
    }

    .vs--disabled {
      background-color: #f8f8f8 !important;
      .vs__dropdown-toggle {
        background-color: transparent !important;
      }
      .vs__search {
        background-color: transparent !important;
      }
    }
  }
}

</style>
