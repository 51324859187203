<template>
  <a class="category-item">
    <div class="index-icon">
      <slot name="icon">

      </slot>
    </div>
    <div class="index-head">
      <h2 class="text-left">{{ title }}</h2>
      <div class="subcategory-list">
        <slot name="category-list">

        </slot>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'CategoryItem',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/themes/variables';

.category-item{
  height: 100%;
  display: block;
  position: relative;
  width: 100%;
  max-width: 260px;
  margin: 0 auto 20px;

  .index-icon{
    width: 20%;
    padding: 5px;
    display: inline-block;
    position: absolute;
    top: 0;
    height: 100%;
    padding-top: 33px;
    svg{
      max-width: 45px;
      max-height: 45px;
      display: block;
      margin: 0 auto;
      fill: $spotlight-color;
    }
  }
  .index-head{
    display: inline-block;
    width: 80%;
    margin-left: 20%;

    h2{
      font-size: 1.9em;
      margin-bottom: 5px;
    }
  }
}
</style>
