<template>
  <section class="service-app-component">
    <div class="container service-app-container">
      <div class="row service-app-row">
        <div class="col-lg-4 col-lg-offset-1 col-md-12 col-sm-12 col-xs-12 content">
          <h1>TODOS OS SERVIÇOS <br /> NA PALMA DA SUA MÃO</h1>
          <p>
            Pelo Aplicativo, você pode fazer um pedido com diversos itens dos mesmos serviços disponíveis em nossa
            plataforma com a mesma segurança e agilidade.
          </p>
        </div>
        <div class="col-lg-6 col-lg-offset-1 col-md-12 col-sm-12 col-xs-12 service-app">
          <img src="/assets/img/app/app-service.png" class="img-responsive" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ServicesApp',
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';
@import "../../themes/mixins/layout";

.service-app-component {
  margin-top: 80px;

  .service-app {
    display: flex;
    justify-content: center;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: $grid-md-max) {
      p {
        text-align: center;
      }
    }

    h1 {
      color: $secondary-color;
      margin-top: 0;
    }
  }
}
</style>
