<template>
  <div class="contact-intercom"></div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ContactIntercom',
  props: {},
  computed: {
    ...mapGetters(['isLogged', 'userData']),
  },
  async mounted () {
    if (this.$intercom) {
      this.$intercom.shutdown();
      const user = !this.isLogged ? {} : {
        user_id: this.userData.email,
        name: this.userData.name,
        email: this.userData.email,
      };
      await this.$intercom.boot(user);

      this.$intercom._call('onShow', async () => {
        if (this.$gtm && this.$gtm.enabled()) {
          await this.$gtm.trackEvent({
            category: 'contact',
            action: 'open-contact-form',
            label: this.userData.email || 'undefied',
          });
        }
      });

      this.$intercom._call('onHide', async () => {
        if (this.$gtm && this.$gtm.enabled()) {
          await this.$gtm.trackEvent({
            category: 'contact',
            action: 'close-contact-form',
            label: this.userData.email || 'undefied',
          });
        }
      });
    }
  },

  methods: {
  },

};
</script>

<style lang="scss" >
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.intercom-lightweight-app-launcher {
  @media only screen and (max-width: $grid-md-max) {
    bottom: 70px !important;
    right: 10px !important;;
  }
}

.contact-container-centred{
  @media print {
    display: none;
  }

  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  color: $white-color;

  .contact-tab{
    .btn-close {
      background: $tertiary-color;
      position: absolute;
      cursor: pointer;
      z-index: 3000;
      @include flex-center();
      width: 45px;
      height: 45px;
      top: -17px;
      right: -18px;

      .icon {
        display: flex;
        background: $spotlight-color;
        width: 18px;
        height: 18px;
        &.icon-close {
          mask: url('/assets/img/icons/icon-close.svg')no-repeat center;
          mask-size: 100% 100%;
        }
      }
    }

    width: 250px;
    height: auto;
    background-color: $spotlight-color;
    border-radius: 4px 4px 0 0;
    border: $white-color solid;
    border-width: 1px 1px 0;
    position: absolute;
    right: 0;
    bottom: 0;

    .tab-header {
      top:0;
      cursor: pointer;
      display: flex;

      .tab-header-content {
        font-size: 18px;
        font-family: 'Roboto', sans-serif;
        color: $white-color;
        width: 100%;
        padding: 10px 25px;
        align-items: center;

        .conversation-icon {
          background: url("/assets/img/icons/conversation-ballon.png") no-repeat;
          width: 28px;
          height: 24px;
          display: inline-block;
          margin-right: 15px;
        }

        p {
          display: inline-block;
          height: 100%;
          vertical-align: middle;
          margin-bottom: 0;

          @media print {
            display: none;
          }
        }
      }

    }

    .contact-form {
      padding: 10px 25px;
      display: block;
      margin-bottom: 20px;
    }

  }
  @media only screen and (max-width: $grid-md-max) {
    display: none;
  }

}

</style>
