import SettingsService from '@/services/SettingsService';

export default {
  async getCurrentSettings ({ commit }) {
    const settingsSite = await SettingsService.getCurrentSettings();
    commit('SET_SETTINGS', settingsSite);
  },
  async setTickerValue ({ commit }, data) {
    commit('SET_GLOBAL_TICKER', data);
  },
};
