<template>
<div class="gateway-page-component">
  <ct-anchor anchor="formGateway">
    <ct-banner :banner="banner" />
  </ct-anchor>
  <ct-gateway-content />
  <ct-form />
</div>
</template>

<script>
import CtAnchor from '@/components/Shared/Anchor';
import CtBanner from '@/components/Banner/Banner';
import CtGatewayContent from '@/components/Gateway/GatewayContent';
import CtForm from '@/components/Gateway/Form';
import config from '@/config';

export default {
  name: 'GatewayPage',
  data () {
    return {
      banner: {
        textAlt: 'Gateway de pagamento. Aceite bitcoins e receba em reais',
        imgUrl: '/assets/img/banners/banner-gateway.jpg',
        imgUrlMobile: '/assets/img/banners/banner-gateway-mobile.jpg',
      },
      pageInfo: {
        title: 'Gateway de pagamento',
        description: 'Gateway de pagamento Bitcoin com conversão automática para Reais. Receba pagamentos em bitcoin com nosso gateway de pagamento multimoeda.',
      },
    };
  },
  metaInfo () {
    return {
      title: this.pageInfo.title,
      meta: [
        { name: 'description', content: this.pageInfo.description },
        { name: 'og:description', content: this.pageInfo.description },
        { name: 'og:image', content: config.app.url + this.banner.imgUrl },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.pageInfo.title },
        { name: 'twitter:description', content: this.pageInfo.description },
        { name: 'twitter:image', content: config.app.url + this.banner.imgUrl },
      ],
    };
  },
  components: {
    CtAnchor,
    CtBanner,
    CtGatewayContent,
    CtForm,
  },
};
</script>

<style></style>
