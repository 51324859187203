<template>
  <section class="recharge-component">
    <div class="container recharge-container">
      <div class="row recharge-row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 content">
          <h1>Recarga de celular <br /> com <span class="emphasis-variant">Bitcoin</span>.</h1>
          <p>
            Recarregue online seu celular pré pago utilizando seus bitcoins de forma fácil e rápida.
          </p>
          <p>
            Nós fazemos a conversão de bitcoins para reais com base na última cotação média de mercado.
          </p>
          <p>
            O prazo de recarga é em média de 5 minutos após a confirmação da transação por meio da carteira de sua
            preferência.
          </p>
          <p>
            Você pode recarregar qualquer celular pré-pago das principais operadoras do território nacional.
          </p>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 recharge">
          <card-image title="Como Funciona?">
            <template slot="img">
               <img src="/assets/img/home/smartphone.png" class="image-card" alt="Recarregue seu celular" />
            </template>
            <template slot="body">
               <p class="title-4">Recarga rápida<p>
              <div class="card-sub-title title-5">Em média 5 minutos
                <br>
                <span>Após a confirmação</span>
              </div>
              <p class="title-4">Confirmação de recarga</p>
              <div class="card-sub-title title-5">SMS e e-mail</div>
            </template>
            <template slot="action">
              <router-link :to="{ name: 'service-cell-phone' }" class="btn btn--secondary">Recarregue já</router-link>
            </template>
          </card-image>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CardImage from '../Shared/CardImage';

export default {
  name: 'Recharge',
  components: {
    CardImage,
  },
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';
@import "../../themes/mixins/layout";

.recharge-component {
  min-height: 380px;
  margin-bottom: 100px;
  @media only screen and (max-width: $grid-md-max) {
    margin-bottom: 0
  }
  @media only screen and (max-width: $grid-xs-max) {
    min-height: 0
  }
  .recharge-container {
    max-width: 1000px;
    .recharge-row {
      margin: 0;
      .content {
        @media only screen and (max-width: $grid-md-max) {
          text-align: center;
        }
        h1 {
          min-width: 448px;
          margin-bottom: 20px;
          text-align: left;
          @media only screen and (max-width: $grid-md-max) {
            margin:0 auto 20px;
            min-width: 255px;
            text-align: center;
          }
          @media only screen and (max-width: $grid-xs-max) {
            margin: 0 auto 20px auto;
            font-size: 26px
          }
        }
        p {
          width: 375px;
          @media only screen and (max-width: $grid-md-max) {
            width: 100%;
            min-width: 255px;
            margin: 0 auto 20px auto;
          }
        }

        .content-action {
          display: flex;
          margin: 10px 0;
          @media only screen and (max-width: $grid-md-max) {
            justify-content: center
          }
        }
      }
      .recharge {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        min-height: 450px;
        @media only screen and (max-width: $grid-md-max) {
          align-items: center;
          justify-content: center;
        }
        @media only screen and (max-width: $grid-xs-max) {
          align-items: flex-end;
          justify-content: center;
          min-height: 290px
        }

        .image-card {
          position: absolute;
          right: 314px;
          bottom: -92px;
          @media only screen and (max-width: $grid-md-max) {
            right: 300px;
          }
          @media only screen and (max-width: $grid-xs-max) {
            width: 124px;
            height: auto;
            right: 180px;
            bottom: -62px
          }
        }
      }
    }
  }
}
</style>
