export default {
  SET_OPERATORS_FOR_SELECT (state, payload) {
    state.operatorsRecharge = payload;
  },
  SET_AMOUNTS_FOR_SELECT (state, payload) {
    state.amountsRecharge = payload;
  },
  SET_GIFT_CARD_OPERATORS_FOR_SELECT (state, payload) {
    state.operatorsGiftCard = payload;
  },
  SET_GIFT_CARD_AMOUNTS_FOR_SELECT (state, payload) {
    const value = {};
    value[payload.operator] = payload.amounts;
    state.amountsGiftCard = { ...state.amountsGiftCard, ...value };
  },
};
