<template>
  <section class="invoice-status-component">
    <div class="container">
      <h1 class="invoice-status-title">
        Você ainda não realizou nenhum pedido
      </h1>

      <p>
        Na PagueComBitcoin você pode utilizar seus Bitcoins para:
        <br>
        <br>- Pagar boletos.
        <br>- Realizar Conversão de Bitcoins para Reais por meio de transferência para sua própria conta bancária ou de terceiros.
        <br>- Recarregar celular pré-pago, próprio ou de terceiros, das principais operadoras.
      </p>

      <router-link :to="{ name: 'services' }" class="btn btn-action">
        Quero fazer um pedido
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: 'InvoiceStatus',
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';
@import '../../themes/mixins/layout';

.invoice-status-component {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 50px;

  @media only screen and (max-width: $grid-sm-max) {
    margin: 0 10px 25px
  }

  .container {
    .invoice-status-title {
      font-size: 34px;
      margin-bottom: 35px;
      text-align: center;

      @media only screen and (max-width: $grid-sm-max) {
        display: none
      }

      @media print {
        display: none;
      }
    }

    p {
      font-size: 20px;
      line-height: 1.6em;
      font-weight: 300;

      @media only screen and (max-width: $grid-xs-max) {
        font-size: 12px;
        line-height: 1.2em;
      }
    }

    .btn-action {
      margin: 30px auto 0;
      display: block;
      max-width: fit-content;
      padding: 15px 50px;
    }
  }
}

</style>
