<template>
<section class="form-component" id="formGateway">
  <div class="container">

    <form @submit.prevent>
      <div class="row">
        <div class="form-group single-input">
          <input
            type="text"
            placeholder="NOME COMPLETO*"
            class="form-control"
            name="name"
            id="name"
            v-model="form.name"
            v-validate="{required: true}"
            data-vv-as="NOME"
            :class="{'is-invalid': errors.has('name')}"
          />
          <div class="invalid-feedback" v-show="errors.has('name')">{{ errors.first('name') }}</div>
        </div>
        <div class="form-group">
          <div class="form-inline">
            <the-mask
              class="form-control"
              id="cpf"
              name="cpf"
              mask="###.###.###-##"
              :masked="false"
              placeholder="CPF*"
              v-model="form.document"
              v-validate="{required: true}"
              data-vv-as="CPF"
              :class="{'is-invalid': errors.has('cpf')}"
            ></the-mask>
            <div class="invalid-feedback" v-show="errors.has('cpf')">{{ errors.first('cpf') }}</div>
          </div>
          <div class="form-inline">
            <the-mask
              mask="##.###.###-#"
              class="form-control"
              id="documentSecondary"
              name="documentSecondary"
              :masked="false"
              placeholder="RG*"
              v-model="form.document_secondary"
              v-validate="{required: true}"
              data-vv-as="RG"
              :class="{'is-invalid': errors.has('documentSecondary')}"
            ></the-mask>
            <div class="invalid-feedback" v-show="errors.has('documentSecondary')">{{ errors.first('documentSecondary') }}</div>
          </div>
        </div>
        <div class="form-group single-input">
          <input
            type="email"
            placeholder="E-MAIL*"
            class="form-control"
            name="email"
            id="email"
            v-model="form.email"
            v-validate="{required: true, email: true}"
            data-vv-as="E-MAIL"
            :class="{'is-invalid': errors.has('email')}"
          />
          <div class="invalid-feedback" v-show="errors.has('email')">{{ errors.first('email') }}</div>
        </div>
        <div class="form-group">
          <div class="form-inline">
            <the-mask
              mask="(##) #####-####"
              :masked="true"
              placeholder="CELULAR*"
              class="form-control"
              name="cellphone"
              id="cellphone"
              v-model="form.phone"
              v-validate="{required: true}"
              data-vv-as="CELULAR"
              :class="{'is-invalid': errors.has('cellphone')}"
            />
            <div class="invalid-feedback" v-show="errors.has('cellphone')">{{ errors.first('cellphone') }}</div>
          </div>
          <div class="form-inline">
            <the-mask
              mask="(##) ####-####"
              :masked="true"
              placeholder="TELEFONE"
              class="form-control"
              name="telephone"
              id="telephone"
              v-model="form.phone_secondary"
            />
          </div>

        </div>
        <div class="form-group">
          <div class="form-inline">
            <the-mask
              mask="#####-###"
              placeholder="CEP*"
              class="form-control"
              name="zip_code"
              id="zip_code"
              v-model="form.addresses[0].zip_code"
              v-validate="{required: true}"
              data-vv-as="CEP"
              @input="findAddressMain(form.addresses[0].zip_code)"
              :class="{'is-invalid': errors.has('zip_code')}"
            />
            <div class="invalid-feedback" v-show="errors.has('zip_code')">{{ errors.first('zip_code') }}</div>
          </div>
          <div class="form-inline">
            <input
              type="text"
              placeholder="ENDEREÇO*"
              class="form-control"
              name="address"
              id="address"
              v-model="form.addresses[0].address"
              v-validate="{required: true}"
              data-vv-as="ENDEREÇO"
              :class="{'is-invalid': errors.has('address')}"
            />
            <div class="invalid-feedback" v-show="errors.has('address')">{{ errors.first('address') }}</div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-inline">
            <input
              ype="text"
              placeholder="CIDADE*"
              class="form-control"
              name="city"
              id="city"
              v-model="form.addresses[0].city"
              v-validate="{required: true}"
              data-vv-as="CIDADE"
              :class="{'is-invalid': errors.has('city')}"
            />
            <div class="invalid-feedback" v-show="errors.has('city')">{{ errors.first('city') }}</div>
          </div>
          <div class="form-inline">
            <input
              ype="text"
              placeholder="NÚMERO*"
              class="form-control"
              name="number"
              id="number"
              v-model="form.addresses[0].number"
              v-validate="{required: true}"
              data-vv-as="NÚMERO"
              :class="{'is-invalid': errors.has('number')}"
            />
            <div class="invalid-feedback" v-show="errors.has('number')">{{ errors.first('number') }}</div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-inline">
            <input
              type="text"
              placeholder="BAIRRO*"
              class="form-control"
              name="district"
              id="district"
              v-model="form.addresses[0].district"
              v-validate="{required: true}"
              data-vv-as="BAIRRO"
              :class="{'is-invalid': errors.has('district')}"
            />
            <div class="invalid-feedback" v-show="errors.has('district')">{{ errors.first('district') }}</div>
          </div>
          <div class="form-inline">
            <input
              type="text"
              placeholder="COMPLEMENTO"
              class="form-control"
              name="complement"
              id="complement"
              v-model="form.addresses[0].complement"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="form-inline">
            <ct-select
              class="form-control"
              :class="{'is-invalid': errors.has('uf')}"
              name="uf"
              id="uf"
              placeholder="ESTADO*"
              v-model="form.addresses[0].uf"
              :options="states"
              :reduce="state => state.value"
              v-validate="{ required: true }"
              data-vv-as="ESTADO"
            />
            <div class="invalid-feedback" v-show="errors.has('uf')">{{ errors.first('uf') }}</div>
          </div>
          <div class="form-inline">
          </div>
        </div>
        <div class="form-group check-group">
          <div class="checkbox" :class="{'checked': form.is_company}">
            <input type="checkbox" id="is_company" :value="true" v-model="form.is_company" />
          </div>
          <label for="is_company">Eu concordo em receber notificações e ofertas personalizadas de acordo com os meus interesses.</label>
        </div>

        <div class="row" v-if="form.is_company">
          <div class="form-group single-input">
            <input
              type="text"
              placeholder="SITE OU LOJA VIRTUAL*"
              class="form-control"
              name="website"
              id="website"
              v-model="form.company.website"
              v-validate="{required: true, url: true}"
              data-vv-as="NOME"
              :class="{'is-invalid': errors.has('website')}"
            />
            <div class="invalid-feedback" v-show="errors.has('website')">{{ errors.first('website') }}</div>
          </div>
          <div class="form-group single-input">
            <input
              type="email"
              placeholder="E-MAIL*"
              class="form-control"
              name="companyEmail"
              id="companyEmail"
              v-model="form.company.email"
              v-validate="{required: true, email: true}"
              data-vv-as="E-MAIL"
              :class="{'is-invalid': errors.has('companyEmail')}"
            />
            <div class="invalid-feedback" v-show="errors.has('companyEmail')">{{ errors.first('companyEmail') }}</div>
          </div>
          <div class="form-group">
            <div class="form-inline">
              <the-mask
                class="form-control"
                id="cnpj"
                name="cnpj"
                mask="##.###.###/####-##"
                :masked="false"
                placeholder="CNPJ*"
                v-model="form.company.document"
                v-validate="{required: true}"
                data-vv-as="CNPJ"
                :class="{'is-invalid': errors.has('cnpj')}"
              ></the-mask>
              <div class="invalid-feedback" v-show="errors.has('cnpj')">{{ errors.first('cnpj') }}</div>
            </div>
            <div class="form-inline">
              <input
                type="text"
                class="form-control"
                id="nameCompany"
                name="nameCompany"
                :masked="false"
                placeholder="RAZÃO SOCIAL*"
                v-model="form.company.name"
                v-validate="{required: true}"
                data-vv-as="RAZÃO SOCIAL"
                :class="{'is-invalid': errors.has('nameCompany')}"
              >
              <div class="invalid-feedback" v-show="errors.has('nameCompany')">{{ errors.first('nameCompany') }}</div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-inline">
              <the-mask
                mask="#####-###"
                placeholder="CEP*"
                class="form-control"
                name="zipCodeCompany"
                id="zipCodeCompany"
                v-model="form.company.addresses[0].zip_code"
                v-validate="{required: true}"
                data-vv-as="CEP"
                @input="findAddressCompany(form.company.addresses[0].zip_code)"
                :class="{'is-invalid': errors.has('zipCodeCompany')}"
              />
              <div class="invalid-feedback" v-show="errors.has('zipCodeCompany')">{{ errors.first('zipCodeCompany') }}</div>
            </div>
            <div class="form-inline">
              <input
                type="text"
                placeholder="ENDEREÇO*"
                class="form-control"
                name="addressCompany"
                id="addressCompany"
                v-model="form.company.addresses[0].address"
                v-validate="{required: true}"
                data-vv-as="ENDEREÇO"
                :class="{'is-invalid': errors.has('addressCompany')}"
              />
              <div class="invalid-feedback" v-show="errors.has('addressCompany')">
                {{ errors.first('addressCompany') }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-inline">
              <input
                ype="text"
                placeholder="CIDADE*"
                class="form-control"
                name="cityCompany"
                id="cityCompany"
                v-model="form.company.addresses[0].city"
                v-validate="{required: true}"
                data-vv-as="CIDADE"
                :class="{'is-invalid': errors.has('cityCompany')}"
              />
              <div class="invalid-feedback" v-show="errors.has('cityCompany')">{{ errors.first('cityCompany') }}</div>
            </div>
            <div class="form-inline">
              <input
                ype="text"
                placeholder="NÚMERO*"
                class="form-control"
                name="numberCompany"
                id="numberCompany"
                v-model="form.company.addresses[0].number"
                v-validate="{required: true}"
                data-vv-as="NÚMERO"
                :class="{'is-invalid': errors.has('numberCompany')}"
              />
              <div class="invalid-feedback" v-show="errors.has('numberCompany')">{{ errors.first('numberCompany') }}</div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-inline">
              <input
                type="text"
                placeholder="BAIRRO*"
                class="form-control"
                name="districtCompany"
                id="districtCompany"
                v-model="form.company.addresses[0].district"
                v-validate="{required: true}"
                data-vv-as="BAIRRO"
                :class="{'is-invalid': errors.has('districtCompany')}"
              />
              <div class="invalid-feedback" v-show="errors.has('districtCompany')">
                {{ errors.first('districtCompany') }}
              </div>
            </div>
            <div class="form-inline">
              <input
                type="text"
                placeholder="COMPLEMENTO"
                class="form-control"
                name="complementCompany"
                id="complementCompany"
                v-model="form.company.addresses[0].complement"
              />
            </div>

          </div>
          <div class="form-group">
            <div class="form-inline">
              <ct-select
                class="form-control"
                :class="{'is-invalid': errors.has('ufCompany')}"
                name="ufCompany"
                id="ufCompany"
                placeholder="ESTADO*"
                v-model="form.company.addresses[0].uf"
                :options="states"
                :reduce="state => state.value"
                v-validate="{ required: true }"
                data-vv-as="ESTADO"
              />
              <div class="invalid-feedback" v-show="errors.has('ufCompany')">{{ errors.first('ufCompany') }}</div>
            </div>
            <div class="form-inline">
            </div>
          </div>
        </div>

        <div class="form-group single-input">
          <div class="file-group" v-if="files.length === 0">
            <div class="row-group">
              <input ref="file" type="file" accept=".jpg, .png, .pdf" @change="changeFiles" multiple />
              <div class="col-left">
                <button class="btn btn--primary">Enviar Documentos</button>
              </div>
              <div class="col-right">
                <p>
                  Verifique acima a lista de documentos obrigatórios para cadastro e faça o upload de arquivos.
                </p>
              </div>
            </div>
          </div>
          <div class="field-list" v-if="files.length > 0">
            <ul class="file-list">
              <li class="file-item" v-for="(file, key) in files" :key="key">
                <img :src="file.image" alt="" v-if="file.image" class="img-file-info" />
                <span class="file-info" v-else>
                  <p><span>{{ file.sizeMb }}</span> MB</p>
                  <p>{{ file.name }}</p>
                </span>
                <span class="icon-delete" @click="clearFile(key)" title="Remover arquivo" ></span>
              </li>
            </ul>
            <div class="content-more-upload">
              <input ref="addfile" type="file" accept=".jpg, .png, .pdf" @change="addFiles" multiple />
              <p><span>Clique aqui</span> para enviar mais arquivos</p>
            </div>
          </div>
          <div class="invalid-feedback text-error-files">
            {{ errorFiles ? 'É necessário selecionar ao menos 4 arquivos' : '' }}
          </div>
        </div>

        <div class="form-group">
          <button class="btn btn--secondary" @click="save">
            Realizar pré-cadastro
          </button>
        </div>

        <div class="form-group">
          <div class="alert alert-success" v-if="success">
            Pré-cadastro efetuado com sucesso!
          </div>
          <div class="alert alert-error" v-if="error">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </form>

  </div>
</section>
</template>

<script>
import { TheMask } from 'vue-the-mask';
import { mapState } from 'vuex';
import GatewayService from './../../services/GatewayService';
import AddressService from './../../services/AddressService';
import CtSelect from '../Shared/Select';

export default {
  name: 'Form',
  components: {
    CtSelect,
    TheMask,
  },
  data: () => ({
    form: {
      files: [],
      is_company: false,
      addresses: [{
        country: 'Brasil',
      }],
      company: {
        addresses: [{
          country: 'Brasil',
        }],
      },
    },
    files: [],
    filesHashs: [],
    errorFiles: false,
    error: false,
    errorMessage: '',
    success: false,
  }),
  created () {
    this.errorMessage = this.$t('gateway.errorDefaultMessage');
  },
  computed: {
    ...mapState({
      states: state => state.select.states || {},
    }),
  },
  methods: {
    async changeFiles () {
      const files = Array.from(this.$refs.file.files);
      await this.uploadFiles(files);
      this.verifyFilesError();
    },
    async uploadFiles (files) {
      try {
        for (let file of files) {
          const res = await GatewayService.uploadFile(file);
          this.form.files.push(res.data.hash);
        }
        this.files = [...this.files, ...this.mapFiles(files)];
      } catch (e) {
        this.errorMessage = this.$t('gateway.errorFilesMessage');
        this.error = true;
        this.clearAllFiles();
      } finally {
        this.verifyFilesError();
      }
    },
    async addFiles () {
      const files = Array.from(this.$refs.addfile.files);
      await this.uploadFiles(files);
    },
    clearAllFiles () {
      this.files = [];
      this.filesHashs = [];
      this.form.files = [];
    },
    clearFile (index) {
      this.files.splice(index, 1);
      this.filesHashs.splice(index, 1);
      this.form.files.splice(index, 1);
      this.verifyFilesError();
    },

    clearFiles () {
      this.files = [];
    },
    mapFiles (files) {
      return files.map((file) => {
        if (file.type !== 'application/pdf') {
          file.image = URL.createObjectURL(file);
        } else {
          file.image = false;
        }

        file.sizeMb = (file.size / 100000).toFixed(2);
        return file;
      });
    },
    verifyFilesError () {
      this.errorFiles = this.files.length < 4;
    },
    async populateAddress (form, res) {
      const addressItem = {
        district: res.bairro,
        complement: '',
        city: res.localidade,
        uf: res.uf,
        address: res.logradouro,
      };

      this.$set(form.addresses, '0', { ...form.addresses[0], ...addressItem });
    },
    async findAddressMain (zipCode) {
      try {
        zipCode = zipCode.replace(/( |\.|-)/g, '');
        if (zipCode.length === 8) {
          const res = await AddressService.findAddress(zipCode);
          this.populateAddress(this.form, res);
          this.$nextTick(async () => {
            await this.$validator.validateAll(['city', 'address', 'complement', 'district', 'uf']);
          });
        }
      } catch (e) {
      }
    },
    async findAddressCompany (zipCode) {
      try {
        zipCode = zipCode.replace(/( |\.|-)/g, '');
        if (zipCode.length === 8) {
          const res = await AddressService.findAddress(zipCode);
          this.populateAddress(this.form.company, res);
          this.$nextTick(async () => {
            await this.$validator.validateAll([
              'cityCompany',
              'addressCompany',
              'complementCompany',
              'districtCompany',
              'ufCompany',
            ]);
          });
        }
      } catch (e) {
      }
    },
    async save () {
      let isValid = await this.$validator.validate();
      if (this.files.length < 4) {
        this.errorFiles = true;
        isValid = false;
      }

      if (isValid) {
        const rand = Math.floor(Math.random() * 1000000000);
        this.form.signature = `Pcb@${rand}-${this.form.document.replace(/[.-]/g, '')}`;
        const data = this.form;

        if (!data.is_company) {
          delete data.company;
        }
        try {
          await GatewayService.create(data);
          this.success = true;
          await this.resetForm();
          if (this.$gtm && this.$gtm.enabled()) {
            await this.$gtm.trackEvent({
              category: 'gateway',
              action: 'gateway-register',
              label: 'gateway-registered',
            });
          }
        } catch (error) {
          const res = error.response;
          this.errorMessage = 'Erro ao tentar salvar, tente novamente mais tarde ou contate o suporte!';
          if (res.status === 422) {
            const message = res.data && res.data.message ? res.data.message : '';
            let fields = '';
            if (res.data.errors && res.data.errors.fields) {
              fields = `${res.data.errors.fields.map(i => this.$t(`register.steps.fields.${i.field}`)).join(', ')}`;
            }
            this.errorMessage = fields ? `${message}: ${fields}.` : message;
          }
          this.error = true;
        }
      }
    },
    async resetForm () {
      this.form = {
        is_company: false,
        addresses: [{
          country: 'Brasil',
        }],
        company: {
          addresses: [{
            country: 'Brasil',
          }],
        },
      };
      this.clearAllFiles();
      this.errorMessage = '';
      this.error = false;
      await this.$validator.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';
@import '../../themes/mixins/layout';

.form-component {
  @media only screen and (max-width: $grid-sm-max) {
    padding: 0 20px;
  }
  ::v-deep {
    .v-select {
      input {
        text-transform: uppercase;
        font-family: inherit;
        color: #003058;
        &::placeholder {
          color: #003058 !important;
        }
      }
      .vs__dropdown-toggle {
        padding: 0 0 4px 5px;
      }
      .vs__selected {
        z-index: 2;
        color: #003058;
        margin: -10px;
      }
    }
  }
  .container {
    form {
      width: 100%;
      max-width: 640px;
      margin: 0 auto 95px;
      background-color: $spotlight-color;
      padding: 12px 15px 40px;

      @media only screen and (max-width: $grid-xs-max) {
        margin-bottom: 50px;
      }
      .row {
        padding: 0;
        margin: 0;

        .form-group {
          width: 100%;
          display: flex;

          @media only screen and (max-width: $grid-sm-max) {
            flex-wrap: wrap;
          }

          &.single-input {
            flex-direction: column;
          }

          .form-inline {
            width: 50%;

            @media only screen and (max-width: $grid-sm-max) {
              width: 100%;
            }

            &:first-child {
              margin-right: 5px;

              @media only screen and (max-width: $grid-sm-max) {
                margin-right: 0;
              }
            }
            &:last-child {
              margin-left: 5px;

              @media only screen and (max-width: $grid-sm-max) {
                margin-left: 0;
              }
            }
          }

          .form-control {
            width: 100%;
            height: 37px;
            background-color: $ice-color;
            margin-top: 10px;
            outline: none;
            text-indent: 15px;
            color: $spotlight-color;
            line-height: 44.67px;
            font: {
              size: 13px;
              weight: 400;
            }

            &::placeholder {
              color: $spotlight-color;
            }

            &.is-invalid {
              background-color: $very-light-pink;
            }
          }
          .invalid-feedback {

            &.text-error-files {
              text-align: center;
              margin: 0 0 10px;
            }
          }

          &.check-group {
            margin: 16px 0 8px;
            justify-content: center;
            align-items: center;

            .checkbox {
              width: 20px;
              height: 20px;
              background-color: $ice-color;

              input[type='checkbox'] {
                width: 20px;
                height: 20px;
                opacity: 0;

                &:hover {
                  cursor: pointer;
                }
              }

              &.checked {
                background-image: url('/assets/img/check-icon.svg');
                background-position: center center;
                background-size: 20px 20px;
                background-repeat: no-repeat;
              }
            }

            label {
              color: $white-color;
              font-size: 13px;
              font-weight: 400;
              text-transform: uppercase;
              margin-left: 10px;
            }
          }

          .file-group {
            width: 100%;
            margin: 40px auto;
            display: flex;
            justify-content: center;

            @media only screen and (max-width: $grid-xs-max) {
              flex-direction: column;
            }

            * {
              &:hover {
                cursor: pointer;
              }
            }

            .row-group {
              width: 100%;
              display: flex;
              align-items: center;
              position: relative;

              @media only screen and (max-width: $grid-sm-max) {
                flex-wrap: wrap;
                justify-content: center;
              }

              .col-left {
                width: 40%;
                @media only screen and (max-width: $grid-sm-max) {
                  width: 100%;

                  .btn {
                    display: block;
                  }
                }
              }

              .col-right {
                width: 60%;
                margin-left: 30px;

                @media only screen and (max-width: $grid-sm-max) {
                  width: 100%;
                  margin-left: 0;
                  margin-top: 20px;
                }

                p {
                  max-width: 100%;
                  margin-bottom: 0;
                  color: $white-color;
                  font-size: 12px;
                  font-weight: 400;
                  text-transform: uppercase;

                  @media only screen and (max-width: $grid-sm-max) {
                    max-width: 100%;
                    margin: auto;
                    text-align: center;
                  }
                }
              }
            }
          }

          .field-list {
            width: 100%;
            margin-top: 15px;

            .file-list {
              .file-item {
                display: inline-block;
                position: relative;

                .img-file-info {
                  width: 60px;
                  height: 60px;
                  border-radius: 5px;
                  margin: 5px;
                  object-fit: cover;
                }

                .file-info {
                  width: 60px;
                  height: 60px;
                  border-radius: 5px;
                  margin: 5px;
                  padding: 10px 5px;
                  background-color: $ice-color;
                  color: $black-color;
                  display: block;

                  p {
                    width: 50px;
                    white-space: nowrap;
                    overflow: hidden;
                    font-family: 'Roboto', sans-serif;
                    text: {
                      overflow: ellipsis;
                      align: center;
                    }

                    + p {
                      margin-top: 5px;
                    }

                    span {
                      font-weight: 700;
                    }
                  }
                }

                .icon-delete {
                  width: 15px;
                  height: 15px;
                  position: absolute;
                  top: 0;
                  right: 0;
                  cursor: pointer;
                  background: url('/assets/img/icons/delete-icon.png')no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            .content-more-upload {
              width: 100%;
              height: 30px;
              color: $white-color;
              margin: 15px auto;
              position: relative;
              @include flex-center;

              *:hover {
                cursor: pointer;
              }

              span {
                color: $primary-color;
                text-decoration: underline;
              }
            }
          }

          .btn {
            margin: auto;
            font-size: 17px;
            padding: 6px 20px;

            &.btn-file {

              font-weight: 500;
              background-color: $primary-color;
              color: $spotlight-color;
            }

            &.btn-submit {
              font-weight: 400;
              background-color: $secondary-color;
              color: $white-color;
            }
          }

          input[type="file"] {
            -webkit-tap-highlight-color: transparent;
            width: 100%;
            height: 100%;
            opacity: 0;
            position: absolute;
            outline: none;
          }

          .alert {
            height: auto;
            max-width: 400px;
            margin: 15px auto;
            border-radius: 2px;
          }
        }
      }
    }
  }
}

</style>
