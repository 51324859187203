<template>
  <section class="services-intro">
    <div class="container">
      <h1>
        {{ $t('services.title.page.cellPhone.title') }}
        <span class="emphasis-variant">
          {{ $t('services.title.page.cellPhone.crypto') }}
        </span>
      </h1>

      <p>
        Com a PagueComBitcoin você também pode fazer a <span class="weight-bold">Recarga de Celular Pré-pago</span> das
        principais operadoras de telefonia (Claro, Nextel, Oi, Tim, Vivo, etc) utilizando seus bitcoins em apenas
        alguns minutos.
      </p>

      <h2>Como recarregar celular com <span class="emphasis-variant">bitcoins</span>?</h2>

      <p>
        Faça seu
        <a href="#" @click.prevent="openLoginModal" class="text weight-bold">Login</a> com email e senha ou
        <a class="weight-bold" @click="openRegisterModal">Cadastre-se aqui</a>.
      </p>
      <p>
        Selecione no <span class="weight-bold">Menu de Serviços</span> acima o item
        <span class="weight-bold">Recarga de celular</span>. Você pode  "+ Adicionar" a quantidade de itens
        que desejar no mesmo pedido ou “- Excluir” um item de serviço.
      </p>
      <p>
        <span class="weight-bold">Preencha todos os campos</span> com o DDD, o Número do celular, a Operadora e o
        valor de recarga em reais. Clique no botão
        <span class="weight-bold">Finalizar Pedido</span> para ser direcionado à página de
        <span class="weight-bold">Checkout</span>.
      </p>
      <p>
        Confira as informações do <span class="weight-bold">Valor do Pedido em BTC</span>, Cotação Bitcoin e Valor
        em Real. O <span class="weight-bold">Valor Confirmado</span> BTC será atualizado após confirmação.
      </p>
      <p>
        Para garantirmos o valor da cotação, você deve <span class="weight-bold">realizar a transação de bitcoins em
        até <span class="emphasis-variant">{{ settings && settings.expireInvoice ? settings.expireInvoice : '?' }} minutos</span></span> por meio das
        seguintes opções:
      </p>
      <ul class="simple-list">
        <li><span class="weight-bold">Copiar e colar  o código</span> para realizar o envio;</li>
        <li>Clicar no botão <span class="weight-bold">Abrir Carteira</span>, caso tiver uma carteira bitcoin instalada no mesmo dispositivo;</li>
        <li>Realizar a <span class="weight-bold"> leitura do QRCode</span> pela carteira instalada no seu dispositivo móvel. </li>
      </ul>

      <p>
        Após realizar a transação do <span class="weight-bold">Valor do Pedido em BTC</span> da sua carteira para a
        carteira da PagueComBitcoin, você será comunicado por e-mail com o processamento e confirmação do pagamento.
      </p>
      <p class="weight-bold">
        Você também pode acompanhar o status do seu
        <router-link class="weight-bold emphasis-variant" :to="{ name: 'checkout-last' }">último pedido</router-link>
        pelo link ou atalho da área de usuário logada.
      </p>

      <p class="weight-bold">
        Verifique seu e-mail e acompanhe as mensagens de cada etapa do seu pedido.
      </p>

      <h2>
        Prazos e valores
      </h2>

      <p>
        O valor mínimo e máximo para recarga de celular variam de acordo com a operadora de telefonia e área DDD. Não
        existe limite de quantidade de recargas, ou seja, <span class="text-underline">você pode realizar quantas recargas precisar em um ÚNICO
        PEDIDO.</span>
      </p>

      <p>
        Após a confirmação da transação, realizamos o processamento da recarga junto à operadora. Assim que a operadora
        confirmar a operação, sua recarga será realizada e um email de confirmação será enviado. O tempo médio de
        recarga é de 5 minutos independente de horário e de dia da semana. Em caso de indisponibilidade da operadora,
        algumas solicitações podem sofrer atraso de até 24h até a normalização do serviço.
      </p>

      <p>
        Cobramos uma <span class="weight-bold">taxa de conveniência de
          <span class="emphasis-variant">R$ {{ (settings.itemFees && settings.itemFees.recharge ? settings.itemFees.recharge : 0) | currency }}</span></span> para cada item de Recarga de celular, ou seja, cada
          operação. Mais em <router-link :to="{ name: 'taxes' }">Taxas e Informações</router-link>.
      </p>

      <p class="weight-bold">
        Para acompanhar seu pedido, verifique seu e-mail de cadastro.
      </p>

    </div>

    <ct-user-login-modal
      :isModalVisible="isModalLoginVisible"
      @close="closeLoginModal"
      @successLogin="closeLoginModal"
    />

  </section>
</template>

<script>
import currency from './../../filters/currency';
import { mapActions, mapState } from 'vuex';
import CtUserLoginModal from '../Navbar/UserLoginModal';

export default {
  name: 'ServicesIntroBillet',
  filters: {
    currency,
  },
  components: {
    CtUserLoginModal,
  },
  data () {
    return {
      isModalLoginVisible: false,
    };
  },
  methods: {
    ...mapActions(['openRegisterModal']),
    openLoginModal () {
      this.isModalLoginVisible = true;
    },
    closeLoginModal () {
      this.isModalLoginVisible = false;
    },
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.settings,
    }),
  },
};
</script>

<style lang="scss" scoped>

</style>
