<template>
  <div class="maintenance-component">
    <div class="container">
      <ct-errors-page
        :status="status"
        :details="details"
        :instruction="instruction"
        :icon="icons"
      />
    </div>
  </div>
</template>

<script>
import CtErrorsPage from '@/components/Shared/Errors';

export default {
  name: 'Maintenance',
  metaInfo: {
    title: 'Página em manutenção',
    meta: [
      { name: 'description', content: 'Página em manutenção. Em breve a página estará disponível' },
    ],
  },
  data () {
    return {
      status: '!',
      details: 'Página em manutenção',
      instruction:
        'Esta página está passando por manutenção, tente mais tarde.',
      icons: {
        font: 'broswer',
        destiny: 'like',
      },
    };
  },
  components: {
    CtErrorsPage,
  },
};
</script>

<style lang="scss" scoped>
@import "../themes/variables";

.maintenance-component {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: $grid-lg-max) {
      min-height: calc(100vh - 124px)
    }
    @media only screen and (max-width: $grid-sm-max) {
      min-height: calc(100vh - 62px)
    }
  }
}
</style>
