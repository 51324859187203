<template>
  <div class="error-500-component">
    <div class="container">
      <ct-errors-page
        :status="status"
        :details="details"
        :instruction="instruction"
        :icon="icons"
        :showError="showError"
      />
    </div>
  </div>
</template>

<script>
import CtErrorsPage from '@/components/Shared/Errors';

export default {
  name: 'Error500',
  metaInfo: {
    title: 'Algo deu errado',
    meta: [
      { name: 'description', content: 'Nossos servidores estão ocupados, tente novamente em alguns minutos.' },
    ],
  },
  data () {
    return {
      showError: true,
      status: '500',
      details: 'Algo deu errado',
      instruction:
        'Nossos servidores estão ocupados, tente novamente em alguns minutos.',
      icons: {
        font: 'bitcoin',
        destiny: 'server',
      },
    };
  },
  components: {
    CtErrorsPage,
  },
};
</script>

<style lang="scss" scoped>
@import "../themes/variables";

.error-500-component {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: $grid-lg-max) {
      min-height: calc(100vh - 124px)
    }
    @media only screen and (max-width: $grid-sm-max) {
      min-height: calc(100vh - 62px)
    }
  }
}
</style>
