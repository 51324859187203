<template>
  <div class="user-login-mobile-component">
    <ct-modal>
      <div slot="button">
        <div class="btn-close" @click="$emit('close')">
          <span class="icon icon-close"></span>
        </div>
      </div>
      <div class="card-modal">
        <div class="modal-title">Indique e ganhe</div>
        <p v-if="!isLogged" class="text-content sugestion-text">
          Faça seu login ou cadastre-se para obter seu link do programa de indicação.
        </p>
        <p class="text-content">
          Copie e compartilhe seu link de rastreio único para amigos <strong>não cadastrados</strong> na PagueComBitcoin.
        </p>
        <p class="text-content">
          Após a realização do cadastro com seu link, você recebe uma <strong>comissão de 0,2%</strong> sobre o valor de todos os pedidos finalizados pelas suas indicações.
        </p>
        <p class="text-content">
          A recompensa será paga por pix até dia 10 de cada mês quando solicitado.
        </p>
        <span v-if="showReferralLink">
          <a @click="copyReferralLink" class="btn btn--secondary block-center referral-link">Copiar link</a>
          <p v-if="copied" class="referral-copied">Link copiado!</p>
        </span>
        <span v-else-if="isLogged">
          <p class="text-content sugestion-text">Efetue um novo login para liberar seu link de rastreio</p>
        </span>
      </div>
    </ct-modal>
  </div>
</template>

<script>
import CtModal from '../Shared/Modal';
import { mapGetters } from 'vuex';
import config from '@/config';

export default {
  name: 'RefererralModal',
  data: () => ({
    copied: false,
    url: config.app.url,
  }),
  methods: {
    close () {
      this.$emit('close');
    },
    copyReferralLink () {
      const link = `${this.url}?ref=${this.userData.partnerCode}`;
      this.copyToClipboard(link);
    },
    copyToClipboard (value) {
      const el = document.createElement('textarea');
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.copied = true;
    },
  },
  components: {
    CtModal,
  },
  computed: {
    ...mapGetters(['userData', 'isLogged']),

    showReferralLink () {
      return !(!this.userData || !this.userData.partnerCode);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.user-login-mobile-component {
  .modal {
    .btn-close {
      background: $primary-color;
      position: absolute;
      cursor: pointer;
      z-index: 3000;
      @include flex-center();
      width: 51px;
      height: 51px;
      top: -25px;
      left: 162px;
      @media only screen and (max-width: $grid-xs-max) {
        width: 34px;
        height: 34px;
        top: -17px;
        left: 112px;
      }

      .icon {
        display: flex;
        background: $spotlight-color;
        width: 18px;
        height: 18px;
        @media only screen and (max-width: $grid-xs-max) {
          width: 12px;
          height: 12px;
        }
        &.icon-close {
          mask: url('/assets/img/icons/icon-close.svg')no-repeat center;
          mask-size: 100% 100%;
        }
      }
    }
    .modal-content {
      .card-modal {
        width: 380px;
        background: $spotlight-color;
        padding: 25px;
        @media only screen and (max-width: $grid-xs-max) {
          padding: 15px;
          width: 260px;
        }

        .modal-title {
          color: $primary-color;
          text-align: center;
          margin: 10px 30px 25px;
          line-height: 1;
          text-transform: uppercase;
          font: {
            size: 23px;
            weight: 400
          }
        }
        .text-content{
          font-family: 'Roboto', sans-serif;
          color: $white-color;
          text-transform: none;
          font-size: 16px;
          text-align: center;
          margin-bottom: 10px;
          line-height: 1.3em;
          &.sugestion-text{
            margin-top: 25px;
          }

          @media screen and (max-width: $grid-xs-max) {
            font-size: 14px;
          }
        }
        .referral-link{
          max-width: 200px;
          margin-top: 25px;
        }

        .referral-copied{
          color: $primary-color;
          text-align: center;
          margin: 10px 0 0 0 ;
        }
      }
    }
  }
}

</style>
