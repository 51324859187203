// Blacklist common values.
const BLACKLIST = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999',
  '12345678909',
];

const BLACKLIST_CNPJ = [
  '00000000000000',
  '11111111111111',
  '22222222222222',
  '33333333333333',
  '44444444444444',
  '55555555555555',
  '66666666666666',
  '77777777777777',
  '88888888888888',
  '99999999999999',
];

const STRICT_STRIP_REGEX_CNPJ = /[-/.]/g;
const LOOSE_STRIP_REGEX_CNPJ = /[^\d]/g;

const STRICT_STRIP_REGEX = /[.-]/g;
const LOOSE_STRIP_REGEX = /[^\d]/g;

const verifierDigitCnpj = (numbers) => {
  let index = 2;
  const reverse = numbers.split('').reduce(function (buffer, number) {
    return [parseInt(number, 10)].concat(buffer);
  }, []);

  const sum = reverse.reduce(function (buffer, number) {
    buffer += number * index;
    index = (index === 9 ? 2 : index + 1);
    return buffer;
  }, 0);

  const mod = sum % 11;
  return (mod < 2 ? 0 : 11 - mod);
};

const stripCnpj = (cnpj, isStrict) => {
  const regex = isStrict ? STRICT_STRIP_REGEX_CNPJ : LOOSE_STRIP_REGEX_CNPJ;
  return (cnpj || '').toString().replace(regex, '');
};

export const isValidCnpj = (cnpj, isStrict) => {
  const stripped = stripCnpj(cnpj, isStrict);

  // CNPJ must be defined
  if (!stripped) { return false; }

  // CNPJ must have 14 chars
  if (stripped.length !== 14) { return false; }

  // CNPJ can't be blacklisted
  if (BLACKLIST_CNPJ.includes(stripped)) { return false; }

  let numbers = stripped.substr(0, 12);
  numbers += verifierDigitCnpj(numbers);
  numbers += verifierDigitCnpj(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
};

/**
 * Compute the Verifier Digit (or "Dígito Verificador (DV)" in PT-BR).
 *
 * You can learn more about the algorithm on [wikipedia (pt-br)](https://pt.wikipedia.org/wiki/D%C3%ADgito_verificador)
 *
 * @export
 * @param {string} numbers a string with only numbers.
 * @returns {number} the verifier digit.
 */
const verifierDigit = (numbers) => {
  numbers = numbers
    .split('')
    .map(function (number) { return parseInt(number, 10); })
  ;

  const modulus = numbers.length + 1;

  const multiplied = numbers.map(function (number, index) {
    return number * (modulus - index);
  });

  const mod = multiplied.reduce(function (buffer, number) {
    return buffer + number;
  }) % 11;

  return (mod < 2 ? 0 : 11 - mod);
};

/**
 * Remove some characters from the `number` input.
 *
 * Example:
 * ```
 * strip('29537995593'); // Result: '29537995593'
 * strip('295.379.955-93'); // Result: '29537995593'
 * strip('295a379b9c5d59e3'); // Result: '29537995593'
 * strip('295a379b9c5d59e3', true); // Result: '295a379b9c5d59e3' - Atention!
 * ```
 *
 * @export
 * @param {string} cpf the CPF text.
 * @param {boolean} [isStrict] if `true`, it will remove only `.` and `-` characters.
 *                             Otherwise, it will remove all non-digit (`[^\d]`) characters. Optional.
 * @returns {string} the stripped CPF.
 */
const strip = (cpf, isStrict) => {
  const regex = isStrict ? STRICT_STRIP_REGEX : LOOSE_STRIP_REGEX;
  return (cpf || '').toString().replace(regex, '');
};

/**
 * Validate the CPF.
 *
 * @export
 * @param {string} cpf the CPF number.
 * @param {boolean} [isStrict] if `true`, it will accept only `digits`, `.` and `-` characters. Optional.
 * @returns {boolean} `true` if CPF is valid. Otherwise, `false`.
 */
const isValidCpf = (cpf, isStrict) => {
  const stripped = strip(cpf, isStrict);

  // CPF must be defined
  if (!stripped) { return false; }

  // CPF must have 11 chars
  if (stripped.length !== 11) { return false; }

  // CPF can't be blacklisted
  if (BLACKLIST.includes(stripped)) { return false; }

  let numbers = stripped.substr(0, 9);
  numbers += verifierDigit(numbers);
  numbers += verifierDigit(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
};

export const cpfValidator = {
  validate (value, args) {
    return isValidCpf(value);
  },
};

export const cnpjValidator = {
  validate (value, args) {
    return isValidCnpj(value);
  },
};
