<template>
  <header class="navbar-simple-component">
    <div class="container">
      <router-link :to="{ name: 'home' }" class="brand">
        Pague com bitcoin
      </router-link>
    </div>
  </header>
</template>

<script>
export default {
  name: 'NavbarSimple',
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";

.navbar-simple-component {
  background: $spotlight-color;
  width: 100%;
  position: fixed;
  z-index: 3;
  top: 0;

  .container {
    .brand {
      color: $primary-color;
      font-size: 18px;
      max-width: 60px;
      display: block;
      padding-top: 19px;
      padding-bottom: 19px;
      line-height: 1.14;
      text: {
        decoration: none;
        transform: uppercase;
      }

      @media only screen and (max-width: $grid-sm-max) {
        font-size: 14px;
        padding-top: 9px;
        padding-bottom: 9px;
        padding-left: 12px;
      }
    }
  }
}
</style>
