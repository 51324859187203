<template>
  <div class="footer-component"
    :class="{ 'mode-page-services' : $route.meta.hideShortcutMenu }"
  >
    <div class="container">
      <div class="row top-container">
        <div class="col col-md-2 col-md-offset-1 col-xs-11 col-xs-offset-1 ">
          <h2 class="text-left">
            PAGUE <br /> COM <br /> BITCOIN.
          </h2>
          <ct-android-badge class="link-image" />
          <ct-ios-badge class="link-image" />
        </div>
        <div class="col col-md-2 col-lg-2 col-xs-11 col-xs-offset-1 col-md-offset-0">
          <p class="title-4 text-left">
            PagueComBitcoin
          </p>
          <ul class="block">
            <li>
              <router-link :to="{ name: 'home' }">
                Home
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'about' }">
                Quem Somos
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'app' }">
                Aplicativo Mobile
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'tutorials-how-to-use' }">
                Como Usar os Serviços
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col col-md-2 col-lg-2 col-xs-11 col-xs-offset-1 col-md-offset-0">
          <router-link :to="{ name: 'services' }" class="title-4 text-left">
            Serviços
          </router-link>
          <ul class="block">
            <li v-if="enabledBilletService">
              <router-link :to="{ name: 'service-billet' }">
                Pagamento de Boleto
              </router-link>
            </li>
            <li v-if="enabledShipmentService">
              <router-link :to="{ name: 'service-shipment' }">
                Conversão de Bitcoin
              </router-link>
            </li>
            <li v-if="enabledRechargeService">
              <router-link :to="{ name: 'service-cell-phone' }">
                Recarga de Celular
              </router-link>
            </li>
            <li v-if="enabledGiftcardService">
              <router-link :to="{ name: 'service-gift-card' }">
                Gift Card
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'gateway' }">
                Gateway de Pagamento
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col col-md-2 col-lg-2 col-xs-11 col-xs-offset-1 col-md-offset-0">
          <p class="title-4 text-left">
            Informações
          </p>
          <ul class="block">
            <li>
              <router-link :to="{ name: 'faq' }">
                Dúvidas Frequentes
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'taxes' }">
                Taxas dos Serviços
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'bitcoin' }">
                O que é Bitcoin
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'terms' }">
                Termos de Uso
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col col-md-2 col-lg-2 col-xs-11 col-xs-offset-1 col-md-offset-0">
          <p @click="openContactForm" class="title-4 title-link hidden-md hidden-sm hidden-xs text-left">
            Fale Conosco
          </p>
          <p @click="openContactModal" class="title-4 title-link hidden-lg text-left">
            Fale Conosco
          </p>
          <ul class="inline">
            <li>
              <ct-facebook-button url="https://www.facebook.com/paguecombitcoin?utm_source=pague-site&utm_medium=social&utm_campaign=site"></ct-facebook-button>
            </li>
            <li>
              <ct-instagram-button url="https://instagram.com/paguecombitcoin?utm_source=pague-site&utm_medium=social&utm_campaign=site"></ct-instagram-button>
            </li>
            <li>
              <ct-twitter-button url="https://twitter.com/paguecombitcoin?utm_source=pague-site&utm_medium=social&utm_campaign=site"></ct-twitter-button>
            </li>
          </ul>
          <a href="mailto:info@paguecombitcoin.com" class="footer-link">
            info@paguecombitcoin.com
          </a>
        </div>
      </div>
    </div>
    <div class="container">
      <hr />
      <div class="row footer-container">
        <div class="col-xs-12 footer-content">
          <p>
            Razão social: Citar Tech EIRELI <span class="separator" >|</span>
            CNPJ: 19.004.882/0001-91 <br />
            Endereço: Rua Doutor Celestino, Nº122, sala 611, Centro, Niterói - RJ, CEP: 24020-091 <br />
            <b>PAGUECOMBITCOIN</b>® <b>2020 versão {{ version }}</b> | Todos os direitos reservados.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CtFacebookButton from '@/components/SocialMedia/FacebookButton';
import CtInstagramButton from '@/components/SocialMedia/InstagramButton';
import CtTwitterButton from '@/components/SocialMedia/TwitterButton';
import CtIosBadge from '@/components/ContentApp/IosBadge';
import CtAndroidBadge from '@/components/ContentApp/AndroidBadge';
import { version } from '../../config';

export default {
  name: 'Footer',
  components: {
    CtFacebookButton,
    CtInstagramButton,
    CtTwitterButton,
    CtIosBadge,
    CtAndroidBadge,
  },
  computed: {
    ...mapGetters([
      'enabledPixService',
      'enabledShipmentService',
      'enabledRechargeService',
      'enabledBilletService',
      'enabledGiftcardService',
    ]),
    version () {
      return version || '-';
    },
  },
  methods: {
    ...mapActions(['openContactForm', 'openContactModal']),
  },
};
</script>
<style lang="scss" scoped>
@import "../../themes/variables";

.footer-component {
  padding-top: 35px;
  margin-top: 50px;

  @media only screen and (max-width: $grid-md-max) {
    margin-bottom: 40px;
  }

  @media print {
    display: none;
  }

  width: 100%;
  background-color: $spotlight-color;
  min-height: 71px;

  hr {
    background-color: #004c8b;
    color: #004c8b;
    height: 1px;
    border: none;
    margin-top: 20px;
    @media only screen and (max-width: $grid-sm-max) {
      margin-top: 45px;
    }
  }

  .top-container {
    .title-4, h2 {
      color: $primary-color;
      font-weight: normal;
      padding: 0;
      display: block;
      text-decoration: none;
    }

    .title-link {
      cursor: pointer;
    }

    .col {
      @media only screen and (max-width: $grid-sm-max) {
        margin-top: 35px;
      }
    }

    .link-image {
      margin-left: -10px;
      margin-top: -10px;
    }

    a.footer-link {
      color: $white-color;
      font-size: 16px;
      font-weight: normal;
      margin-top: 19px;
      display: block;
    }

    h2 {
      font-size: 30px;
    }

    ul {
      list-style: none;
      &.inline {
        li {
          display: inline;
          padding: 5px;
        }
      }

      &.block {
        li, .anchor-component::v-deep {
          a {
            color: $white-color;
            font-family: Roboto;
            font-size: 14px;
            line-height: 2.14;
            text-decoration: none;
          }
        }
      }
    }
  }

  .footer-container {
    margin-top: 19px;

    p {
      font-family: Roboto;
      font-size: 14px;
      text-align: center;
      color: $white-color;
      line-height: normal;
      @media only screen and (max-width: $grid-sm-max) {
        padding: 15px;
      }
    }
  }
}
</style>
