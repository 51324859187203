import ResetPasswordService from '../../../services/ResetPasswordService';

export default {
  openResetPasswordModal ({ commit }) {
    commit('SET_RESET_PASSWORD_MODAL_VISIBILITY', true);
  },
  closeResetPasswordModal ({ commit }) {
    commit('SET_RESET_PASSWORD_MODAL_VISIBILITY', false);
    commit('SET_RESET_PASSWORD_MODAL_STATUS', 'DEFAULT');
  },
  changeResetPasswordModalStatus ({ commit }, status) {
    commit('SET_RESET_PASSWORD_MODAL_STATUS', status);
  },
  async requestPasswordReset ({ commit }, email) {
    await ResetPasswordService.requestPasswordReset(email);
  },
  changeResetPasswordFormStatus ({ commit }, status) {
    commit('SET_RESET_PASSWORD_FORM_STATUS', status);
  },
  async resetPassword ({ commit }, { password, token }) {
    await ResetPasswordService.resetPassword(password, token);
  },
};
