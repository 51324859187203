export default {
  toggleContactForm ({ commit }) {
    commit('TOGGLE_CONTACT_FORM');
  },
  openContactForm ({ commit }) {
    commit('OPEN_CONTACT_FORM');
  },
  openContactModal ({ commit }) {
    commit('OPEN_CONTACT_MODAL');
  },
  closeContactModal ({ commit }) {
    commit('CLOSE_CONTACT_MODAL');
  },
  openReferralModal ({ commit }) {
    commit('OPEN_REFERRAL_MODAL');
  },
  closeReferralModal ({ commit }) {
    commit('CLOSE_REFERRAL_MODAL');
  },
};
