<template>
  <div class="ios-badge-component" :class="classObject">
    <a
      href="https://apps.apple.com/br/app/paguecombitcoin/id1511333416?utm_source=website&utm_medium=badge"
      target="_blank"
    >
      <img alt='Disponível no Apple Store' class="link-img" src='/assets/img/app-store-badge.png' />
    </a>
  </div>
</template>

<script>
export default {
  name: 'IosBadge',
  props: {
    size: {
      type: String,
      required: false,
      default: 'small',
    },
  },
  computed: {
    classObject () {
      return {
        'small': this.size === 'small',
        'medium': this.size === 'medium',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.ios-badge-component {
  &.small {
    img {
      max-width: 153px;
    }
  }

  &.medium {
    img {
      max-width: 176px;
    }
  }
}
</style>
