import { getQueryString } from './url.helper';

const refKey = 'REFERRAL_CODE_CLIENT';

export const setReferral = () => {
  const value = getQueryString().ref;
  if (value) {
    const hoursExpired = process.env.VUE_REFERRAL_PERSISTENCE_TIME || 180;
    const hourstime = (hoursExpired * 60 * 60 * 1000);
    localStorage.setItem(refKey, JSON.stringify({ value, timestamp: (new Date().getTime() + hourstime) }));
  }
};

export const getReferral = () => {
  if (!localStorage.getItem(refKey)) { return null; }
  const object = JSON.parse(localStorage.getItem(refKey));
  const dateString = object.timestamp;
  return !isExpired(dateString) ? object.value : null;
};

const isExpired = (dateTimeExpire) => {
  const now = new Date().getTime().toString();
  if (now > dateTimeExpire) {
    localStorage.removeItem(refKey);
    return true;
  }

  return false;
};
