const appData = JSON.parse(decodeURIComponent(process.env.APP_DATA || '%7B%7D'));

export const isProduction = process.env.NODE_ENV === 'production';
export const name = appData.name || 'PagueComBitcoin';
export const version = appData.version || '0';

export const intervals = {
  depositions: 5000,
  invoices: {
    new: 15000,
    default: 60000,
  },
  banners: 10000,
  ticker: 120000,
};

export const app = {
  url: `${process.env.VUE_APP_BASE_URL || 'https://paguecombitcoin.com'}`,
  appUrl: `${process.env.VUE_APP_APP_URL || 'https://app.paguecombitcoin.com'}`,
  apiUrl: `${process.env.VUE_APP_API_URL || 'https://api.paguecombitcoin.com'}`,
  contactUrl: `${process.env.VUE_APP_CONTACT_API_URL || 'https://beta-services.citar.tech'}`,
};

export const intercom = {
  id: process.env.VUE_APP_INTERCOM_APP_ID || '',
};

export default {
  isProduction,
  name,
  version,
  intervals,
  app,
  analytics: {
    id: process.env.VUE_APP_ANALYTICS_ID || '',
  },
  intercom,
  gtm: {
    id: `${process.env.VUE_APP_GTM_ID || ''}`,
  },
};
