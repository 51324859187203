import UserService from '@/services/UserService';

export default {
  openRegisterModal ({ commit }) {
    commit('SET_MODAL_VISIBILITY', true);
  },
  closeRegisterModal ({ commit, dispatch }) {
    commit('SET_MODAL_VISIBILITY', false);
    dispatch('resetRegistrationData');
  },
  returnToRegisterStep ({ commit }) {
    commit('SET_REGISTRATION_STEP', 'REGISTER');
  },
  async registerUser ({ commit }, userData) {
    commit('SET_REGISTERING_USER', userData);
    const response = await UserService.registerUser(userData);
    switch (response.status) {
      case 'SUCCESS':
        commit('SET_VERIFICATION_TOKEN', response.token);
        commit('SET_REGISTRATION_STEP', 'VERIFY');
        break;
      case 'DUPLICATE':
        commit('SET_REGISTRATION_STEP', 'DUPLICATE');
        break;
      case 'DUPLICATE_PHONE':
        commit('SET_REGISTRATION_STEP', 'PHONE-DUPLICATE');
        break;
      case 'INVALID_PHONE':
        commit('SET_REGISTRATION_STEP', 'PHONE-INVALID');
        break;
      case 'INVALID':
        commit('SET_REGISTRATION_STEP', 'FIELDS-INVALID');
        commit('SET_REGISTER_FORM_ERRORS', response.errors);
        break;
      case 'ERROR':
        commit('SET_REGISTRATION_STEP', 'ERROR');
        break;
      case 'INVALID_TEMP_PHONE':
        commit('SET_REGISTRATION_STEP', 'ERROR-TEMP');
        commit('SET_REGISTER_FORM_ERRORS', response.message);
        break;
      case 'INVALID_TEMP_EMAIL':
        commit('SET_REGISTRATION_STEP', 'ERROR-TEMP');
        commit('SET_REGISTER_FORM_ERRORS', response.message);
        break;
    }
  },
  async hasUserForData ({ commit }, userData) {
    const response = await UserService.hasUser(userData);
    if (response.data.hasUser) {
      commit('SET_REGISTERING_USER', userData);
      commit('SET_REGISTRATION_STEP', 'DUPLICATE');
      return;
    }

    if (response.data.hasTempEmail) {
      commit('SET_REGISTERING_USER', userData);
      commit('SET_REGISTRATION_STEP', 'TEMPEMAIL');
    }
  },
  async verifyUser ({ commit, state, dispatch }, verificationCode) {
    const response = await UserService.verifyUser(state.verificationToken, verificationCode);
    switch (response.status) {
      case 'SUCCESS':
        commit('SET_REGISTRATION_STEP', 'SUCCESS');
        const userData = {
          email: state.registeringUser.email,
          name: state.registeringUser.name,
          document: state.registeringUser.document,
          phone: state.registeringUser.phone,
          token: response.token,
        };
        dispatch('setUserData', userData);
        break;
      case 'VALIDATING':
        commit('SET_REGISTRATION_STEP', 'VALIDATING');
        break;
      case 'INVALID':
        commit('SET_REGISTRATION_STEP', 'VERIFY-ERROR');
        break;
      case 'ERROR':
        commit('SET_REGISTRATION_STEP', 'ERROR');
        break;
    }
  },
  resetRegistrationData ({ commit }) {
    commit('SET_REGISTERING_USER', {});
    commit('SET_VERIFICATION_TOKEN', '');
    commit('SET_REGISTRATION_STEP', 'REGISTER');
  },
};
