<template>
  <div class="register-validating-component">
    <h3>{{ $t('register.steps.validating.title') }}</h3>
    <p> {{ $t('register.steps.validating.text') }} </p>

    <br /><br />
    <h3>Ok! Thank you for the informations</h3>
    <p>Please check your inbox to conclud your registration.</p>
    <br /><br />
    <div style="text-align: center">
      <button @click.prevent @click="closeRegisterModal" class="btn btn--secondary">
        {{ $t('register.steps.validating.buttons.continue') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'RegisterValidating',
  methods: {
    ...mapActions(['closeRegisterModal']),
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.register-validating-component {
  padding: 30px;
  margin-bottom: 30px;
  @media screen and (max-width: $grid-xs-max) {
    padding: 10px;
  }

  h1, p, h2, h3 {
    color: $tertiary-color;
    line-height: 1;
  }

  h1 {
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 25px;
    text-transform: uppercase;
    @media screen and (max-width: $grid-xs-max) {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }
  p {
    margin-bottom: 25px;
  }

  .btn-action {

    background: $tertiary-color;
    color: $spotlight-color;
    font-size: 23px;
    margin: auto;
    width: 50%;
    @media only screen and (max-width: $grid-xs-max) {
      font-size: 13px;
    }
  }
}

</style>
