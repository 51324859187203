<template>
  <div class="giftcard-item" :id="`giftcard-form-${this.uuid}`">
    <div>
      <form role="form" class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 has-bottom-mobile">
          <label :for="`operator${this.uuid}`">
            {{ $t('services.giftcard.fields.operator') }}
          </label>
            <ct-select
              name="operatorGiftCard"
              :id="`operatorGiftCard${this.uuid}`"
              class="from-control"
              v-model="giftCard.operator"
              :reduce="operator => operator.value"
              :placeholder="$t('services.giftcard.placeholder.operator')"
              v-validate="{ required: true }"
              :class="{'is-invalid': errors.has('operatorGiftCard')}"
              :data-vv-as="$t('services.giftcard.fields.operatorError')"
              :options="operators"
              @input="changeOperator"
            />
            <div class="invalid-feedback">{{ errorMessages.operatorGiftCard || errors.first('operatorGiftCard') }}</div>
        </div>
        <div class="col-lg-4 col-md-3 col-sm-6 col-xs-6 has-bottom-mobile">
          <label :for="`card${this.uuid}`">
            {{ $t('services.giftcard.fields.card') }}
          </label>
            <ct-select
              name="cardGiftCard"
              :id="`cardGiftCard${this.uuid}`"
              class="from-control"
              v-model="giftCard.card"
              :reduce="card => card.value"
              :placeholder="$t('services.giftcard.placeholder.card')"
              v-validate="{ required: true }"
              :class="{'is-invalid': errors.has('cardGiftCard')}"
              :data-vv-as="$t('services.giftcard.fields.card')"
              :options="currentAmounts"
              :disabled="disableCard"
              @input="changeCard"
            />
            <div class="invalid-feedback">{{ errorMessages.cardGiftCard || errors.first('cardGiftCard') }}</div>
        </div>
        <div class="ml-lg-auto ml-md-auto col-amount col-lg-2 col-md-3 col-sm-6 col-xs-6">
          <label :for="`quantity${this.uuid}`">
            {{ $t('services.giftcard.fields.quantity') }}
          </label>
          <input
            type="number"
            class="form-control"
            min="1"
            step="1"
            :id="`quantity${this.uuid}`"
            name="quantity"
            autocomplete="off"
            :placeholder="$t('services.giftcard.placeholder.quantity')"
            v-validate="{ required: true, min: 1, max: 10 }"
            v-model="giftCard.quantity"
            :class="{'is-invalid': errors.has('quantity')}"
            :data-vv-as="$t('services.giftcard.fields.quantity')"
          />
          <div class="invalid-feedback">{{ errorMessages.quantity || (errors.first('quantity') ? $t('services.giftcard.errorQuantity') : '')}}</div>
        </div>
        <div class="ml-lg-auto ml-md-auto col-amount col-lg-2 col-md-3 col-sm-12 col-xs-12">
          <label :for="`amountGiftCard${this.uuid}`">
            {{ $t('services.giftcard.fields.amount') }}
          </label>
          <ct-input-numeric
            :thousand-separator="`.`"
            :decimal-separator="`,`"
            :precision="2"
            autocomplete="off"
            class="form-control"
            :id="`amountGiftCard${this.uuid}`"
            name="amount"
            :readonly="disableValue"
            :placeholder="$t('services.giftcard.placeholder.amount')"
            v-validate="{required: true, decimal: 2}"
            :data-vv-as="$t('services.giftcard.fields.amount')"
            :class="{'is-invalid': errors.has('amount')}"
            v-model="totalForItem"
          ></ct-input-numeric>
          <div class="invalid-feedback">{{ errorMessages.amount || errors.first('amount')}}</div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CtSelect from '../Shared/Select';
import CtInputNumeric from '../Shared/InputNumeric';
import { mapGetters, mapState, mapActions } from 'vuex';

export default {
  name: 'GiftCardForm',
  props: {
    uuid: {
      type: [ String, Number ],
      required: true,
    },
  },
  components: {
    CtSelect,
    CtInputNumeric,
  },
  async created () {
    await this.getOperatorsGiftCardForSelect();
  },
  methods: {
    ...mapActions([
      'getOperatorsGiftCardForSelect',
      'getAmountsGiftCardForSelect',
    ]),

    async validate () {
      return this.$validator.validate();
    },
    async changeCard () {
      if (this.giftCard.card) {
        const card = this.currentAmounts.find(amount => amount.value === this.giftCard.card);
        this.giftCard.amount = Number(card.cardValue) || null;
      }
      await this.$emit('verifyError', { uuid: this.uuid, field: 'card' });
    },
    async changeOperator () {
      this.giftCard.card = null;
      await this.getOperatorCards();
      await this.$emit('verifyError', { uuid: this.uuid, field: 'operator' });
    },
    async getOperatorCards () {
      if (this.giftCard.operator) {
        await this.getAmountsGiftCardForSelect(this.giftCard.operator);
      }
    },
  },
  computed: {
    ...mapState({
      invoice: (state) => state.services.invoice,
      itemsErrors: (state) => state.services.invoice.itemsErrors,
      operators: (state) => state.select.operatorsGiftCard,
      amounts: (state) => state.select.amountsGiftCard,
    }),
    ...mapGetters(['getGiftCardByUUID']),
    currentAmounts () {
      return this.amounts[this.giftCard.operator] || [];
    },
    giftCard () {
      return this.getGiftCardByUUID(this.uuid);
    },
    disableValue () {
      return true;
    },
    disableCard () {
      return !this.giftCard.operator;
    },
    totalForItem () {
      return this.giftCard.amount * this.giftCard.quantity;
    },
    errorMessages () {
      const messages = {
        operador: '',
        card: '',
        amount: '',
        quantity: '',
      };
      for (const row of this.itemsErrors) {
        if (row.uuid.toString() === this.uuid.toString()) {
          messages[row.field] = row.message;
        }
      }
      return messages;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../themes/variables";

  .giftcard-item {
    .row {
      .has-bottom-mobile {
        @media only screen and (max-width: $grid-md-max) {
          margin-bottom: 15px;
        }
      }
    }

    .field-info {
      font-family: 'Oswald', sans-serif;
      font-size: 12.5px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.92;
      letter-spacing: 0.6px;
      text-align: left;
      padding-left: 10px;
      text-transform: uppercase;
      color: $spotlight-color;
      position: relative;
      top: 8px;
      @media only screen and (max-width: $grid-lg-min) {
        display: block;
        margin-bottom: 20px;
      }
    }
    .field-info-alert {
      color: $secondary-color;
    }
  }

</style>
