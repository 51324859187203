export default (value) => {
  if (typeof value === 'undefined') return value;

  if (typeof value !== 'string' || value.length < 5) {
    return value;
  }

  const domain = value.split('@');
  const starter = domain[0].charAt(0);
  return starter + '*****@' + (domain[1] || '');
};
