<template>
  <div class="register-phone-error-component">
    <h1 v-if="kind === 'PHONE-DUPLICATE'">{{ $t('register.steps.phoneError.titleDuplicate') }}</h1>

    <p v-if="kind === 'PHONE-DUPLICATE'" v-html="$t('register.steps.phoneError.errorMessageDuplicate')"></p>

    <h1 v-if="kind === 'PHONE-INVALID'">{{ $t('register.steps.phoneError.titleInvalid') }}</h1>

    <p v-if="kind === 'PHONE-INVALID'" v-html="$t('register.steps.phoneError.errorMessageInvalid')"></p>

    <h2 v-html="$t('register.steps.phoneError.goBack')"></h2>

    <button @click.prevent @click="returnToRegisterStep" class="btn btn--primary block-center">
      {{ $t('register.steps.phoneError.buttons.back') }}
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'RegisterPhoneError',
  props: {
    kind: {
      type: String,
      default: 'PHONE-INVALID',
    },
  },
  methods: {
    ...mapActions(['returnToRegisterStep']),
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.register-phone-error-component {
  padding: 25px;
  @media screen and (max-width: $grid-xs-max) {
    padding: 0px;
  }

  h1, h2, p {
    color: $tertiary-color;
    ::v-deep a {
      color: $tertiary-color;
    }
  }

  h1 {
    font-size: 38px;
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 25px;
    text-transform: uppercase;
    @media screen and (max-width: $grid-xs-max) {
      font-size: 20px;
      margin-bottom: 15px;
      line-height: 24px;
    }
  }
  h2 {
    font-size: 19px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 25px;
    text-transform: uppercase;
    text-align: center;
    @media screen and (max-width: $grid-xs-max) {
      font-size: 16px;
    }
  }
  p {
    margin-bottom: 111px;
    @media screen and (max-width: $grid-xs-max) {
      margin-bottom: 30px;
    }
  }
}

</style>
