<template>
  <div class="deposition-item-component">
    <div class="caption">
      <div class="icon">
        <span class="quote-left"></span>
      </div>
      <div class="caption-content">
        <p class="caption-text">{{ deposition.text || '' }}</p>
        <div class="caption-info">
          <p class="author">{{ deposition.name || '' }},</p>
          <p class="company">{{ deposition.company || '' }}</p>
        </div>
      </div>
      <div class="icon icon-bottom">
        <span class="quote-right"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DepositionItem',
  props: {
    deposition: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.deposition-item-component {
  width: 100%;
  max-width: 833px;
  position: absolute;
  line-height: 27px;
  color: $spotlight-color;
  font: {
    family: 'Roboto', sans-serif;
    size: 21px;
    weight: 300
  }
  @media only screen and (max-width: $grid-sm-max) {
    max-width: 700px;
  }
  @media only screen and (max-width: $grid-xs-max) {
    font-size: 12px;
    line-height: 13px
  }
  .caption {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media only screen and (max-width: $grid-xs-max) {
      justify-content: space-around
    }
    .icon {
      display: flex;
      height: 43px;
      width: 59px;
      @media only screen and (max-width: $grid-xs-max) {
        width: 21px;
        height: 15px;
      }

      &.icon-bottom {
        align-self: flex-end
      }

      span {
        &.quote-left {
          background: url("/assets/img/icons/quotation_marks.png")no-repeat center;
          width: 100%;
          @media only screen and (max-width: $grid-xs-max) {
            background-size: 100%
          }
        }
        &.quote-right {
          background: url("/assets/img/icons/quotation_marks.png")no-repeat center;
          width: 100%;
          transform: rotate(180deg);
          @media only screen and (max-width: $grid-xs-max) {
            background-size: 100%
          }
        }
      }
    }
    .caption-content {
      max-width: 663px;
      min-height: 240px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media only screen and (max-width: $grid-xs-max) {
        max-width: 100%;
        min-height: 140px
      }
      .caption-text {
        min-width: 663px;
        display: flex;
        @media only screen and (max-width: $grid-sm-max) {
          min-width: 0;
          max-width: 100%;
        }
      }
      .caption-info {
        margin-top: 15px;
        min-width: 663px;
        display: flex;
        justify-content: flex-end;
        @media only screen and (max-width: $grid-sm-max) {
          min-width: 0;
          max-width: 550px;
        }
        @media only screen and (max-width: $grid-xs-max) {
          justify-content: center;
          min-width: 258px;
          margin-top: 7px
        }

        .author {
          color: $primary-color
        }
        .company {
          margin-left: 10px;
          color: $spotlight-color
        }
      }
    }
  }
}
</style>
