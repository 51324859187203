<template>
  <div class="reset-password-modal-component">
    <ct-modal v-if="modalVisible">
      <div slot="button">
        <div class="btn-close" @click="closeResetPasswordModal">
          <span class="icon icon-close"></span>
        </div>
      </div>
      <div class="card card-default">
        <h4 class="card-title">
          {{ $t('resetPassword.emailReset.title') }}
        </h4>

        <p class="card-subtitle" v-if="modalStatus == 'DEFAULT'">
          {{ $t('resetPassword.emailReset.subtitle') }}
        </p>

        <ct-email-reset-password @sendEmail="sendEmail" v-if="modalStatus == 'DEFAULT'" />

        <!-- messages -->

        <p class="card-subtitle" v-if="modalStatus == 'SUCCESS'">
          {{ $t('resetPassword.emailReset.messageSuccess') }}
          <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>
        </p>

        <p class="card-subtitle" v-if="modalStatus == 'ERROR'">
          {{ $t('resetPassword.emailReset.messageError') }}
          <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>
        </p>

        <!-- end messages -->

      </div>
    </ct-modal>
  </div>

</template>

<script>
import { mapActions, mapState } from 'vuex';
import CtModal from '../Shared/Modal';
import CtEmailResetPassword from '@/components/ResetPassword/EmailResetPassword';

export default {
  name: 'ResetPasswordModal',
  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CtModal,
    CtEmailResetPassword,
  },
  methods: {
    ...mapActions(['closeResetPasswordModal', 'changeResetPasswordModalStatus', 'requestPasswordReset']),
    async sendEmail (event) {
      try {
        await this.requestPasswordReset(event.email);
        await this.changeResetPasswordModalStatus('SUCCESS');
      } catch (err) {
        await this.changeResetPasswordModalStatus('ERROR');
      }
    },
  },
  computed: {
    ...mapState({
      modalVisible: state => state.users.modalVisible,
      modalStatus: state => state.users.modalStatus,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.reset-password-modal-component {
  .btn-close {
    background: $tertiary-color;
    position: absolute;
    cursor: pointer;
    z-index: 3000;
    @include flex-center();
    width: 51px;
    height: 51px;
    top: -25px;
    left: 162px;
    @media only screen and (max-width: $grid-xs-max) {
      width: 34px;
      height: 34px;
      top: -17px;
      left: 112px;
    }

    .icon {
      display: flex;
      background: $spotlight-color;
      width: 18px;
      height: 18px;
      @media only screen and (max-width: $grid-xs-max) {
        width: 12px;
        height: 12px;
      }
      &.icon-close {
        mask: url('/assets/img/icons/icon-close.svg')no-repeat center;
        mask-size: 100% 100%;
      }
    }
  }

  .card-default {
    padding: 40px 40px 30px;

    @media only screen and (max-width: $grid-xs-max) {
      padding: 20px
    }

    .card-title {
      color: $primary-color;
      margin-bottom: 25px;
      font-weight: 400;

      @media only screen and (max-width: $grid-xs-max) {
        font-size: 14px;
        margin-bottom: 15px;
      }
    }
    .card-subtitle {
      line-height: 1.79;
      font-size: 14px;

      @media only screen and (max-width: $grid-xs-max) {
        font-size: 12px;
        line-height: 1.5;
      }

      a {
        color: $secondary-color;
      }
    }
  }
}

</style>
