<template>
  <section class="pix-component">
    <div class="container pix-container">
      <div class="row pix-row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 content">

          <h1>
            <span class="emphasis-variant">BITCOIN</span> por PIX.
          </h1>
          <p>
            Converta seus bitcoins em reais e saque por pix de forma automática direto para a sua conta ou para conta de terceiros, inclusive CNPJ.
          </p>
          <p>
            Nosso pix funciona 24 horas e 7 dias na semana. Você consegue transformar seus bitcoins em reais quando quiser.
          </p>
          <p>
            É simples, rápido e totalmente seguro.
          </p>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pix">
          <card-image title="Como Funciona?">
            <template slot="img">
               <img src="/assets/img/home/pix.png" alt="Conversão de bitcoin" class="image-card" />
            </template>
            <template slot="body">
              <p class="title-4">Limite para conversão mensal<p>
              <div class="card-sub-title title-5">
                R$ {{ maxShipmentMonthlyLimitValue }} *
              </div>
              <p class="title-4">Limite para conversão diária<p>
              <div class="card-sub-title title-5">
                R$ {{ maxShipmentDailyLimitValue }} *
              </div>
              <p class="title-4">Sem limitação de valor por horário</p>
              <div class="card-sub-title obs" style="margin-top: 10px">
                * Limite PIX/TED
              </div>
            </template>
            <template slot="action">
              <router-link :to="{ name: 'service-pix' }" class="btn btn--secondary">
                Fazer PIX agora
              </router-link>
            </template>
          </card-image>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import currency from '@/filters/currency';
import CardImage from '../Shared/CardImage';

export default {
  name: 'SaleBitcoin',
  components: {
    CardImage,
  },
  computed: {
    ...mapGetters(['currentRestriction']),
    maxShipmentValue () {
      return this.currentRestriction.maxShipmentValue ? currency(this.currentRestriction.maxShipmentValue) : '?';
    },
    maxShipmentDailyLimitValue () {
      return this.currentRestriction.maxShipmentDailyLimitValue ? currency(this.currentRestriction.maxShipmentDailyLimitValue) : '?';
    },
    maxShipmentMonthlyLimitValue () {
      return this.currentRestriction.maxShipmentMonthlyLimitValue ? currency(this.currentRestriction.maxShipmentMonthlyLimitValue) : '?';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';
@import "../../themes/mixins/layout";

.pix-component {
  .pix-container {
    max-width: 1000px;
    .pix-row {
      margin: 0;
      .content {
        @media only screen and (max-width: $grid-sm-max) {
          text-align: center
        }
        h1 {
          min-width: 451px;
          margin: 140px 0 20px 0;
          text-align: left;
          @media only screen and (max-width: $grid-md-max) {
            min-width: 100%;
            text-align: center
          }
          @media only screen and (max-width: $grid-sm-max) {
            margin: 100px auto 20px auto;
          }
          @media only screen and (max-width: $grid-xs-max) {
            margin: 30px auto 20px auto;
            font-size: 26px;
          }
        }
        p {
          max-width: 325px;
          @media only screen and (max-width: $grid-md-max) {
            max-width: 100%;
            min-width: 248px;
            text-align: center;
            margin: 0px auto 20px auto;
          }
        }
        .content-action {
          display: flex;
          margin: 40px 0 0;
          @media only screen and (max-width: $grid-md-max) {
            justify-content: center;
          }
          @media only screen and (max-width: $grid-xs-max) {
            font-size: 1.1em
          }
        }
      }
      .pix {
        margin: 0px 0 50px 0;
        min-height: 534px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        @media only screen and (max-width: $grid-md-max) {
          min-height: 500px;
          justify-content: center;
        }
        @media only screen and (max-width: $grid-xs-max) {
          margin: 50px 0 0 0;
          min-height: 260px
        }
        .image-card {
          position: absolute;
          z-index: -1;
          right: 330px;
          bottom: -16px;
          @media only screen and (max-width: $grid-md-max) {
            bottom: -19px;
            right: 330px;
          }
          @media only screen and (max-width: $grid-xs-max) {
            width: 180px;
            right: 140px;
            top: 10px;
          }
        }

        .card {
          @media only screen and (max-width: $grid-xs-max) {
            padding: 5px 0 15px 15px;
          }

          .card-sub-title.obs {
            font-size: 10px;
            @media only screen and (max-width: $grid-xs-max) {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
