<template>
  <a class="facebook-button-component" @click="clicked" :href="url" :target="target">
    <svg class="facebook" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"/></svg>
  </a>
</template>

<script>
export default {
  name: 'FacebookButton',
  props: {
    url: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: '_blank',
    },
  },
  data () {
    return {};
  },
  methods: {
    clicked () {
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
  @import "../../themes/variables";
  .facebook-button-component{
    cursor: pointer;
    svg {
      width: 2.88em;
      height: 2.88em;
      fill: $white-color;
      &:hover {
        fill: $white-color;
      }
    }
  }
</style>
