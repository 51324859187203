<template>
  <section class="reset-password-page">
    <div class="container">
      <h3 class="title-page">{{ $t('resetPassword.titlePage') }}</h3>

      <div class="card card-default">

        <ct-form-reset-password  @resetPassword="sendPassword" v-if="formStatus == 'DEFAULT'" />

        <h3 class="card-title" v-if="formStatus == 'ERROR'" >{{ $t('resetPassword.alert.messageError') }}</h3>

        <h3 class="card-title" v-if="formStatus == 'SUCCESS'">{{ $t('resetPassword.alert.messageSuccess') }}</h3>

        <router-link v-if="formStatus !== 'DEFAULT'" class="btn btn-primary" :to="{ name: 'home' }">
          {{ $t('resetPassword.alert.buttonBackToHome') }}
        </router-link>

      </div>
      <p class="support-info center">
        {{ $t('resetPassword.messageSupport') }}
        <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>
      </p>

    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CtFormResetPassword from '@/components/ResetPassword/FormResetPassword';

export default {
  name: 'ResetPassword',
  components: {
    CtFormResetPassword,
  },
  methods: {
    ...mapActions(['changeResetPasswordFormStatus', 'resetPassword']),
    async sendPassword (event) {
      try {
        await this.resetPassword({ password: event.password, token: this.$route.params.token });
        await this.changeResetPasswordFormStatus('SUCCESS');
      } catch (err) {
        await this.changeResetPasswordFormStatus('ERROR');
      }
    },
  },
  async created () {
    if (!this.$route.params.token) {
      this.$router.push({ name: 'home' });
    }
    await this.changeResetPasswordFormStatus('DEFAULT');
  },
  computed: {
    ...mapState({
      formStatus: state => state.users.formStatus,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "../themes/variables";

.reset-password-page {
  min-height: calc(100vh - 229px);
  .container {
    @media only screen and (max-width: $grid-sm-max) {
      margin-right: 30px;
      margin-left: 30px;
    }
    .title-page {
      text-align: center;
      margin-top: 50px;
      margin-bottom: 40px;

      @media only screen and (max-width: $grid-xs-max) {
        font-size: 26px;
      }
    }

    .card-default {
      max-width: 440px;
      padding: 35px 15px;

      @media only screen and (max-width: $grid-xs-max) {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .card-title {
        line-height: 1.3;
        margin-bottom: 30px;

        @media only screen and (max-width: $grid-xs-max) {
          font-size: 24px;
          margin-bottom: 15px;
        }
      }

      .btn-primary {
        display: initial;
      }
    }

    .support-info {
      max-width: 430px;
      margin-top: 20px;
      line-height: 1.32;
      font: {
        family: 'Roboto', sans-serif;
        size: 12px;
      }

      a {
        font-weight: 700;
        color: $text-color;
      }
    }
  }
}

</style>
