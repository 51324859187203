<template>
<div>
  <div id="paguecombitcoin">
    <h2 class="info-content-tile">PagueComBitcoin</h2>

    <div id="sobre-nos">
      <h3 class="info-content-tile">Sobre nós</h3>
      <h4>A PagueComBitcoin é confiável?</h4>
      <p>
        Desde 2013, a PagueComBitcoin está presente no mercado de meio de pagamentos com criptoativos, sendo marca da
        fintech brasileira <a target="_blank" href="https://citar.tech/">CITAR TECH</a>. Hoje líder nacional do setor,
        foi a primeira solução de intermediação de pagamentos com bitcoins da América Latina.
      </p>

      <h4 id="aplicativo-android-e-ios">
        A PAGUECOMBITCOIN TEM APP?
      </h4>
      <p>
        Tem sim! Em março de 2020, a PagueComBitcoin lançou o aplicativo mobile para celulares Android. Ele conta com
        leitor de código de barras para facilitar pagamento de boletos com bitcoin. Pelo aplicativo você também pode
        solicitar os demais serviços: conversão de bitcoin em real, Bitcoin por Pix, compra de gift cards e recarga
        de celular
      </p>
      <p>
        Baixe agora e deixe seu comentário em <router-link :to="{name: 'app'}">paguecombitcoin.com/aplicativo</router-link>.
      </p>

      <h4>Como acessar os Termos de Uso da PagueComBitcoin?</h4>
      <p>Você pode encontrar nossos <router-link :to="{name: 'terms'}">Termos de Uso aqui</router-link>.</p>

      <h4>Como entrar em contato com a PagueComBitcoin?</h4>
      <p>
        Para dúvidas, solicitações e sugestões, você pode entrar em contato pelo e-mail
        <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a> ou pelo nosso chat disponível no
        canto inferior direito do nosso site.
      </p>
      <p>Acompanhe nossas redes sociais oficiais abaixo:</p>
      <p>
        <span>Facebook: </span>
        <a target="_blank" href="https://facebook.com/PagueComBitcoin">facebook.com/PagueComBitcoin</a>
      </p>

      <p>
        <span>Instagram: </span>
        <a target="_blank" href="https://www.instagram.com/paguecombitcoin">instagram.com/paguecombitcoin</a>
      </p>

      <p>
        <span>Twitter: </span>
        <a target="_blank" href="https://twitter.com/PagueComBitcoin">twitter.com/PagueComBitcoin</a>
      </p>
    </div>

    <span class="divider-border"/>

  </div>

  <ct-user-login-modal
      :isModalVisible="isModalLoginVisible"
      @close="closeLoginModal"
      @successLogin="closeLoginModal"
    />
</div>

</template>

<script>
import currency from '@/filters/currency';
import { mapActions, mapState } from 'vuex';
import CtUserLoginModal from '@/components/Navbar/UserLoginModal';

export default {
  name: 'FaqPagueComBitcoinContent',
  components: {
    CtUserLoginModal,
  },
  filters: {
    currency,
  },
  data () {
    return {
      isModalLoginVisible: false,
    };
  },
  methods: {
    ...mapActions(['openRegisterModal']),
    openLoginModal () {
      this.isModalLoginVisible = true;
    },
    closeLoginModal () {
      this.isModalLoginVisible = false;
    },
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.settings,
      quotation: state => state.settings.ticker,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/themes/variables';

</style>
