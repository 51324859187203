export default {
  app: {
    name: 'Pague com Bitcoin',
  },
  pages: {
    home: 'Home',
    about: 'Quem somos',
    bitcoin: 'O que é Bitcoin',
    terms: 'Termos de uso',
    faq: 'Dúvidas Frequentes',
    services: 'Serviços',
    gateway: 'Gateway',
    contact: 'Contato',
    faqTop: 'FAQ',
    aboutTop: 'Sobre',
  },
  register: {
    steps: {
      form: {
        title: 'Preencha as informações abaixo para efetuar o seu cadastro',
        titleStepTwo: 'Preencha seu endereço abaixo para efetuar o seu cadastro',
        titleStepThree: 'Última etapa, informe o número do seu celular para concluir o cadastro.',
        subTitleStepThree: 'Preencha com um número válido. Enviaremos uma mensagem via {type} para validar seu cadastro.',
        phoneDetails: 'Uma mensagem de confirmação será enviada para este número',
        buttons: {
          register: 'Cadastre-se',
          next: 'Próxima',
        },
      },
      duplicate: {
        title: 'O seu cadastro já se encontra em nosso sistema.',
        contact: 'Em caso de dúvidas ou se estiver com problemas para realizar o seu login, entre em contato pelo e-mail',
        loginTitle: 'Digite o seu login e senha abaixo para utilizar os nosso serviços.',
      },
      tempemail: {
        title: 'O seu e-mail consta em nosso sistema como um e-mail temporário, o que não é aceito para efetuar cadastro em nosso site.',
        contact: 'Em caso de dúvidas ou se estiver com problemas para realizar o seu login, entre em contato pelo e-mail',
        loginTitle: 'Digite o seu login e senha abaixo para utilizar os nosso serviços.',
      },
      verify: {
        greeting: 'Olá',
        registerDone: 'o seu cadastro foi efetuado',
        codeSent: 'Foi enviado ao seu <strong>celular</strong> um código de verificação via <strong>{type}</strong>. Fique atento à sua chegada e o digite no local abaixo para confirmar seu cadastro.',
        resendCode: 'Caso não tenha recebido o código em seu celular ou ele esteja expirado clique em <strong>reenviar</strong>, para recebe-lo novamente.',
        buttons: {
          send: 'Enviar',
          resend: 'Reenviar',
          back: 'Voltar',
        },
      },
      verifyError: {
        title: 'Erro no código de verificação.',
        errorMessage: 'Desculpe, ocorreu um erro no processamento do seu código de verificação, preencha-o novamente.',
      },
      success: {
        title: 'Cadastro finalizado com sucesso.',
        welcomeTo: 'Bem vindo à',
        registerComplete: 'Seu cadastro foi efetuado com sucesso. Você está logado em nosso site e pode continuar navegando e usando nossos serviços normalmente.',
        buttons: {
          continue: 'Continuar',
        },
      },
      validating: {
        title: 'Tudo certo! Obrigado pelas informações!',
        text: 'Por favor, verifique sua caixa de entrada para concluir seu registro.',
        buttons: {
          continue: 'Continuar',
        },
      },
      error: {
        title: 'Falha no envio de informações.',
        errorMessage: 'Houve um erro no envio da sua <br /> solicitação, tente envia-la novamente em <br /> algumas horas.',
        goBack: 'Volte ao formulário no <strong>botão <br /> abaixo</strong>, verifique as informações e envie novamente.',
        buttons: {
          back: 'Voltar',
        },
      },
      phoneError: {
        titleDuplicate: 'Celular já cadastrado.',
        errorMessageDuplicate: 'Este celular já está cadastrado em nosso sistema. Informe um número diferente para prosseguir com a validação do cadastro. <br /><br />Em caso de dúvidas ou problemas para realizar seu cadastro, entre em contato pelo e-mail <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>.',
        titleInvalid: 'Celular inválido.',
        errorMessageInvalid: 'Este número de celular é inválido. Verifique o número informado ou informe um número diferente para prosseguir com a validação do cadastro. <br /><br />Em caso de dúvidas ou problemas para realizar seu cadastro, entre em contato pelo e-mail <a href="mailto:info@paguecombitcoin.com">info@paguecombitcoin.com</a>.',
        goBack: 'Volte ao formulário no <strong>botão <br /> abaixo</strong>, verifique as informações e envie novamente.',
        buttons: {
          back: 'Voltar',
        },
      },
      validationError: {
        title: 'Falha na validação das informações.',
        errorMessage: 'Houve um erro no envio da sua <br /> solicitação, por favor verifique os campos listados abaixo: <br />',
        goBack: 'Volte ao formulário no <strong>botão <br /> abaixo</strong>, verifique as informações e envie novamente.',
        buttons: {
          back: 'Voltar',
        },
      },
      fields: {
        name: 'Nome completo/Razão Social',
        country: 'País',
        kind: 'Tipo de Cadastro',
        document: 'CPF/CNPJ',
        documentExternal: 'Passaporte/DNI',
        email: 'E-mail',
        zipCode: 'CEP',
        address: 'Logradouro',
        complement: 'Complemento',
        number: 'Número',
        district: 'Bairro',
        city: 'Cidade',
        uf: 'Estado',
        phoneCode: 'Código do País',
        phone: 'Número de Celular',
        emailComfirm: 'Confirmação de E-mail',
        password: 'Senha',
      },
    },
  },
  services: {
    title: {
      payment: 'Pagamento de Boleto',
      recharge: 'Recarga de Celular',
      btc: 'Conversão de Bitcoin',
      giftcard: 'Gift Card',
      giftCardTitle: 'Gift Card / Cartão Presente',
      pix: 'Bitcoin por PIX',
      notFoundServices: 'Não há serviços disponíveis nesse momento, tente novamente mais tarde!',
      page: {
        services: {
          title: 'Serviços de pagamento com',
          crypto: 'bitcoins',
        },
        payment: {
          title: 'Pagamento de boleto com',
          crypto: 'Bitcoins',
        },
        shipment: {
          titleOne: 'Conversão de',
          crypto: 'bitcoin',
          titleTwo: 'em real',
        },
        giftcard: {
          titleOne: 'Gift Card com',
          crypto: 'Bitcoin',
        },
        cellPhone: {
          title: 'Recarga de celular',
          crypto: 'Bitcoin',
        },
        pix: {
          titleOne: 'Conversão de',
          crypto: 'Bitcoin',
          titleTwo: 'por PIX',
        },
      },
    },
    addButton: {
      billet: 'Adicionar novo pagamento',
      recharge: 'Adicionar nova recarga de celular',
      shipment: 'Adicionar nova conversão de bitcoins',
      giftCard: 'Adicionar novo gift card',
      pix: 'Adicionar novo PIX',
    },
    removeButton: 'Excluir',
    itemFee: {
      title: 'Taxa de conveniência',
      perItem: 'por item',
    },
    payment: {
      fields: {
        digitableLine: 'Linha Digitável',
        dueDate: 'Vencimento',
        discount: 'Desconto',
        addition: 'Juros/Multa',
        amount: 'Valor',
      },
      placeholder: {
        digitableLine: 'Ex.: 0000000000 00000000 0000000 0 000 00 000000',
        dueDate: 'Ex.: 16/07/2020',
        discount: 'Ex.: 100,00',
        addition: 'Ex.: 100,00',
        amount: 'Ex.: 100,00',
      },
      dueDateAlert: {
        expiredToday: 'Vence hoje',
        expired: 'Boleto vencido',
      },
      alert: {
        title: 'Atenção!',
        expiredToday: 'Transações feitas a partir das 20h, podem não ser confirmadas à tempo para o pagamento ainda hoje. Verifique se há juros ou multa e preencha no campo juros/multa.',
        expired: 'Este boleto está vencido. Verifique se há juros ou multa e preencha no campo juros/multa.',
        learnMore: 'Saiba mais',
      },
      totalValue: 'Valor Total',
      duplicatedBillet: 'Boleto duplicado',
    },
    pix: {
      fields: {
        key: 'Chave Pix',
        keyType: 'Tipo de Chave',
        isThirdParty: 'Pix para terceiros',
        name: 'Titular da conta',
        document: 'Documento',
        email: 'E-mail',
        purpose: 'Finalidade',
        brcode: 'Copia e Cola',
      },
      placeholder: {
        key: 'Chave do Pix',
        keyType: 'CPF, CNPJ, E-mail, etc.',
      },
    },
    sell: {
      fields: {
        bank: 'Banco',
        agency: 'Agência',
        account: 'Conta',
        accountComplement: '(com digito)',
        kind: 'Tipo',
        operation: 'Operação',
        amount: 'Valor',
        isThirdParty: 'Transferência para terceiros',
        name: 'Titular da conta',
        document: 'Documento',
        email: 'E-mail',
        purpose: 'Finalidade',
      },
      placeholder: {
        bank: 'Selecione um banco',
        kind: 'Selecione um tipo',
        agency: 'Ex.: 0000',
        account: 'Ex.: 000007',
        operation: 'Ex.: 0004',
        amount: 'Ex.: 100,00',
        name: 'Nome completo/Razão Social',
        document: 'CPF ou CNPJ do titular',
        email: 'E-mail do titular da conta',
        purpose: 'Selecione uma finalidade',
      },
      alert: 'Somente são aceitas contas bancárias onde próprio cliente seja o titular. Não realizamos a transferência bancárias para contas de terceiros.',
    },
    recharge: {
      fields: {
        ddd: 'DDD',
        number: 'Número',
        operator: 'Operadora',
        amount: 'Valor',
      },
      placeholder: {
        ddd: 'DDD',
        number: 'Ex.: 00000-0000',
        amount: 'Selecione um valor',
        operator: 'Operadora',
      },
    },
    giftcard: {
      fields: {
        operator: 'Fornecedor',
        operatorError: 'Fornecedor',
        card: 'Gift Card',
        quantity: 'Quantidade',
        amount: 'Valor',
      },
      placeholder: {
        operator: 'Escolha seu Fornecedor',
        card: 'Escolha seu Gift Card',
        quantity: 'Ex: 1',
        amount: 'Ex.: 100.00',
      },
      errorQuantity: 'É necessário informar uma quantidade mair que 1',
    },
    checkout: {
      title: 'Resumo do Pedido',
      button: {
        login: 'Login',
        create: 'Cadastre-se',
      },
      wrongUser: 'Não é você?',
      logout: 'Sair',
      finishButton: 'Finalizar pedido',
    },
    summary: {
      title: 'Resumo do pedido',
      quotation: 'Cotação BTC',
      services: 'Itens',
      fee: 'Taxas',
      total: 'Total BRL',
      totalBtc: 'Total Btc',
      quotationObs: 'Os valores de cotação dos BTCs podem se alterar desde o início do processos de pagamento até finalização das operações.',
    },
  },
  invoices: {
    items: {
      status: {
        OPEN: 'Aberto',
        PENDING: 'Pendente',
        CANCELLED: 'Cancelado',
        FINISHED: 'Finalizado',
        PROCESSING: 'Processando',
        ERROR: 'Processando',
      },
    },
  },
  invoiceCheckout: {
    title: 'Realize a Transação de Bitcoins',
    sendBTCTitle: 'Envie exatamente: ',
    valueBTC: 'BTC',
    openWallet: 'Abrir carteira',
    showQRCode: 'Mostrar QR Code',
    hideQRCode: 'Ocultar QR Code',
    expired: 'Seu Pedido Expira em:',
    detailsPayment: 'Valor equivalente em Reais',
  },
  gateway: {
    errorFilesMessage: 'Não foi possivel realizar o upload dos arquivos, possivelmente você atingiu o limite de requisições, aguarde alguns minutos e tente novamente!',
    errorDefaultMessage: 'Erro ao tentar salvar, tente novamente mais tarde ou contate o suporte!',
  },
  shortcutMenu: {
    infoMessage: 'Use os atalhos para escolher os serviços',
    menu: {
      billets: 'Boletos',
      recharge: 'Recarga',
      bitcoin: 'Bitcoin',
      giftCard: 'Gift Card',
      pix: 'PIX',
    },
  },
  validations: {
    messages: {
      fullName: 'É necessário informar o nome completo',
      cpf: 'CPF inválido',
      cnpj: 'CNPJ inválido',
    },
  },
  resetPassword: {
    titlePage: 'Redefinir senha',
    messageSupport: 'Está com dúvidas? A qualquer momento você pode contatar nosso suporte pelo email',
    form: {
      password: 'senha',
      newPassword: 'Nova senha',
      confirmedPassword: 'Confirmação de senha',
      button: 'Redefinir senha',
    },
    alert: {
      messageError: 'Erro ao redefinir sua senha, tente novamente mais tarde',
      messageSuccess: 'Sua senha foi redefinida com sucesso.',
      buttonBackToHome: 'Ir para pague com bitcoin',
    },
    emailReset: {
      title: 'Redefinir senha',
      subtitle: `Informe o endereço de email usado no seu cadastro. Enviaremos um email com instruções para redefinição
      da sua senha`,
      messageSuccess: `Um email foi enviado para redefinição da sua senha. Em caso de dúvidas ou problemas entre em
      contato`,
      messageError: `Erro ao enviar email, tente novamente mais tarde. Em caso de dúvidas ou problemas entre em
      contato`,
      form: {
        email: 'E-mail de cadastro',
        button: 'Enviar',
      },
    },
  },
};
