import About from './views/About.vue';
import App from './views/App.vue';
import Bitcoin from './views/Bitcoin.vue';
import CheckoutPage from './views/CheckoutPage.vue';
import Dashboard from './views/Dashboard.vue';
import Error404 from './views/Error404.vue';
import Error500 from './views/Error500.vue';
import Faq from './views/Faq.vue';
import GatewayPage from './views/GatewayPage.vue';
import HowToUseOurServices from './views/Tutorials/HowToUseOurServices.vue';
import Maintenance from './views/Maintenance.vue';
import ResetPassword from './views/ResetPassword.vue';
import Router from 'vue-router';
import ServiceBillet from './views/ServiceBillet.vue';
import ServiceCellPhone from './views/ServiceCellPhone.vue';
import ServiceGiftCard from './views/ServiceGiftCard.vue';
import ServicePix from './views/ServicePix.vue';
import ServiceShipment from './views/ServiceShipment.vue';
import ServicesPage from './views/ServicesPage.vue';
import StyleGuide from './views/StyleGuide.vue';
import Taxes from './views/Taxes.vue';
import Terms from './views/Terms.vue';
import Vue from 'vue';

Vue.use(Router);

export const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    sitemap: {
      priority: 1,
    },
  },
  {
    path: '/quem-somos',
    name: 'about',
    component: About,
    sitemap: {
      priority: 0.8,
    },
  },
  {
    path: '/gateway',
    name: 'gateway',
    component: GatewayPage,
    sitemap: {
      priority: 0.7,
    },
  },
  {
    path: '/meu-ultimo-pedido',
    name: 'checkout-last',
    component: CheckoutPage,
  },
  {
    path: '/termos-de-uso',
    name: 'terms',
    component: Terms,
    sitemap: {
      priority: 0.6,
    },
  },
  {
    path: '/aplicativo',
    name: 'app',
    component: App,
    sitemap: {
      priority: 0.6,
    },
  },
  {
    path: '/servicos',
    name: 'services',
    component: ServicesPage,
    meta: { hideShortcutMenu: true },
    sitemap: {
      priority: 0.9,
    },
  },
  {
    path: '/pagar-boleto-com-bitcoin',
    name: 'service-billet',
    component: ServiceBillet,
    meta: { hideShortcutMenu: true },
    sitemap: {
      priority: 0.9,
    },
  },
  {
    path: '/recarregar-celular-com-bitcoin',
    name: 'service-cell-phone',
    component: ServiceCellPhone,
    meta: { hideShortcutMenu: true },
    sitemap: {
      priority: 0.9,
    },
  },
  {
    path: '/converter-bitcoins-em-reais',
    name: 'service-shipment',
    component: ServiceShipment,
    meta: { hideShortcutMenu: true },
    sitemap: {
      priority: 0.9,
    },
  },
  {
    path: '/bitcoin-por-pix',
    name: 'service-pix',
    component: ServicePix,
    meta: { hideShortcutMenu: true },
    sitemap: {
      priority: 0.9,
    },
  },
  {
    path: '/comprar-gift-card-com-bitcoin',
    name: 'service-gift-card',
    component: ServiceGiftCard,
    meta: { hideShortcutMenu: true },
    sitemap: {
      priority: 0.9,
    },
  },
  {
    path: '/tutoriais/como-usar-nossos-servicos',
    name: 'tutorials-how-to-use',
    component: HowToUseOurServices,
    sitemap: {
      priority: 0.5,
    },
  },
  {
    path: '/duvidas-frequentes',
    name: 'faq',
    component: Faq,
    sitemap: {
      priority: 0.7,
    },
  },
  {
    path: '/taxas',
    name: 'taxes',
    component: Taxes,
    sitemap: {
      priority: 0.6,
    },
  },
  {
    path: '/o-que-e-bitcoin',
    name: 'bitcoin',
    component: Bitcoin,
    sitemap: {
      priority: 0.5,
    },
  },
  {
    path: '/errors/503',
    name: 'maintenance',
    component: Maintenance,
  },
  {
    path: '/errors/404',
    name: 'error404',
    component: Error404,
  },
  {
    path: '/errors/500',
    name: 'error500',
    component: Error500,
  },
  {
    path: '/checkout/:id',
    name: 'checkout',
    component: CheckoutPage,
    meta: {
      hideTicker: true,
    },
  },
  {
    path: '/style',
    name: 'style',
    component: StyleGuide,
  },
  {
    path: '/reset-password/:token?',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      hideContactTab: true,
      showNavbarSimple: true,
      hideShortcutMenu: true,
    },
  },
  {
    path: '/servicos/boleto',
    redirect: '/pagar-boleto-com-bitcoin',
  },
  {
    path: '/servicos/recarga',
    redirect: '/recarregar-celular-com-bitcoin',
  },
  {
    path: '/servicos/bitcoin',
    redirect: '/converter-bitcoins-em-reais',
  },
  {
    path: '/contato',
    redirect: '/',
  },
  {
    path: '/cadastro',
    redirect: '/',
  },
  {
    path: '/como-pagar-com-bitcoin',
    redirect: '/tutoriais/como-usar-nossos-servicos',
  },
  {
    path: '/noticias',
    redirect: '/quem-somos',
  },
  {
    path: '*',
    component: Error404,
  },
];

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior (to, _, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
